import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';

const FAVORITE_CONTRACTOR_APPLICATION = gql`
  mutation FavoriteContractorApplication($uuid: ID!, $favorite: Boolean) {
    favoriteContractorApplication(uuid: $uuid, favorite: $favorite) {
      uuid
      favorite
    }
  }
`;

class FavoriteContractorApplication extends Component {
  render() {
    const {
      contractorApplication,
    } = this.props;

    const {
      favorite,
      uuid: contractorUuid,
    } = contractorApplication;

    return (
      <Mutation
        key="favorite"
        mutation={FAVORITE_CONTRACTOR_APPLICATION}
        variables={{ favorite: !favorite, uuid: contractorUuid }}
      >
        {(favoriteContractorApplication, { loading: favoriteLoading }) => {
          return (
            <Button
              onClick={favoriteContractorApplication}
              loading={favoriteLoading}
            >
              {favorite ? 'Unfavorite' : 'Favorite'}
              <Icon type="star" theme={favorite ? 'filled' : ''} />
            </Button>
          );
        }}
      </Mutation>
    );
  }
}

export default FavoriteContractorApplication;
