import React from 'react';
import _ from 'lodash';
import ReactJson from 'react-json-view';
import pluralize from 'pluralize';
import styles from './styles.scss';

const ParcelData = ({ parcel }) => {
  const {
    apn,
    dataTreeJsonPdr,
    dataTreeJsonTvr,
  } = parcel;

  let jsonPdr = null;
  try {
    jsonPdr = JSON.parse(dataTreeJsonPdr);
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
  }

  let jsonTvr = null;
  try {
    jsonTvr = JSON.parse(dataTreeJsonTvr);
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
  }

  const rawData = {
    'Report 1': jsonPdr || '(not found)',
    'Report 2': jsonTvr || '(not found)',
  };

  return (
    <div className={styles.parcelData}>
      <div className={styles.apnHeader}>Data for APN: {apn}</div>
      <ReactJson src={rawData} collapsed />
    </div>
  );
};

const RawData = (props) => {
  const { data } = props;
  const { application } = data;
  const { parcels } = application;

  return (
    <div>
      <div>Data requested for {pluralize('parcel', parcels.length, true)}.</div>
      {parcels.map((parcel) => <ParcelData key={parcel.uuid} parcel={parcel} />)}
    </div>
  );
};

export default RawData;
