import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { routes } from '@usurp-power/aqua-silver';
import { Button, Form, Input, Select, notification } from 'antd';
import CURRENT_USER_ONBOARDING_PROGRESS from '$queries/currentUserOnboardingProgress';
import history from '$lib/history';

import styles from './styles.scss';

const { Option } = Select;

const UPDATE_CONTRACTOR_PROFILE = gql`
  mutation UpdateContractorProfile($input: UpdateContractorProfileInput!) {
    updateContractorProfile(input: $input) {
      contractorProfile {
        uuid
        company
        contactName
        address
        lat
        lng
        phone
        email
        scopeOfWork
        url
        locality
        postalCode
        mapUrl
        route
        state
        streetNumber
        range
      }
    }
  }
`;

const handleUpdateContractorProfile = ({ form, updateContractorProfileMutation }) => (e) => {
  e.preventDefault();

  form.validateFields(async (err, values) => {
    const {
      company,
      contactName,
      address,
      lat,
      lng,
      phone,
      email,
      url,
      locality,
      postalCode,
      route,
      state,
      streetNumber,
      scopeOfWork,
      range,
    } = values;

    await updateContractorProfileMutation({
      variables: {
        input: {
          company,
          contactName,
          address,
          lat,
          lng,
          phone,
          email,
          url,
          scopeOfWork,
          locality,
          postalCode,
          route,
          state,
          streetNumber,
          range,
        },
      },
      refetchQueries: [{ query: CURRENT_USER_ONBOARDING_PROGRESS }]
    });

    notification.info({
      message: 'Profile updated',
      description: 'You can change your profile information any time.'
    });

    history.push(routes.contractor.projects.overview);
  });
};

function ContractorProfile(props) {
  const { currentUser, form } = props;
  const contractorProfile = currentUser.contractorProfile || {};
  const {
    company,
    contactName,
    address,
    phone,
    email,
    url,
    range,
    scopeOfWork,
  } = contractorProfile;

  const { getFieldDecorator } = form;

  const [updateContractorProfileMutation, { loading: updateContractorProfileMutationLoading }] = useMutation(UPDATE_CONTRACTOR_PROFILE);

  return (
    <div className={styles.layout}>
      <div className={styles.title}>Your public profile</div>
      <div className={styles.subtitle}>The information that you provide below will be shown to property owners that are searching for solution providers.</div>
      <div className={styles.contactInfoForm}>
        <Form onSubmit={handleUpdateContractorProfile({ form, updateContractorProfileMutation })}>
          <Form.Item label="Your name">
            {getFieldDecorator('contactName', {
              rules: [{ required: true }],
              initialValue: contactName,
            })(
              <Input
                data-aaa="contactName"
                disabled={updateContractorProfileMutationLoading}
                placeholder="Bob Robson"
              />
            )}
          </Form.Item>
          <Form.Item label="Company name">
            {getFieldDecorator('company', {
              rules: [{ required: true }],
              initialValue: company,
            })(
              <Input
                data-aaa="company"
                disabled={updateContractorProfileMutationLoading}
                placeholder="Bob's Energy"
              />
            )}
          </Form.Item>
          <Form.Item label="Company address">
            {getFieldDecorator('address', {
              rules: [{ required: true }],
              initialValue: address,
            })(
              <Input
                data-aaa="address"
                disabled={updateContractorProfileMutationLoading}
                placeholder="717 Grand View Ave, San Francisco, CA 94114"
              />
            )}
          </Form.Item>
          <Form.Item label="Range">
            {getFieldDecorator('range', {
              rules: [{ required: true }],
              initialValue: (range || 'local'),
            })(
              <Select
                data-aaa="range"
                disabled={updateContractorProfileMutationLoading}
                placeholder="Click to select"
              >
                <Option key="local">Local - within 100mi</Option>
                <Option key="regional">Regional - within 500mi</Option>
                <Option key="national">National</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Company phone">
            {getFieldDecorator('phone', {
              rules: [],
              initialValue: phone,
            })(
              <Input
                data-aaa="phone"
                disabled={updateContractorProfileMutationLoading}
                placeholder="415-518-3926"
              />
            )}
          </Form.Item>
          <Form.Item label="Company website">
            {getFieldDecorator('url', {
              rules: [],
              initialValue: url,
            })(
              <Input
                data-aaa="url"
                disabled={updateContractorProfileMutationLoading}
                placeholder="https://bobs-energy.com"
              />
            )}
          </Form.Item>
          <Form.Item label="Company email">
            {getFieldDecorator('email', {
              rules: [],
              initialValue: email || currentUser.email,
            })(
              <Input
                data-aaa="email"
                disabled={updateContractorProfileMutationLoading}
                placeholder="bob@bobs-energy.com"
              />
            )}
          </Form.Item>
          <Form.Item label="Projects supported">
            {getFieldDecorator('scopeOfWork', {
              rules: [],
              initialValue: (scopeOfWork || []),
            })(
              <Select
                data-aaa="scopeOfWork"
                disabled={updateContractorProfileMutationLoading}
                mode="multiple"
                placeholder="Click to select"
              >
                <Option key="Renewables">Renewables</Option>
                <Option key="Envelope">Envelope</Option>
                <Option key="Lighting">Lighting</Option>
                <Option key="Landscaping">Landscaping</Option>
                <Option key="Hot Water">Hot Water</Option>
                <Option key="HVAC">HVAC</Option>
                <Option key="Water">Water</Option>
                <Option key="Other">Other</Option>
              </Select>
            )}
          </Form.Item>
          <Button
            loading={updateContractorProfileMutationLoading}
            type="primary"
            htmlType="submit"
          >
            Update
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Form.create()(ContractorProfile);
