import React from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import classNames from 'classnames';
import {
  aggregatedInputs,
  rowLabels,
  getTextForApnSituation,
} from './inputFormats';
import styles from './styles.scss';

const ValueRenderer = ({ name, val }) => {
  if (val === null || typeof val === 'undefined') {
    // return <span className={styles.layout__values__null}>(not provided)</span>;
    return null;
  }

  if (name === 'apnSituation') {
    return getTextForApnSituation(val);
  }

  if (typeof val === 'number' && val > 100) {
    const shouldRenderDollar = name === 'estimatedProjectCost'
      || name === 'propertyMarketValue';
    return shouldRenderDollar
      ? numeral(val).format('$0,0')
      : numeral(val).format('0');
  }

  if (val === 'yes' || val === true || val === 'true') {
    return 'yes';
  }
  if (val === 'no' || val === false || val === 'false') {
    return 'no';
  }

  return val.toString();
};

const ValuesList = (props) => {
  const { data } = props;

  console.log(data);

  return (
    <div>
      {data.usurp && rowLabels.map((rowLabelEntry) => {
        const { title, fields } = rowLabelEntry;
        return (
          <div key={title} className={styles.section}>
            <div className={styles.title}>{title}</div>
            <div className={styles.layout__inputs}>
              <div className={classNames(styles.layout__inputs__rowHeader, styles.layout__inputs__row, styles.layout__inputs__row__contractor)}>
                <div>Field</div>
                <div><strong>Source data</strong></div>
              </div>
              <div>
                {fields.map((field) => {
                  const { label, key } = field;
                  const aggregateVal = typeof aggregatedInputs[key] === 'function' ? aggregatedInputs[key](data) : _.get(data, aggregatedInputs[key]);
                  const rowClasses = classNames(styles.layout__inputs__row, styles.layout__inputs__row__contractor);

                  if (!aggregateVal) {
                    return null;
                  }

                  return (
                    <div key={key} className={rowClasses}>
                      <div>{label}</div>
                      <div>
                        {key === 'TODO' ? <b>TODO</b> : <ValueRenderer name={key} val={aggregateVal} />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ValuesList;
