import React, { Component } from 'react';
import { withApollo } from '@apollo/react-hoc';
import { Base64 } from 'js-base64';
import SpinnerError from '$components/common/SpinnerError';
import history from '$lib/history';
import storage from '$lib/storage';


class Magic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    const { client } = this.props;
    const { params: { data } } = this.props.match;

    let d;
    let v;

    try {
      const decoded = Base64.decode(data);
      const parsed = JSON.parse(decoded);
      ({ d, v } = parsed);
    } catch (e) {
      this.setState({
        error: true,
      });
      return;
    }

    switch (v) {
      case 1: {
        const { a } = d;
        switch (a) {
          case 'sign-in': {
            const { e: email, t: accessToken } = d;
            storage.setAuth({ email, accessToken });
            client.resetStore();
            setTimeout(() => {
              history.push('/');
            }, 1000);
            break;
          }
          default: {
            this.setState({
              error: true,
            });
          }
        }
        break;
      }
      default: {
        this.setState({
          error: true,
        });
      }
    }
  }

  render() {
    const { error } = this.state;

    return (
      <SpinnerError error={error} />
    );
  }
}

export default withApollo(Magic);
