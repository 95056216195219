import React, { Component } from 'react';
import { Card, Form, Icon, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { ThemeContext } from '$lib/context';
import unetyLogoSrc from '$assets/logoWithText.png';

import styles from '../styles.scss';

class UnwrappedForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.signIn({ variables: { ...values } });
      }
    });
  }

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug } = theme;

          const logoSrc = (slug === 'ecoamerica') ? 'https://storage.googleapis.com/public-assets.usurppower.com/theme/ecoamerica.png' : unetyLogoSrc;

          return (
            <Card
              actions={[
                <Link key="signup" to={routes.auth.signUp}>Sign up</Link>,
                <Link key="forgotpassword" to={routes.auth.forgotPassword}>Forgot password</Link>,
              ]}
            >
              <Form onSubmit={this.handleSubmit} className={styles.form}>
                <Form.Item>
                  <img className={styles.logo} src={logoSrc} />
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email!' }],
                  })(
                    <Input autoComplete="username" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your password!' }],
                  })(
                    <Input autoComplete="current-password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button data-aaa="signInSubmit" type="primary" htmlType="submit" block loading={loading}>Sign in</Button>
                </Form.Item>
              </Form>
            </Card>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default Form.create()(UnwrappedForm);
