import React, { PureComponent } from 'react';

import ProjectScreenLayout from '../ProjectScreenLayout';

import {
  ProjectApplicationForm,
} from '../../project';

import styles from './styles.scss';

class FundingOptionsScreen extends PureComponent {
  render() {
    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          return (
            <div className={styles.layout}>
              <div className={styles.title}>Preferences for funding options</div>
              <div className={styles.disclaimer}>
                Default values have been prefilled based on a combination of industry norms and our analysis of the property. You can change your responses and reassess funding options at any time.
              </div>
              <ProjectApplicationForm values={contractorApplication} />
            </div>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default FundingOptionsScreen;
