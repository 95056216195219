import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Icon } from 'antd';

import styles from './multiStep.scss';

import {
  ApplicantDetailsForm
} from '../../Forms';

class Step1 extends PureComponent {
  static propTypes = {
    buttonLoading: PropTypes.bool,
    values: PropTypes.shape({}),
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
  };

  static defaultProps = {
    disabbuttonLoadingleNext: false,
    values: {},
    onNext: () => {},
    onPrev: () => {},
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.onNext(values);
      }
    });
  }

  render() {
    const {
      buttonLoading,
      form,
      values,
    } = this.props;

    const {
      getFieldDecorator
    } = form;

    return (
      <Form onSubmit={this.handleSubmit}>

        <ApplicantDetailsForm
          form={form}
          getFieldDecorator={getFieldDecorator}
          values={values}
        />

        <div className={styles.formActions} style={{ justifyContent: 'flex-end' }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              disabled={buttonLoading}
              type="primary"
              htmlType="submit"
            >
              Next Step
              <Icon type={buttonLoading ? 'loading' : 'right'} />
            </Button>
          </Form.Item>
        </div>
      </Form>
    );
  }
}

export default Form.create()(Step1);
