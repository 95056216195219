import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { hot, setConfig } from 'react-hot-loader';
import { Router, Route } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { setContext } from 'apollo-link-context';
import { init } from '@sentry/browser';
import { notification } from 'antd';
import gql from 'graphql-tag';
import update from 'immutability-helper';

import history from '$lib/history';
import storage from '$lib/storage';
import RootBoundary from '$components/common/RootBoundary';
import App from '$components/App';

import 'antd/dist/antd.less';
import './styles/general.less';
import './styles/global.scss';
import './styles/theme--ecoamerica.scss';
import '@justinpincar/rc-slider/assets/index.css';

import introspectionQueryResultData from '../fragmentTypes.json';

const GRAPHQL_URL = process.env.GRAPHQL_URL; // eslint-disable-line prefer-destructuring
const RAVEN_DSN = process.env.RAVEN_DSN; // eslint-disable-line prefer-destructuring
const STAGE = process.env.STAGE; // eslint-disable-line prefer-destructuring

setConfig({ logLevel: 'debug' });

if (RAVEN_DSN) {
  init({
    dsn: RAVEN_DSN,
    environment: STAGE,
  });
}


const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const clientCache = new InMemoryCache({
  dataIdFromObject: (o) => o.uuid || null,
  fragmentMatcher,
});

const stateLink = withClientState({
  cache: clientCache,
  resolvers: {
    Mutation: {
      updateQuotes: (parent, variables, { cache }) => {
        const { quotes, uuid } = variables;
        const id = uuid;
        const fragment = gql`
          fragment quoteStatus on LenderApplication {
            quoteStatus {
              quotes {
                applicationUuid
                uuid
                ask
                term
                rate
                prepayment
                fees
                message
              }
            }
          }
        `;
        const quotesWithTypename = quotes.map((quote) => {
          return {
            ...quote,
            __typename: 'ApplicationQuoteInput',
          };
        });
        const data = {
          __typename: 'LenderApplication',
          quoteStatus: {
            __typename: 'QuoteStatus',
            quotes: quotesWithTypename,
          },
        };
        cache.writeFragment({ fragment, id, data });
        return null;
      },
      toggleApplications: (_, variables, { cache }) => {
        const { uuids, checked } = variables;
        const ids = uuids;
        const fragment = gql`
          fragment applicationStatus on LenderApplication {
            applicationStatus {
              isChecked
            }
          }
        `;
        ids.forEach((id) => {
          const currentData = cache.readFragment({ fragment, id });
          const data = update(currentData, {
            applicationStatus: {
              isChecked: { $set: typeof checked === 'undefined'
                ? !currentData.applicationStatus.isChecked
                : checked
              }
            },
          });
          cache.writeFragment({ fragment, id, data });
        });
        return null;
      },
    },
  },
  defaults: {
    applicationStatus: {
      __typename: 'ActiveApplicationStatus',
      isChecked: false,
    },
    quoteStatus: {
      __typename: 'QuoteStatus',
      quotes: [],
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const tempAuth = storage.tempAuth.get();
  const auth = storage.getAuth();
  const authToUse = tempAuth || auth;
  const { email, accessToken } = authToUse;
  return {
    headers: {
      ...headers,
      'USURP-ACCESS-TOKEN': accessToken,
      'USURP-USER-EMAIL': email,
    },
  };
});

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'same-origin'
});

const ignoreErrorOperations = [
  'FindParcels',
];

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.info('[GraphQL error]', { message, locations, path }); // eslint-disable-line no-console
          if (ignoreErrorOperations.indexOf(operation.operationName) === -1) {
            if (message === 'Unauthorized') {
              window.location.reload();
              return;
            }

            notification.error({
              message: 'Error',
              description: message,
            });
          }
        });
      }
      if (networkError) {
        console.log('[Network error]', networkError); // eslint-disable-line no-console
      }
    }),
    stateLink,
    authLink,
    httpLink,
  ]),
  cache: clientCache,
});

class Root extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <RootBoundary>
          <Router history={history}>
            <Route path="/" component={App} />
          </Router>
        </RootBoundary>
      </ApolloProvider>
    );
  }
}

export default hot(module)(Root);
