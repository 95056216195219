import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import { products } from '@usurp-power/aqua-silver';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import styles from './styles.scss';

const STAGE = process.env.STAGE; // eslint-disable-line prefer-destructuring
const STRIPE_PK = process.env.STRIPE_PK; // eslint-disable-line prefer-destructuring
const stripe = window.Stripe(STRIPE_PK);
const { PRODUCTS } = products({ stage: STAGE });

const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input) {
      sessionId
      errors {
        detail
      }
    }
  }
`;

const createCheckoutSession = async ({ client, product }) => {
  const { priceId } = product;
  const { data } = await client.mutate({
    mutation: CREATE_CHECKOUT_SESSION,
    variables: {
      input: {
        priceId,
      }
    },
  });

  stripe.redirectToCheckout({
    sessionId: data.createCheckoutSession.sessionId
  });
};

const Tile = (props) => {
  const [loading, setLoading] = useState(false);

  const {
    product,
    client,
    disabled,
    setDisabled,
    user,
  } = props;

  const { planType } = user;

  const {
    name,
    type,
    price,
    description,
    priceId,
  } = product;

  const onClick = () => {
    setLoading(true);
    setDisabled(true);
    createCheckoutSession({
      client,
      product,
    });
  };

  return (
    <div className={classnames(styles.tile, (planType === type) && styles.selected)}>
      <div className={styles.planName}>{name}</div>
      <div>{price}</div>
      <div>{description}</div>
      {!planType && (
        <Button className={styles.button} onClick={onClick} disabled={disabled} loading={loading}>Subscribe</Button>
      )}
    </div>
  );
};

const PlanTiles = (props) => {
  const { client, user } = props;
  const [disabled, setDisabled] = useState(false);

  return (
    <div>
      <Tile
        product={PRODUCTS.standard}
        client={client}
        disabled={disabled}
        user={user}
        setDisabled={setDisabled}
      />
      <Tile
        product={PRODUCTS.premium}
        client={client}
        disabled={disabled}
        user={user}
        setDisabled={setDisabled}
      />
      <div className={styles.tile}>
        <div className={styles.planName}>Custom</div>
        <div>Pricing varies</div>
        <div>A plan tailored to fit your needs</div>
        <a href="mailto:sales@unety.io?subject=Interested in a custom Unety plan" target="_blank" rel="noopener noreferrer">
          <Button className={styles.button}>Contact us</Button>
        </a>
      </div>
    </div>
  );
};

export default withApollo(PlanTiles);
