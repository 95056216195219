import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Divider } from 'antd';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { routes } from '@usurp-power/aqua-silver';
import { PROPERTY_TYPE_MAPPINGS } from '$lib/filters';
import Refine from './Refine';
import ListItem from './ListItem';
import styles from './styles.scss';

const TOGGLE_APPLICATIONS = gql`
  mutation ToggleApplications($uuids: [String!], $checked: Boolean) {
    toggleApplications(uuids: $uuids, checked: $checked) @client
  }
`;

const FILTER_FIELDS = [{
  field: 'usurp.propertyType',
  name: 'Property types',
  nameFn: (val) => PROPERTY_TYPE_MAPPINGS[val],
}, {
  field: 'property.state',
  name: 'State',
  nameFn: (val) => val,
}, {
  field: 'application.financingType',
  name: 'Financing types',
  nameFn: (val) => val,
}];

const filterableFields = FILTER_FIELDS.map(({ field }) => field);

const getFilters = ({ items }) => {
  const filters = [];

  FILTER_FIELDS.forEach((filterField) => {
    const { field, nameFn } = filterField;
    const rawValues = items.map((item) => _.get(item, field));
    const uniqRawValues = _.uniq(rawValues);

    const unsortedValues = uniqRawValues.map((value) => {
      const fieldName = nameFn(value);
      return {
        name: fieldName,
        value,
        isSelected: true,
      };
    });
    const options = _.sortBy(unsortedValues, 'name');

    const filter = {
      field: filterField.field,
      name: filterField.name,
      options,
    };

    filters.push(filter);
  });

  return filters;
};

const filterItems = ({ filters, items, minAsk, maxAsk }) => {
  const itemsWithinAsks = items.filter((item) => ((item.application.ask >= minAsk) && (item.application.ask <= maxAsk)));

  const filtersByFields = _.keyBy(filters, 'field');
  const fields = filters.map((filter) => filter.field);
  return itemsWithinAsks.filter((item) => {
    return _.every(fields, (field) => {
      const filter = filtersByFields[field];
      const { options } = filter;
      const optionsByValue = _.keyBy(options, 'value');
      const value = _.get(item, field);
      const option = optionsByValue[value];
      return option.isSelected;
    });
  });
};

const getFiltersWithCounts = ({ filters, items, filteredItems, minAsk, maxAsk }) => {
  const filtersWithCounts = _.cloneDeep(filters);

  filtersWithCounts.forEach((filter) => {
    const { field, options } = filter;
    options.forEach((option) => {
      const { value } = option;
      const tempFilters = _.cloneDeep(filters);
      const tempFiltersByField = _.keyBy(tempFilters, 'field');
      const tempFilter = tempFiltersByField[field];
      const tempOptionsByValue = _.keyBy(tempFilter.options, 'value');
      const tempOption = tempOptionsByValue[value];
      tempOption.isSelected = !tempOption.isSelected;

      const tempFilteredItems = filterItems({ filters: tempFilters, items, minAsk, maxAsk });
      option.count = Math.abs(tempFilteredItems.length - filteredItems.length); // eslint-disable-line no-param-reassign
    });
  });

  return filtersWithCounts;
};

class ApplicationsNew extends Component {
  constructor(props) {
    super(props);

    const { items } = props;


    const {
      minAsk,
      maxAsk
    } = this.getAskMinMax(items);

    const filters = getFilters({ items });
    const filtersWithCounts = getFiltersWithCounts({
      filters,
      items,
      filteredItems: items,
      minAsk,
      maxAsk,
    });

    this.state = {
      activeFilters: {},
      checked: [],
      filteredItems: items,
      filters,
      filtersWithCounts,
      minAsk,
      maxAsk,
    };

    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleClearActiveFilters = this.handleClearActiveFilters.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleItemChecked = this.handleItemChecked.bind(this);
    this.handlePrepareQuoteClick = this.handlePrepareQuoteClick.bind(this);
  }

  getAskMinMax(items) {
    const minAsk = (items.length === 0) ? null : _.minBy(items, (item) => item.application.ask).application.ask;
    const maxAsk = (items.length === 0) ? null : _.maxBy(items, (item) => item.application.ask).application.ask;

    return {
      minAsk,
      maxAsk
    };
  }

  filterItems({ activeFilters, items }) {
    const hasOptions = filterableFields.filter((key) => Array.isArray(activeFilters[key]) && activeFilters[key].length > 0).length > 0;
    let newItems = hasOptions
      ? items.filter((item) => {
        return Object.entries(activeFilters).find(([field, values]) => {
          if (field === 'application.ask') {
            return false;
          }
          const itemValue = _.get(item, field); // eslint-disable-line no-shadow
          return values.indexOf(itemValue) !== -1;
        });
      })
      : items;

    if (activeFilters['application.ask']) {
      const [min, max] = activeFilters['application.ask'];
      newItems = newItems.filter((item) => {
        const itemValue = _.get(item, 'application.ask');
        return itemValue >= min && itemValue <= max;
      });
    }

    return newItems;
  }

  handleItemChecked(uuid) {
    const { checked } = this.state;
    const uuidIndex = checked.indexOf(uuid);

    const newChecked = uuidIndex === -1
      ? [...checked, uuid]
      : [
        ...checked.slice(0, uuidIndex),
        ...checked.slice(uuidIndex + 1)
      ];

    this.setState(() => ({
      checked: newChecked,
    }));
  }

  handleCheckAll() {
    const { filteredItems } = this.state;
    this.setState(() => ({
      checked: filteredItems.map(({ uuid }) => uuid)
    }));
  }

  handleClearActiveFilters() {
    const { items } = this.props;
    this.setState(() => ({
      ...this.getAskMinMax(items),
      activeFilters: {},
      checked: [],
      filteredItems: items,
    }));
  }

  handleFilterChange(toggleApplications, field, options) {
    const { items } = this.props;
    const { activeFilters } = this.state;
    let newAskLabel = {};
    const newActiveFilters = {
      ...activeFilters,
      [field]: options,
    };

    const newItems = this.filterItems({
      activeFilters: newActiveFilters,
      items
    });

    if (newActiveFilters['application.ask']) {
      const [min, max] = newActiveFilters['application.ask'];
      newAskLabel = {
        minAsk: min,
        maxAsk: max
      };
    }

    this.setState(() => ({
      activeFilters: newActiveFilters,
      checked: [],
      filteredItems: newItems,
      ...newAskLabel,
    }));
  }

  handlePrepareQuoteClick() {
    const { checked } = this.state;
    this.props.history.push({
      pathname: routes.lender.quote,
      state: {
        checkedApplications: checked
      }
    });
  }

  render() {
    const { currentUser, items } = this.props;
    const { lenderPreferences } = currentUser;

    if (items.length === 0) {
      return (
        <div>
          There are no new applications at this time.
        </div>
      );
    }

    const {
      activeFilters,
      checked,
      filteredItems,
      filtersWithCounts,
      minAsk,
      maxAsk
    } = this.state;

    const someChecked = (checked.length > 0);
    const indeterminate = someChecked && (checked.length < filteredItems.length);
    const allChecked = (checked.length === filteredItems.length);

    return (
      <div>

          <>
            <Mutation mutation={TOGGLE_APPLICATIONS}>
              {(toggleApplications) => (
                <div>
                  <Refine
                    filters={filtersWithCounts}
                    items={items}
                    minAsk={minAsk}
                    maxAsk={maxAsk}
                    values={activeFilters}
                    onFilterChange={this.handleFilterChange.bind(this, toggleApplications)}
                    onClearFilters={this.handleClearActiveFilters}
                  />
                  <Divider />
                  <div className={styles.resultsHeader}>
                    <div className={styles.resultsHeader__title}>{filteredItems.length} results found</div>
                    { filteredItems.length > 0 && (
                      <Button
                        disabled={!someChecked}
                        type="primary"
                        onClick={this.handlePrepareQuoteClick.bind(this, toggleApplications)}
                      >
                        Prepare quotes
                      </Button>
                    )}
                  </div>

                  { filteredItems.length > 0 && (
                    <div className={styles.actions}>
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={this.handleCheckAll.bind(this, toggleApplications)}
                        checked={allChecked}
                      >
                        Check all
                      </Checkbox>
                    </div>
                  )}

                  <div>
                    {filteredItems.length > 0
                      ? _.map(filteredItems, (item) => {
                        return (
                          <ListItem
                            key={item.uuid}
                            checked={Boolean(checked.indexOf(item.uuid) !== -1)}
                            item={item}
                            lenderPreferences={lenderPreferences}
                            onCheckChange={this.handleItemChecked.bind(this, item.uuid)}
                          />
                      );
                    }) : (
                       <div>No results.</div>
                     )}
                  </div>
                  { filteredItems.length > 0 && (
                    <div style={{ textAlign: 'right', marginTop: '24px' }}>
                      <Button
                        disabled={!someChecked}
                        type="primary"
                        onClick={this.handlePrepareQuoteClick.bind(this, toggleApplications)}
                      >
                        Prepare quotes
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Mutation>
          </>

      </div>
    );
  }
}

export default withRouter(ApplicationsNew);
