const getTextForAvailability = (availability) => {
  if (availability === 'unavailable') {
    return 'Not available';
  }

  if (availability === 'partial') {
    return 'Partially available';
  }

  return 'Available';
};

module.exports = getTextForAvailability;
