import _ from 'lodash';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import ListItemComponent from './ListItemComponent';

const UPDATE_QUOTES = gql`
  mutation UpdateQuotes($uuid: String!, $quotes: [ApplicationQuoteInput]!) {
    updateQuotes(uuid: $uuid, quotes: $quotes) @client
  }
`;

class ListItem extends Component {
  static propTypes = {
    messageText: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    messageText: '',
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      messageText: this.props.messageText,
    };


    this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  handleMessageChange = ({ e, uuid }) => {
    const messageText = e.target.value;
    this.setState({
      messageText,
    });

    const { item: application, mutate: updateQuotes } = this.props;
    const { quotes } = application.quoteStatus;
    const nextQuotes = _.cloneDeep(quotes);
    nextQuotes.forEach((quote) => {
      quote.message = messageText;
    });

    updateQuotes({
      variables: {
        quotes: nextQuotes,
        uuid,
      },
    });
  }

  handleNumberChange(evt) {
    const [whole, decimal] = evt.target.value.toString().split('.');
    if (decimal) {
      const decimalTrimmed = decimal.substring(0, 2);
      return `${whole}.${decimalTrimmed}`;
    }
    return evt.target.value;
  }


  handleRemoveQuote = (uuid, i) => {
    const updateQuotes = this.props.mutate;
    const { quotes } = this.props.item.quoteStatus;
    const nextQuotes = _.cloneDeep(quotes);
    nextQuotes.splice(i, 1);
    updateQuotes({
      variables: {
        quotes: nextQuotes,
        uuid,
      },
    });
  };

  handleAddQuote = ({ uuid }) => {
    const { item: application, generateNewQuote, mutate: updateQuotes } = this.props;
    const { quotes } = application.quoteStatus;
    const nextQuotes = _.cloneDeep(quotes);
    nextQuotes.push(generateNewQuote({ application: application.application }));

    setTimeout(() => {
      Array.from(document.querySelectorAll('[name="rate"]')).forEach((elem) => {
        elem.value = parseFloat(elem.value).toFixed(2); // eslint-disable-line no-param-reassign
      });
    });

    updateQuotes({
      variables: {
        quotes: nextQuotes,
        uuid,
      },
    });
  };

  handleQuoteInputChange = ({ e, i, uuid }) => {
    if (!e.target || !e.target.name) {
      return null;
    }
    const updateQuotes = this.props.mutate;
    const { target } = e;
    if (!target) {
      return null;
    }
    const { name, value } = target;

    const { quotes } = this.props.item.quoteStatus;
    const nextQuotes = _.cloneDeep(quotes);
    const val = (() => {
      if (name === 'rate') {
        return parseFloat(value).toFixed(2);
      }

      if (name === 'ask') {
        return numeral(value).value();
      }

      return value;
    })();

    if (name === 'rate') {
      setTimeout(() => {
        const elem = document.querySelectorAll(`[name="${name}"]`)[i];
        elem.value = parseFloat(val).toFixed(2);
        const evt = new Event('change');
        elem.dispatchEvent(evt);
      });
    }
    nextQuotes[i][name] = val;

    updateQuotes({
      variables: {
        quotes: nextQuotes,
        uuid,
      },
    });

    return false;
  }

  render() {
    const { lenderPreferences } = this.props;
    return (
      <ListItemComponent
        {...this.props}
        {...this.state}
        onAddQuote={this.handleAddQuote.bind(this)}
        onMessageChange={this.handleMessageChange}
        onNumberChange={this.handleNumberChange.bind(this)}
        onQuoteInputChange={this.handleQuoteInputChange.bind(this)}
        onRemoveQuote={this.handleRemoveQuote.bind(this)}
        lenderPreferences={lenderPreferences}
      />
    );
  }
}

export default graphql(UPDATE_QUOTES)(ListItem);
