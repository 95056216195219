import gql from 'graphql-tag';

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      uuid
      email
      nickname
      fullName
      lenderPreferences
      planCredits
      planExpiresAt
      planStatus
      planType
      status
      stripeCustomerId
      tier
      tierUpdatedAt
      referralCode
      type
      grants {
        scope
        targetType
        targetUuid
      }
      isEnterprise

      contractorProfile {
        uuid
        company
        contactName
        address
        lat
        lng
        phone
        email
        scopeOfWork
        url
        locality
        postalCode
        mapUrl
        route
        state
        streetNumber
        range
      }

      organization {
        uuid
        name
        slug
      }

      onboardingProgress {
        progress
        nextActionMessage
        nextActionUrl
      }
    }
  }
`;

export default CURRENT_USER;
