import React, { PureComponent } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { withApollo } from '@apollo/react-hoc';

import gql from 'graphql-tag';
import styles from './button.scss';

const SEND_FEEDBACK = gql`
  mutation ContractorApplicationSendReport($issue: String!, $message: String!, $url: String!, $prevUrl: String!){
    sendFeedbackEmail(issue: $issue, message: $message, url: $url, prevUrl: $prevUrl) {
      success
    }
  }
`;

class FeedbackButton extends PureComponent {
  constructor(props) {
    super(props);

    this.issueFieldRef = React.createRef();

    this.state = {
      formSubmitting: false,
      modalOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModalVisibility = this.toggleModalVisibility.bind(this);
  }

  handleSubmit(evt) {
    evt.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState(() => ({
          formSubmitting: true,
        }));
        const { data, error } = await this.props.client.mutate({
          mutation: SEND_FEEDBACK,
          variables: {
            ...values,
            url: window.location.href,
            prevUrl: window.document.referrer,
          }
        });

        const notificationType = !data.sendFeedbackEmail.success || error ? 'error' : 'success';
        notification[notificationType]({
          message: notificationType === 'error'
            ? 'There was an error:'
            : 'Thank you for your feedback',
          description: notificationType === 'error'
            ? error || ''
            : 'We\'ll get back to you shortly.'
        });

        this.setState(() => ({
          formSubmitting: false,
          modalOpen: false
        }));
      }
    });
  }

  toggleModalVisibility(modalOpen = true) {
    if (modalOpen) {
      setTimeout(() => {
        if (this.issueFieldRef.current) {
          this.issueFieldRef.current.focus();
        }
      }, 300);

      this.props.form.resetFields();
    }
    this.setState(() => ({
      modalOpen
    }));
  }

  render() {
    const {
      formSubmitting,
      modalOpen,
    } = this.state;

    const {
      getFieldDecorator
    } = this.props.form;

    const okButtonProps = {
      form: 'feedbackMessageForm',
      htmlType: 'submit',
      key: 'submit',
      loading: formSubmitting,
    };

    return (
      <div>
        <Modal
          okText="Send"
          title="Contact us"
          visible={modalOpen}
          okButtonProps={okButtonProps}
          onOk={this.handleOk}
          onCancel={this.toggleModalVisibility.bind(this, false)}
        >
          <p>Need help? Have any feedback or suggestions for our platform? Fill out the form below and we&apos;ll get back to you soon.</p>
          <Form
            id="feedbackMessageForm"
            onSubmit={this.handleSubmit}
          >
            <Form.Item label="Summary">
              {getFieldDecorator('issue', {
                rules: [{ required: true }],
              })(
                <Input
                  ref={this.issueFieldRef}
                />
              )}

            </Form.Item>
            <Form.Item label="Details">
              {getFieldDecorator('message', {
                rules: [{ required: true }],
              })(
                <Input.TextArea />
              )}
            </Form.Item>
          </Form>
        </Modal>

        <Button
          className={styles.feedbackButton}
          icon="message"
          shape="circle"
          size="large"
          type="primary"
          onClick={this.toggleModalVisibility.bind(this, true)}
        />
      </div>
    );
  }
}


export default Form.create({ name: 'register' })(withApollo(FeedbackButton));
