import gql from 'graphql-tag';

const CURRENT_USER_ONBOARDING_PROGRESS = gql`
  query CurrentUser {
    currentUser {
      uuid
      tier
      tierUpdatedAt
      onboardingProgress {
        progress
        nextActionMessage
        nextActionUrl
      }
    }
  }
`;

export default CURRENT_USER_ONBOARDING_PROGRESS;
