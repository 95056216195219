import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { ThemeContext } from '$lib/context';
import SpinnerError from '$components/common/SpinnerError';
import getTheme from './getTheme';
import styles from './styles.scss';

const CURRENT_THEME = gql`
  query CurrentTheme($input: CurrentThemeInput) {
    currentTheme(input: $input) {
      theme {
        slug
        organizationUuid
        logoUrl
        contractorTagline
      }
    }
  }
`;

class Theme extends Component {
  render() {
    const { children } = this.props;

    const theme = getTheme(window.location);

    return (
      <Query query={CURRENT_THEME} variables={{ input: { theme } }}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return (
              <div className={styles.spinnerContainer}>
                <SpinnerError error={error} />
              </div>
            );
          }

          const themeValue = data.currentTheme.theme;

          return (
            <ThemeContext.Provider value={themeValue}>
              {children}
            </ThemeContext.Provider>
          );
        }}
      </Query>
    );
  }
}

Theme.getTheme = getTheme;

export default Theme;
