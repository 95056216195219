import _ from 'lodash';
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';
import { Query } from '@apollo/react-components';
import { Form } from 'antd';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import { Link } from 'react-router-dom';
import SpinnerError from '$components/common/SpinnerError';
import { serializeApplicationData } from '../../../lib/application';
import { LENDER_NOTIFICATION_CRITERIA } from '../../contractor/screens/LenderList/lenderList';
import APPLICATION from './applicationQuery';
import Stepper from './Stepper';

import styles from './revise.scss';

const CREATE_APPLICATIONS = gql`
  mutation CreateApplications($input: CreateApplicationsInput!) {
    createApplications(input: $input) {
      applications {
        uuid
      }
      contractorApplication {
        uuid
        financingApplications {
          uuid
          financingType
          status
          quotes {
            uuid
          }
        }
      }
      lendersNotified {
        uuid
        email
        company
        logoHref
        website
      }
    }
  }
`;

const ApplicationLoader = (props) => {
  const { children, match } = props;
  const { applicationUuid } = match.params;

  return (
    <Query query={APPLICATION} variables={{ applicationUuid }}>
      {({ loading, error, data }) => {
        if (loading || error) {
          return (
            <div className={styles.spinnerContainer}>
              <SpinnerError error={error} />
            </div>
          );
        }

        const { application } = data;

        return children({ application });
      }}
    </Query>
  );
}

class Revise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
      creationComplete: false,
      startTime: new Date(),
    };
  }

  formatValues(values) {
    return Object.entries(values).reduce((acc, [key, value]) => {
      switch (key) {
        case 'scopeOfWork':
        case 'availableDocuments':
          return {
            ...acc,
            [key]: JSON.stringify(value)
          };
        case 'singleTenant': {
          const isSingleTenant = !!value && Boolean(value.toLowerCase() === 'yes');
          return {
            ...acc,
            [key]: isSingleTenant,
          };
        }
        case 'ownerOccupied':
          return {
            ...acc,
            [key]: Boolean(value)
          };
        case 'propertyMarketValue':
        case 'propertyBuildingSize':
        case 'projectEstimatedCost':
        case 'propertyDebt':
          return {
            ...acc,
            [key]: parseFloat(value.toString().replace(/[\$,]/g, '')) // eslint-disable-line no-useless-escape
          };
        default:
          return {
            ...acc,
            [key]: value
          };
      }
    }, {});
  }

  handleSubmit = ({ application, projectUuid }) => (e) => {
    e.preventDefault();

    const { form } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          buttonLoading: true
        });

        const {
          address,
          city,
          postalCode,
          state,
        } = values;

        const {
          startTime,
        } = this.state;

        const { rawParcels } = application;

        try {
          const generatedValues = {
            ...values,
            location: `${address}, ${city}, ${state} ${postalCode}`,
            projectUuid,
            rawParcels,
            source: 'applicant',
            termsAccepted: 'true',
          };
          const input = serializeApplicationData({
            startTime,
            values: generatedValues,
          });

          const { data } = await this.props.client.mutate({
            mutation: CREATE_APPLICATIONS,
            variables: {
              input,
            },
          });

          const { lendersNotified } = data.createApplications;

          this.setState({
            buttonLoading: true,
            creationComplete: true,
            lendersNotified,
          });
        } catch (catchError) {
          console.log('Error', catchError);// eslint-disable-line no-console
          this.setState({
            buttonLoading: false
          });
        }
      }
    });
  }

  render() {
    const {
      currentUser,
      form,
      match,
    } = this.props;
    const {
      getFieldDecorator,
    } = form;
    const {
      buttonLoading,
      creationComplete,
      lendersNotified,
    } = this.state;

    // const creationComplete = true;
    // const lendersNotified = LENDER_NOTIFICATION_CRITERIA;

    const lendersNotifiedEl = (lendersNotified && (lendersNotified.length > 0)) && (
      <div>
        <br />
        <div>The following lenders have been notified:</div>
        <ul>
          {lendersNotified.map((lender) => {
            const { company, logoHref, website } = lender;
            return (
              <div key={company} className={styles.lender}>
                <img src={logoHref} className={styles.lenderLogo} />
                <div className={styles.lenderInfo}>
                  <div className={styles.lenderCompany}>{company}</div>
                  <a href={website} target="_blank" rel="noopener noreferrer">{website}</a>
                </div>
              </div>
            );
          })}
        </ul>
        <div>We encourage you to follow up directly.</div>
        <br />
        <div>Feel free to view our full <Link to={routes.contractor.lenderList}>lender list.</Link></div>
      </div>
    );


    if (creationComplete) {
      return (
        <div className={styles.confirmation}>
          <div className={styles.confirmationTitle}>Thank you!</div>
          <span>Your application has been submit.</span>
          {lendersNotifiedEl}
        </div>
      );
    }

    return (
      <ApplicationLoader match={match}>
        {({ application }) => {
          if (!application) {
            return null;
          }

          const {
            propertyType,
            status,
            usurp: {
              altv,
              altvScore,
              communicationScore
            },
            project,
          } = application;

          const contractorApplication = project;
          const projectUuid = _.get(project, 'uuid');
          const usurp = _.get(project, 'application.usurp');

          const communicationStar = parseFloat(communicationScore, 10) / 0.33;

          let errors = [];
          const errorMessage = [];

          if (altv && altv > 2) {
            errors = [
              'availableDocuments',
              'propertyMarketValue',
              'propertyDebt',
            ];
            errorMessage.push('The financing amount and any other existing debt cannot be more than 100% of your appraised property value. You will need to provide an appraisal.');
          }

          const statusExpired = status === 'expired' || status === 'applicantNotified';

          if (communicationStar < 1 && statusExpired) {
            const newErrors = Object.entries(application).reduce((acc, [key, val]) => {
              if (val === null || typeof val === 'undefined') {
                return [
                  ...acc,
                  key
                ];
              }
              return acc;
            }, []);
            const newErrorMessage = 'Providing more information will make lenders more likely to give you a quote.';

            if (altvScore > 0.8) {
              errors = errors.concat(newErrors);
            } else {
              errors = [
                ...errors,
                ...newErrors
              ];
            }
            errorMessage.push(newErrorMessage);
          }

          if (propertyType === 'residential') {
            errors.push('propertyType');
            errorMessage.push('Residential properties with 5 or less individual units are not available for financing via Unety.');
          }

          const onSubmit = this.handleSubmit({ application, projectUuid });

          return (
            <div className={styles.layout}>
              <h1 className={styles.header}>
                <div className={styles.title}>
                  {status === 'active' || status === 'augmented' ? 'Shop for financing' : 'Revise your application'}
                  <div className={styles.subtitle}>5-10 min</div>
                </div>
                <div className={styles.subtitle}>Submit a non-binding request for quotes from 20+ lenders</div>
                {errorMessage && <span dangerouslySetInnerHTML={{ __html: errorMessage.join('</br>') }} />}
              </h1>
              <Form onSubmit={onSubmit}>
                <div className={styles.formContainer}>
                  <Stepper
                    currentUser={currentUser}
                    errors={errors}
                    form={form}
                    getFieldDecorator={getFieldDecorator}
                    application={application}
                    contractorApplication={contractorApplication}
                    usurp={usurp}
                    loading={buttonLoading}
                    handleSubmit={onSubmit}
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </ApplicationLoader>
    );
  }
}

export default Form.create()(withApollo(Revise), { ignoreMissing: true });
