import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import classnames from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { Spin } from 'antd';
import { routes } from '@usurp-power/aqua-silver';
import { AuthenticatedRoute, PropsRoute, UnauthenticatedRoute } from '$lib/routes';
import CURRENT_USER from '$queries/currentUser';
import Bounce from '$components/auth/Bounce';
import SignOut from '$components/auth/SignOut';
import ForgotPassword from '$components/auth/ForgotPassword';
import ResetPassword from '$components/auth/ResetPassword';
import SignUp from '$components/auth/SignUp';
import SignIn from '$components/auth/SignIn';
import Dashboard from '$components/Dashboard';
import AdminDashboard from '$components/admin/screens/dashboard';
import LastSeen from '$components/LastSeen';
import Applicant from '$components/Applicant';
import NotFound from '$components/NotFound';
import Magic from '$components/Magic';
import Debug from '$components/Debug';
import storage from '$lib/storage';
import Theme from './Theme';
import { ThemeContext } from '$lib/context';
import UtmSource from './UtmSource';
import SessionUuid from './SessionUuid';
import ProjectReport from '$components/contractor/screens/ProjectReport';
import SessionRedirect from '$components/SessionRedirect';
import { ContractorDashboard } from '$components/contractor/screens';
import ManagerDashboard from '$components/manager/ManagerDashboard';
import PropertiesGrid from '$components/admin/PropertiesGrid';
import Account from '$components/Account';
import Plan from '$components/Plan';
import Invite from '$components/Invite';
import Tiers from '$components/Tiers';
import Scoring from '$components/Scoring';
import ScoringEvaluate from '$components/Scoring/ScoringEvaluate';

import styles from './styles.scss';

const STAGE = process.env.STAGE; // eslint-disable-line prefer-destructuring

class App extends Component {
  exitImpersonate = () => {
    storage.tempAuth.clear();
    window.location.href = '/';
  }

  renderImpersonated = () => {
    const tempAuth = storage.tempAuth.get();

    if (!tempAuth) {
      return null;
    }

    const { email } = tempAuth;

    return (
      <div className={styles.impersonateOverlay}>
        <div className={styles.impersonate}>
          <div className={styles.impersonateText}>impersonated</div>
          <div className={styles.impersonateEmail}>{email}</div>
        </div>
        <i className="fad fa-portal-exit" onClick={this.exitImpersonate} />
      </div>
    );
  }

  render() {
    return (
      <Theme>
        <ThemeContext.Consumer>
          {(theme) => {
            const { slug } = theme;

            return (
              <div className={classnames(styles.root, `theme--${slug}`)}>
                <SessionRedirect>
                  <UtmSource />
                  <SessionUuid />
                  {this.renderImpersonated()}
                  <Query query={CURRENT_USER}>
                    {({ loading, _error, data }) => {
                      if (loading) {
                        return (
                          <Spin size="large" />
                        );
                      }

                      const { currentUser } = data;
                      if (((STAGE === 'production') || (STAGE === 'staging')) && currentUser && window.FS) {
                        window.FS.identify(currentUser.uuid, {
                          email: currentUser.email,
                        });
                      }

                      return (
                        <>
                          {currentUser && (<div data-aaa="isAuthenticated" className={styles.hidden} />)}
                          <LastSeen key={currentUser ? currentUser.uuid : null} currentUser={currentUser} />
                          <Switch>
                            <PropsRoute exact path="/" currentUser={currentUser} component={Bounce} />
                            <UnauthenticatedRoute exact path={routes.auth.signIn} component={SignIn} currentUser={currentUser} />
                            <UnauthenticatedRoute exact path={routes.auth.signUp} component={SignUp} currentUser={currentUser} />
                            <UnauthenticatedRoute exact path={routes.auth.forgotPassword} component={ForgotPassword} currentUser={currentUser} />
                            <UnauthenticatedRoute exact path={routes.auth.resetPassword} component={ResetPassword} currentUser={currentUser} />
                            <AuthenticatedRoute exact path={routes.auth.signOut} component={SignOut} currentUser={currentUser} />
                            <PropsRoute path={routes.applicant.overview} component={Applicant} currentUser={currentUser} />

                            <PropsRoute path={routes.contractor.projects.project.report} component={ProjectReport} />

                            <PropsRoute path={routes.contractor.overview} component={ContractorDashboard} currentUser={currentUser} />
                            <AuthenticatedRoute path={routes.lender.overview} component={Dashboard} currentUser={currentUser} />
                            <AuthenticatedRoute path={routes.admin.prefix} component={AdminDashboard} currentUser={currentUser} />

                            <AuthenticatedRoute path={routes.manager.overview} component={ManagerDashboard} currentUser={currentUser} />

                            {/* <AuthenticatedRoute exact path={routes.invite} component={Invite} currentUser={currentUser} /> */}
                            {/* <PropsRoute exact path={routes.tiers} component={Tiers} currentUser={currentUser} /> */}
                            <AuthenticatedRoute exact path={routes.scoring.overview} component={Scoring} currentUser={currentUser} />
                            <AuthenticatedRoute exact path={routes.scoring.evaluate} component={ScoringEvaluate} currentUser={currentUser} />

                            <AuthenticatedRoute exact path={routes.account} component={Account} currentUser={currentUser} />
                            <AuthenticatedRoute exact path={routes.plan} component={Plan} currentUser={currentUser} />
                            <PropsRoute exact path={routes.magic} component={Magic} currentUser={currentUser} />
                            <Route exact path={routes.debug} component={Debug} />
                            <Route exact path="/debug/properties-grid" component={PropertiesGrid} />

                            <Route component={NotFound} />
                          </Switch >
                        </>
                      );
                    }}
                  </Query>
                </SessionRedirect>
              </div>
            );
          }}
        </ThemeContext.Consumer>
      </Theme>
    );
  }
}

export default hot(module)(App);
