import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';
import styles from '../styles.scss';

class UnwrappedForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.forgotPassword({ variables: { ...values } });
      }
    });
  }

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;

    return (
    <Form onSubmit={this.handleSubmit} className={styles.form}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input autoComplete="username" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>Send reset email</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(UnwrappedForm);
