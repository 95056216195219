import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import { Link } from 'react-router-dom';
import { auth, routes } from '@usurp-power/aqua-silver';
import styles from './profile-dropdown.scss';

const { isAdmin, isContractor, isLender } = auth;

class ProfileDropdown extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape({
      fullName: PropTypes.string,
      type: PropTypes.string,
    })
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();

    this.state = {
      open: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  handleClickOutside(evt) {
    const elem = this.wrapperRef.current;
    if (elem && !elem.contains(evt.target)) {
      this.setOpen(false);
    }
  }

  handleDocumentKeyDown(evt) {
    if (evt.which === 27) {
      this.setOpen(false);
    }
  }

  setOpen(open = true) {
    if (open) {
      document.addEventListener('click', this.handleClickOutside);
      document.addEventListener('keydown', this.handleDocumentKeyDown);
    } else {
      document.removeEventListener('click', this.handleClickOutside);
      document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    this.setState({
      open
    });
  }

  render() {
    const {
      location: {
        pathname,
      },
      user
    } = this.props;

    const {
      open
    } = this.state;

    if (!user) {
      return null;
    }

    const selectedKey = pathname.split('/')[1];
    let toolNav = [];

    if (isAdmin(user)) {
      toolNav.push(
        <Menu.Item key="admin">
          <span className={styles.dropdown__noIcon}>
            <Link to={routes.admin.prefix}>Admin</Link>
          </span>
        </Menu.Item>
      );
    }

    if (auth.canScore(user)) {
      toolNav.push(
        <Menu.Item key="scoring">
          <span className={styles.dropdown__noIcon}>
            <Link to={routes.scoring.overview}>Scoring tool</Link>
          </span>
        </Menu.Item>
      );
    }

    if (toolNav.length > 0) {
      toolNav.unshift(
        <Menu.Item key="tools-title">
          <span className={styles.dropdown__title}>
            <i className="fas fa-tools" />
            <span>Tools</span>
          </span>
        </Menu.Item>
      );
    }

    if (isLender(user) || isAdmin(user)) {
      toolNav = toolNav.concat([
        <Menu.Item key="contractor">
          <span className={styles.dropdown__noIcon}>
            <Link to={routes.contractor.projects.new}>New project</Link>
          </span>
        </Menu.Item>,
        <Menu.Item key="lender">
          <span className={styles.dropdown__noIcon}>
            <Link to={routes.lender.overview}>Lender</Link>
          </span>
        </Menu.Item>,
        <Menu.Divider key="divider" />,
      ]);
    }

    return (
      <div ref={this.wrapperRef}>
        <Col>
          <Row type="flex">
            <Col>
              <Dropdown
                overlay={(
                  <Menu
                    style={{ width: 200 }}
                    selectedKeys={[selectedKey]}
                  >
                    {toolNav}
                    {isLender(user) && (
                      <Menu.Item key="profile">
                        <span className={styles.dropdown__item}>
                          <i className="fas fa-id-card" />
                          <Link to={routes.lender.profile}>Lender profile</Link>
                        </span>
                      </Menu.Item>
                    )}
                    {isContractor(user) && (
                      <Menu.Item key="profile">
                        <span className={styles.dropdown__item}>
                          <i className="fas fa-id-card" />
                          <Link to={routes.contractor.profile}>Public profile</Link>
                        </span>
                      </Menu.Item>
                    )}
                    {/* {isContractor(user) && (
                      <Menu.Item key="tiers">
                        <span className={styles.dropdown__item}>
                          <i className="fas fa-file-certificate" />
                          <Link to={routes.tiers}>Pro tier eligibility</Link>
                        </span>
                      </Menu.Item>
                    )} */}
                    {/* <Menu.Item key="invite">
                      <span className={styles.dropdown__item}>
                        <i className="fas fa-envelope-open-dollar" />
                        <Link to={routes.invite}>Invite and earn</Link>
                      </span>
                    </Menu.Item> */}
                    <Menu.Item key="plan">
                      <span data-aaa="navPlan" className={styles.dropdown__item}>
                        <i className="fas fa-file-invoice-dollar" />
                        <Link to={routes.plan}>Billing plan</Link>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="account">
                      <span data-aaa="navAccount" className={styles.dropdown__item}>
                        <i className="fas fa-id-card" />
                        <Link to={routes.account}>Account</Link>
                      </span>
                    </Menu.Item>
                    <Menu.Item key="sign-out">
                      <span data-aaa="navSignOut" className={styles.dropdown__item}>
                        <i className="fas fa-sign-out" />
                        <Link to={routes.auth.signOut}>Sign Out</Link>
                      </span>
                    </Menu.Item>
                  </Menu>
                )}
                overlayClassName={[styles.dropdown, 'profile-dropdown'].join(' ')}
                trigger={['click']}
                visible={open}
                onClick={this.setOpen.bind(this, !open)}
              >
                <i className={classnames('fas fa-user', styles.avatar)} data-aaa="profileAvatar" />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

export default ProfileDropdown;
