import _isNumber from 'lodash/isNumber';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import React, { PureComponent } from 'react';
import { withApollo } from '@apollo/react-hoc';
import {Icon, Input, Radio, Slider, Tooltip} from 'antd';
import Breakdown from './breakdown';
import { formatNumber } from '../../../lib/formatter';
import ThatsWacc from './wacc';

import styles from './styles.scss';

const VALUE_CALCULATOR = gql`
  mutation UpdateContractorApplicationValueCalculator($input: UpdateContractorApplicationValueCalculatorInput!) {
    updateContractorApplicationValueCalculator(input: $input) {
      contractorApplication {
        uuid

        cpaceProjectAmount
        taxEscalationModifiedGrossLease
        term
        percentOwnerOccupied
        percentLeased
        percentUnoccupied
        percentLeasedFsg
        percentLeasedNnn
        percentLeasedMg

        valueCalculator {
          effectiveCostPace
          effectiveCostLoan
          effectiveCostCash

          newRevenueFromTenantsPace
          newRevenueFromTenantsLoan
          newRevenueFromTenantsCash

          owedFromLandlordPace
          owedFromLandlordLoan
          owedFromLandlordCash

          tenantCostIncreasePace
          tenantCostIncreaseLoan
          tenantCostIncreaseCash
        }
      }
    }
  }
`;

const CalculatorSlider = ({
  defaultValue,
  label,
  marks,
  max,
  min,
  name,
  showSlider = true,
  textPostLabel,
  textPreLabel,
  value,
  valueFormatter,
  onAfterChange,
  onChange,
  onTextChange,
}) => {
  const valueClasses = classNames(styles.calculator__slider__value, {
    [styles.calculator__slider__value__isinput]: onTextChange,
  });

  const handleTextChange = (evt) => {
    onTextChange({
      name,
      value: evt.target.value.replace(/,/g, ''),
    });
  };

  const sliderProps = {
    [onTextChange ? 'value' : 'defaultValue']: defaultValue || value / max,
    marks,
    tooltipVisible: false,
    onAfterChange: onAfterChange.bind(this, { field: name, max, min }),
    onChange: onChange.bind(this, { field: name, max, min }),
  };

  return (
    <div className={styles.calculator__slider}>
      {showSlider ? <Slider {...sliderProps} className={styles.calculator__slider__control} /> : <div />}
      <div className={styles.calculator__slider__input}>
        <div className={styles.calculator__slider__label}>
          {label}
        </div>
        <div className={valueClasses}>
          {onTextChange ? (
            <div className={styles.calculator__slider__value__inputContainer}>
              <Input
                addonAfter={textPostLabel}
                addonBefore={textPreLabel}
                className={styles.calculator__slider__value__field}
                value={formatNumber(value)}
                onChange={handleTextChange}
              />
            </div>
          ) : valueFormatter(value)}
        </div>
      </div>
    </div>
  );
};

CalculatorSlider.propTypes = {
  defaultValue: PropTypes.number,
  label: PropTypes.string,
  marks: PropTypes.shape(),
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  textPostLabel: PropTypes.string,
  textPreLabel: PropTypes.string,
  value: PropTypes.number,
  valueFormatter: PropTypes.func,
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
  onTextChange: PropTypes.func,
};

CalculatorSlider.defaultProps = {
  defaultValue: 0,
  label: '',
  marks: {
    0: 0,
    100: {
      label: '100%',
      value: 100,
    }
  },
  max: 100,
  min: 0,
  name: '',
  textPostLabel: '',
  textPreLabel: '',
  value: 0,
  valueFormatter: (val) => `${parseInt(val, 10)}%`,
  onAfterChange: () => { },
  onChange: () => { },
  onTextChange: null,
};

class ValueCalculator extends PureComponent {
  static propTypes = {
    application: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);

    const { application: contractorApplication } = props;

    const {
      projectSize = 100000,
      cpaceProjectAmount,
      preferredFinancingTerm = 15,
      application,

      percentOwnerOccupied,
      percentLeased,
      percentUnoccupied,
      percentLeasedFsg,
      percentLeasedNnn,
      percentLeasedMg,
    } = contractorApplication;

    // 20200108JP: These fields need to be consolidated and standardized.
    let pctOwnerOccupied = _isNumber(percentOwnerOccupied) ? percentOwnerOccupied * 100 : undefined;
    let pctLeased = _isNumber(percentLeased) ? percentLeased * 100 : undefined;
    const pctUnoccupied = _isNumber(percentUnoccupied) ? percentUnoccupied * 100 : 0;
    let pctFullServiceGrossLease = _isNumber(percentLeasedFsg) ? percentLeasedFsg * 100 : undefined;
    let pctTripleNetLease = _isNumber(percentLeasedNnn) ? percentLeasedNnn * 100 : undefined;
    let modifiedGrossLease = _isNumber(percentLeasedMg) ? percentLeasedMg * 100 : undefined;

    let {
      taxEscalationModifiedGrossLease = 0,
    } = contractorApplication;

    taxEscalationModifiedGrossLease *= 100;

    const { usurp } = application;
    const {
      propertyGroup,
      singleTenant,
      ownerOccupied,
    } = usurp;

    // 20200108JP: If percentLeasedNnn is present, the others should be present also.
    if (!_isNumber(percentLeasedNnn)) {
      switch (propertyGroup) {
        case 'multifamily': {
          modifiedGrossLease = 0;
          pctFullServiceGrossLease = 50;
          pctTripleNetLease = 50;
          break;
        }
        case 'office': {
          modifiedGrossLease = 90;
          pctFullServiceGrossLease = 0;
          pctTripleNetLease = 10;
          taxEscalationModifiedGrossLease = 5;
          break;
        }
        case 'retailNotMall': {
          modifiedGrossLease = 0;
          pctFullServiceGrossLease = 0;
          pctTripleNetLease = 100;
          break;
        }
        case 'shoppingCenterAndStripMalls': {
          modifiedGrossLease = 0;
          pctFullServiceGrossLease = 50;
          pctTripleNetLease = 50;
          break;
        }
        case 'industrial': {
          modifiedGrossLease = 0;
          pctFullServiceGrossLease = 0;
          pctTripleNetLease = 100;
          break;
        }
        case 'specialty': {
          modifiedGrossLease = 0;
          pctFullServiceGrossLease = 20;
          pctTripleNetLease = 80;
          break;
        }
        default: {
          modifiedGrossLease = 0;
          pctFullServiceGrossLease = 100;
          pctTripleNetLease = 0;
          break;
        }
      }
    }

    if (!_isNumber(pctOwnerOccupied)) {
      if (ownerOccupied) {
        if (singleTenant) {
          pctOwnerOccupied = 100;
          pctLeased = 0;
        } else {
          pctOwnerOccupied = 50;
          pctLeased = 50;
        }
      } else {
        pctOwnerOccupied = 0;
        pctLeased = 100;
      }
    }

    this.state = {
      cost: cpaceProjectAmount || projectSize,
      costMax: 20000000,
      costMin: 50000,
      currentFinanceMethod: 'pace',
      errorMessage: '',
      modifiedGrossLease,
      singleTenant,
      ownerOccupied,
      pctFullServiceGrossLease,
      pctTripleNetLease,
      showWACC: false,
      taxEscalationModifiedGrossLease,
      term: preferredFinancingTerm,
      termMax: 30,
      termMin: 1,
      pctOwnerOccupied: 0, // Override
      pctLeased: 0,
      pctNotUnderLease: 100,
      pctUnoccupied,
    };

    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  componentDidMount() {
    this.getValueCalculator();
  }

  handleAfterSliderChange = () => {
    this.getValueCalculator();
  }

  handleSliderChange({ field, max = 1, min = 0 }, val) {
    const pct = val / 100;
    this.setState({
      [field]: pct ? (max * pct) : min,
    });

    if (field === 'pctNotUnderLease') {
      this.setState({
        pctLeased: 100 - val,
        pctOwnerOccupied: val,
      })
    }

    if ((field === 'pctOwnerOccupied') && (val === 100)) {
      this.setState({
        pctLeased: 0,
        pctUnoccupied: 0,
      });
    }
  }

  handleTextChange({ name, value }) {
    const val = parseFloat(value);
    this.setState({
      [name]: val,
    }, () => this.getValueCalculator());
  }

  handleToggleWACC = (showWacc = null) => {
    this.setState({
      showWACC: showWacc === null ? !this.state.showWACC : showWacc,
    });
  }

  handleCurrentFinanceMethod = (e) => {
    const currentFinanceMethod = e.target.value;
    const newState = {
      currentFinanceMethod,
    };

    // eslint-disable-next-line default-case
    switch (currentFinanceMethod) {
      case 'loan':
        newState.pctNotUnderLease = 100;
        newState.pctLeased = 0;
        newState.taxEscalationModifiedGrossLease = this.state.prevTaxEscalationModifiedGrossLease || this.state.taxEscalationModifiedGrossLease;
        break;
      case 'pace':
        newState.pctNotUnderLease = 100;
        newState.pctLeased = 0;
        newState.pctNotUnderLease = this.state.prevPctNotUnderLease || this.state.pctNotUnderLease;
        newState.taxEscalationModifiedGrossLease = this.state.prevTaxEscalationModifiedGrossLease || this.state.taxEscalationModifiedGrossLease;
        break;
      case 'onBill':
        newState.taxEscalationModifiedGrossLease = 20;
        newState.prevTaxEscalationModifiedGrossLease = this.state.taxEscalationModifiedGrossLease;
        newState.pctNotUnderLease = this.state.prevPctNotUnderLease || this.state.pctNotUnderLease;
    }

    this.setState(newState);
    setTimeout(() => {
      this.getValueCalculator();
    })
  }

  async getValueCalculator() {
    const {
      cost,
      term,
      pctOwnerOccupied,
      pctLeased,
      pctUnoccupied,
      modifiedGrossLease,
      pctFullServiceGrossLease,
      pctTripleNetLease,
      taxEscalationModifiedGrossLease,
    } = this.state;

    const percentOwnerOccupied = pctOwnerOccupied / 100;
    const percentLeased = pctLeased / 100;
    const percentUnoccupied = pctUnoccupied / 100;
    const percentLeasedFsg = pctFullServiceGrossLease / 100;
    const percentLeasedNnn = pctTripleNetLease / 100;
    const percentLeasedMg = modifiedGrossLease / 100;

    const normalizedCost = Math.max(Math.min(cost || 0, 20000000), 50000);
    const isLeaseTypesValid = modifiedGrossLease + pctFullServiceGrossLease + pctTripleNetLease <= 100;
    const isOccupancyValid = pctOwnerOccupied + pctLeased + pctUnoccupied <= 100;
    let errorMessage = '';

    if (cost < 50000 || cost > 20000000) {
      errorMessage = 'Project size should be between $50,000 and $20,000,000';
    } else if (!isLeaseTypesValid) {
      errorMessage = 'Lease type percents should add up to 100';
    }

    if (errorMessage) {
      this.setState({
        errorMessage
      });
      return;
    }

    const { uuid: contractorApplicationUuid } = this.props.application;

    const { data } = await this.props.client.mutate({
      mutation: VALUE_CALCULATOR,
      variables: {
        input: {
          contractorApplicationUuid,
          cpaceProjectAmount: normalizedCost,

          taxEscalationModifiedGrossLease: taxEscalationModifiedGrossLease / 100,

          preferredFinancingTerm: (term && parseInt(term, 10)) || 1,

          percentOwnerOccupied,
          percentLeased,
          percentUnoccupied,
          percentLeasedFsg,
          percentLeasedNnn,
          percentLeasedMg,
        }
      },
    });

    this.setState({
      ...data.valueCalculator,
      isLeaseTypesValid: true,
      errorMessage: '',
    });
  }

  render() {
    const { application: contractorApplication } = this.props;

    const {
      cost,
      costMax,
      costMin,
      currentFinanceMethod,
      errorMessage,
      modifiedGrossLease,
      pctOwnerOccupied,
      pctLeased,
      pctNotUnderLease,
      pctUnoccupied,
      pctFullServiceGrossLease,
      pctTripleNetLease,
      showWACC,
      taxEscalationModifiedGrossLease,
      term,
      termMax,
      termMin,
    } = this.state;

    const {
      applicationUuid,
      valueCalculator,
    } = contractorApplication;

    const {
      effectiveCostPace,

      newRevenueFromTenantsPace,
      newRevenueFromTenantsLoan,
      newRevenueFromTenantsCash,

      owedFromLandlordPace,
      owedFromLandlordLoan,
      owedFromLandlordCash,

      tenantCostIncreasePace,
      tenantCostIncreaseLoan,
      tenantCostIncreaseCash
    } = valueCalculator;

    const showAdvanced = pctNotUnderLease !== 100;

    const costMarks = {
      0: `$${formatNumber(costMin)}`,
      100: {
        label: '20 million'
      }
    };

    const termMarks = {
      0: '1 year',
      100: {
        label: `${termMax} years`
      }
    };

    const taxEscalationMarks = {
      0: '0',
      100: {
        label: '20%'
      }
    };

    const errorClasses = classNames(styles.advancedButton__error, {
      [styles.advancedButton__error__showing]: errorMessage
    });

    const effectiveRate = (effectiveCostPace * 100).toFixed(2);
    const contractorRate = 6.00.toFixed(2);

    return (
      <div className={styles.content}>
        <div className={styles.fundingTypeSelector}>
          <Radio.Group defaultValue={currentFinanceMethod} onChange={this.handleCurrentFinanceMethod}>
            <Radio.Button value="pace">PACE</Radio.Button>
            <Radio.Button value="loan">Loan</Radio.Button>
            <Radio.Button value="onBill">OnBill</Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles.funding__container}>
          <div className={styles.funding}>
            <div className={[styles.costTo, 'costTo'].join(' ')}>
              <div className={styles.costTo__tooltip}>
                <div onClick={this.handleToggleWACC}>
                  <Icon type="info-circle" />
                </div>
              </div>
              <div className={styles.header}>
                Interest rate
              </div>
              <div className={styles.costTo__content}>
                <div className={styles.costTo__main}>
                  <div className={styles.costTo__label}>
                    Contract rate
                    <Tooltip placement="right" title={`"Contract Rate" is the interest rate that you would see listed in your financing contract.`}>
                      <Icon type="info-circle" />
                    </Tooltip>
                  </div>
                  {contractorRate}<sup>%</sup>
                </div>
                {currentFinanceMethod !== 'loan' && (
                  <div className={styles.costTo__main}>
                    <div className={styles.costTo__label}>
                      Effective rate
                      <Tooltip placement="right" title={`'Effective Rate' is the actual cost of capital that you will pay after you have experienced the accounting benefits unique to ${currentFinanceMethod} financing`}>
                        <Icon type="info-circle" />
                      </Tooltip>
                    </div>
                    {effectiveRate}<sup>%</sup>
                  </div>
                )}
              </div>
            </div>
            <div className={[styles.calculator, 'hideFromReport'].join(' ')}>
              <div className={styles.header}>
                Control panel
              </div>
              <div className={[styles.calculator__content, 'calculator__content'].join(' ')}>
                <div className={styles.calculator__sliders}>
                  <CalculatorSlider
                    defaultValue={(cost / costMax) * 100}
                    label="Project cost"
                    marks={costMarks}
                    max={costMax}
                    min={costMin}
                    name="cost"
                    textPreLabel="$"
                    value={Math.min(cost)}
                    valueFormatter={(val) => `$${formatNumber(val)}`}
                    onAfterChange={this.handleAfterSliderChange}
                    onChange={this.handleSliderChange}
                    onTextChange={this.handleTextChange}
                  />
                  <CalculatorSlider
                    defaultValue={(term / termMax) * 100}
                    label="Contract term"
                    marks={termMarks}
                    max={termMax}
                    min={termMin}
                    name="term"
                    textPostLabel="years"
                    value={Math.max(parseInt(term, 10), 1)}
                    valueFormatter={() => `${parseInt(term, 10)} years`}
                    onAfterChange={this.handleAfterSliderChange}
                    onChange={this.handleSliderChange}
                    onTextChange={this.handleTextChange}
                  />
                  {currentFinanceMethod !== 'loan' && (
                    <CalculatorSlider
                      defaultValue={pctNotUnderLease}
                      label="Percent of space not under lease"
                      name="pctNotUnderLease"
                      value={pctNotUnderLease}
                      onAfterChange={this.handleAfterSliderChange}
                      onChange={this.handleSliderChange}
                    />
                  )}
                  {currentFinanceMethod !== 'loan' && (
                    <CalculatorSlider
                      defaultValue={pctLeased}
                      label="Percent currently leased"
                      name="pctLeased"
                      showSlider={false}
                      value={pctLeased}
                      onAfterChange={this.handleAfterSliderChange}
                      onChange={this.handleSliderChange}
                    />
                  )}
                  {showAdvanced && (
                    <>

                      <hr />
                      <CalculatorSlider
                        defaultValue={pctFullServiceGrossLease}
                        label="Leased as full service gross"
                        name="pctFullServiceGrossLease"
                        value={pctFullServiceGrossLease}
                        onAfterChange={this.handleAfterSliderChange}
                        onChange={this.handleSliderChange}
                      />
                      <CalculatorSlider
                        defaultValue={pctTripleNetLease}
                        label="Leased as triple net"
                        name="pctTripleNetLease"
                        value={pctTripleNetLease}
                        onAfterChange={this.handleAfterSliderChange}
                        onChange={this.handleSliderChange}
                      />
                      <CalculatorSlider
                        defaultValue={modifiedGrossLease}
                        label="Leased as modified gross"
                        name="modifiedGrossLease"
                        value={modifiedGrossLease}
                        onAfterChange={this.handleAfterSliderChange}
                        onChange={this.handleSliderChange}
                      />
                      {currentFinanceMethod !== 'onBill' && (
                        <>
                          <hr />
                          <CalculatorSlider
                            defaultValue={(taxEscalationModifiedGrossLease / 20) * 100}
                            label="Tax escalation cap"
                            marks={taxEscalationMarks}
                            max={20}
                            name="taxEscalationModifiedGrossLease"
                            value={taxEscalationModifiedGrossLease}
                            onAfterChange={this.handleAfterSliderChange}
                            onChange={this.handleSliderChange}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className={styles.advancedButton}>
                  {errorMessage && (
                    <div className={errorClasses}>
                      {errorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={[showWACC ? styles.waccContainer__show : '', styles.waccContainer].join(' ')}>
            <ThatsWacc
              applicationUuid={applicationUuid}
              onClose={() => this.handleToggleWACC(false)}
            />
          </div>
        </div>
        <Breakdown
          effectiveRate={effectiveRate}
          interestRate={6}
          newRevenueFromTenantsPace={newRevenueFromTenantsPace}
          newRevenueFromTenantsLoan={newRevenueFromTenantsLoan}
          newRevenueFromTenantsCash={newRevenueFromTenantsCash}
          owedFromLandlordPace={owedFromLandlordPace}
          owedFromLandlordLoan={owedFromLandlordLoan}
          owedFromLandlordCash={owedFromLandlordCash}
          tenantCostIncreasePace={tenantCostIncreasePace}
          tenantCostIncreaseLoan={tenantCostIncreaseLoan}
          tenantCostIncreaseCash={tenantCostIncreaseCash}
        />
      </div>
    );
  }
}

export default withApollo(ValueCalculator);
