import numeral from 'numeral';
import React, { Component } from 'react';
import moment from 'moment';
import { Button, Card, Table } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import styles from './styles.scss';

const ResponsiveProjects = ({ data }) => {
  return (
    <div className={styles.list}>
      {data.map((contractorApplication) => {
        const { uuid, application, createdAt } = contractorApplication;
        const { property, usurp } = application;

        const qualifiedAmount = (() => {
          if (!usurp.taxAssessedValue) {
            return null;
          }

          return (
            numeral(usurp.taxAssessedValue * 0.2).format('$0,0')
          );
        })();

        return (
          <div key={uuid} className={styles.listItem}>
            <div className={styles.body}>
              <div className={styles.address}>{property.streetNumber} {property.route}</div>
              <div>Pre-qualified: {qualifiedAmount || 'unknown'}</div>
              <div>{moment(createdAt).format('MMM Do, YYYY')}</div>
            </div>
            <div className={styles.cta}>
              <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid: uuid })}>
                <Button>View</Button>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

class ContractorApplications extends Component {
  render() {
    const { contractorApplications } = this.props;

    const columns = [
      {
        title: 'Address',
        key: 'address',
        render: (text, record) => record.application.property.formattedAddress,
      },
      {
        title: 'Pre-qualified amount',
        key: 'qualifiedAmount',
        render: (text, record) => numeral(record.application.usurp.marketValue * 0.2).format('$0,0'),
      },
      {
        align: 'right',
        title: '',
        key: 'actions',
        render: (text, record) => (
          <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid: record.uuid })}>
            <Button>view</Button>
          </Link>
        ),
      },
    ];

    return (
      <Card title="Your projects">
        <div className={styles.desktop}>
          <Table
            columns={columns}
            dataSource={contractorApplications}
            rowKey="uuid"
          />
        </div>
        <div className={styles.mobile}>
          <ResponsiveProjects data={contractorApplications} />
        </div>
      </Card>
    );
  }
}

export default withApollo(ContractorApplications);
