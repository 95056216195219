import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { AuthenticatedRoute, PropsRoute } from '$lib/routes';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import NotFound from '$components/NotFound';

import ContractorDataSheet from './ContractorDataSheet';
import ProjectOverviewSwitcher from './ProjectOverviewSwitcher';
import ProjectApplications from './ProjectApplications';
import EnergySavingsScreen from './EnergySavingsScreen';
import EnergySavingsResultsScreen from './EnergySavingsResultsScreen';
import FindContractorsScreen from './FindContractorsScreen';
import FindFundingScreen from './FindFundingScreen';
import FundingOptionsScreen from './FundingOptionsScreen';
import FundingOptionsSurveyScreen from './FundingOptionsSurveyScreen';
import LenderList from './LenderList';
import ValueCalculatorScreen from './ValueCalculatorScreen';
import WhiteGlove from './WhiteGlove';

import NewPaceRefi from './PaceRefi/NewPaceRefi';

import ContractorProfile from '../ContractorProfile';
import ContractorProjectList from './list';
import ContractorQuote from './quote';


class ContractorDashboard extends Component {
  render() {
    const { currentUser, location } = this.props;

    return (
      <DefaultLayout
        location={location}
        currentUser={currentUser}
      >
        <Switch>
          <Redirect exact from={routes.contractor.overview} to={routes.contractor.projects.overview} />
          <PropsRoute path={routes.contractor.projects.new} component={ContractorQuote} currentUser={currentUser} />
          <PropsRoute path={routes.contractor.projects.newPaceRefi} component={NewPaceRefi} currentUser={currentUser} />

          <PropsRoute exact path={routes.contractor.projects.project.overview} component={ProjectOverviewSwitcher} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.applications} component={ProjectApplications} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.energySavings} component={EnergySavingsScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.dataSheet} component={ContractorDataSheet} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.energySavingsResults} component={EnergySavingsResultsScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.findContractors} component={FindContractorsScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.findFunding} component={FindFundingScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.fundingOptions} component={FundingOptionsScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.fundingOptionsSurvey} component={FundingOptionsSurveyScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.valueCalculator} component={ValueCalculatorScreen} currentUser={currentUser} />
          <PropsRoute exact path={routes.contractor.projects.project.whiteGlove} component={WhiteGlove} currentUser={currentUser} />

          <AuthenticatedRoute path={routes.contractor.projects.overview} component={ContractorProjectList} currentUser={currentUser} />
          <AuthenticatedRoute path={routes.contractor.profile} component={ContractorProfile} currentUser={currentUser} />
          <AuthenticatedRoute path={routes.contractor.quote} component={ContractorQuote} currentUser={currentUser} />
          <PropsRoute path={routes.contractor.lenderList} component={LenderList} currentUser={currentUser} />

          <Route component={NotFound} />
        </Switch>
      </DefaultLayout>
    );
  }
}

export default ContractorDashboard;
