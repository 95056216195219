import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import styles from './styles.scss';

const PropertyOwnerSignUpBanner = (props) => {
  const {
    signUpLink,
    message,
    iconClasses,
  } = props;

  return (
    <div className={styles.layout} data-aaa="createAccountBanner">
      <div><i className={iconClasses} /> {message}</div>
      <Link to={signUpLink}><Button data-aaa="createAccount" type="primary">Create an account</Button></Link>
    </div>
  );
};

export default PropertyOwnerSignUpBanner;
