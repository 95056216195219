import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';
import { AutoComplete } from 'antd';

const GET_ZIPCODES = gql`
  query GetZipcodes($match: String) {
    getZipcodes(match: $match) {
      zipcode
    }
  }
`;

const unavailableMessage = 'Your zipcode is not available';

class ZipcodeSelector extends PureComponent {
  static propTypes = {
    'data-aaa': PropTypes.string,
    disabled: PropTypes.bool,
    initialValue: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    initialValue: '',
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      match: this.props.initialValue || '',
    };

    this.getZipcodes = this.getZipcodes.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.getZipcodes(this.state.match);
  }

  async getZipcodes(match) { // eslint-disable-line consistent-return
    const { client } = this.props;
    if (match === unavailableMessage) {
      return null;
    }

    const newMatch = match.substr(0, 5);

    this.setState(() => ({
      match: newMatch,
    }));

    try {
      const { data } = await client.query({
        query: GET_ZIPCODES,
        variables: {
          match: newMatch
        },
      });
      const { getZipcodes } = data;
      const dataSource = getZipcodes.map(({ zipcode }) => zipcode);

      this.setState({
        dataSource: dataSource.length === 0 ? [unavailableMessage] : dataSource,
      });
    } catch (e) {
      console.log('ERR', e); // eslint-disable-line no-console
    }
  }

  handleSelect(val) {
    if (val !== unavailableMessage) {
      this.props.onChange(val);
    }
  }

  render() {
    const {
      dataSource,
      match,
    } = this.state;

    const {
      disabled,
      'data-aaa': dataAaa,
    } = this.props;

    return (
      <AutoComplete
        data-aaa={dataAaa}
        autocomplete={false}
        dataSource={dataSource}
        disabled={disabled}
        filterOption={this.filterOption}
        maxLength={100}
        placeholder="Zipcode"
        value={match}
        onChange={this.getZipcodes}
        onSelect={this.handleSelect}
      />
    );
  }
}

export default withApollo(ZipcodeSelector);
