import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Icon, Input } from 'antd';

import styles from './styles.scss';

const STORE_QUALIFICATION = gql`
  mutation StoreQualification($input: StoreQualificationInput!) {
    storeQualification(input: $input) {
      uuid
      email
      postalCode
    }
  }
`;

class UnwrappedQualificationForm extends Component {
  handleStoreQualification = (storeQualification) => (e) => {
    e.preventDefault();

    const { form, location } = this.props;
    const { postalCode } = location;

    form.validateFields(async (err, values) => {
      const { email } = values;

      storeQualification({
        variables: {
          input: {
            email,
            postalCode,
          },
        },
      });
    });
  }

  render() {
    const { form, onCompleted } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Mutation mutation={STORE_QUALIFICATION} onCompleted={onCompleted}>
        {(storeQualification, { loading, data }) => {
          return (
            <Form onSubmit={this.handleStoreQualification(storeQualification)} className={styles.emailForm}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email!' }],
                })(
                  <Input
                    disabled={loading}
                    autoComplete="Email"
                    placeholder="Email"
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="email"
                  />
                )}
              </Form.Item>
              <Button
                disabled={loading || data}
                type="primary"
                htmlType="submit"
              >
                Submit
            <Icon type='right' />
              </Button>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default Form.create()(UnwrappedQualificationForm);
