import React, { Component } from 'react';
import pluralize from 'pluralize';
import { Button } from 'antd';

import styles from './styles.scss';

class FindContractorItem extends Component {
  render() {
    const {
      contractor,
    } = this.props;

    const {
      uuid,
      company,
      contactName,
      distance,
      email,
      locality,
      phone,
      postalCode,
      route,
      state,
      streetNumber,
      mapUrl,
      isEnterprise,
      range,
    } = contractor;

    const _distance = parseInt(distance, 10);

    return (
      <div key={uuid} className={styles.contractor}>
        <div className={styles.company}>{company} ({range || 'local'})</div>
        <div className={styles.body}>
          <div className={styles.mapContainer}>
            <img src={mapUrl} alt="" />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>
              {isEnterprise && (
                <div className={styles.preferred}>
                  <i className="fal fa-star" /> Preferred contractor
                                </div>
              )}
              {!Number.isNaN(_distance) && <div className={styles.distance}>{pluralize('mile', _distance, true)} from you</div>}
            </div>
            <div className={styles.address}>
              {streetNumber} {route}<br />
              {locality}, {state} {postalCode}
            </div>
            <div className={styles.contact}>
              <strong>{contactName}</strong><br />
            </div>
            <div className={styles.address}>
              {email && (
                <a href={`mailto:${email}`}>{email}</a>
              )}
              <br />{phone}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FindContractorItem;
