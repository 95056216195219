import React, { Component } from 'react';

import DesktopRequired from '$components/DesktopRequired';
import ProjectScreenLayout from '../ProjectScreenLayout';
import ValueCalculator from '../../ValueCalculator';

import styles from './styles.scss';

class ValueCalculatorScreen extends Component {
  render() {
    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => (
          <DesktopRequired>
            <div className={styles.layout}>
              <div className={styles.title}>Cash flow calculator</div>
              <ValueCalculator application={contractorApplication} />
            </div>
          </DesktopRequired>
        )}
      </ProjectScreenLayout>
    );
  }
}

export default ValueCalculatorScreen;
