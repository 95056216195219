import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { PropsRoute } from '$lib/routes';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import NotFound from '$components/NotFound';
import Unauthorized from '$components/common/Unauthorized';

import ManagerOverview from '../ManagerOverview';

class ManagerDashboard extends Component {
  render() {
    const { currentUser, location } = this.props;

    if (currentUser.type !== 'manager') {
      return <Unauthorized />;
    }

    return (
      <DefaultLayout
        location={location}
        currentUser={currentUser}
      >
        <Switch>
          <PropsRoute exact path={routes.manager.overview} component={ManagerOverview} currentUser={currentUser} />

          <Route component={NotFound} />
        </Switch>
      </DefaultLayout>
    );
  }
}

export default ManagerDashboard;
