import _get from 'lodash/get';
import classNames from 'classnames';
import React from 'react';
import { Tooltip } from 'antd';

import styles from './styles.scss';

const formatScore = (num) => {
  if (typeof num === 'string') {
    return num;
  }
  return `${Math.round((parseFloat(num) * 100))}%`;
};

const ScoreTable = ({
  data,
  isContractorApplication,
  showHeader = true,
  tableData,
}) => {
  return (
    <div className={styles.layout}>
      {showHeader && (
        <div className={[styles.layout__row, styles.layout__row__header].join(' ')}>
          <div></div>
          <div>Scores</div>
          <div>Rating</div>
        </div>
      )}
      {tableData.map(({ className, label, key, show, tooltip, rating }) => {
        if (show && !show({ isContractorApplication })) {
          return null;
        }

        const rowClasses = classNames(
          styles.layout__row,
          className
        );
        const val = _get(data, key);
        const RatingComponent = rating && rating(data);
        return (
          <div key={label} className={rowClasses}>
            {tooltip ? (
                <div>
                  <span className={styles.tooltip_label}>
                    <Tooltip placement="left" title={tooltip}>
                      {label}
                    </Tooltip>
                  </span>
                </div>
            ) : (
              <div>{label}</div>
            )}

            <div>
              <Tooltip placement="top" title={(val * 100).toFixed(2)}>
                {formatScore(val)}
              </Tooltip>
            </div>
            <div>
              {RatingComponent && <RatingComponent />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScoreTable;
