import _ from 'lodash';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Button, Select } from 'antd';
import RefineSizeModal from './RefineSizeModal';

import styles from './styles.scss';

const { Option } = Select;

class Refine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.handleAskChange = this.handleAskChange.bind(this);
    this.handleFilterAutocomplete = this.handleFilterAutocomplete.bind(this);
  }

  handleChange = (field, value, isSelected) => {
    const { filters, minAsk, maxAsk, setFilters } = this.props;
    const nextFilters = _.cloneDeep(filters);

    const filtersByField = _.keyBy(nextFilters, 'field');
    const filter = filtersByField[field];

    const optionsByValue = _.keyBy(filter.options, 'value');
    const option = optionsByValue[value];
    option.isSelected = isSelected;

    setFilters({
      filters: nextFilters,
      minAsk,
      maxAsk,
    });
  }

  handleFilterAutocomplete(inputValue, option) {
    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleAskChange(range) {
    const { onFilterChange } = this.props;
    this.setState({
      visible: false,
    });

    onFilterChange('application.ask', range);
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      filters,
      items,
      minAsk,
      maxAsk,
      values,
      onClearFilters,
      onFilterChange,
    } = this.props;

    const hasItemsWithinAsk = _.some(items, (item) => (item.application.ask >= minAsk) && (item.application.ask <= maxAsk));
    const hasFilters = Object.keys(values).length > 0;

    return (
      <div className={styles.filters}>
        <div className={styles.filters__container}>
          <div>
            <h5>Ask size</h5>
            {numeral(minAsk).format('$0,0')} to {numeral(maxAsk).format('$0,0')}
            &nbsp;<a onClick={this.showModal}>(change)</a>
            <RefineSizeModal
              items={items}
              range={!hasFilters ? [0, 100] : null}
              visible={this.state.visible}
              onOk={this.handleAskChange}
              onCancel={this.handleCancel}
            />
          </div>
          {hasItemsWithinAsk && filters.map((filter) => {
            const { field, name: fieldName, options } = filter;
            const dataSource = options.map(({ name, value }) => {
              return {
                text: name,
                value,
              };
            });
            return (
              <div key={field} className={styles.filterField}>
                <h5>{fieldName}</h5>
                <Select
                  filterOption={this.handleFilterAutocomplete}
                  mode="tags"
                  placeholder={`Type to filter ${fieldName.toLowerCase()}`}
                  value={values[field]}
                  onChange={onFilterChange.bind(this, field)}
                >
                  {dataSource.map(({ text, value }) => (
                    <Option key={value} value={value}>{text}</Option>
                  ))}
                </Select>
              </div>
            );
          })}
        </div>
        { hasFilters && (
          <div className="filters__reset">
            <Button
              type="primary"
              onClick={onClearFilters}
            >
              Reset Filters
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default Refine;
