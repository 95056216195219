import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styles from './interactive-map.scss';

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY; // eslint-disable-line prefer-destructuring

const MAP_LOADED_EVENT = 'googleMapLoaded';

window.initGoogleMaps = () => window.dispatchEvent(new CustomEvent(MAP_LOADED_EVENT));

if (!window.google || !window.google.maps) {
  const scriptTag = document.createElement('script');
  const scriptURL = `//maps.google.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initGoogleMaps`;
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute('src', scriptURL);
  (document.getElementsByTagName('head')[0] || document.documentElement).appendChild(scriptTag);
}

class InteractiveMap extends PureComponent {
  static propTypes = {
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    disableDefaultUI: PropTypes.bool,
    mapTypeControl: PropTypes.bool,
    markers: PropTypes.array,
    maxZoom: PropTypes.number,
    zoom: PropTypes.number,
    onMarkerClick: PropTypes.func,
    onMarkerOut: PropTypes.func,
    onMarkerOver: PropTypes.func,
  };

  static defaultProps = {
    center: {
      lat: 40.7128,
      lng: -74.0060
    },
    disableDefaultUI: true,
    mapTypeControl: false,
    markers: [],
    maxZoom: 20,
    zoom: 12,
    onMarkerClick: () => {},
    onMarkerOut: () => {},
    onMarkerOver: () => {},
  };

  constructor(props) {
    super(props);

    this.map = null;
    this.mapElemRef = React.createRef();
    this.markers = [];
    this.infoWindows = [];
  }

  componentDidMount() {
    if (!window.google || !window.google.maps) {
      window.addEventListener(MAP_LOADED_EVENT, this.renderMap.bind(this));
    } else {
      this.renderMap();
    }
  }

  componentDidUpdate() {
    this.renderMap();
  }

  clearMarkers({
    markers,
    force = false,
  }) {
    this.markers.forEach((marker) => {
      if (force || !markers.find((m) => m.id === marker.id)) {
        marker.setMap(null);
      }
    });
  }

  fitInBounds() {
    const bounds = this.markers.reduce((acc, marker) => {
      return acc.extend(marker.getPosition());
    }, new window.google.maps.LatLngBounds());
    this.map.fitBounds(bounds);
    this.map.setZoom(Math.min(this.map.getZoom() - 1, this.props.maxZoom));
  }

  isCurrentCenter({ lat, lng }) {
    const mapCenter = this.map.getCenter();
    return parseInt(mapCenter.lat(), 10) === parseInt(lat, 10)
      && parseInt(mapCenter.lng(), 10) === parseInt(lng, 10);
  }

  renderMap() {
    const {
      markers,
      ...props
    } = this.props;

    if (!this.map) {
      this.map = new window.google.maps.Map(this.mapElemRef.current, {
        ...props,
        clickableIcons: false,
        // 20200316JP: POI are very helpful for map context, let's leave them on.
        // styles: [{
        //   featureType: 'poi',
        //   elementType: 'labels',
        //   stylers: [
        //     { visibility: 'off' }
        //   ]
        // }],
      });
    } else if (this.props.center && !this.isCurrentCenter(this.props.center)) {
      const center = new window.google.maps.LatLng(this.props.center.lat, this.props.center.lng);
      this.map.panTo(center);
    }


    const currentMarkers = this.markers.map((m) => m.id);
    const newMarkers = markers.map((m) => m.id);

    if (!_isEqual(currentMarkers, newMarkers)) {
      this.clearMarkers({ markers });
      this.markers = markers.reduce((acc, { id, lat, lng, title }, index) => {
        const hasLatLng = lat && lng;

        const foundMarker = this.markers.find((m) => m.id === id);
        if (foundMarker) {
          return [
            ...acc,
            foundMarker,
          ];
        }

        if (!hasLatLng) {
          return acc;
        }

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <div id="content">
              <div id="bodyContent">
                <p>${title}</p>
              </div>
            </div>
          `
        });

        const marker = new window.google.maps.Marker({
          id: id || index,
          infoWindow,
          position: {
            lat,
            lng,
          },
          map: this.map,
          optimized: false,
          scaledSize: new window.google.maps.Size(27, 43),
          title,
        });

        marker.addListener('mouseout', () => {
          this.props.onMarkerOut({ id, lat, lng, title }, index, marker);
          infoWindow.close();
        });

        marker.addListener('mouseover', () => {
          this.props.onMarkerOver({ id, lat, lng, title }, index, marker);
          infoWindow.open(this.map, marker);
        });

        marker.addListener('click', () => {
          this.props.onMarkerClick({ id, lat, lng, title }, index, marker);
        });

        return [
          ...acc,
          marker,
        ];
      }, []);
      if (this.markers.length > 0) {
        this.fitInBounds();
      }
    }
  }

  updateMarkerIcon(index) {
    return (icon) => {
      if (this.markers[index] && this.markers[index].setIcon) {
        this.markers[index].setIcon(new window.google.maps.Marker({
          ...icon,
          map: this.map
        }));
      }
    };
  }

  render() {
    return (
      <div className={styles.map} ref={this.mapElemRef}></div>
    );
  }
}

export default InteractiveMap;
