import _capitalize from 'lodash/capitalize';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import classnames from 'classnames';
import numeral from 'numeral';
import { withApollo } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Radio, Spin } from 'antd';
import { auth, routes } from '@usurp-power/aqua-silver';
import withContractorApplication, { WITH_CONTRACTOR_APPLICATION } from '$components/contractor/screens/withContractorApplication';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import Unauthorized from '$components/common/Unauthorized';
import EdrResultsGrid from '$components/common/EdrEvents/EdrResultsGrid';
import getRiskLevel from '$components/RiskRow/getRiskLevel';

import styles from './styles.scss';

const CREATE_SCORE_ESTIMATE = gql`
  mutation CreateScoreEstimate($input: CreateScoreEstimateInput!) {
    createScoreEstimate(input: $input) {
      contractorApplication {
        uuid
        scoreEstimate {
          propertyClass
          environmentalRisk
          creditRisk
          bankability
          capRate
        }
      }
    }
  }
`;

const yn = (val) => (val ? 'Yes' : 'No');

const Field = ({ label, value }) => {
  return (
    <div className={classnames(styles.field, styles.facts)}>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.fieldValue}>{value}</div>
    </div>
  );
};

const ScoreComparison = ({ label, estimate, value }) => {
  return (
    <div className={classnames(styles.field, styles.comparison)}>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.fieldValue}>{estimate}</div>
      <div className={styles.fieldValue}>{value}</div>
    </div>
  );
};

const Estimate = ({ label, children }) => {
  return (
    <div className={classnames(styles.field, styles.estimate)}>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.fieldInput}>
        {children}
      </div>
    </div>
  );
};

class ScoringEvaluate extends Component {
  constructor(props) {
    super(props);

    const { contractorApplication } = props;
    const { application } = contractorApplication;

    this.state = {
      formLoading: false,
    };

    if (/^pending/.test(application.status)) {
      setTimeout(this.checkApplication, 5000);
    }
  }

  checkApplication = async () => {
    const { client, contractorApplication } = this.props;

    const { data: { contractorApplication: { application } } } = await client.query({
      query: WITH_CONTRACTOR_APPLICATION,
      variables: { uuid: contractorApplication.uuid },
      fetchPolicy: 'network-only',
    });

    if (/^pending/.test(application.status)) {
      setTimeout(this.checkApplication, 5000);
    }
  }

  renderColumnForMode = () => {
    const { formLoading, formError } = this.state;
    const { contractorApplication, form: { getFieldDecorator } } = this.props;
    const { application, scoreEstimate } = contractorApplication;
    const { usurp } = application;

    if (scoreEstimate) {
      const {
        propertyClass,
        capRate,
        environmentalRisk,
        creditRisk,
        bankability,
      } = scoreEstimate;

      return (
        <div className={classnames(styles.fields, styles.comparison)}>
          <ScoreComparison label="" estimate="Estimate" value="Scored" />
          <ScoreComparison label="Property class" estimate={propertyClass} value={usurp.propertyClass} />
          <ScoreComparison label="Cap rate" estimate={numeral(capRate).format('0.00%')} value={numeral(usurp.capRate).format('0.00%')} />
          <ScoreComparison label="Environmental risk" estimate={_capitalize(environmentalRisk)} value={_capitalize(getRiskLevel({ value: usurp.environmentalScore, type: 'environmental' }))} />
          <ScoreComparison label="Credit risk" estimate={_capitalize(creditRisk)} value={_capitalize(getRiskLevel({ value: usurp.creditScore, type: 'credit' }))} />
          <ScoreComparison label="Bankability" estimate={bankability} value={usurp.usurpRating} />
        </div>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className={classnames(styles.fields, styles.estimates)}>
          <Estimate>Estimate</Estimate>
          <Estimate label="Property class" name="propertyClass">
            {getFieldDecorator('propertyClass', {
              rules: [{ required: true }],
            })(
              <Radio.Group name="propertyClass" buttonStyle="solid">
                <Radio.Button value="A">A</Radio.Button>
                <Radio.Button value="B">B</Radio.Button>
                <Radio.Button value="C">C</Radio.Button>
              </Radio.Group>
            )}
          </Estimate>
          <Estimate label="Capitalization rate" name="capRate">
            {getFieldDecorator('capRate', {
              rules: [{ required: true }],
            })(
              <Input addonAfter="%" placeholder="5.05" />
            )}
          </Estimate>
          <Estimate label="Environmental risk" name="environmentalRisk">
            {getFieldDecorator('environmentalRisk', {
              rules: [{ required: true }],
            })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="low">Low</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="high">High</Radio.Button>
              </Radio.Group>
            )}
          </Estimate>
          <Estimate label="Credit risk" name="creditRisk">
            {getFieldDecorator('creditRisk', {
              rules: [{ required: true }],
            })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="low">Low</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="high">High</Radio.Button>
              </Radio.Group>
            )}
          </Estimate>
          <Estimate label="Bankability" name="bankability">
            {getFieldDecorator('bankability', {
              rules: [{ required: true }],
            })(
              <Radio.Group buttonStyle="solid" className={styles.bankabilityRadioGroup}>
                <Radio.Button value="AAA">AAA</Radio.Button>
                <Radio.Button value="AA">AA</Radio.Button>
                <Radio.Button value="A">A</Radio.Button>
                <br />
                <Radio.Button value="BBB">BBB</Radio.Button>
                <Radio.Button value="BB">BB</Radio.Button>
                <Radio.Button value="B">B</Radio.Button>
                <br />
                <Radio.Button value="CCC">CCC</Radio.Button>
                <Radio.Button value="CC">CC</Radio.Button>
                <Radio.Button value="C">C</Radio.Button>
                <br />
                <Radio.Button value="D">D</Radio.Button>
              </Radio.Group>
            )}
          </Estimate>
          <div className={styles.formSubmit}>
            <div className={styles.formError}>{formError && 'All fields are required.'}</div>
            <Button size="small" type="primary" htmlType="submit" loading={formLoading}>Submit</Button>
          </div>
        </div>
      </Form>
    );
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { client, contractorApplication } = this.props;
    const { uuid: contractorApplicationUuid } = contractorApplication;

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.setState({
          formError: err,
        });
        return;
      }

      this.setState({
        formLoading: true,
      });

      const {
        propertyClass,
        capRate: rawCapRate,
        environmentalRisk,
        creditRisk,
        bankability,
      } = values;

      const capRate = numeral(rawCapRate).value() / 100;

      await client.mutate({
        mutation: CREATE_SCORE_ESTIMATE,
        variables: {
          input: {
            contractorApplicationUuid,
            propertyClass,
            capRate,
            environmentalRisk,
            creditRisk,
            bankability,
          },
        },
      });

      this.setState({
        formLoading: false,
      });
    });
  }

  renderEnvironmental = () => {
    const { contractorApplication } = this.props;
    const { application } = contractorApplication;
    const { edrStatus } = application;
    const { property } = application;
    const eventMatrix = (() => {
      try {
        return JSON.parse(property.edrEventMatrix);
      } catch (e) { /* NOP */ }
      return null;
    })();


    if (edrStatus !== 'done') {
      return (
        <div className={classnames(styles.fields, styles.facts)} style={{ padding: 8 }}>
          <div>Environmental screen pending.</div>
        </div>
      );
    }

    if (eventMatrix) {
      return (
        <EdrResultsGrid eventMatrix={eventMatrix} />
      );
    }

    return (
      <div className={classnames(styles.fields, styles.facts)} style={{ padding: 8 }}>
        <div>No environmental data found.</div>
      </div>
    );
  }

  render() {
    const { currentUser, location, contractorApplication } = this.props;
    const { application } = contractorApplication;


    if (!auth.canScore(currentUser)) {
      return <Unauthorized />;
    }

    const {
      status: applicationStatus,
    } = application;

    const nav = (
      <div className={styles.nav}>
        <div className={styles.propertyAddress}>{application.rawAddress}</div>
        <Link to={routes.scoring.overview}><Button>New address</Button></Link>
      </div>
    );

    if (/^failed/.test(applicationStatus)) {
      return (
        <DefaultLayout
          location={location}
          currentUser={currentUser}
        >
          {nav}
          <div className={styles.info}>
            <p>We couldn&apos;t automatically screen this property; please try another address.</p>
            <Link to={routes.scoring.overview}><Button>New address</Button></Link>
          </div>
        </DefaultLayout>
      );
    }

    console.log('STATUS', application);

    if (/^pending/.test(applicationStatus)) {
      return (
        <DefaultLayout
          location={location}
          currentUser={currentUser}
        >
          {nav}
          <div className={styles.info}>
            <p>Data augmentation and scoring in progress, please wait...</p>
            <p>This typically takes less than two minutes.</p>
            <Spin />
          </div>
        </DefaultLayout>
      );
    }

    const { usurp } = application;

    return (
      <DefaultLayout
        location={location}
        currentUser={currentUser}
      >
        {nav}
        <div>
          <div className={styles.grids}>
            <div className={styles.column}>
              <div className={classnames(styles.fields, styles.facts)}>
                <Field label="" value="Data" />
                <Field label="Property type" value={usurp.propertyType} />
                <Field label="Tax asssessed value" value={numeral(usurp.taxAssessedValue).format('$0,0')} />
                <Field label="Debt" value={numeral(usurp.debt).format('$0,0')} />
                <Field label="Sqft" value={numeral(usurp.sqft).format('0,0')} />
                <Field label="Multiple owners" value={_capitalize(usurp.multipleTitleOwners)} />
                <Field label="Owner occupied" value={yn(usurp.ownerOccupied)} />
                <Field label="Single tenant" value={yn(usurp.singleTenant)} />
              </div>

              {this.renderEnvironmental()}
            </div>

            <div className={styles.column}>
              {this.renderColumnForMode()}
            </div>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

export default Form.create()(withApollo(withContractorApplication(ScoringEvaluate)));
