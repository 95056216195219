import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import numeral from 'numeral';
import SpinnerError from '$components/common/SpinnerError';

import { camelToTitle } from '../../../lib/formatter';

import waccDefault from './images/wacc-default.png';
import waccIndustrial from './images/wacc-industrial.png';
import waccMultifamily from './images/wacc-multifamily.png';
import waccOffice from './images/wacc-office.png';
import waccRetailNotMall from './images/wacc-retailNotMall.png';
import waccShoppingCenterAndStripMalls from './images/wacc-shoppingCenterAndStripMalls.png';

import styles from './wacc.scss';

const APPLICATION_WACC = gql`
  query ApplicationWacc($applicationUuid: ID!){
      applicationWacc(applicationUuid: $applicationUuid) {
      augmentedCapRate
      capRates {
        month1
        month2
        month3
        month6
        year1
        year2
        year3
        year5
        year7
        year10
        year20
        year30
      },
      city,
      propertyType,
      state
      capRateCategory
    }
  }
`;

const waccCharts = {
  default: {
    header: '',
    image: waccDefault,
  },
  industrial: {
    header: 'National Cap Rates for Light Industrial and Warehouse',
    image: waccIndustrial,
  },
  multifamily: {
    header: 'National Cap Rates for Multifamily',
    image: waccMultifamily,
  },
  office: {
    header: 'National Cap Rates for Office',
    image: waccOffice,
  },
  retailNotMall: {
    header: 'National Cap Rates for Retail (excluding malls) by City Type',
    image: waccRetailNotMall,
  },
  shoppingCenterAndStripMalls: {
    header: 'National Cap Rates for Shopping Centers and Malls by City Type',
    image: waccShoppingCenterAndStripMalls,
  },
};

class ThatsWacc extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
  };

  render() {
    const { applicationUuid } = this.props;

    return (
      <Query query={APPLICATION_WACC} variables={{ applicationUuid }}>
        {({ data, error, loading }) => {
          if (loading || error) {
            return (
              <SpinnerError error={error} />
            );
          }

          const {
            augmentedCapRate,
            city,
            propertyType,
            capRateCategory,
          } = data.applicationWacc;

          const _propertyType = camelToTitle(propertyType).toLowerCase();

          const waccEntry = waccCharts[capRateCategory];
          const { image: waccChartImage, header: waccChartHeader } = waccEntry;

          const waccClasses = classNames(styles.wacc__container, 'wacc');

          return (
            <div className={waccClasses}>
              <div className={styles.wacc__close}>
                <Button
                  icon="close"
                  shape="circle"
                  type="primary"
                  onClick={this.props.onClose}
                />
              </div>
              <div className={styles.wacc}>
                <div className={styles.wacc__text}>
                  <div className={styles.wacc__title}>
                    That&apos;s WACC!
                  </div>
                  <div className={styles.wacc__subtext}>
                    Cash is not free; and a cash investment is not always cheaper than a loan.
                  </div>
                  <div className={styles.wacc__subtext}>
                    The cost of money – known as the Weighted Average Cost of Capital (WACC) - tells you the cost of the cash that is currently in your bank account. The money in your bank account may have come from your company’s profits, but those profits came at a steep price. You may have spent money on marketing, operations, or overhead; some of the money you spent may have come from a loan, line of credit, an investor, or maybe even your personal savings. So, how do you know how much that cash costs?
                  </div>
                  <div className={styles.wacc__copy}>
                    One way is to find a reliable comparison. In your case, a reliable comparison could be to group the most premium quality {_propertyType} properties in {city}. Their cost of cash is currently {numeral(augmentedCapRate).format('0.00%')}. So, if you’re not part of this group, your cost of cash is likely higher than theirs.
                  </div>
                  <div className={styles.wacc__subtext}>
                    For more information on WACC and our estimates for cost of cash, visit <a rel="noopener noreferrer" href="https://unety.io/thats-wacc" target="_blank">www.unety.io/resources</a>.
                  </div>
                </div>
                <div className={styles.wacc__graph}>
                  <div className={styles.wacc__graph__header}>{waccChartHeader}</div>
                  <img className={styles.wacc__graph__image} alt={`Wacc chart for ${capRateCategory}`} src={waccChartImage} />
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default ThatsWacc;
