import React, { Component } from 'react';

class OfferAccepted extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center', padding: '32px' }}>
        Thank you, your response has been recorded and the lender will contact you.<br />
        <br />
        You may close this window.
      </div>
    );
  }
}

export default OfferAccepted;
