import _includes from 'lodash/includes';
import _without from 'lodash/without';
import React, { Component } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Button, Form, Select } from 'antd';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import SpinnerError from '$components/common/SpinnerError';
import FindContractorItem from '$components/Applicant/FindContractorItem';

import ProjectScreenLayout from '../ProjectScreenLayout';
import styles from './styles.scss';

const { Option } = Select;

const INVITE_CONTRACTORS = gql`
  mutation InviteContractorsToProject($input: InviteContractorsToProjectInput!) {
    inviteContractorsToProject(input: $input) {
      contractorApplication {
        uuid
        scopeOfWork

        contractorProjectInvitations {
          uuid

          contractor {
            uuid
            company
            postalCode
          }
          status
        }
      }
    }
  }
`;

const FIND_CONTRACTORS = gql`
  query GetClosestContractor($address: String!) {
    getClosestContractor(address: $address) {
      uuid
      address
      company
      contactName
      distance
      email
      lat
      lng
      locality
      phone
      postalCode
      route
      state
      streetNumber
      url
      mapUrl
      isEnterprise
      range
    }
  }
`;

class FindContractorsScreen extends Component {
  constructor(props) {
    super(props);

    // 20200116JP: This would be faster as a hash, but since we're only showing like
    //  twenty items it probably doesn't matter.
    const selectedContractorUuids = [];

    this.state = {
      selectedContractorUuids,
    };
  }

  handleToggle = (uuid) => {
    const { selectedContractorUuids } = this.state;

    const nextSelectedContractorUuids = _includes(selectedContractorUuids, uuid)
      ? _without(selectedContractorUuids, uuid)
      : selectedContractorUuids.concat(uuid);

    this.setState({
      selectedContractorUuids: nextSelectedContractorUuids,
    });
  }

  handleInviteContractors = ({ contractorApplication, inviteContractors }) => {
    const { form } = this.props;
    const { selectedContractorUuids } = this.state;

    form.validateFields(async (err, values) => {
      const { scopeOfWork } = values;
      if (!scopeOfWork || (scopeOfWork.length === 0)) {
        this.setState({
          scopeOfWorkError: 'Please select a scope of work above.',
        });
        return;
      }

      inviteContractors({
        variables: {
          input: {
            contractorApplicationUuid: contractorApplication.uuid,
            contractorUuids: selectedContractorUuids,
            scopeOfWork,
          },
        },
      });
    });
  }

  render() {
    const { currentUser, form } = this.props;
    const { getFieldDecorator } = form;
    const { scopeOfWorkError, selectedContractorUuids } = this.state;
    const canSelectMoreContractors = (selectedContractorUuids.length < 3);
    const hasSelectedContractors = (selectedContractorUuids.length > 0);

    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          const { uuid: contractorUuid, application, scopeOfWork: scopeOfWorkJsonString } = contractorApplication;
          const { uuid: applicationUuid, rawAddress } = application;
          const signUpLink = `${routes.auth.signUp}?contractorApplicationUuid=${contractorUuid}&applicationUuid=${applicationUuid}`;

          const scopeOfWork = (() => {
            let result = [];
            try {
              result = JSON.parse(scopeOfWorkJsonString);
            } catch (e) { /* NOP */ }
            return result;
          })();

          return (
            <Mutation mutation={INVITE_CONTRACTORS}>
              {(inviteContractors, { data: inviteContractorsData, error: inviteContractorsError, loading: inviteContractorsLoading }) => {
                if (inviteContractorsError || inviteContractorsLoading) {
                  return (
                    <div className={styles.invitedLayout}>
                      <SpinnerError error={inviteContractorsError} />
                    </div>
                  );
                }

                if (inviteContractorsData) {
                  return (
                    <div className={styles.invitedLayout}>
                      <i className="fad fa-check-circle" />
                      <div className={styles.invitedTitle}>Selected contractors have been invited</div>
                      <div className={styles.invitedText}>
                        We&apos;ve emailed the contractors you selected with information about your project. If interested, the contractors will reach out to you directly. You can view the status of your invitations on your project dashboard.
                      </div>
                      <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid })}>
                        <Button type="primary">Back to dashboard</Button>
                      </Link>
                    </div>
                  );
                }

                return (
                  <div className={styles.layout}>
                    <div className={styles.title}>Find contractors</div>
                    <div className={styles.description}>
                      <div>Feel free to reach out directly to these pre-screened contractors in your area.</div>
                      {/* <div>
                        Select up to three contractors to share your project with. They will not be able to view your financing information, but will be able to see the following project information in order to help them determine if the project is a fit and contact you:
                      </div>
                      <ul>
                        <li>Property postal code</li>
                        <li>Estimated project amount</li>
                        <li>Your email address</li>
                        <li>Scope of work</li>
                      </ul>

                      <Form.Item label="Scope of work">
                        {getFieldDecorator('scopeOfWork', {
                          rules: [{ required: true, message: 'Required' }],
                          initialValue: (scopeOfWork || []),
                        })(
                          <Select
                            data-aaa="scopeOfWork"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Click to select"
                          >
                            <Option key="Renewables">Renewables</Option>
                            <Option key="Envelope">Envelope</Option>
                            <Option key="Lighting">Lighting</Option>
                            <Option key="Landscaping">Landscaping</Option>
                            <Option key="Hot Water">Hot Water</Option>
                            <Option key="HVAC">HVAC</Option>
                            <Option key="Water">Water</Option>
                            <Option key="Other">Other</Option>
                          </Select>
                        )}
                      </Form.Item> */}
                    </div>

                    <Query query={FIND_CONTRACTORS} variables={{ address: rawAddress }}>
                      {({ data, loading, error }) => {
                        if (loading || error) {
                          return <SpinnerError error={error} />;
                        }

                        const { getClosestContractor } = data;

                        return (
                          <div className={styles.contractorList}>
                            {getClosestContractor.map((contractor) => (
                              <FindContractorItem
                                key={contractor.uuid}
                                contractor={contractor}
                                onChange={() => this.handleToggle(contractor.uuid)}
                                selected={_includes(selectedContractorUuids, contractor.uuid)}
                                disabled={inviteContractorsLoading || (!_includes(selectedContractorUuids, contractor.uuid) && !canSelectMoreContractors)}
                              />
                            ))}
                          </div>
                        );
                      }}
                    </Query>
                    <div className={styles.scopeOfWorkError}>{scopeOfWorkError}</div>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default Form.create()(FindContractorsScreen);
