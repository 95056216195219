/* eslint-disable */
// THIS FILE IS GENERATED
// SEE /src/scripts/lender-generator to update the source

module.exports = {
  "americasChristianCreditUnion": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "America’s Christian Credit Union",
    "size": "mid"
  },
  "a2B2LLC": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "A2B2 LLC",
    "size": "mid"
  },
  "academyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Academy Bank",
    "size": "mid"
  },
  "allyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Ally Bank",
    "size": "mid"
  },
  "allyCapital": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Ally Capital",
    "size": "mid"
  },
  "amalgamatedBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "yes",
    "name": "Amalgamated Bank",
    "size": "mid"
  },
  "americanAgriculturalBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "American Agricultural Bank",
    "size": "mid"
  },
  "americanRiverBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "American River Bank",
    "size": "mid"
  },
  "americanWestBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "American West Bank",
    "size": "mid"
  },
  "ameripriseFinancial": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Ameriprise Financial",
    "size": "mid"
  },
  "ameritasInvestmentPartners": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Ameritas Investment Partners",
    "size": "mid"
  },
  "bankOfAmerica": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "yes",
    "name": "Bank of America",
    "size": "very large"
  },
  "bankOfAnnArbor": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bank of Ann Arbor",
    "size": "mid"
  },
  "bankOfNewYorkMellon": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bank of New York Mellon",
    "size": "mid"
  },
  "bankOfSullivan": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bank of Sullivan",
    "size": "mid"
  },
  "bankOfTheWest": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Bank of the West",
    "size": "mid"
  },
  "bankRhodeIsland": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bank Rhode Island",
    "size": "mid"
  },
  "bankwell": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bankwell",
    "size": "mid"
  },
  "bannerBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Banner Bank",
    "size": "mid"
  },
  "bBCNBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "BBCN Bank",
    "size": "mid"
  },
  "bellcoCreditUnion": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bellco Credit Union",
    "size": "mid"
  },
  "berkshireBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Berkshire Bank",
    "size": "mid"
  },
  "bMOHarrisBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "BMO Harris Bank",
    "size": "mid"
  },
  "bremerBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bremer Bank",
    "size": "mid"
  },
  "bridgeBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Bridge Bank",
    "size": "mid"
  },
  "businessLendersLLC": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Business Lenders LLC",
    "size": "mid"
  },
  "byzantineDioceseOfStamford": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Byzantine Diocese of Stamford",
    "size": "mid"
  },
  "cABAssociates": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "CAB Associates",
    "size": "mid"
  },
  "californiaBankAndTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "California Bank and Trust",
    "size": "mid"
  },
  "californiaPlanOfChurchFinanceInc": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "California Plan of Church Finance, Inc",
    "size": "mid"
  },
  "capitalRegionDevelopmentAuthority": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Capital Region Development Authority",
    "size": "mid"
  },
  "cenCalBusinessFinanceGroup": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Cen-Cal Business Finance Group",
    "size": "mid"
  },
  "charterOakFederalCreditUnion": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Charter Oak Federal Credit Union",
    "size": "mid"
  },
  "chase": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Chase",
    "size": "mid"
  },
  "chelseaGrotonBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Chelsea Groton Bank",
    "size": "mid"
  },
  "chelseaStateBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Chelsea State Bank",
    "size": "mid"
  },
  "chestnutz": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Chestnutz",
    "size": "mid"
  },
  "cheviotSavingsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Cheviot Savings Bank",
    "size": "mid"
  },
  "circleBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Circle Bank",
    "size": "mid"
  },
  "citibank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Citibank",
    "size": "very large"
  },
  "citizensBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Citizens Bank",
    "size": "mid"
  },
  "citizensBusinessBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Citizens Business Bank",
    "size": "mid"
  },
  "cityNationalBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "City National Bank",
    "size": "mid"
  },
  "commerceBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Commerce Bank",
    "size": "mid"
  },
  "connecticutInnovations": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Connecticut Innovations",
    "size": "mid"
  },
  "cTDeptOfEconomicCommunityDevelopment": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "CT Dept of Economic & Community Development",
    "size": "mid"
  },
  "cTBCBankCorporation": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "CTBC Bank Corporation",
    "size": "mid"
  },
  "cWCapital": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "CW Capital",
    "size": "mid"
  },
  "departmentOfEconomicAndCommunityDevelopmentCT": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Department of Economic and Community Development (CT)",
    "size": "mid"
  },
  "deutscheBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "yes",
    "name": "Deutsche Bank",
    "size": "very large"
  },
  "dimeBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Dime Bank",
    "size": "mid"
  },
  "districtOfColumbiaHousingAuthority": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "District of Columbia Housing Authority",
    "size": "mid"
  },
  "eagleBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "yes",
    "name": "Eagle Bank",
    "size": "mid"
  },
  "enterpriseBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Enterprise Bank",
    "size": "mid"
  },
  "exchangeBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Exchange Bank",
    "size": "mid"
  },
  "fairfieldCountyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Fairfield County Bank",
    "size": "mid"
  },
  "farmCreditEast": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Farm Credit East",
    "size": "mid"
  },
  "farmingtonBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Farmington Bank",
    "size": "mid"
  },
  "fifthThirdBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Fifth Third Bank",
    "size": "large"
  },
  "firstBankOfBoulder": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "First Bank of Boulder",
    "size": "mid"
  },
  "firstBankOfLakeForest": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "First Bank of Lake Forest",
    "size": "mid"
  },
  "firstCitizensBankTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "First Citizens Bank & Trust",
    "size": "mid"
  },
  "firstCommunityBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "First Community Bank",
    "size": "mid"
  },
  "firstCountyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "First County Bank",
    "size": "mid"
  },
  "firstMountainBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "First Mountain Bank",
    "size": "mid"
  },
  "firstNationalBankOfBoulder": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "First National Bank of Boulder",
    "size": "mid"
  },
  "firstNiagaraBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "yes",
    "name": "First Niagara Bank",
    "size": "mid"
  },
  "firstRepublicBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "First Republic Bank",
    "size": "mid"
  },
  "firstStateBankTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "First State Bank & Trust",
    "size": "mid"
  },
  "firstUtahBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "First Utah Bank",
    "size": "mid"
  },
  "fiveStarBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Five Star Bank",
    "size": "mid"
  },
  "flatironsBankOfBoulder": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Flatirons Bank of Boulder",
    "size": "mid"
  },
  "folsomLakeBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Folsom Lake Bank",
    "size": "mid"
  },
  "frandsenBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Frandsen Bank",
    "size": "mid"
  },
  "freeAndClear": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Free and Clear",
    "size": "mid"
  },
  "genworthLifeInsCo": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Genworth Life Ins. Co",
    "size": "mid"
  },
  "goldenPacificBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Golden Pacific Bank",
    "size": "mid"
  },
  "greatWesternBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Great Western Bank",
    "size": "mid"
  },
  "greaterSacramentoDevelopmentCorp": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Greater Sacramento Development Corp.",
    "size": "mid"
  },
  "gRSRealtyLLC": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "GRS Realty, LLC",
    "size": "mid"
  },
  "guarantyBankAndTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Guaranty Bank and Trust",
    "size": "mid"
  },
  "hanmiBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Hanmi Bank",
    "size": "mid"
  },
  "helabaLandesbankHesSenThringen": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Helaba Landesbank Hes- sen-Thüringen",
    "size": "mid"
  },
  "heritageBankOfCommerce": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Heritage Bank of Commerce",
    "size": "mid"
  },
  "hometownBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Hometown Bank",
    "size": "mid"
  },
  "hSHNordbank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "HSH Nordbank",
    "size": "mid"
  },
  "jPMorganChase": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "JP Morgan Chase",
    "size": "very large"
  },
  "keyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Key Bank",
    "size": "mid"
  },
  "layneFoundation": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Layne Foundation",
    "size": "mid"
  },
  "lehmanBrothers": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Lehman Brothers",
    "size": "mid"
  },
  "libertyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Liberty Bank",
    "size": "mid"
  },
  "litchfieldBancorp": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Litchfield Bancorp",
    "size": "mid"
  },
  "mTBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "M&T Bank",
    "size": "mid"
  },
  "marionHaddad": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Marion Haddad",
    "size": "mid"
  },
  "mcCoyVentures": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "McCoy Ventures",
    "size": "mid"
  },
  "mechanicsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Mechanics Bank",
    "size": "mid"
  },
  "mercedesBenzFinancialServices": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Mercedes Benz Financial Services",
    "size": "mid"
  },
  "metropolitanLifeInsurance": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Metropolitan Life Insurance",
    "size": "very large"
  },
  "michiganBusinessConnectionLLC": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Michigan Business Connection LLC",
    "size": "mid"
  },
  "monsonSavingsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Monson Savings Bank",
    "size": "mid"
  },
  "mortgageCalmwaterCapital": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Mortgage Calmwater Capital",
    "size": "mid"
  },
  "mutualOfOmahaBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Mutual of Omaha Bank",
    "size": "mid"
  },
  "nationalIronBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "National Iron Bank",
    "size": "mid"
  },
  "newResourceBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "New Resource Bank",
    "size": "mid"
  },
  "newtekSmallBusinessFinanceLLC": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Newtek Small Business Finance, LLC",
    "size": "mid"
  },
  "newtownSavingsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Newtown Savings Bank",
    "size": "mid"
  },
  "northEastCommunityBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "NorthEast Community Bank",
    "size": "mid"
  },
  "northwestMutualInsurance": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Northwest Mutual Insurance",
    "size": "mid"
  },
  "nUVOBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "NUVO Bank",
    "size": "mid"
  },
  "nYStateTeachersRetirementSystem": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "NY State Teachers’ Retirement System",
    "size": "mid"
  },
  "oritaniBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Oritani Bank",
    "size": "mid"
  },
  "pacificAllianceBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Pacific Alliance Bank",
    "size": "mid"
  },
  "pacificPremierBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Pacific Premier Bank",
    "size": "mid"
  },
  "pacificUnion7thDayAdventists": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Pacific Union 7th Day Adventists",
    "size": "mid"
  },
  "parkerMortgageInvestmentCo": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Parker Mortgage & Investment Co.",
    "size": "mid"
  },
  "patriotBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Patriot Bank",
    "size": "mid"
  },
  "peoplesNationalBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "People’s National Bank",
    "size": "mid"
  },
  "peoplesBankOfMassachusetts": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Peoples Bank of Massachusetts",
    "size": "mid"
  },
  "peoplesUnitedBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Peoples United Bank",
    "size": "mid"
  },
  "plumasBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Plumas Bank",
    "size": "mid"
  },
  "preferredBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Preferred Bank",
    "size": "mid"
  },
  "premierValleyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Premier Valley Bank",
    "size": "mid"
  },
  "presbyterianChurchInvestmentLoanProgramInc": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Presbyterian Church Investment & Loan Program, Inc.",
    "size": "mid"
  },
  "proAmerica": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "ProAmerica",
    "size": "mid"
  },
  "prosperityBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Prosperity Bank",
    "size": "mid"
  },
  "protectiveLifeInsurance": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Protective Life Insurance",
    "size": "mid"
  },
  "prudentialInsurance": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Prudential Insurance",
    "size": "very large"
  },
  "pyraMaxBankMilwaukeeEconomicDevelopmentCorporation": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "PyraMax Bank, Milwaukee Economic Development Corporation",
    "size": "mid"
  },
  "redwoodCreditUnion": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Redwood Credit Union",
    "size": "mid"
  },
  "riversourceLifeInsuranceCompany": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Riversource Life Insurance Company",
    "size": "mid"
  },
  "rocklandTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Rockland Trust",
    "size": "mid"
  },
  "rockvilleBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Rockville Bank",
    "size": "mid"
  },
  "royalBankOfCanada": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Royal Bank of Canada",
    "size": "very large"
  },
  "royalCreditUnion": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Royal Credit Union",
    "size": "mid"
  },
  "salisburyBankTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Salisbury Bank & Trust",
    "size": "mid"
  },
  "santaCruzCountyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Santa Cruz County Bank",
    "size": "mid"
  },
  "savingsInstituteBankTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Savings Institute Bank & Trust",
    "size": "mid"
  },
  "securityBankOfKansasCity": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Security Bank of Kansas City",
    "size": "mid"
  },
  "simsburyBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Simsbury Bank",
    "size": "mid"
  },
  "sonomaBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Sonoma Bank",
    "size": "mid"
  },
  "stancorpMortgageInvestors": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Stancorp Mortgage Investors",
    "size": "mid"
  },
  "standardLifeInsurance": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Standard Life Insurance",
    "size": "mid"
  },
  "stateBankOfDelano": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "State Bank of Delano",
    "size": "mid"
  },
  "sterlingSavingsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Sterling Savings Bank",
    "size": "mid"
  },
  "summitStateBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Summit State Bank",
    "size": "mid"
  },
  "tDBank": {
    "consentHistory": "yes",
    "consentHistoryType": "mixed",
    "consentPolicy": "unknown",
    "name": "TD Bank",
    "size": "large"
  },
  "thePrivateBankTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "The Private Bank & Trust",
    "size": "mid"
  },
  "thomastonSavingsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Thomaston Savings Bank",
    "size": "mid"
  },
  "torreyPinesBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Torrey Pines Bank",
    "size": "mid"
  },
  "toyotaFinancialServices": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Toyota Financial Services",
    "size": "mid"
  },
  "triCountiesBankPrivateParty": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "TriCounties Bank/Private party",
    "size": "mid"
  },
  "umpquaBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Umpqua Bank",
    "size": "mid"
  },
  "unionBankOfCalifornia": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Union Bank of California",
    "size": "mid"
  },
  "unionSavingsBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Union Savings Bank",
    "size": "mid"
  },
  "unitedBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "United Bank",
    "size": "mid"
  },
  "uPSCapitalBusinessCredit": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "UPS Capital Business Credit",
    "size": "mid"
  },
  "uSBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "US Bank",
    "size": "large"
  },
  "uSDepartmentOfAgricultureUSDA": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "US Department of Agriculture (USDA)",
    "size": "mid"
  },
  "uSSmallBusinessAdministrationSBA": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "US Small Business Administration (SBA)",
    "size": "large"
  },
  "vectraBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Vectra Bank",
    "size": "mid"
  },
  "washingtonDCHousingAuthority": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Washington D.C. Housing Authority",
    "size": "mid"
  },
  "washingtonTrust": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Washington Trust",
    "size": "mid"
  },
  "waterburyDevelopmentCorporation": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Waterbury Development Corporation",
    "size": "mid"
  },
  "websterBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Webster Bank",
    "size": "mid"
  },
  "wellsFargo": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "Wells Fargo",
    "size": "very large"
  },
  "westAmericaBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedMoreThanOnce",
    "consentPolicy": "unknown",
    "name": "West America Bank",
    "size": "mid"
  },
  "westerlyCommunityCreditUnion": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Westerly Community Credit Union",
    "size": "mid"
  },
  "westernBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Western Bank",
    "size": "mid"
  },
  "westfieldBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Westfield Bank",
    "size": "mid"
  },
  "whitcraftLLC": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Whitcraft, LLC",
    "size": "mid"
  },
  "whittierTrustCompanyOfNevada": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Whittier Trust Company of Nevada",
    "size": "mid"
  },
  "wilshireBank": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Wilshire Bank",
    "size": "mid"
  },
  "windsorFederalSavings": {
    "consentHistory": "yes",
    "consentHistoryType": "consentedOnce",
    "consentPolicy": "unknown",
    "name": "Windsor Federal Savings",
    "size": "mid"
  }
};
