import React, { Component } from 'react';
import { Form, Icon, Input, Button, Select, notification } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import storage from '$lib/storage';
import styles from '../styles.scss';

const { Option } = Select;

const LENDER_SIGNUP = gql`
  mutation LenderSignup($input: SignUpInput!) {
    lenderSignup(input: $input) {
      email
      accessToken
    }
  }
`;

class LenderSignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
    };

    this.filterOption = this.filterOption.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  filterOption(inputValue, option) {
    return option.props.children === 'Other'
      || option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  }

  handleSelect(val) {
    this.setState(() => ({
      showOtherField: val === 'Other',
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, input) => {
      if (!err) {
        this.setState({
          creating: true,
        });

        const { organizationUuid } = this.props;
        const { data } = await this.props.client.mutate({
          mutation: LENDER_SIGNUP,
          variables: {
            input: {
              ...input,
              organizationUuid,
              lenderFinancingTypes: JSON.stringify(input.lenderFinancingTypes),
            },
          },
        });

        const { lenderSignup } = data;

        if (!lenderSignup) {
          notification.error({
            message: 'Unauthorized',
            description: 'Please verify your inputs.',
          });
          this.setState({
            creating: false,
          });
          return;
        }

        const { email, accessToken } = lenderSignup;
        storage.setAuth({ email, accessToken });

        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    });
  };

  render() {
    const { creating } = this.state;
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form__large}>
        <Form.Item label="Full name">
          {getFieldDecorator('fullName', {
            rules: [{ required: true, message: 'Please input your name!' }],
          })(
            <Input
              placeholder="James White"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input a email!' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          )}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input a password!' }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
          )}
        </Form.Item>
        <Form.Item label="Lender Name">
          {getFieldDecorator('company', {
            rules: [{ required: true, message: 'Field is required!' }],
          })(
            <Input prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Lender" />
          )}
        </Form.Item>
        <Form.Item label="Financing types supported">
          {getFieldDecorator('lenderFinancingTypes', {
            rules: [{ required: true, message: 'Field is required!' }],
          })(
            <Select
              data-aaa="lenderFinancingTypes"
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Click to select"
            >
              <Option key="pace">PACE</Option>
              <Option key="ppa">PPA</Option>
              <Option key="efa">EFA</Option>
              <Option key="lease">Lease</Option>
              <Option key="hard-money">Hard money</Option>
              <Option key="on-bill">OnBill</Option>
              <Option key="other">Other</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading || creating}
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(withRouter(withApollo(LenderSignupForm)));
