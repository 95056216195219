import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import numeral from 'numeral';
import gql from 'graphql-tag';
import { Button } from 'antd';
import SpinnerError from '$components/common/SpinnerError';

import styles from './styles.scss';

const ORGANIZATION = gql`
  query Organization($slug: String!) {
    organization(slug: $slug) {
      uuid
      name

      stats {
        totalContractorApplications
        totalApplications
        totalApplicationsClosed
        totalApplicationsProjectAmount
        totalContractorsContacted
        totalContractorsMatched
        trailing30DayContractorApplications
        trailing30DayApplications
        trailing30DayApplicationsClosed
        trailing30DayApplicationsProjectAmount
        trailing30DayContractorsContacted
        trailing30DayContractorsMatched
        totalCarbonSavedPounds
        totalCarbonSavedTrees
        trailing30DayCarbonSavedPounds
        trailing30DayCarbonSavedTrees
      }
    }
  }
`;

const STATS = [{
  key: 'totalContractorApplications',
  key30: 'trailing30DayContractorApplications',
  text: 'Properties screened',
  icon: 'fad fa-house',
}, {
  key: 'totalApplications',
  key30: 'trailing30DayApplications',
  text: 'Applications submit',
  icon: 'fad fa-envelope-open-text',
}, {
  key: 'totalApplicationsClosed',
  key30: 'trailing30DayApplicationsClosed',
  text: 'Applications closed',
  icon: 'fad fa-envelope-open-dollar',
}, {
  key: 'totalApplicationsProjectAmount',
  key30: 'trailing30DayApplicationsProjectAmount',
  text: 'Project financing',
  icon: 'fad fa-money-check-alt',
  prefix: '$',
}, {
  key: 'totalContractorsContacted',
  key30: 'trailing30DayContractorsContacted',
  text: 'Contractors contacted',
  icon: 'fad fa-address-card',
}, {
  key: 'totalContractorsMatched',
  key30: 'trailing30DayContractorsMatched',
  text: 'Contractors matched',
  icon: 'fad fa-comments',
}, {
  key: 'totalCarbonSavedPounds',
  key30: 'trailing30DayCarbonSavedPounds',
  text: 'Carbon saved (lbs)',
  icon: 'fad fa-clouds-sun',
}, {
  key: 'totalCarbonSavedTrees',
  key30: 'trailing30DayCarbonSavedTrees',
  text: 'Carbon saved (trees)',
  icon: 'fad fa-trees',
}];


const StatsCard = (props) => {
  const { value, text, icon, prefix } = props;

  return (
    <div className={styles.card}>
      <div className={styles.valueLine}>
        <div className={styles.value}>{prefix}{numeral(value).format('0,0')}</div>
        <i className={icon} />
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

class ManagerOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: 'Lifetime',
    };
  }

  render() {
    const {
      currentUser,
    } = this.props;
    const { period } = this.state;

    const { organization } = currentUser;
    const { slug, name } = organization;

    return (
      <div className={styles.layout}>
        <div className={styles.header}>
          <div className={styles.title}>{period} stats for {name}</div>
          {(period === 'Lifetime') ? (
            <Button onClick={() => this.setState({ period: '30 day' })}>show 30 day</Button>
          ) : (
            <Button onClick={() => this.setState({ period: 'Lifetime' })}>show lifetime</Button>
          )}
        </div>
        <Query query={ORGANIZATION} variables={{ slug }}>
          {({ data, loading, error }) => {
            if (loading || error) {
              return <SpinnerError error={error} />;
            }

            const { organization: { stats } } = data;

            return (
              <div className={styles.stats}>
                {STATS.map((stat) => {
                  const { key, key30, text, prefix, icon } = stat;
                  const keyToUse = (period === 'Lifetime') ? key : key30;
                  const value = stats[keyToUse];

                  return (
                    <StatsCard
                      key={key}
                      icon={icon}
                      prefix={prefix}
                      text={text}
                      value={value}
                    />
                  );
                })}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ManagerOverview;
