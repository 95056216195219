import React from 'react';
import MAP_PATHS from './mapPaths';
import styles from './styles.scss';

const BASE_MAP_URL = 'http://localhost:5000';

const PropertiesGrid = () => {

  return (
    <div className={styles.layout}>
      {MAP_PATHS.map((mapPath) => {
        const mapUrl = `${BASE_MAP_URL}/${mapPath}`;
        return (
          <img key={mapUrl} src={mapUrl} className={styles.img} />
        );
      })}
    </div>
  );
};

export default PropertiesGrid;
