import _ from 'lodash';
import gql from 'graphql-tag';
import keyMirror from 'keymirror';
import numeral from 'numeral';
import pluralize from 'pluralize';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Alert, Button, Icon, notification, PageHeader } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import { Query, Mutation } from '@apollo/react-components';
import { routes } from '@usurp-power/aqua-silver';
import CURRENT_USER from '$queries/currentUser';
import DASHBOARD_QUERIES from '$queries/dashboard';

import ListItem from './ListItem';
import styles from './styles.scss';

const SUBMIT_QUOTES = gql`
  mutation SubmitQuotes($quotes: [ApplicationQuoteInput!]!) {
    submitQuotes(quotes: $quotes) {
      _empty
    }
  }
`;

const UPDATE_QUOTES = gql`
  mutation UpdateQuotes($uuid: String!, $quotes: [ApplicationQuoteInput]!) {
    updateQuotes(uuid: $uuid, quotes: $quotes) @client
  }
`;

const STEPS = keyMirror({
  quote: null,
  submitting: null,
  success: null,
});

const camelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

const generateNewQuote = ({ currentUser }) => ({ application }) => {
  const lenderPreferences = (() => {
    try {
      return JSON.parse(currentUser.lenderPreferences) || { conformingPropertyTypes: [] };
    } catch (e) { /* NOP */ }
    return {};
  })();

  const {
    applicantMessage,
    conformingPropertyTypes,
    fees,
    prepaymentTerms,
    rates,
  } = lenderPreferences;

  const isConforming = _.includes(conformingPropertyTypes, camelCase(application.propertyType));
  const defaultTerm = _.get(rates, 'default') || '20';
  const defaultFees = _.get(fees, isConforming ? 'conforming' : 'nonConforming');
  const rate = isConforming ? _.get(rates, `${defaultTerm}.conforming`) : _.get(rates, `${defaultTerm}.nonConforming`);

  const defaultQuote = {
    applicationUuid: application.uuid,
    uuid: uuidv4(),
    ask: application.ask,
    term: defaultTerm,
    rate: rate || '',
    prepayment: prepaymentTerms || '',
    fees: defaultFees || '',
    message: applicantMessage || '',
  };

  return defaultQuote;
};

class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: STEPS.quote,
    };
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  submitQuotes = ({ mutation }) => {
    const { items } = this.props;
    const quotes = _.flatten(items.map((item) => {
      const sanitizedQuotes = _.cloneDeep(item.quoteStatus.quotes);
      sanitizedQuotes.forEach((sanitizedQuote) => {
        /* eslint-disable no-param-reassign */
        delete sanitizedQuote.__typename;
        sanitizedQuote.rate = parseFloat(sanitizedQuote.rate, 10) / 100;
        sanitizedQuote.ask = parseInt(sanitizedQuote.ask, 10);
        if (Number.isNaN(sanitizedQuote.ask)) {
          notification.error({
            message: 'Input error',
            description: `Ask "${sanitizedQuote.ask}" is invalid; please enter an integer number.`,
          });
          throw new Error(`Unable to parseInt(ask): ${sanitizedQuote.ask}`);
        }
        sanitizedQuote.term = parseInt(sanitizedQuote.term, 10);
        if (Number.isNaN(sanitizedQuote.term)) {
          notification.error({
            message: 'Input error',
            description: `Term "${sanitizedQuote.term}" is invalid; please enter an integer number.`,
          });
          throw new Error(`Unable to parseInt(term): ${sanitizedQuote.term}`);
        }

        if (!Number.isFinite(sanitizedQuote.rate) && _.isEmpty(sanitizedQuote.rate)) {
          notification.error({
            message: 'Input error',
            description: 'Please enter rates for all quotes.',
          });
          throw new Error('Missing rate for quote.');
        }

        sanitizedQuote.rate = parseFloat(sanitizedQuote.rate);
        if (Number.isNaN(sanitizedQuote.rate)) {
          notification.error({
            message: 'Input error',
            description: `Rate "${sanitizedQuote.rate}" is invalid; please enter a decimal number.`,
          });
          throw new Error(`Unable to parseFloat(rate): ${sanitizedQuote.rate}`);
        }
      });
      return sanitizedQuotes;
    }));
    this.setState({
      step: STEPS.submitting,
    });
    mutation({ variables: { quotes } });
  }

  submitQuotesCompleted = () => {
    this.setState({
      step: STEPS.success,
    });
  }

  renderCta = () => {
    const { step } = this.state;

    return (
      <div style={{ textAlign: 'center' }}>
        <Mutation
          mutation={SUBMIT_QUOTES}
          onCompleted={this.submitQuotesCompleted}
          refetchQueries={[{ query: DASHBOARD_QUERIES }]}
        >
          {(mutation) => (
            <Button
              type="primary"
              loading={(step === STEPS.submitting)}
              onClick={() => this.submitQuotes({ mutation })}
            >
              Submit quotes
              <Icon type="right" />
            </Button>
          )}
        </Mutation>
      </div>
    );
  }

  render() {
    const { items } = this.props;
    const { step } = this.state;

    if (step === 'success') {
      return (
        <div className={styles.root}>
          <div className={styles.empty}>
            Quotes have been submit successfully.
          </div>
        </div>
      );
    }

    if (items.length === 0) {
      return (
        <div className={styles.root}>
          <div className={styles.empty}>
            Please select some applications first.
            <br />
            <br />
            <Button type="primary"><Link to={routes.lender.applications.new}>View applications</Link></Button>
          </div>
        </div>
      );
    }

    return (
      <Query query={CURRENT_USER}>
        {({ _loading, _error, data }) => {
          const { currentUser } = data;
          let lenderPreferences = {};

          try {
            lenderPreferences = JSON.parse(currentUser.lenderPreferences);
          } catch (e) { /* NOP */ }

          const totalAsk = _.sumBy(items, 'application.ask');

          return (
            <div id="quotes">
              <PageHeader backIcon={false} title="Prepare Quotes" />
              <div className={styles.root}>
                <div>
                  You have selected {pluralize('property', items.length, true)} totaling {numeral(totalAsk).format('$0,0')} in financing.
                </div>
                <br />
                <Alert message={<div>Quotes are prepopulated based on your <Link to={routes.lender.profile}>preferences</Link>.</div>} type="info" showIcon />

                <br />

                {_.map(items, (item) => {
                  return (
                    <ListItem
                      item={item}
                      key={item.uuid}
                      lenderPreferences={lenderPreferences}
                      messageText={(lenderPreferences && lenderPreferences.applicantMessage) || ''}
                      generateNewQuote={generateNewQuote({ currentUser })}
                    />
                  );
                })}
                {this.renderCta()}
              </div>
            </div >
          );
        }}
      </Query>
    );
  }
}

export default graphql(UPDATE_QUOTES)(withRouter(Quote));
