import React, { PureComponent } from 'react';
import ValueCalculator from '../ValueCalculator';

import styles from './application-form.scss';

class ProjectValueCalculator extends PureComponent {
  render() {
    return (
      <div className={styles.content}>
        <ValueCalculator />
      </div>
    );
  }
}

export default ProjectValueCalculator;
