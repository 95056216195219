const store = require('store');

const AUTH_KEY = 'auth';
const SESSION_UUID_KEY = 'sessionUuid';
const TEMP_AUTH_KEY = 'tempAuth';

const defaultAuth = {
  email: null,
  accessToken: null,
};
const clearAuth = () => store.set(AUTH_KEY, null);
const getAuth = () => store.get(AUTH_KEY) || defaultAuth;
const setAuth = (auth) => store.set(AUTH_KEY, auth);

const tempAuth = {
  clear: () => store.remove(TEMP_AUTH_KEY),
  get: () => {
    const tempAuthObj = store.get(TEMP_AUTH_KEY);
    if (!tempAuthObj) {
      return null;
    }

    return Object.assign(store.get(TEMP_AUTH_KEY), { isImpersonated: true });
  },
  set: (val) => (store.set(TEMP_AUTH_KEY, val)),
};

const sessionUuid = {
  get: () => store.get(SESSION_UUID_KEY),
  set: (val) => (store.set(SESSION_UUID_KEY, val)),
};

const storage = {
  clearAuth,
  getAuth,
  setAuth,
  sessionUuid,
  tempAuth,
};

export default storage;
