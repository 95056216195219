import classnames from 'classnames';
import numeral from 'numeral';
import React, { Component } from 'react';

import getTextForAvailability from '../getTextForAvailability';
import tooltipCopy from '../../../../constants/funding-options-tooltips';

import styles from './styles.scss';

const valueToIcon = (value) => {
  const icon = (() => {
    if (value >= 1) {
      return 'check';
    }

    if (value >= 0) {
      return 'exclamation-triangle';
    }

    return 'times';
  })();

  return (
    <i className={`fal fa-${icon}`} />
  );
};

class FundingOptionRow extends Component {
  render() {
    const {
      name,
      optionName,
      keyName,
      value,
    } = this.props;

    const subtext = tooltipCopy[optionName][keyName];

    return (
      <div className={styles.fundingOptionRow}>
        <div className={styles.fundingOptionRowHeader}>
          <div className={styles.fundingOptionRowName}>{name}</div>
          <div className={styles.fundingOptionRowSubtext}>{subtext}</div>
        </div>
        {valueToIcon(value)}
      </div>
    );
  }
}

class FundingOption extends Component {
  render() {
    const { fundingOption } = this.props;
    const {
      message,
      name,
      options,
      bestMatch,
      description,
    } = fundingOption;

    const {
      availability,
      abilityToSell,
      upfrontCost,
      closeTime,
      rate,
      rateText,
    } = options;

    const isAvailable = (availability === 'available');

    const rateFormatted = rateText || numeral(rate).format('%0.00');
    const displayMessage = bestMatch ? 'Best match!' : message;

    return (
      <div className={classnames(styles.fundingOption, !isAvailable && styles.unavailable)}>
        <div className={styles.name}>{name} <span className={styles.rate}>{rateFormatted}</span></div>
        <div className={styles.description}>{description}</div>
        <div className={styles.availability}>
          <div className={styles.availabilityTitle}>{getTextForAvailability(availability)}</div>
          {displayMessage && <div className={styles.availabilityMessage}>{displayMessage}</div>}
        </div>
        <FundingOptionRow
          keyName="abilityToSell"
          name="Ability to sell"
          optionName={name}
          value={abilityToSell}
        />
        <FundingOptionRow
          keyName="upfrontCost"
          name="Upfront cost"
          optionName={name}
          value={upfrontCost}
        />
        <FundingOptionRow
          keyName="closeTime"
          name="Close time"
          optionName={name}
          value={closeTime}
        />
      </div>
    );
  }
}

class ResponsiveFundingOptions extends Component {
  render() {
    const { fundingOptions } = this.props;

    return (
      <div className={styles.fundingOptions}>
        {fundingOptions.map((fundingOption) => {
          return (
            <FundingOption
              key={fundingOption.name}
              fundingOption={fundingOption}
            />
          );
        })}
      </div>
    );
  }
}

export default ResponsiveFundingOptions;
