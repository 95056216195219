import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';

import withContractorApplication from '../withContractorApplication';

import styles from './styles.scss';


class ProjectScreenLayout extends Component {
  render() {
    const {
      application,
      contractorApplication,
      usurp,

      children,
    } = this.props;

    const { uuid: contractorUuid } = contractorApplication;

    return (
      <div>
        <div className={styles.navigation}>
          <Link className={styles.addressBlock} data-aaa="backToDashboard" to={reverse(routes.contractor.projects.project.overview, { contractorUuid })}>
            <i className="fad fa-home" />
            <span className={styles.address}>Back to overview for {application.rawAddress}</span>
          </Link>
        </div>
        <div>
          {children({ application, contractorApplication, usurp })}
        </div>
      </div>
    );
  }
}

export default withContractorApplication(ProjectScreenLayout);
