import classNames from 'classnames';
import numeral from 'numeral';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Icon, Tooltip } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import { Query } from '@apollo/react-components';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';

import SpinnerError from '$components/common/SpinnerError';
import ResponsiveFundingOptions from './ResponsiveFundingOptions';
import tooltipCopy from '../../../constants/funding-options-tooltips';
import getTextForAvailability from './getTextForAvailability';

import styles from './table.scss';

const FUNDING_OPTIONS = gql`
  query ContractorApplication($uuid: ID!) {
    contractorApplication(uuid: $uuid) {
      uuid
      fundingOptions {
        name
        message
        score
        slug
        description
        options {
          availability
          rate
          rateText
          closeTime
          projectType
          projectSize
          upfrontCost
          contractDuration
          abilityToSell
          borrowingCapacity
          taxDeductions
        }
      }
    }
  }
`;

const rows = [{
  name: 'Close time',
  key: 'closeTime',
  tooltip: 'After I apply, how long will it take to receive the money?',
}, {
  name: 'Project type',
  key: 'projectType',
  tooltip: 'What sort of projects can I pay for with the financing?',
}, {
  name: 'Project size',
  key: 'projectSize',
  tooltip: 'How much money can I borrow or use with the financing?',
}, {
  name: 'Upfront cost',
  key: 'upfrontCost',
  tooltip: 'What sort of upfront costs, fees, or down payments relate to the financing?',
}, {
  name: 'Contract duration',
  key: 'contractDuration',
  tooltip: 'What is the most common length of the financing contract?',
}, {
  name: 'Ability to sell',
  key: 'abilityToSell',
  tooltip: 'How could it effect your ability to sell your property before the term expires?',
}, {
  name: 'Borrowing capacity',
  key: 'borrowingCapacity',
  tooltip: 'How could it effect your ability to apply for a loan in the future?',
}, {
  name: 'Tax deductions',
  key: 'taxDeductions',
  tooltip: 'How will it impact your taxes?',
}];

const rateRow = ({ contractorUuid }) => ({
  name: 'Effective rate',
  key: 'rate',
  tooltip: <div>All types of financing can be compared through the &quot;effective rate.&quot; The effective rate is the interest rate and/or other hidden costs of capital minus any rebates, pass-thurs, or incentives.<br />Visit our <Link to={reverse(routes.contractor.projects.project.valueCalculator, { contractorUuid })}>Cash flow calculator</Link> to see a more precise achievable effective rate for PACE, Loans, and OnBill.</div>,
});

const getRelevantInfo = ({ contractorApplication, key }) => {
  switch (key) {
    case 'closeTime':
      return `Your funding timeline: ${contractorApplication.fundingQuickness}.`;
    case 'projectType': {
      const { scopeOfWork } = contractorApplication;
      const stringVal = scopeOfWork ? JSON.parse(scopeOfWork).join(', ') : 'unspecified';
      return `Your project type: ${stringVal}.`;
    }
    case 'projectSize':
      return `Your project size: ${numeral(contractorApplication.projectSize).format('$0,0')}.`;
    case 'upfrontCost':
      return `Your desire to have low upfront cost: ${contractorApplication.zeroDownImportance}.`;
    case 'contractDuration':
      return `Your preferred financing term: ${contractorApplication.preferredFinancingTerm}.`;
    case 'abilityToSell':
      return `Your importance of preserving ability to sell: ${contractorApplication.sellAbilityFiveYears}.`;
    default:
      return null;
  }
};

const FundingOptionPoint = ({
  tooltipPlacement,
  tooltipTitle,
  value
}) => {
  const props = {
    className: styles.error,
    type: 'close',
  };

  const isMatch = value === 1;
  const isPartial = value === 0

  if (isMatch) {
    props.className = styles.check;
    props.type = 'check';
  }
  if (isPartial) {
    props.className = styles.warning;
    props.type = 'warning';
    props.theme = 'filled';
  }

  const {
    className,
    ...restProps
  } = props;

  const tooltipMessage = isMatch
    ? `Match: ${tooltipTitle}`
    : tooltipTitle

  return (
    <div className={[className, styles.fundingOptions__point__option].join(' ')}>
      <Tooltip placement={tooltipPlacement} title={tooltipMessage}>
        <span><Icon {...restProps} /></span>
      </Tooltip>
    </div>
  );
};

FundingOptionPoint.propTypes = {
  tooltipPlacement: PropTypes.string,
  tooltipTitle: PropTypes.string,
  value: PropTypes.oneOf([1, 0, -1]).isRequired
};

FundingOptionPoint.defaultProps = {
  tooltipPlacement: 'top',
};

class FundingOptionsTable extends PureComponent {
  static propTypes = {
    applicationUuid: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.content = React.createRef();
    this.scrollContainer = React.createRef();
    this.state = {
      actionsFloating: false,
      fundingOptions: null,
      offset: 0
    };

    this.handleContentScroll = this.handleContentScroll.bind(this);
    this.handleScrollOptions = this.handleScrollOptions.bind(this);
    this.handleSelectFunding = this.handleSelectFunding.bind(this);
  }

  async componentDidMount() {
    if (this.content.current) {
      this.content.current.addEventListener('scroll', this.handleContentScroll);
      this.handleContentScroll();
    }
  }

  handleContentScroll() {
    const elem = this.content.current;

    const actionsFloating = (elem.scrollTop !== (elem.scrollHeight - elem.offsetHeight));
    this.setState({
      actionsFloating,
    });
  }

  handleScrollOptions({ direction = 1 }) {
    this.setState({
      offset: this.state.offset + (250 * direction),
    });
  }

  handleSelectFunding(index) {
    this.setState({
      selectedIndex: index
    });
  }

  render() {
    const {
      contractorApplication,
      applicationUuid,
    } = this.props;

    const { uuid: contractorUuid } = contractorApplication;

    const {
      offset,
    } = this.state;

    return (
      <Query query={FUNDING_OPTIONS} variables={{ uuid: applicationUuid }}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return <SpinnerError error={error} />;
          }

          if (!data.contractorApplication.fundingOptions) {
            return (
              <div>
                Complete the <Link to={reverse(routes.contractor.projects.project.fundingOptions, { contractorUuid: contractorApplication.uuid })}>funding options survey</Link>.
              </div>
            );
          }

          const { available, unavailable } = data.contractorApplication.fundingOptions.reduce((acc, option) => {
            return {
              available: [
                ...acc.available,
                ...(option.options.availability !== 'unavailable' ? [option] : [])
              ],
              unavailable: [
                ...acc.unavailable,
                ...(option.options.availability === 'unavailable' ? [option] : [])
              ]
            };
          }, { available: [], unavailable: [] });

          const fundingOptions = [
            ...available.sort((a, b) => {
              if (a.score < b.score) {
                return 1;
              }
              if (a.score > b.score) {
                return -1;
              }
              return 0;
            }),
            ...unavailable,
          ];

          let selectedIndex = -1;
          let topScore = 0;
          fundingOptions.forEach(({ options: { availability: optionAvailability }, score }, i) => {
            if (optionAvailability !== 'unavailable' && score > topScore) {
              selectedIndex = i;
              topScore = score;
            }
          });

          if (selectedIndex !== -1) {
            fundingOptions[selectedIndex].bestMatch = true;
          }

          const bestMatchIndex = selectedIndex;

          const hasAvailableOption = fundingOptions && fundingOptions.filter(({ options: { availability } }) => availability !== 'unavailable').length > 0; // eslint-disable-line no-shadow
          const fundingOptionsClasses = classNames(styles.fundingOptions, 'fundingOptions', {
            [styles.fundingOptions__notAvailable]: !hasAvailableOption
          });
          const fundingOptionsContentClasses = classNames(styles.fundingOptions__content, {
            [styles.fundingOptions__content__notAvailable]: !hasAvailableOption
          });
          const fundingOptionsNavigationClasses = classNames(styles.fundingOptions__navigation, 'fundingOptions__navigation', {
            [styles.fundingOptions__navigation__disabled]: !hasAvailableOption
          });
          const legendClasses = classNames(styles.fundingOptions__legend, {
            [styles.fundingOptions__legend__notAvailable]: !hasAvailableOption
          });
          const blankClasses = classNames(styles.fundingOptions__chart__column__blank, 'fundingOptions__blank');
          const labelColumnClasses = classNames(styles.fundingOptions__chart__column, styles.fundingOptions__chart__column__labels);
          const chartClasses = classNames(styles.fundingOptions__chart, 'fundingOptions__chart');

          return (
            <>
              <div className={styles.responsiveLayout}>
                {fundingOptions && <ResponsiveFundingOptions fundingOptions={fundingOptions} />}
              </div>
              <div className={styles.staticLayout}>
                <div className={fundingOptionsClasses}>
                  {!hasAvailableOption && fundingOptions && (
                    <div className={styles.fundingOptions__notOptionsMessage}>
                      <h2>Based on the survey, there are no available funding options.</h2>
                      <ul>
                        <li>{fundingOptions[0].message}</li>
                        <li>{fundingOptions[1].message}</li>
                        <li>{fundingOptions[2].message}</li>
                      </ul>
                      <div className={styles.actions}>
                        <Link to={reverse(routes.contractor.projects.project.fundingOptionsSurvey, { contractorUuid })}>
                          <Button>Modify preferences</Button>
                        </Link>
                        <Link to={routes.contractor.projects.new}>
                          <Button type="primary">New project</Button>
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className={fundingOptionsContentClasses} ref={this.content}>
                    <div className={styles.fundingOptions__content__container}>
                      {fundingOptions && [
                        <div className={styles.fundingOptions__chart__container} key="title">
                          <div className={fundingOptionsNavigationClasses}>
                            <Button
                              disabled={offset === 0}
                              onClick={this.handleScrollOptions}
                            >
                              <Icon type="arrow-left" />
                            </Button>
                            <Button
                              disabled={offset === -1000}
                              onClick={this.handleScrollOptions.bind(this, { direction: -1 })}
                            >
                              View More
                              <Icon type="arrow-right" />
                            </Button>
                          </div>
                          <div className={chartClasses}>
                            <div className={labelColumnClasses}>
                              <div className={blankClasses} />
                              <div className={blankClasses} />
                              <div className={blankClasses} />
                              {rows.concat(rateRow({ contractorUuid })).map(({ name, key, tooltip }) => {
                                const relevantInfo = getRelevantInfo({ contractorApplication, key });
                                const personalizedTooltip = relevantInfo ? `${tooltip} ${relevantInfo}` : tooltip;
                                return (
                                  <div className={styles.fundingOptions__chart__label} key={`label-${key}`}>
                                    <Tooltip placement="top" title={personalizedTooltip}>
                                      <Icon type="info-circle" /> {name}
                                    </Tooltip>
                                  </div>
                                );
                              })}
                              <div className={blankClasses} />
                            </div>
                            <div>
                              <div
                                className={styles.fundingOptions_chartScrollContainer}
                                ref={this.scrollContainer}
                              >
                                <div style={{ transform: `translateX(${offset}px)` }}>
                                  {fundingOptions.map((option, i) => {
                                    const columnClasses = classNames(styles.fundingOptions__chart__column, 'fundingOptions__chart__column', {
                                      [styles.fundingOptions__chart__column__active]: selectedIndex === i,
                                      fundingOptions__chart__column__active: selectedIndex === i
                                    });
                                    const bestMatchClasses = classNames(styles.fundingOptions__chart__bestMatch, 'bestMatch', {
                                      [styles.fundingOptions__chart__bestMatch__active]: bestMatchIndex === i
                                    });

                                    const rate = option.options.rateText || numeral(option.options.rate).format('0.[00]%');

                                    return [
                                      <div className={columnClasses} key={`${option.name}-column`} onClick={this.handleSelectFunding.bind(this, i)}>
                                        <div className={bestMatchClasses}>
                                          {bestMatchIndex === i ? 'Best match' : ''}
                                        </div>
                                        <div className={styles.fundingOptions__chart__title}>
                                          <div>{option.name}</div>
                                          <div className={styles.fundingOptions__chart__description}>{option.description}</div>
                                        </div>
                                        <div className={styles.fundingOptions__chart__available}>
                                          {option.message && (
                                            <Tooltip placement="top" title={option.message}>
                                              <Icon type="info-circle" />
                                            </Tooltip>
                                          )}
                                          {getTextForAvailability(option.options.availability)}
                                        </div>
                                        {rows.map(({ key }) => {
                                          const value = option.options[key];
                                          const tooltipTitle = tooltipCopy[option.name][key];
                                          return (
                                            <FundingOptionPoint
                                              key={key}
                                              tooltipTitle={tooltipTitle}
                                              value={value}
                                            />
                                          );
                                        })}
                                        <div className={styles.fundingOptions__chart__column__rate}>{rate}</div>
                                        <div className={[styles.fundingOptions__chart__column__rate__button, styles.fundingOptions__chart__column__blank, 'fundingOptions__blank'].join(' ')} />
                                      </div>
                                    ];
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>,
                      ]}
                    </div>
                  </div>
                  <div className={legendClasses}>
                    <div className={styles.fundingOptions__legend__option}>
                      <Icon type="close" className={styles.error} />
                      <span>Not a match</span>
                    </div>
                    <div className={styles.fundingOptions__legend__option}>
                      <Icon type="warning" className={styles.warning} theme="filled" />
                      <span>Partial match</span>
                    </div>
                    <div className={styles.fundingOptions__legend__option}>
                      <Icon type="check" className={styles.check} />
                      <span>Match</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(withApollo(FundingOptionsTable));
