import React, { Component } from 'react';
import RiskIcon from '$components/RiskIcon';
import getRiskLevel from './getRiskLevel';
import styles from './riskRowStyles.scss';

class RiskRow extends Component {
  getRiskIconValue = () => {
    const { lenderPreferences, value, type } = this.props;

    const result = getRiskLevel({ lenderPreferences, value, type });
    return result;
  }

  render() {
    const { text } = this.props;

    return (
      <div className={styles.root}>
        <RiskIcon value={this.getRiskIconValue()} />
        <div className={styles.text}>{text}</div>
      </div>
    );
  }
}

export default RiskRow;
