import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import { Button, Checkbox, Icon, Input, Spin, Table } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { reverse } from 'named-urls';
import { isApplicationApproved } from '$lib/application';
import OnboardingProgress from '$components/common/OnboardingProgress';
import ResponsiveProjects from './ResponsiveProjects';

import styles from './list.scss';

const { Search } = Input;

const CONTRACTOR_APPLICATIONS = gql`
  query ContractorApplications{
    contractorApplications {
      uuid
      address
      createdAt
      favorite
      projectType
      numberFundingOptions
      application {
        expiresAt
        rawAddress
        property {
          postalCode
          googleStaticMapUrl
          googleStreetViewUrl
          streetNumber
          route
        }
        status
        usurp {
          environmentalScore,
          propertyType
          taxDelinquency
          titleScore
          usurpScore
          postalCodeValid
          taxAssessedValue
        }
      }
    }
  }
`;

const FAVORITE_CONTRACTOR_APPLICATIONS = gql`
  mutation ContractorApplication($uuid: ID!, $favorite: Boolean){
      favoriteContractorApplication(uuid: $uuid, favorite: $favorite) {
        uuid
        favorite
      }
  }
`;

const generateTableData = (list) => {
  return list.map((data) => {
    const { application } = data;
    const { status, property, usurp } = application;
    const {
      environmentalScore,
      propertyType,
      taxDelinquency,
      titleScore,
      usurpScore,
    } = usurp;

    const { approved } = isApplicationApproved({
      applicationStatus: status,
      environmentalScore,
      propertyType,
      taxDelinquency,
      titleScore,
      usurpScore,
    });

    return {
      address: data.application.rawAddress,
      approved,
      createdAt: data.createdAt,
      favorite: data.favorite,
      fundingOptions: data.numberFundingOptions,
      uuid: data.uuid,
      property,
      usurp,
    };
  });
};

class ProjectList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: true,
      searchTerm: '',
      sortInfo: null,
    };

    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    this.handleFavoriteFilter = this.handleFavoriteFilter.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

    this.columnData = [
      {
        dataIndex: '',
        title: '',
        render: ({ favorite, uuid }, _x, index) => { // eslint-disable-line react/display-name
          return (
            <button
              className={styles.projectListDesktop__favoriteRowButton}
              onClick={this.handleFavoriteClick.bind(this, { uuid, index })}
            >
              <Icon type="star" theme={favorite ? 'filled' : ''} />
            </button>
          );
        }
      },
      {
        dataIndex: 'address',
        title: 'Address',
        key: 'address',
        sorter: (a, b) => a.address - b.address,
      },
      {
        className: 'center-column',
        dataIndex: 'fundingOptions',
        title: '# of funding options',
        key: 'fundingOptions',
        sorter: (a, b) => a.numberFundingOptions - b.numberFundingOptions,
      },
      {
        className: 'center-column',
        dataIndex: '',
        key: 'approved',
        render: ({ approved, uuid }) => ( // eslint-disable-line react/display-name
          <Link to={reverse(routes.contractor.projects.project.fundingOptions, { contractorUuid: uuid })}>
            <Icon
              className={approved ? styles.success : styles.error}
              type={approved ? 'check' : 'warning'}
            />
          </Link>
        ),
        sorter: (a, b) => a.approved - b.approved,
        title: 'Good candidate',
      },
      {
        className: 'center-column',
        dataIndex: '',
        title: 'Created',
        key: 'createdAt',
        render: ({ createdAt }) => moment.unix(createdAt / 1000).fromNow(),
        sorter: (a, b) => a.createdAt - b.createdAt,
      },
      {
        className: 'right-column',
        dataIndex: '',
        key: 'uuid',
        render: ({ uuid }) => { // eslint-disable-line react/display-name
          return (
            <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid: uuid })}>
              <Button>
                View
              </Button>
            </Link>
          );
        },
        title: '',
      }
    ];
  }

  async componentDidMount() {
    try {
      const { data } = await this.props.client.query({
        fetchPolicy: 'no-cache',
        query: CONTRACTOR_APPLICATIONS,
      });

      const filteredContractorApplications = data.contractorApplications.filter((contractorApplication) => _.get(contractorApplication, 'application.property'));
      const list = generateTableData(filteredContractorApplications);

      this.setState(() => ({
        initialList: list,
        list,
        loading: false
      }));
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  }

  filterList({
    searchTerm = this.state.searchTerm,
    favoritesOnly = this.state.favoritesOnly,
    sortInfo = this.state.sortInfo
  }) {
    const {
      initialList,
    } = this.state;

    let newList = initialList.slice(0);

    if (favoritesOnly) {
      newList = newList.filter((item) => item.favorite);
    }

    if (searchTerm) {
      newList = newList.filter(({ address }) => address.toLowerCase().indexOf(searchTerm) > -1);
    }

    if (sortInfo) {
      newList.sort((a, b) => {
        if (a[sortInfo.field] < b[sortInfo.field]) {
          return sortInfo.order === 'ascend' ? -1 : 1;
        }

        if (a[sortInfo.field] > b[sortInfo.field]) {
          return sortInfo.order === 'ascend' ? 1 : -1;
        }
        return 0;
      });
    }

    this.setState({
      favoritesOnly,
      list: newList,
      searchTerm,
      sortInfo
    });
  }

  async handleFavoriteClick({ uuid, index }) {
    const { list } = this.state;
    const favorite = !list[index].favorite;
    list[index].favorite = favorite;

    try {
      await this.props.client.mutate({
        mutation: FAVORITE_CONTRACTOR_APPLICATIONS,
        variables: {
          favorite,
          uuid,
        }
      });
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }

    this.setState({
      list
    }, () => this.forceUpdate());
  }

  handleFavoriteFilter(evt) {
    this.filterList({
      favoritesOnly: evt.target.checked
    });
  }

  handleSearchChange(evt) {
    this.filterList({
      searchTerm: evt.target.value.toLowerCase()
    });
  }

  handleTableChange(pagination, filters, sorter) {
    this.filterList({
      sortInfo: sorter,
    });
  }

  render() {
    const { currentUser } = this.props;

    const {
      initialList,
      list,
      loading,
      searchTerm,
    } = this.state;

    const searchEl = (
      <Search
        placeholder="123 Main St"
        style={{ width: 200 }}
        value={searchTerm}
        onChange={this.handleSearchChange}
      />
    );

    return (
      <div className={styles.layout}>
        {/* <OnboardingProgress currentUser={currentUser} /> */}
        {loading && (
          <div className={styles.projectList}>
            <div className={styles.spinnerContainer}>
              <Spin className={styles.projectList__loader} tip="Loading your projects" />
            </div>
          </div>
        )}
        {!loading && (initialList && (initialList.length > 0)) && (
          <>
            <div className={styles.projectListDesktop}>
              <div className={styles.projects}>
                <div className={styles.tableHeader}>
                  <div className={styles.tableHeader__actions}>
                    <div className={styles.tableHeader__favoriteFilter}>
                      <Checkbox onChange={this.handleFavoriteFilter}>Favorites only</Checkbox>
                    </div>
                    {searchEl}
                  </div>
                </div>
                <Table
                  dataSource={list}
                  rowKey="uuid"
                  columns={this.columnData}
                  onChange={this.handleTableChange}
                />
              </div>
            </div>
            <div className={styles.projectListMobile}>
              <ResponsiveProjects
                list={list}
                searchEl={searchEl}
              />
            </div>
          </>
        )}
        <div className={styles.benefits}>
          <div className={styles.benefitsText}>
            <p>Leverage Unety&apos;s property evaluation engine to:</p>
            <ul>
              <li>Screen your own projects for credit risks</li>
              <li>Discover how much funding is available for your projects</li>
              <li>Match projects to funding options from sources you know and trust</li>
              <li>Generate customizable reports about the costs and benefits of each funding option</li>
            </ul>
          </div>
          <Link to={routes.contractor.projects.new}>
            <Button type="primary">
              <i className={'fas fa-plus'} style={{ marginRight: 8}} />
              New project
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withApollo(ProjectList);
