import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './styles.scss';

class MessageRotator extends Component {
  static propTypes = {
    delay: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    delay: 5000,
  };

  constructor(props) {
    super(props);

    this.interval = null;
    this.state = {
      currentIndex: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.handleInterval.bind(this), this.props.delay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleInterval() {
    const newIndex = (this.state.currentIndex + 1) % this.props.messages.length;

    this.setState({
      currentIndex: newIndex,
    });
  }

  render() {
    const {
      messages
    } = this.props;
    const {
      currentIndex
    } = this.state;
    return (
      <div className={styles.messageContainer}>
        {messages.map((message, i) => {
          const classnames = classNames(styles.message, {
            [styles.message__active]: i === currentIndex,
            [styles.message__waiting]: i !== currentIndex,
          });

          return <div key={i} className={classnames} dangerouslySetInnerHTML={{ __html: message }} />;
        })}
      </div>
    );
  }
}

export default MessageRotator;
