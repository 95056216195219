import React, { Component } from 'react';
import { notification, Input, Button, Form } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const UPDATE_PASSWORD = gql`
  mutation UserUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      user {
        uuid
      }
      errors {
        detail
      }
    }
  }
`;

class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { client, form } = this.props;

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({
        loading: true,
      });

      const { currentPassword, newPassword } = values;

      const { data } = await client.mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          input: {
            currentPassword,
            newPassword,
          },
        },
      });

      const { updateUserPassword } = data;
      const { errors } = updateUserPassword;

      if (!errors) {
        notification.success({
          message: 'Account password updated',
        });
      } else {
        notification.error({
          message: 'Unable to update password',
          description: errors[0].detail,
        });
      }

      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { loading } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Item>
          {getFieldDecorator('currentPassword', {
            rules: [{ required: true, message: 'Please input your current password' }],
          })(
            <Input data-aaa="currentPassword" autoComplete="current-password" prefix={<i className="fal fa-lock" />} type="password" placeholder="Current password" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('newPassword', {
            rules: [{ required: true, message: 'Please input your new password' }],
          })(
            <Input data-aaa="newPassword" autoComplete="new-password" prefix={<i className="fal fa-lock" />} type="password" placeholder="New password" />
          )}
        </Form.Item>
        <Form.Item>
          <Button data-aaa="updatePasswordSubmit" type="primary" htmlType="submit" block loading={loading}>Update</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default withApollo(Form.create()(UpdatePasswordForm));
