import { geocode } from '@usurp-power/aqua-silver';

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY; // eslint-disable-line prefer-destructuring

export const convertParcelsLatLng = ({ parcels }) => {
  return parcels.reduce((acc, parcel) => [
    ...acc,
    {
      ...parcel,
      lat: parseFloat(parcel.lat),
      lng: parseFloat(parcel.lng),
      title: parcel.formattedAddress,
    }
  ], []);
};

export const parseAddress = async ({ location }) => {
  const addressResp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(location)}&key=${GOOGLE_API_KEY}`)
    .then((resp) => resp.json());

  return addressResp;
};

export const findDistance = ({
  lat1,
  lng1,
  lat2,
  lng2,
  unit = 'M'
}) => {
  if ((lat1 === lat2) && (lng1 === lng2)) {
    return 0;
  }

  const radlat1 = lat1 * (Math.PI / 180);
  const radlat2 = lat2 * (Math.PI / 180);
  const theta = lng1 - lng2;
  const radtheta = theta * (Math.PI / 180);
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = dist * (180 / Math.PI); // eslint-disable-line operator-assignment
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    return dist * 1.609344;
  }
  if (unit === 'N') {
    return dist * 0.8684;
  }
  return dist;
};

export const validateParcels = ({
  parcels,
  initialAddress,
  retry = true,
}) => {
  if (parcels.length === 0) {
    return false;
  }

  if (parcels.length === 1) {
    return true;
  }

  const _address = initialAddress.address.trim().toLowerCase();
  const _city = initialAddress.city.trim().toLowerCase();
  const _postalCode = initialAddress.postalCode.trim().toLowerCase();
  const _state = initialAddress.state.trim().toLowerCase();

  const matchesInitial = parcels.findIndex((parcel) => {
    return parcel.street.trim().toLowerCase().indexOf(_address) !== 0
      || parcel.city.trim().toLowerCase() !== _city
      || parcel.stateCode.trim().toLowerCase() !== _state
      || parcel.postalCode.trim() !== _postalCode;
  }) === -1;

  if (matchesInitial) {
    return true;
  }

  if (retry) {
    return false;
  }

  return validateParcels({
    initialAddress: {
      address: parcels[0].street,
      city: parcels[0].city,
      postalCode: parcels[0].postalCode,
      state: parcels[0].stateCode,
    },
    parcels: parcels.slice(1),
    retry: false,
  });
};

export const findClosestParcels = async ({
  parcels,
  initialAddress,
  returnCount = 7,
}) => {
  const {
    address,
    city,
    postalCode,
    state,
  } = initialAddress;

  let {
    lat,
    lng,
  } = initialAddress;

  if (!lat || !lng) {
    const urlAddress = `${address}, ${city}, ${state} ${postalCode}`;
    const addressResp = await parseAddress({
      location: urlAddress,
    });

    try {
      lat = addressResp.results[0].geometry.location.lat; // eslint-disable-line prefer-destructuring
      lng = addressResp.results[0].geometry.location.lng; // eslint-disable-line prefer-destructuring
    } catch (e) {
      return parcels;
    }
  }

  parcels = parcels // eslint-disable-line no-param-reassign
    .filter((par) => !Number.isNaN(parseInt(par.street, 10)))
    .map((par) => ({
      ...par,
      distance: findDistance({
        lat1: par.lat,
        lng1: par.lng,
        lat2: lat,
        lng2: lng,
      })
    })).sort((a, b) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      return 0;
    });

  return parcels.slice(0, returnCount);
};

export const parseParcels = async ({
  initialAddress,
  parcels,
}) => {
  const areParcelsValid = validateParcels({
    parcels,
    initialAddress,
  });

  if (areParcelsValid) {
    return {
      isValid: true,
      parcels: convertParcelsLatLng({ parcels })
    };
  }

  const newParcels = await findClosestParcels({
    parcels,
    initialAddress,
  });

  return {
    isValid: false,
    parcels: convertParcelsLatLng({ parcels: newParcels }),
  };
};

export const parseAddressWithFormatting = async ({ location }) => {
  const resp = await parseAddress({ location });

  if (!resp || !resp.results || !resp.results[0] || !resp.results[0]) {
    return null;
  }

  const parsedResult = geocode.parseGeocodeResult(resp.results[0]);

  const {
    addressComponents: {
      locality,
      sublocality,
      neighborhood,
      county,
      state,
      route,
      streetNumber,
      postalCode,
    },
    lat,
    lng,
  } = parsedResult;

  const cityish = locality || sublocality || neighborhood || county || state;

  return {
    number: streetNumber,
    city: cityish,
    street: route,
    zip: postalCode,
    state,
    locality: cityish,
    lat,
    lng,
  };
};
