import numeral from 'numeral';
import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import CommonSmallBlock from '../CommonSmallBlock';

import sharedStyles from '../CommonSmallBlock/shared.scss';
import styles from './styles.scss';

const NAME = 'Cash flow calculator';
const TIME = '6 min';

class ValueCalculatorBlock extends Component {
  render() {
    const {
      contractorApplication,
    } = this.props;

    const {
      uuid: contractorUuid,
      valueCalculator,
    } = contractorApplication;

    if (!valueCalculator) {
      return (
        <div className={styles.layout}>
          <Link to={reverse(routes.contractor.projects.project.valueCalculator, { contractorUuid })}>
            <Button>Calculate effective rate</Button>
          </Link>
        </div>
      );
    }

    const { effectiveCostPace } = valueCalculator;

    return (
      <CommonSmallBlock name={NAME} time={TIME}>
        <div className={sharedStyles.layout}>
          <div className={sharedStyles.title}>Your rate might be better than the sticker rate</div>
          <div className={sharedStyles.description}>
            If you have tenants, you may be able to lower your effective rate. Your estimate:
            <br />
            Effective rate: {numeral(effectiveCostPace).format('0.00%')}<br />
          </div>
          <Link to={reverse(routes.contractor.projects.project.valueCalculator, { contractorUuid })}>
            <Button type="primary">Calculate effective rates</Button>
          </Link>
        </div>
      </CommonSmallBlock>
    );
  }
}

export default ValueCalculatorBlock;
