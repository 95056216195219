import _ from 'lodash';
import React from 'react';
import { Form, Input, Select } from 'antd';
import { fieldDecoratorRules } from '../../../../constants/forms';
import { fieldError, formatNumber } from '../../../../lib/formatter';
import styles from '../styles.scss';

const { Option } = Select;

const PpaForm = ({
  errors,
  getFieldDecorator,
  values, // eslint-disable-line no-unused-vars
  className,
}) => {

  return (
    <div className={className}>
      <div className={styles.twoColumn}>
        <Form.Item
          label="Planned solar system size"
          validateStatus={fieldError('solarSystemSize', errors)}
        >
          {getFieldDecorator('solarSystemSize', {
            getValueFromEvent: (e) => formatNumber(e.target.value),
            rules: [{ required: true, message: 'Required' }],
            initialValue: formatNumber(values.solarSystemSize),
          })(
            <Input
              data-aaa="solarSystemSize"
              addonAfter="kW"
              placeholder={60}
              type="text"
            />
          )}
        </Form.Item>
        <Form.Item
          label="Expected first-year production"
          validateStatus={fieldError('solarFirstYearProduction', errors)}
        >
          {getFieldDecorator('solarFirstYearProduction', {
            getValueFromEvent: (e) => formatNumber(e.target.value),
            rules: [{ required: true, message: 'Required' }],
            initialValue: formatNumber(values.solarFirstYearProduction),
          })(
            <Input
              data-aaa="solarFirstYearProduction"
              addonAfter="kWh"
              placeholder="75,000"
              type="text"
            />
          )}
        </Form.Item>
      </div>
      <div className={styles.twoColumn}>
        <Form.Item
          label="Solar system type"
          validateStatus={fieldError('solarSystemType', errors)}
        >
          {getFieldDecorator('solarSystemType', {
            initialValue: values.solarSystemType,
            rules: fieldDecoratorRules,
          })(
            <Select placeholder="Select one" data-aaa="solarSystemType">
              <Option value="roofMounted">Roof-mounted</Option>
              <Option value="groundMounted">Ground-mounted</Option>
              <Option value="carportMounted">Carport-mounted</Option>
              <Option value="other">Other</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Property electric rate"
          validateStatus={fieldError('propertyElectricRate', errors)}
        >
          {getFieldDecorator('propertyElectricRate', {
            initialValue: values.propertyElectricRate,
          })(
            <Input
              data-aaa="propertyElectricRate"
              addonAfter="&cent;/kWh"
              placeholder="8.98"
              type="text"
            />
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default PpaForm;
