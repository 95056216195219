import _ from 'lodash';
import update from 'immutability-helper';
import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Button, Checkbox, Input, message, Radio, Select } from 'antd';
import { PROPERTY_TYPE_OBJECTS } from '$lib/filters';
import PREPAYMENT from '$lib/prepayment';
import RiskThresholdRow from './RiskThresholdRow';
import {
  THRESHOLD_ENVIRONMENTAL_LOW,
  THRESHOLD_ENVIRONMENTAL_MEDIUM,
  THRESHOLD_LENDER_CONSENT_LOW,
  THRESHOLD_LENDER_CONSENT_MEDIUM,
  THRESHOLD_TITLE_LOW,
  THRESHOLD_TITLE_MEDIUM,
} from '../RiskRow/riskThresholds';

import styles from './styles.scss';

const SET_LENDER_PREFERENCES = gql`
  mutation SetLenderPreferences($lenderPreferences: JSON!) {
    setLenderPreferences(lenderPreferences: $lenderPreferences) {
      uuid
      lenderPreferences
    }
  }
`;

const EMPTY_LENDER_PREFERENCES = {
  conformingPropertyTypes: [],
  applicantMessage: '',
  prepaymentTerms: '',
  fees: {
    conforming: '',
    nonConforming: '',
  },
  riskThresholds: {
    environmental: {
      low: THRESHOLD_ENVIRONMENTAL_LOW,
      medium: THRESHOLD_ENVIRONMENTAL_MEDIUM,
    },
    lenderConsent: {
      low: THRESHOLD_LENDER_CONSENT_LOW,
      medium: THRESHOLD_LENDER_CONSENT_MEDIUM,
    },
    title: {
      low: THRESHOLD_TITLE_LOW,
      medium: THRESHOLD_TITLE_MEDIUM,
    },
  },
  rates: {
    default: '20year',
    '5year': {
      conforming: '',
      nonConforming: '',
    },
    '10year': {
      conforming: '',
      nonConforming: '',
    },
    '15year': {
      conforming: '',
      nonConforming: '',
    },
    '20year': {
      conforming: '',
      nonConforming: '',
    },
  },
};

const formatNumber = (val) => parseFloat(val).toFixed(2);

class RateRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, evt) {
    const { handleRateChange, termKey } = this.props;

    if (evt.type === 'blur') {
      evt.target.value = formatNumber(evt.target.value); // eslint-disable-line no-param-reassign
    }
    const {
      value
    } = evt.target;
    const decimal = value.toString().split('.')[1];
    const errors = {};

    if (decimal && decimal.length > 2) {
      errors[field] = 'Rate is limited to hundreths';
      evt.target.value = formatNumber(evt.target.value); // eslint-disable-line no-param-reassign
    }

    this.setState(() => ({
      errors
    }));

    handleRateChange(evt, termKey, field);
  }

  render() {
    const { rates, term, termKey } = this.props;
    const { errors } = this.state;
    return (
      <div className={styles.rateRow}>
        <div className={styles.rateRowHeader}>
          <Radio value={termKey}>
            {term}
          </Radio>
        </div>
        <div className={styles.rateRowBody}>
          <div className={styles.inputContainer}>
            <Input
              addonAfter="%"
              defaultValue={formatNumber(rates[termKey].conforming)}
              type="number"
              value={rates[termKey].conforming}
              onBlur={this.handleChange.bind(this, 'conforming')}
              onChange={this.handleChange.bind(this, 'conforming')}
            />
            {errors.conforming && (
              <div className={styles.errorMessage}>
                {errors.conforming}
              </div>
            )}
          </div>
          <div className={styles.inputContainer}>
            <Input
              addonAfter="%"
              defaultValue={formatNumber(rates[termKey].nonConforming)}
              type="number"
              value={rates[termKey].nonConforming}
              onBlur={this.handleChange.bind(this, 'nonConforming')}
              onChange={this.handleChange.bind(this, 'nonConforming')}
            />
          </div>
        </div>
      </div>
    );
  }
}

class LenderPreferences extends Component {
  constructor(props) {
    super(props);

    const { lenderPreferences } = props;

    const nextLenderPreferences = {
      ...EMPTY_LENDER_PREFERENCES,
      ...lenderPreferences,
    };

    this.state = {
      exportLoading: false,
      lenderPreferences: nextLenderPreferences,
    };
  }

  onCompleted = () => {
    message.success('Preferences saved.');
  }

  setLenderPreferences = (mutate) => {
    const { lenderPreferences } = this.state;
    mutate({ variables: { lenderPreferences } });
  }

  togglePropertyType = (propertyTypeKey) => {
    const { lenderPreferences } = this.state;
    const { conformingPropertyTypes } = lenderPreferences;

    const nextConformingPropertyTypes = _.includes(conformingPropertyTypes, propertyTypeKey)
      ? _.without(conformingPropertyTypes, propertyTypeKey)
      : [...conformingPropertyTypes, propertyTypeKey];

    const nextLenderPreferences = {
      ...lenderPreferences,
      conformingPropertyTypes: nextConformingPropertyTypes,
    };

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  }

  handleRiskThresholdChange = ({ category, values }) => {
    const { lenderPreferences } = this.state;
    const [riskMedium, riskLow] = values;
    const nextLenderPreferences = update(lenderPreferences, {
      riskThresholds: {
        [category]: {
          low: { $set: riskLow / 100 },
          medium: { $set: riskMedium / 100 }
        }
      },
    });

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  };

  handleFeeChange = (e, field) => {
    const { lenderPreferences } = this.state;
    const nextLenderPreferences = update(lenderPreferences, {
      fees: { [field]: { $set: e.target.value } },
    });

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  }

  handleMessageChange = (e) => {
    const { lenderPreferences } = this.state;
    const nextLenderPreferences = update(lenderPreferences, {
      applicantMessage: { $set: e.target.value },
    });

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  }

  handlePrepaymentTermsChange = (e) => {
    const { target: { value } } = e;
    const { lenderPreferences } = this.state;
    const nextLenderPreferences = update(lenderPreferences, {
      prepaymentTerms: { $set: value },
    });

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  }

  handleDefaultRateChange = (e) => {
    const { lenderPreferences } = this.state;
    const nextLenderPreferences = update(lenderPreferences, {
      rates: { default: { $set: e.target.value } },
    });

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  }

  handleRateChange = (e, term, field) => {
    const { lenderPreferences } = this.state;
    const nextLenderPreferences = update(lenderPreferences, {
      rates: { [term]: { [field]: { $set: e.target.value } } },
    });

    this.setState({
      lenderPreferences: nextLenderPreferences,
    });
  }

  render() {
    const { lenderPreferences } = this.state;
    const {
      applicantMessage,
      conformingPropertyTypes,
      prepaymentTerms,
      fees,
      rates,
      riskThresholds,
    } = lenderPreferences;

    return (
      <div className={styles.root}>
        <Mutation mutation={SET_LENDER_PREFERENCES} onCompleted={this.onCompleted}>
          {(setLenderPreferences, { loading }) => (
            <div>
              <div className={styles.row}>
                <div className={styles.rowHeader}>Conforming Property Types</div>
                <div className={styles.rowContent}>
                  <div className={styles.propertyTypes}>
                    {PROPERTY_TYPE_OBJECTS.map((propertyTypeObj) => {
                      const { key, name } = propertyTypeObj;
                      const checked = _.includes(conformingPropertyTypes, key);

                      return (
                        <div key={key}>
                          <Checkbox
                            checked={checked}
                            onChange={() => this.togglePropertyType(key)}
                          >
                            {name}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowHeader}>Risk indicator thresholds</div>
                <div className={styles.rowContent}>
                  <div className={styles.radioGroup}>
                    <RiskThresholdRow
                      category="environmental"
                      categoryName="Environmental"
                      riskThresholds={riskThresholds}
                      platformDefaults={[20, 80]}
                      onChange={this.handleRiskThresholdChange}
                    />
                    <RiskThresholdRow
                      category="lenderConsent"
                      categoryName="Lender consent"
                      riskThresholds={riskThresholds}
                      platformDefaults={[30, 70]}
                      onChange={this.handleRiskThresholdChange}
                    />
                    <RiskThresholdRow
                      category="title"
                      categoryName="Title"
                      platformDefaults={[40, 60]}
                      riskThresholds={riskThresholds}
                      onChange={this.handleRiskThresholdChange}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowHeader}>Default rates</div>
                <div className={styles.rowContent}>
                  <Radio.Group className={styles.radioGroup} onChange={this.handleDefaultRateChange} value={rates.default}>
                    <div className={styles.rateRow}>
                      <div className={styles.rateRowHeader}><strong>Default Prepayment Terms</strong></div>
                      <div className={styles.rateRowBody}>
                        <div><strong>Conforming rate</strong></div>
                        <div><strong>Non-conforming rate</strong></div>
                      </div>
                    </div>
                    <RateRow
                      handleRateChange={this.handleRateChange}
                      term="5 year"
                      termKey="5year"
                      rates={rates}
                    />
                    <RateRow
                      handleRateChange={this.handleRateChange}
                      term="10 year"
                      termKey="10year"
                      rates={rates}
                    />
                    <RateRow
                      handleRateChange={this.handleRateChange}
                      term="15 year"
                      termKey="15year"
                      rates={rates}
                    />
                    <RateRow
                      handleRateChange={this.handleRateChange}
                      term="20 year"
                      termKey="20year"
                      rates={rates}
                    />
                    <br />
                    <div className={styles.rateRow}>
                      <div className={styles.rateRowHeader}>
                        Fees
                      </div>
                      <div className={styles.rateRowBody}>
                        <Input
                          onChange={(e) => this.handleFeeChange(e, 'conforming')}
                          value={fees.conforming}
                        />
                        <Input
                          onChange={(e) => this.handleFeeChange(e, 'nonConforming')}
                          value={fees.nonConforming}
                        />
                      </div>
                    </div>
                  </Radio.Group>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowHeader}>Default prepayment terms</div>
                <div className={styles.rowContent}>
                  <Input
                    className={styles.prepaymentTermsSelect}
                    defaultValue={prepaymentTerms}
                    onChange={this.handlePrepaymentTermsChange}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowHeader}>Default message</div>
                <div className={styles.rowContent}>
                  <Input.TextArea onChange={this.handleMessageChange} value={applicantMessage} />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.rowHeader}></div>
                <div className={styles.rowContent}>
                  <Button
                    type="primary"
                    disabled={loading}
                    loading={loading}
                    onClick={() => this.setLenderPreferences(setLenderPreferences)}
                  >
                    Save preferences
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

export default LenderPreferences;
