import React, { Component } from 'react';
import Helmet from 'react-helmet';
import styles from './styles.scss';

export default class NotFound extends Component {
  render() {
    return (
      <main className={styles.container}>
        <Helmet title="Page not Found" />
        <section className={styles.section}>
          <h1 className={styles.glitch} data-text="404">404</h1>
        </section>
      </main>
    );
  }
}
