import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import { Button, Modal } from 'antd';
import gql from 'graphql-tag';
import { routes } from '@usurp-power/aqua-silver';

const ACCEPT_QUOTE = gql`
  mutation AcceptQuote($quoteUuid: String!) {
    acceptQuote(quoteUuid: $quoteUuid) {
      _empty
    }
  }
`;

class AcceptQuote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
      loading: false,
      okButtonProps: {},
    };

    this.acceptQuote = this.acceptQuote.bind(this);
    this.closeConfirm = this.toggleConfirmOpen.bind(this, false);
    this.openConfirm = this.toggleConfirmOpen.bind(this, true);
  }

  async acceptQuote() {
    this.setState({
      okButtonProps: {
        disabled: true,
        loading: true
      }
    });
    try {
      const { client, uuid } = this.props;
      const { data } = await client.mutate({
        mutation: ACCEPT_QUOTE,
        variables: {
          quoteUuid: uuid
        },
      });

      if (data) {
        this.props.history.push(routes.applicant.offerAccepted);
      }
    } catch (e) {
      console.log('ERROR', e); // eslint-disable-line no-console
    }
  }

  toggleConfirmOpen(confirmOpen = false) {
    this.setState({
      confirmOpen,
      loading: confirmOpen,
    });
  }

  render() {
    const {
      confirmOpen,
      loading,
      okButtonProps,
    } = this.state;
    return (
      <div>
        <Modal
          okText="I agree"
          title="Disclaimer"
          visible={confirmOpen}
          okButtonProps={okButtonProps}
          onOk={this.acceptQuote}
          onCancel={this.closeConfirm}
        >
          <p>This quote is intended as an outline of the key material terms of the proposed financing provided by the lender.</p>
          <p><strong>This is not a commitment for financing.</strong></p>
          <p>Final financing approval is contingent upon (1) compliance with the underwriting requirements of the lender, (2) lender approval pursuant to its bylaws and policies, and (3) compliance with all financing program requirements, including but not limited to, a project savings to investment ratio of greater than one.</p>
          <p>After agreeing to this disclaimer, we'll connect you with the lender so you can continue the process with them directly.</p>
        </Modal>

        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          onClick={this.openConfirm}>
          Select
        </Button>
      </div>
    );
  }
}

export default withApollo(withRouter(AcceptQuote));
