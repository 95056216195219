import React, { Component } from 'react';
import { Rate } from 'antd';
import numeral from 'numeral';
import RiskRow from '$components/RiskRow';
import { PROPERTY_TYPE_MAPPINGS } from '$lib/filters';
import styles from './listItemStyles.scss';

class ListItem extends Component {
  render() {
    const { item, lenderPreferences } = this.props;
    const {
      application,
      property,
      usurp,
      receivedOtherQuotes,
    } = item;
    const {
      slug,
      ask,
      financingType,
      projectStage,
      projectType,
    } = application;
    const {
      googleStaticMapUrl,
      googleStreetViewUrl,
    } = property;
    const {
      wal,
      ltv,
      altv,
      environmentalScore,
      titleScore,
      lenderConsentScore,
      propertyType,
      usurpScore,
    } = usurp;

    const propertyName = slug;

    return (
      <div className={styles.root}>
        <div className={styles.row}>
          <div className={styles.leftContent}>
            <img className={styles.map} src={googleStaticMapUrl} />
            <img className={styles.map} src={googleStreetViewUrl} />
          </div>
          <div className={styles.rightContent}>
            <h4 className={styles.propertyName}>{propertyName} - {financingType} {receivedOtherQuotes && (<i>received other quotes</i>)}</h4>
            <Rate disabled defaultValue={Math.round(usurpScore * 5)} className={styles.rate} />
            <div className={styles.statsColumns}>
              <div className={styles.statsColumn}>
                <div>Size: {numeral(ask).format('$0,0')}</div>
                {(projectType || projectStage) && (
                  <div className={styles.projectTypeStage}>{projectType} {projectStage && `(${projectStage})`}</div>
                )}
                <div>{numeral(wal).format('0.00')} WAL</div>
              </div>
              <div className={styles.statsColumn}>
                <div>{PROPERTY_TYPE_MAPPINGS[propertyType]}</div>
                <div>{numeral(ltv).format('0%')} LTV</div>
                <div>{numeral(altv).format('0%')} ALTV</div>
              </div>
              <div className={styles.statsColumn}>
                <RiskRow value={environmentalScore} type="environmental" text="Environmental Risk" lenderPreferences={lenderPreferences} />
                <RiskRow value={titleScore} type="title" text="Title Risk" lenderPreferences={lenderPreferences} />
                <RiskRow value={lenderConsentScore} type="lenderConsent" text="Lender Consent Risk" lenderPreferences={lenderPreferences} />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default ListItem;
