import React from 'react';
import numeral from 'numeral';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import SpinnerError from '$components/common/SpinnerError';

import styles from './styles.scss';

const ADMIN_DASHBOARD_QUERY = gql`
  query AdminDashboard {
    adminDashboard
  }
`;


const StatBlock = ({ label, value }) => {
  return (
    <div className={styles.statLayout}>
      <div className={styles.statLabel}>{label}</div>
      <div className={styles.statValue}>{value}</div>
    </div>
  );
};

const AdminDashboard = () => {
  const { loading, error, data } = useQuery(ADMIN_DASHBOARD_QUERY);

  if (loading || error) {
    return <SpinnerError error={error} />;
  }

  const {
    adminDashboard: {
      stats,
    },
  } = data;

  const {
    applicationsStatusStuck,
    applicationsDataStatusStuck,
    numContractorApplications,
    percentParcelsNotFound,
    percentParcelsHasConflict,
    percentAltvFail,
    percentEnvironmentalRiskHigh,
    percentCreditRiskHigh,
    percentTitleRiskHigh,
    percentGo,
    percentNullDebt,
    numApplicants,
    numContractors,
    numLenders,
    numContractorApplicationsByState,
  } = stats;

  return (
    <div className={styles.layout}>
      <div className={styles.stats}>
        <StatBlock label="Properties screened" value={numeral(numContractorApplications).format('0,0')} />
        <StatBlock label="Searches not found in DT" value={numeral(percentParcelsNotFound).format('0.00%')} />
        <StatBlock label="Searches with multiple parcels" value={numeral(percentParcelsHasConflict).format('0.00%')} />
        <StatBlock label="Projects failed by ALTV" value={numeral(percentAltvFail).format('0.00%')} />
        <StatBlock label="Projects high environmental risk" value={numeral(percentEnvironmentalRiskHigh).format('0.00%')} />
        <StatBlock label="Projects high credit risk" value={numeral(percentCreditRiskHigh).format('0.00%')} />
        <StatBlock label="Projects high title risk" value={numeral(percentTitleRiskHigh).format('0.00%')} />
        <StatBlock label="Projects greenlit" value={numeral(percentGo).format('0.00%')} />
        <StatBlock label="Properties unknown debt" value={numeral(percentNullDebt).format('0.00%')} />
        <StatBlock label="Lenders" value={numLenders} />
        <StatBlock label="Contractors" value={numContractors} />
        <StatBlock label="Property owners" value={numApplicants} />
        <StatBlock label="Applications(status=stuck)" value={applicationsStatusStuck} />
        <StatBlock label="Applications(dataStatus=stuck)" value={applicationsDataStatusStuck} />
      </div>
      <div>
        <h4>Projects by state</h4>
        {numContractorApplicationsByState.map((row) => (
          <div key={row.state}>{row.state}: {row.num}</div>
        ))}
      </div>
    </div>
  );
};


export default AdminDashboard;
