import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import { reverse } from 'named-urls';
import { Link, Redirect } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';

import ProjectScreenLayout from '../ProjectScreenLayout';
import styles from './styles.scss';

import {
  ProjectFundingOptions,
} from '../../project';

class FundingOptionsScreen extends PureComponent {
  render() {
    const {
      currentUser,
    } = this.props;


    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          const {
            uuid: contractorUuid,
            applicationUuid,
            fundingOptions,
          } = contractorApplication;

          const showReportButton = !!fundingOptions;

          if (_.isEmpty(fundingOptions)) {
            return <Redirect to={reverse(routes.contractor.projects.project.fundingOptionsSurvey, { contractorUuid })} />;
          }

          // 20191205JP: FundingOption.available should be a boolean
          // const availableFundingOptions = fundingOptions.filter((value) => value.options.availability !== 'unavailable');

          return (
            <div>
              <div className={styles.header}>
                <div className={styles.title}>Your funding options</div>
                <Link to={reverse(routes.contractor.projects.project.fundingOptionsSurvey, { contractorUuid })}>
                  <i className="fad fa-sliders-v" />
                </Link>
              </div>
              <ProjectFundingOptions
                applicationUuid={contractorApplication.uuid}
                contractorApplication={contractorApplication}
                currentUser={currentUser}
              />
            </div>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default FundingOptionsScreen;
