import _ from 'lodash';
import { Form, Input, Select } from 'antd';
import React from 'react';
import styles from './styles.scss';

import { fieldDecoratorRules } from '../../../constants/forms';
import { fieldError, formatNumber } from '../../../lib/formatter';

const { Option } = Select;

const ProjectDetails = ({
  errors,
  getFieldDecorator,
  values,
  contractorApplication,
  className,
}) => {
  let scopeVal = values.scopeOfWork || _.get(contractorApplication, 'scopeOfWork');
  try {
    scopeVal = JSON.parse(scopeVal);
  } catch (e) {}// eslint-disable-line no-empty

  scopeVal = scopeVal || [];

  return (
    <div className={className}>
      <div className={styles.twoColumn}>
        <Form.Item
          label="Project type"
          validateStatus={fieldError('projectType', errors)}
        >
          {getFieldDecorator('projectType', {
            initialValue: values.projectType,
          })(
            <Select placeholder="Select One" data-aaa="projectType">
              <Option value="Ground-up New Construction">Ground-up new construction</Option>
              <Option value="Gut-Rehab of Existing Building">Gut-rehab of existing building</Option>
              <Option value="Upgrades or Repairs to Existing Building">Upgrades or repairs to existing building</Option>
              <Option value="Interior Fit-out Only">Interior Fit-out only</Option>
              <Option value="I don't know">I don&apos;t know</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Current project stage"
          validateStatus={fieldError('projectStage', errors)}
        >
          {getFieldDecorator('projectStage', {
            initialValue: values.projectStage,
          })(
            <Select placeholder="Select One" data-aaa="projectStage">
              <Option value="Planning">Planning</Option>
              <Option value="Design">Design</Option>
              <Option value="Permitting">Permitting</Option>
              <Option value="Construction">Construction</Option>
              <Option value="Complete (refinance)">Complete (refinance)</Option>
            </Select>
          )}
        </Form.Item>
      </div>

      <div className={styles.twoColumn}>
        <Form.Item
          label="Scope of work"
          validateStatus={fieldError('scopeOfWork', errors)}
        >
          {getFieldDecorator('scopeOfWork', {
            rules: fieldDecoratorRules,
            initialValue: scopeVal,
          })(
            <Select
              data-aaa='scopeOfWork'
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Click to select"
            >
              <Option key="Renewables">Renewables</Option>
              <Option key="Envelope">Envelope</Option>
              <Option key="Lighting">Lighting</Option>
              <Option key="Landscaping">Landscaping</Option>
              <Option key="Hot Water">Hot Water</Option>
              <Option key="HVAC">HVAC</Option>
              <Option key="Water">Water</Option>
              <Option key="Other">Other</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          extra="Please enter a value greater than or equal to 50,000"
          label="Estimated project cost"
          validateStatus={fieldError('projectEstimatedCost', errors)}
        >
          {getFieldDecorator('projectEstimatedCost', {
            getValueFromEvent: (e) => formatNumber(e.target.value),
            rules: fieldDecoratorRules,
            initialValue: formatNumber(values.projectEstimatedCost) || formatNumber(_.get(contractorApplication, 'projectSize')),
          })(
            <Input
              data-aaa="projectEstimatedCost"
              addonBefore="$"
              maxLength={10}
              min={50000}
              type="text"
            />
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default ProjectDetails;
