export const applicationInputs = {
  applicantEmail: 'applicantEmail',
  propertyStreetAddress: 'address',
  city: 'city',
  state: 'state',
  postalCode: 'postalCode',
  propertyType: 'propertyType',
  propertyBuildingSize: 'propertyBuildingSize',
  ownerOccupied: 'ownerOccupied',
  singleTenant: 'singleTenant',
  propertyDebt: 'propertyDebt',
  lender: 'lender',
  otherLender: 'lenderOther',
  currentProjectStage: 'projectStage',
  scopeOfWork: ({ scopeOfWork }) => {
    try {
      const json = JSON.parse(scopeOfWork);
      return json.join(', ');
    } catch (e) {
      return '';
    }
  },
  ask: 'ask',
  providesAppraisal: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('Property appraisal') > -1);
    } catch (e) {
      return false;
    }
  },
  providesFinancialDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('3 years of audited financials') > -1);
    } catch (e) {
      return false;
    }
  },
  numberOfDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return docs.length;
    } catch (e) {
      return 0;
    }
  },
  utmSource: 'utmSource',
  fakePhoneNumber: ({ hasRealPhoneNumber }) => !hasRealPhoneNumber,
  websiteTime: 'websiteTimeMs',
  fullYearOfPositiveNOI: 'propertyPositiveOperatingIncome',
  propertyMarketValue: 'propertyMarketValue',
  applicationPctCompleted: 'applicationPercentComplete',
  wal: 'wal',
  urgencyOfFinancing: ({ utmSource }) => utmSource === 'contractor_email',
  numberOfConflicts: ({ dataTree: { conflicts } }) => {
    try {
      const result = JSON.parse(conflicts);
      return result.length;
    } catch (e) {
      return 0;
    }
  }
};
export const dataTreeInputs = {
  propertyStreetAddress: 'dataTree.address.line',
  city: 'dataTree.address.city',
  state: 'dataTree.address.stateCode',
  postalCode: 'dataTree.address.postalCode',
  propertyType: 'dataTree.propertyType',
  propertyBuildingSize: 'dataTree.sqft',
  ownerOccupied: 'dataTree.ownerOccupied',
  singleTenant: 'dataTree.singleTenant',
  propertyDebt: 'dataTree.tvr.debt',
  lender: ({ dataTree: { lenders } }) => {
    try {
      const result = JSON.parse(lenders);
      return result[0];
    } catch (e) {
      return null;
    }
  },
  otherLender: ({ dataTree: { lenders } }) => {
    try {
      const result = JSON.parse(lenders);
      return result.slice(1).join(', ');
    } catch (e) {
      return null;
    }
  },
  propertyMarketValue: 'dataTree.marketValue',
  buyer: 'dataTree.tvr.buyer',
  ownershipYears: 'dataTree.ownershipYears',
  deedType: 'usurp.deedType',
  yearOfLastTaxDelinquency: 'dataTree.taxDelinquencyYear',
  multipleTitleOwners: 'usurp.multipleTitleOwners',
  multipleRealOwners: 'usurp.multipleRealOwners',
  multipleOwnerAddress: 'dataTree.multipleOwnerMailingAddresses',
  singleLender: 'dataTree.singleLender',
  activeInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  numberActiveInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  nonActiveInvoluntaryLiens: 'usurp.nonActiveInvoluntaryLiensSummary',
  multipleDataTreeRecords: ({ dataTree: { apns } }) => {
    try {
      const result = JSON.parse(apns);
      return Boolean(result.length > 1);
    } catch (e) {
      return false;
    }
  },
  propertyOwner: 'usurp.propertyOwnerName',
  ownerMailingAddress: 'dataTree.ownerMailingAddress',
  buildDate: 'dataTree.propertyStructureBuiltYear',
  unitCount: 'dataTree.unitCount',
};

export const aggregatedInputs = {
  propertyStreetAddress: ({ property: { streetNumber, route } }) => `${streetNumber} ${route}`,
  city: 'property.locality',
  state: 'property.state',
  postalCode: 'property.postalCode',
  propertyType: 'usurp.propertyType',
  propertyBuildingSize: 'usurp.sqft',
  ownerOccupied: 'usurp.ownerOccupied',
  singleTenant: 'usurp.singleTenant',
  propertyDebt: 'usurp.debt',
  lender: 'usurp.lender',
  otherLender: '',
  currentProjectStage: 'projectStage',
  scopeOfWork: ({ scopeOfWork }) => {
    try {
      const json = JSON.parse(scopeOfWork);
      return json.join(', ');
    } catch (e) {
      return '';
    }
  },
  ask: 'ask',
  providesAppraisal: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('Property appraisal') > -1);
    } catch (e) {
      return false;
    }
  },
  providesFinancialDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('3 years of audited financials') > -1);
    } catch (e) {
      return false;
    }
  },
  numberOfDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return docs.length;
    } catch (e) {
      return 0;
    }
  },
  utmSource: 'utmSource',
  fakePhoneNumber: 'hasRealPhoneNumber',
  websiteTime: 'websiteTimeMs',
  fullYearOfPositiveNOI: 'propertyPositiveOperatingIncome',
  propertyMarketValue: 'usurp.marketValue',
  buyer: 'usurp.buyer',
  ownershipYears: 'usurp.ownershipYears',
  deedType: 'usurp.deedType',
  yearOfLastTaxDelinquency: 'dataTree.taxDelinquencyYear',

  propertyOwner: 'usurp.propertyOwnerName',
  ownerMailingAddress: 'usurp.ownerMailingAddress',
  buildDate: 'usurp.propertyStructureBuiltYear',
  unitCount: 'usurp.unitCount',
  activeInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  numberActiveInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  nonActiveInvoluntaryLiens: 'usurp.nonActiveInvoluntaryLiensSummary',
  multipleDataTreeRecords: ({ dataTree: { apns } }) => {
    try {
      const result = JSON.parse(apns);
      return Boolean(result.length > 1);
    } catch (e) {
      return false;
    }
  },
  propertyAddressMatches: 'dataTree.propertyAddressMatches',
  ownerAddressMatch: 'dataTree.ownerAddressMatch',
  quantDataMatch: 'dataTree.quantDataMatch',
  titleOwnerMatch: 'dataTree.titleOwnerMatch',
  multipleApns: ({ dataTree: { apns, estimatedParcels } }) => {
    if (estimatedParcels === '>1') {
      return true;
    }
    try {
      const result = JSON.parse(apns);
      return Boolean(result.length > 1);
    } catch (e) {
      return false;
    }
  },
  applicationPctCompleted: 'applicationPercentComplete',

  wal: 'wal',
  apnSituation: 'dataTree.dataTreeSituation',
  multipleTitleOwners: 'usurp.multipleTitleOwners',
  multipleRealOwners: 'usurp.multipleRealOwners',
  multipleOwnerAddress: 'usurp.multipleOwnerMailingAddresses',
  singleLender: 'usurp.singleLender',
  propertyClass: 'usurp.propertyClass',
  capRate: 'usurp.capRate',
  altv: 'usurp.altv',
  ltv: 'usurp.ltv',
  numberOfConflicts: ({ dataTree: { conflicts } }) => {
    try {
      const result = JSON.parse(conflicts);
      return result.length;
    } catch (e) {
      return 0;
    }
  },
  urgencyOfFinancing: ({ utmSource }) => utmSource === 'contractor_email',
  recentTaxDelinquency: '',
  preferredFinancingTerm: 'preferredFinancingTerm',
  solarSystemSize: 'solarSystemSize',
  solarFirstYearProduction: 'solarFirstYearProduction',
  solarSystemType: 'solarSystemType',
  propertyElectricRate: 'propertyElectricRate',
};

const isConflict = (key, { dataTree: { conflicts } }) => {
  try {
    const result = JSON.parse(conflicts);
    return result.indexOf(key) !== -1;
  } catch (e) {
    return false;
  }
};

export const conflictInputs = {
  propertyBuildingSize: isConflict.bind(null, 'sqft'),
  propertyDebt: isConflict.bind(null, 'debt'),
  propertyMarketValue: isConflict.bind(null, 'marketValue'),
  ownerOccupied: isConflict.bind(null, 'ownerOccupied'),
  propertyType: isConflict.bind(null, 'propertyType'),
  singleTenant: isConflict.bind(null, 'singleTenant'),

};

export const rowLabels = {
  'Applicant email': 'applicantEmail',
  'Property Street Address': 'propertyStreetAddress',
  City: 'city',
  State: 'state',
  'Zip Code': 'postalCode',
  'Property Type': 'propertyType',
  'Building size (sq.ft)': 'propertyBuildingSize',
  'Owner Occupied': 'ownerOccupied',
  'Single tenant': 'singleTenant',
  'DebtApplication / Outstanding Balance': 'propertyDebt',
  '1st Mortgage Lender Name': 'lender',
  'Other Lender': 'otherLender',
  'Current project stage': 'currentProjectStage',
  'Scope of work': 'scopeOfWork',
  'Estimated project cost': 'ask',
  'User offers to provide appraisal': 'providesAppraisal',
  'User offers to provide financials': 'providesFinancialDocuments',
  'How many types of u/w docs is user offering': 'numberOfDocuments',
  'UTM Source': 'utmSource',
  'User supplies fake phone number': 'fakePhoneNumber',
  'Website time (milliseconds)': 'websiteTime',
  'When is your most recent full year of positive NOI': 'fullYearOfPositiveNOI',
  // 'User\'s estimate of property\'s market value' : 'propertyMarketValue',
  'Market value': 'propertyMarketValue',
  // 'D1 Original term of existing (years)' : '',
  // 'D1 Year that existing debt was closed' : '',
  // 'D1 Original financed amt of existing debt' : '',
  // 'D2 Original term of existing (years)' : '',
  // 'D2 Year that existing debt was closed' : '',
  // 'D2 Original financed amt of existing debt' : '',
  Buyer: 'buyer',
  'Ownership Years': 'ownershipYears',
  'Deed Type': 'deedType',
  'Year of Last Tax Deliquency': 'yearOfLastTaxDelinquency',
  'Legal Name of Property Owner Entity': 'propertyOwner',
  'Owner Mailing Address (Street Address)': 'ownerMailingAddress',
  'Renovation/Built Date': 'buildDate',
  'Unit Count': 'unitCount',
  'Active involuntary liens (y/n)': 'activeInvoluntaryLiens',
  'Active involunatary liens #': 'numberActiveInvoluntaryLiens',
  'Non active involunatary liens (y/n)': 'nonActiveInvoluntaryLiens',
  'Datatree query yields multiple records': 'multipleDataTreeRecords',
  'Property Address of InitialLocation matches Property Address of AcceptedLocations': 'propertyAddressMatches',
  'All AcceptedLocations share same Owner Mailing Address': 'ownerAddressMatch',
  'All AcceptedLocations share same Quant Data': 'quantDataMatch',
  'All AcceptedLocations share same Title Owner Name': 'titleOwnerMatch',
  'Multiple APNs': 'multipleApns',
  '% of application completed': 'applicationPctCompleted',
  WAL: 'wal',
  'APN Situation': 'apnSituation',
  'Multiple title owners': 'multipleTitleOwners',
  'Multiple real owners': 'multipleRealOwners',
  'Multiple owner addresses': 'multipleOwnerAddress',
  'Single Lender': 'singleLender',
  'Property class': 'propertyClass',
  CapRate: 'capRate',
  ALTV: 'altv',
  LTV: 'ltv',
  'Number of Conflicts': 'numberOfConflicts',
  'Preferred financing term': 'preferredFinancingTerm',
  'Solar system size (kW)': 'solarSystemSize',
  'Solar first-year production (kWh)': 'solarFirstYearProduction',
  'Solar system type': 'solarSystemType',
  'Property electric rate (¢/kWh)': 'propertyElectricRate',
};
