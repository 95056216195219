import _ from 'lodash';
import React, { Component } from 'react';
import numeral from 'numeral';
import { Rate, Table } from 'antd';
import RiskRow from '$components/RiskRow';
import { PROPERTY_TYPE_MAPPINGS } from '$lib/filters';
import styles from './listItemStyles.scss';

const { Column } = Table;

class ListItem extends Component {
  render() {
    const { item, lenderPreferences } = this.props;
    const {
      quotes,
      application,
      property,
      usurp,
    } = item;

    if (!application) {
      return null;
    }

    const {
      acceptedQuoteUuid,
      financingType,
      projectStage,
      projectType,
      slug,
    } = application;
    const {
      googleStaticMapUrl,
      googleStreetViewUrl,
    } = property;
    const {
      ltv,
      altv,
      environmentalScore,
      titleScore,
      lenderConsentScore,
      propertyType,
      usurpScore,
    } = usurp;

    const propertyName = slug;

    const message = (() => {
      if (quotes.length === 0) {
        return null;
      }

      return quotes[0].message;
    })();

    return (
      <div className={styles.root}>
        <h4 className={styles.propertyName}>{propertyName} - {financingType}</h4>
        <div className={styles.row}>
          <div className={styles.leftContent}>
            <img className={styles.map} src={googleStaticMapUrl} />
            <img className={styles.map} src={googleStreetViewUrl} />
          </div>
          <div className={styles.rightContent}>
            <Rate disabled defaultValue={Math.round(usurpScore * 5)} className={styles.rate} />
            <div className={styles.statsColumns}>
              <div className={styles.statsColumn}>
                <div className={styles.projectTypeStage}>{projectType} {projectStage && `(${projectStage})`}</div>
                <div>{PROPERTY_TYPE_MAPPINGS[propertyType]}</div>
                <div>{numeral(ltv).format('0%')} LTV</div>
                <div>{numeral(altv).format('0%')} ALTV</div>
              </div>
              <div className={styles.statsColumn}>
                <RiskRow value={environmentalScore} type="environmental" text="Environmental Risk" lenderPreferences={lenderPreferences} />
                <RiskRow value={titleScore} type="title" text="Title Risk" lenderPreferences={lenderPreferences} />
                <RiskRow value={lenderConsentScore} type="lenderConsent" text="Lender Consent Risk" lenderPreferences={lenderPreferences} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.quoteBlock}>
          <div className={styles.quoteBlock__title}>
            Quotes
          </div>
          <Table
            className={styles.quoteBlock__table}
            dataSource={_.sortBy(quotes, 'term')}
            pagination={false}
            rowKey="uuid"
            rowClassName={(record) => (acceptedQuoteUuid === record.uuid ? styles.acceptedQuote : null)}
          >
            <Column
              dataIndex=""
              key="term"
              title="Term"
              render={(text, record) => `${record.term} years`}
            />
            <Column
              dataIndex=""
              key="ask"
              title="Amount"
              render={(text, record) => numeral(record.ask).format('$0,0')}
            />
            <Column
              dataIndex=""
              key="rate"
              title="Rate"
              render={(text, record) => numeral(record.rate).format('0.[00]%')}
            />
            <Column
              dataIndex="prepayment"
              key="prepayment"
              title="Prepayment"
            />
            <Column
              dataIndex="fees"
              key="fees"
              title="Fees"
            />
          </Table>
          {message && (
            <div className={styles.message}>Your message: {message}</div>
          )}
        </div>
      </div>
    );
  }
}

export default ListItem;
