import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { UpCard } from '$components/common/card';
import { ApplicationList } from '../applications';

import styles from './applications.scss';

class ApplicationsScreen extends PureComponent {
  render() {
    return (
      <div className={styles.content}>
        <UpCard
          subTitle="These are all the applications that have been submitted through the Contractor and Application tools"
          extra={(
            <div className={styles.content__actions}>
              <Link to={routes.applicant.apply} target="_blank">
                <Button>New full rating</Button>
              </Link>
              <Link to={routes.contractor.overview} target="_blank">
                <Button>New go/no go</Button>
              </Link>
            </div>
          )}
          title="Applications and projects"
        >
          <ApplicationList />
        </UpCard>
      </div>
    );
  }
}

export default ApplicationsScreen;
