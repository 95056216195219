import gql from 'graphql-tag';

const DASHBOARD_QUERIES = gql`
  fragment lenderApplicationFields on LenderApplication {
    uuid
    status
    expiresAt
    createdAt
    application {
      ...applicationFields
    }
    property {
      ...propertyFields
    }
    usurp {
      ...usurpFields
    }
  }

  fragment applicationFields on LimitedApplication {
    ask
    slug
    acceptedQuoteUuid
    financingType
    projectType
    projectStage
    propertyType
    scopeOfWork
    uuid
  }

  fragment propertyFields on Property {
    uuid
    streetNumber
    route
    neighborhood
    locality
    county
    state
    postalCode
    postalCodeSuffix
    formattedAddress
    googleStaticMapUrl,
    googleStreetViewUrl
    googlePlusCode
    googlePlaceId
    lat
    lng
  }

  fragment quoteFields on Quote {
    uuid
    ask
    term
    rate
    prepayment
    fees
    message
  }

  fragment usurpFields on Usurp {
    propertyType
    wal
    ltv
    altv

    communicationRating
    usurpRating
    documentationRating
    creditRating

    environmentalScore
    titleScore
    lenderConsentScore
    availableDocumentsScore

    creditScore
    communicationScore
    documentationScore
    usurpScore
  }

  query DashboardQueries {
    activeApplications {
      ...lenderApplicationFields
      applicationStatus @client {
        isChecked
      }
      quoteStatus @client {
        quotes @client {
          applicationUuid
          uuid
          ask
          term
          rate
          prepayment
          fees
          message
        }
      }
    }
    quotedApplications {
      ...lenderApplicationFields
      quotes {
        ...quoteFields
      }
    }
    expiredApplications {
      ...lenderApplicationFields
      receivedOtherQuotes
      quotes {
        ...quoteFields
      }
    }
  }
`;


export default DASHBOARD_QUERIES;
