const _ = require('lodash');

const LENDER_NOTIFICATION_CRITERIA = [{
  email: 'peter@dividendfinance.com',
  min: 100000,
  financingTypes: ['PACE'],
  company: 'Dividend Finance',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/dividend-logo.jpg',
  website: 'https://dividendfinance.com',
}, {
  email: 'mnunnally@counterpointesre.com',
  min: 1000000,
  financingTypes: ['PACE'],
  company: 'Counterpointe',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/csre-logo.png',
  website: 'https://counterpointesre.com',
}, {
  email: 'bbuhrow@ksstate.bank',
  min: 20000,
  financingTypes: ['EFA', 'Loan'],
  company: 'KS StateBank',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/ks-statebank-logo.png',
  website: 'https://ksstate.bank',
}, {
  email: 'nicholas.zuba@ctgreenbank.com',
  min: 50000,
  financingTypes: ['PACE'],
  company: 'Connecticut Green Bank',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/connecticut-green-bank.png',
  website: 'https://ctgreenbank.com',
}, {
  email: 'danny.roberts@cleanfund.com',
  min: 2000000,
  financingTypes: ['PACE'],
  company: 'Cleanfund',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/cleanfund.jpg',
  website: 'https://cleanfund.com',
}, {
  email: 'Corey.Wurster@inclusiveteam.org',
  min: null,
  financingTypes: ['PPA', 'Loan'],
  company: 'Inclusive',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/inclusive-prosperity-capital-logo.png',
  website: 'https://inclusiveteam.org',
}, {
  email: 'ethan@pace-equity.com',
  min: null,
  financingTypes: ['PACE'],
  company: 'PACE Equity',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/pace-equity.png',
  website: 'https://pace-equity.com',
}, {
  email: 'ckoch@greenworkslending.com',
  min: 250000,
  financingTypes: ['PACE'],
  company: 'Greenworks Lending',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/greenworks-lending-logo.jpg',
  website: 'https://greenworkslending.com',
}, {
  email: 'jacob.neumann@bfef.biz',
  min: 250000,
  financingTypes: ['PACE'],
  company: 'Blue Flame',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/blue-flame-logo.png',
  website: 'https://blueflameenergyfinance.com/',
}, {
  email: 'ryan.mazelli@ygrene.com',
  min: 50000,
  financingTypes: ['PACE'],
  company: 'Ygrene',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/ygrene-energy-fund.jpeg',
  website: 'https://ygrene.com',
}, {
  email: 'joshpatton@ascentiumcapital.com',
  min: null,
  financingTypes: ['Lease', 'EFA', 'Loan'],
  company: 'Ascentium Capital',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/ascentium-capital-logo.png',
  website: 'https://ascentiumcapital.com',
}, {
  email: 'perwin@nyceec.com',
  min: 150000,
  financingTypes: ['PACE', 'EFA'],
  company: 'NYCEEC',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/nyceec-logo.png',
  website: 'https://nyceec.com',
}, {
  email: 'hbraithwaite@neifund.org',
  min: 50000,
  financingTypes: ['Lease', 'EFA'],
  company: 'NEIF',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/neif-logo.png',
  website: 'https://neifund.org',
}, {
  email: 'sal.tarsia@castlegreenfinance.com',
  min: 1000000,
  financingTypes: ['PACE'],
  company: 'Castle Green',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/castle-green-logo.png',
  website: 'https://castlegreenfinance.com',
}, {
  email: 'msinger@leasedirect.com',
  min: null,
  financingTypes: ['Lease', 'Loan'],
  company: 'DLL Group',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/dll-group-logo.png',
  website: 'https://www.dllgroup.com',
}, {
  email: 'dholloway@scf.com',
  min: null,
  financingTypes: ['PPA'],
  company: 'Sustainable Capital Finance',
  logoHref: 'https://storage.googleapis.com/public-assets.usurppower.com/lender-logos/scf-logo.png',
  website: 'https://scf.com',
}];

const LENDER_NOTIFICATION_CRITERIA_BY_EMAIL = _.keyBy(LENDER_NOTIFICATION_CRITERIA, 'email');

export {
  LENDER_NOTIFICATION_CRITERIA,
  LENDER_NOTIFICATION_CRITERIA_BY_EMAIL,
};
