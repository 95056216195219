import React, { Component } from 'react';
import { Card } from 'antd';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { ThemeContext } from '$lib/context';
import unetyLogoSrc from '$assets/logoWithText.png';
import ForgotPasswordForm from './ForgotPasswordForm';
import styles from '../styles.scss';

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      email
    }
  }
`;

class ForgotPassword extends Component {
  render() {
    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug } = theme;

          const logoSrc = (slug === 'ecoamerica') ? 'https://storage.googleapis.com/public-assets.usurppower.com/theme/ecoamerica.png' : unetyLogoSrc;

          return (
            <Mutation mutation={FORGOT_PASSWORD}>
              {(forgotPassword, { loading, data }) => {
                if (!data) {
                  return (
                    <Card
                      actions={[
                        <Link key="signin" to={routes.auth.signIn}>Sign in</Link>,
                        <Link key="signup" to={routes.auth.signUp}>Sign up</Link>,
                      ]}
                      className={styles.form}
                    >
                      <img className={styles.logo} src={logoSrc} />
                      <ForgotPasswordForm forgotPassword={forgotPassword} loading={loading} />
                    </Card>
                  );
                }

                const { forgotPassword: { email } } = data;

                return (
                  <div style={{ textAlign: 'center' }}>
                    Password reset instructions were sent to <b>{email}</b>.<br />
                    <br />
              You may close this window.
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default ForgotPassword;
