import moment from 'moment';
import React, { Component } from 'react';
import { Checkbox, Collapse, Progress, Rate } from 'antd';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { HotApplication } from '$components/common/application';
import { UsurpRatingDescription } from '$components/common/rating';
import { FeedbackRatingsButton } from '$components/common/feedback-ratings';
import RiskRow from '$components/RiskRow';
import { PROPERTY_TYPE_MAPPINGS } from '$lib/filters';
import styles from './listItemStyles.scss';

const TOGGLE_APPLICATIONS = gql`
  mutation ToggleApplications($uuids: [String!]) {
    toggleApplications(uuids: $uuids) @client
  }
`;

const getGenericLsh = (value) => {
  if (value >= 0.84) {
    return 'high';
  }

  if (value >= 0.50) {
    return 'standard';
  }

  return 'low';
};

const getGenericLse = (value) => {
  if (value >= 0.84) {
    return 'exceptional';
  }

  if (value >= 0.50) {
    return 'standard';
  }

  return 'limited';
};

const getGenericNsf = (value) => {
  if (value >= 0.84) {
    return 'no';
  }

  if (value >= 0.50) {
    return 'few';
  }

  return 'several';
};

class ListItem extends Component {
  renderDetails = () => {
    const { item } = this.props;
    const { application, usurp } = item;
    const {
      availableDocumentsScore,
      creditRating,
      creditScore,
      communicationScore,
      documentationScore,
      usurpRating,
    } = usurp;

    const creditLsh = getGenericLsh(creditScore);
    const creditLshReverse = getGenericLsh(1 - creditScore);
    const communicationLsh = getGenericLsh(communicationScore);
    const communicationLse = getGenericLse(communicationScore);
    const documentationNsf = getGenericNsf(documentationScore);
    const availableDocumentsLaa = (() => {
      if (availableDocumentsScore === 1) {
        return 'an above average';
      }

      if (availableDocumentsScore >= 0.75) {
        return 'an adequeate';
      }

      return 'a limited';
    })();

    return (
      <div className={styles.detailsContent}>
        <div className={styles.detailsHeader}>
          <h4><strong>&quot;{usurpRating}&quot;</strong>&nbsp;Rating overall</h4>
          <div className={styles.detailsHeader__feedback}>
            Do these ratings look incorrect?
            <FeedbackRatingsButton
              applicationUuid={application.uuid}
            />
          </div>
        </div>
        <UsurpRatingDescription usurpRating={usurpRating} />
        <h5>Credit <Rate disabled count={3} defaultValue={Math.round(creditRating)} /></h5>
        <p>
          The property value is {creditLsh} for its size, class, location, and type. The likelihood of default is {creditLshReverse}. In the event of a default, the likelihood that a lender will recover owed payments is {creditLsh}. This score is supported by 4 distinct credit assessments.
        </p>

        <h5>Ease of communication <Rate disabled count={3} defaultValue={Math.round(communicationScore * 3)} /> </h5>
        <p>
          The applicant has expressed {communicationLse} interest in closing this financing. There is a {communicationLsh} probability that the applicant possesses incentive and authority to close in a timely manner. This score is based on a comparative assessment of public records and direct applicant feedback.
        </p>

        <h5>Ease of documentation <Rate disabled count={3} defaultValue={Math.round(documentationScore * 3)} /></h5>

        <p>
          Public records indicate that are {documentationNsf} major impediments to collecting the primary documentation needed to validate the underwriting of this property. The applicant has demonstrated {availableDocumentsLaa} ability and willingness to support documentation as needed. This score is supported by 5 distinct assessments and direct applicant feedback.
        </p>
      </div>
    );
  }

  render() {
    const {
      checked,
      item,
      lenderPreferences,
      onCheckChange,
    } = this.props;
    const {
      uuid,
      expiresAt,
      application,
      property,
      usurp,
    } = item;
    const {
      ask,
      slug,
      financingType,
      projectStage,
      projectType,
      scopeOfWork: scopeOfWorkRaw,
    } = application;
    const {
      googleStaticMapUrl,
    } = property;
    const {
      wal,
      ltv,
      altv,
      environmentalScore,
      titleScore,
      lenderConsentScore,
      propertyType,
      usurpRating,
      communicationRating,
    } = usurp;

    const propertyName = slug;

    const scopeOfWork = (() => {
      try {
        return JSON.parse(scopeOfWorkRaw);
      } catch (e) {
        return [];
      }
    })();

    const scope = (scopeOfWork.length > 0) ? scopeOfWork.join(', ') : null;

    const daysLeft = moment.duration(moment(expiresAt).diff(moment())).asDays();
    const timeLeftEl = (daysLeft <= 1)
      ? <div>Last<br />chance!</div>
      : <div>{Math.round(daysLeft)}<br />days left</div>;

    const percent = (daysLeft / 30) * 100;
    const format = (
      <div className={styles.progressText}>{timeLeftEl}</div>
    );

    return (
      <Mutation mutation={TOGGLE_APPLICATIONS} variables={{ uuids: [uuid] }}>
        {() => (
          <div className={styles.root}>
            <div className={styles.leftContent}>
              <Checkbox
                className={styles.checkbox}
                checked={checked}
                onChange={onCheckChange}
              />
              <Progress className={styles.progress} width={80} type="circle" percent={percent} format={() => format} />
              <img className={styles.map} src={googleStaticMapUrl} />
            </div>
            <div className={styles.rightContent}>
              <h4 className={styles.propertyName}>
                <span>{propertyName} - {financingType}</span>
                <HotApplication communicationRating={communicationRating} />
              </h4>
              <div className={styles.rating}>
                <strong>{usurpRating} overall rating</strong>
              </div>
              <div className={styles.scope}>{scope}</div>
              <div className={styles.statsColumns}>
                <div className={styles.statsColumn}>
                  <div>Ask: {numeral(ask).format('$0,0')}</div>
                  {(projectType || projectStage) && (
                    <div className={styles.projectTypeStage}>{projectType} {projectStage && `(${projectStage})`}</div>
                  )}
                  <div>{numeral(wal).format('0.00')} WAL</div>
                </div>
                <div className={styles.statsColumn}>
                  <div>{PROPERTY_TYPE_MAPPINGS[propertyType]}</div>
                  <div>{numeral(ltv).format('0%')} LTV</div>
                  <div>{numeral(altv).format('0%')} ALTV</div>
                </div>
                <div className={styles.statsColumn}>
                  <RiskRow value={environmentalScore} type="environmental" text="Environmental Risk" lenderPreferences={lenderPreferences} />
                  <RiskRow value={titleScore} type="title" text="Title Risk" lenderPreferences={lenderPreferences} />
                  <RiskRow value={lenderConsentScore} type="lenderConsent" text="Lender Consent Risk" lenderPreferences={lenderPreferences} />
                </div>
              </div>
              <div className={styles.detailsCollapse}>
                <Collapse>
                  <Collapse.Panel header="Details">
                    {this.renderDetails()}
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default ListItem;
