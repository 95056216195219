import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import { Range } from '@justinpincar/rc-slider';

import styles from '../styles.scss';

class RiskThresholdRow extends Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    platformDefaults: PropTypes.arrayOf(PropTypes.number),
    riskThresholds: PropTypes.shape(),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    platformDefaults: [40, 60],
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    const {
      riskThresholds,
      category
    } = this.props;

    this.state = {
      values: [
        riskThresholds[category].medium * 100,
        riskThresholds[category].low * 100,
      ]
    };

    this.handleAfterChange = this.handleAfterChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
  }

  handleAfterChange(values) {
    this.props.onChange({
      category: this.props.category,
      values
    });
  }

  handleChange = (values) => {
    this.setState(() => ({
      values
    }));
  };

  handleResetClick() {
    const {
      category,
      platformDefaults,
      onChange,
    } = this.props;

    this.setState(() => ({
      values: platformDefaults
    }));
    onChange({
      category,
      values: platformDefaults
    });
  }

  render() {
    const {
      categoryName,
    } = this.props;

    const {
      values
    } = this.state;

    return (
      <div className={styles.riskRow}>
        <div className={styles.riskRowHeader}>{categoryName}</div>
        <div className={styles.rateRowBody}>
          <Range
            count={1} min={0}
            max={100}
            defaultValue={values}
            value={values}
            pushable
            trackStyle={[{ backgroundColor: 'red' }, { backgroundColor: 'orange' }]}
            railStyle={{ backgroundColor: 'green' }}
            onChange={this.handleChange}
            onAfterChange={this.handleAfterChange}
          />
        </div>
        <div className={styles.riskRow__reset}>
          <Tooltip placement="topRight" title="Restore Unety defaults">
            <Button shape="circle" icon="sync" onClick={this.handleResetClick} />
          </Tooltip>

        </div>
      </div>
    );
  }
}

export default RiskThresholdRow;
