import gql from 'graphql-tag';

export const FIND_PARCELS = gql`
  query FindParcels($input: FindParcelsInput!) {
    getParcelsByLocation(input: $input) {
      isPaceAvailable
      hasConflict
      parcels {
        uuid
        street
        city
        stateCode
        postalCode
        plusFour
        apn
        formattedAddress
        googlePlusCode
        googlePlaceId
        lat
        lng
        dataTreePropertyId
      }
    }
  }
`;
