import React, { useState } from 'react';
import { Button } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import styles from './styles.scss';

const CREATE_PORTAL_SESSION = gql`
  mutation CreatePortalSession {
    createPortalSession {
      portalUrl
      errors {
        detail
      }
    }
  }
`;

const createPortalSession = async ({ client }) => {
  const { data } = await client.mutate({
    mutation: CREATE_PORTAL_SESSION,
  });

  const { portalUrl } = data.createPortalSession;
  window.location.href = portalUrl;
};

const ManageBilling = (props) => {
  const { client, user } = props;
  const [loading, setLoading] = useState(false);

  if (!user.planType) {
    return null;
  }

  const onClick = () => {
    setLoading(true);
    createPortalSession({ client });
  };

  return (
    <div className={styles.layout}>
      <Button onClick={onClick} loading={loading}>Manage billing</Button>
    </div>
  );
};

export default withApollo(ManageBilling);
