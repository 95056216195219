import numeral from 'numeral';
import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import CommonSmallBlock from '../CommonSmallBlock';

import sharedStyles from '../CommonSmallBlock/shared.scss';

const NAME = 'Energy savings';
const TIME = '2 min';

class EnergySavingsBlock extends Component {
  render() {
    const {
      contractorApplication,
    } = this.props;

    const {
      uuid: contractorUuid,
      energySavings,
    } = contractorApplication;

    if (!energySavings) {
      return (
        <CommonSmallBlock name={NAME} time={TIME}>
          <div className={sharedStyles.layout}>
            <div className={sharedStyles.title}>You could save money on your utility bill</div>
            <div className={sharedStyles.description}>
              Small projects can have a big impact. Replacing traditional lights with CFLs or LEDs can reduce your annual bill by $5 per light.
            </div>
            <Link to={reverse(routes.contractor.projects.project.energySavings, { contractorUuid })}>
              <Button data-aaa="energySavings" type="primary">See how much you could save</Button>
            </Link>
          </div>
        </CommonSmallBlock>
      );
    }

    const {
      annualEnergySavings,
    } = energySavings;

    const lifetimeEnergySavings = annualEnergySavings * 15;

    if (lifetimeEnergySavings <= 1000) {
      return (
        <CommonSmallBlock name={NAME}>
          <div className={sharedStyles.layout}>
            <div className={sharedStyles.title}></div>
            <div className={sharedStyles.description}>
              Based on your input, it doesn&apos;t seem like you&apos;ll have significant energy savings.
            </div>
            <Link to={reverse(routes.contractor.projects.project.energySavings, { contractorUuid })}>
              <Button data-aaa="energySavings">Recalculate savings estimate</Button>
            </Link>
          </div>
        </CommonSmallBlock>
      );
    }

    return (
      <CommonSmallBlock name={NAME}>
        <div className={sharedStyles.layout}>
          <div className={sharedStyles.title}>Energy upgrades could mean <br />{numeral(lifetimeEnergySavings).format('$0,0')} saved</div>
          <div className={sharedStyles.description}>
            That&apos;s an estimated {numeral(annualEnergySavings).format('$0,0')} per year, with an average equipment lifespan of 15 years.
          </div>
          <Link to={reverse(routes.contractor.projects.project.energySavings, { contractorUuid })}>
            <Button data-aaa="energySavings">Recalculate savings estimate</Button>
          </Link>
        </div>
      </CommonSmallBlock>
    );
  }
}

export default EnergySavingsBlock;
