import _ from 'lodash';
import React, { Component } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import FinancingTypesForm from '../../Forms/FinancingTypesForm';
import PpaForm from '../../Forms/PpaForm';
import {
  ApplicantDetailsForm,
  ConfirmationForm,
  FinancialDetailsForm,
  PropertyDetailsForm,
  ProjectDetailsForm
} from '../../Forms';
import styles from './styles.scss';

const REQUIRED_STEPS = [{
  title: 'Your info',
  StepComponent: ApplicantDetailsForm,
  validateFields: [
    'propertyRelationship',
    'firstName',
    'lastName',
    'nameTitle',
    'applicantPhone',
    'applicantEmail',
    'contactMethod',
  ],
}, {
  title: 'Property details',
  StepComponent: PropertyDetailsForm,
  validateFields: [
    'address',
    'city',
    'state',
    'postalCode',
    'propertyPositiveOperatingIncome',
    'propertyMarketValue',
    'propertyType',
    'propertyBuildingSize',
    'ownerOccupied',
    'singleTenant',
  ],
}, {
  title: 'Project details',
  StepComponent: ProjectDetailsForm,
  validateFields: [
    'projectType',
    'projectStage',
    'scopeOfWork',
    'projectEstimatedCost',
  ],
}, {
  title: 'Financial info',
  StepComponent: FinancialDetailsForm,
  validateFields: [
    'propertyDebt',
    'lender',
    'lenderOther',
  ],
}, {
  title: 'Financing types',
  StepComponent: FinancingTypesForm,
  validateFields: [
    'financingTypes',
  ],
}];

const PPA_STEP = {
  title: 'PPA application',
  StepComponent: PpaForm,
  validateFields: [
    'solarSystemSize',
    'solarFirstYearProduction',
    'solarSystemType',
  ],
};

const CONFIRMATION_STEP = {
  title: 'Confirmation',
  StepComponent: ConfirmationForm,
  validateFields: [
    'availableDocuments',
    'termsAccepted',
  ],
};

const getSteps = ({ form }) => {
  const financingTypes = form.getFieldValue('financingTypes') || [];

  const steps = [
    ...REQUIRED_STEPS,
  ];

  if (_.includes(financingTypes, 'PPA')) {
    steps.push(PPA_STEP);
  }

  steps.push(CONFIRMATION_STEP);

  return steps;
};

class Dots extends Component {
  render() {
    const { step, steps } = this.props;

    return (
      <div className={styles.dots}>
        {steps.map((_stepObj, i) => {
          const className = classnames('fa-circle', (step === i) && 'fad', (step < i) && 'fal', (step > i) && 'fas');
          return (
            <i key={i} className={className} />
          );
        })}
      </div>
    );
  }
}

class Stepper extends Component {
  constructor(props) {
    super(props);

    const { form } = this.props;

    const steps = getSteps({ form });

    this.state = {
      step: 0,
      steps,
    };
  }

  handleContinue = async (e) => {
    const { form, handleSubmit } = this.props;
    const { step, steps } = this.state;
    const stepObj = steps[step];
    const { validateFields } = stepObj;

    if (validateFields) {
      await form.validateFields(validateFields); // 20200511JP: This throws if validation fails.
    }

    const nextSteps = getSteps({ form });
    this.setState({
      steps: nextSteps,
    });

    if (step === (steps.length - 1)) {
      handleSubmit(e);
      return;
    }

    this.handleStep(1);
  }

  handleStep = (step) => {
    this.setState((state) => ({
      step: state.step + step,
    }));
  };

  render() {
    const { step, steps } = this.state;
    const {
      currentUser,
      errors,
      form,
      getFieldDecorator,
      application,
      usurp,
      loading,
    } = this.props;
    const currentStepObj = steps[step];
    const { title } = currentStepObj;

    const contractorApplication = this.props.contractorApplication || {};

    return (
      <div>
        <div className={styles.header}>
          <div>{title}</div>
          <Dots step={step} steps={steps} />
        </div>
        <div className={styles.body}>
          {steps.map((stepObj, i) => {
            const { StepComponent } = stepObj;
            return (
              <StepComponent
                key={i}
                errors={errors}
                form={form}
                className={classnames((i !== step) && styles.hidden)}
                currentUser={currentUser}
                getFieldDecorator={getFieldDecorator}
                values={application}
                contractorApplication={contractorApplication}
                usurp={usurp}
              />
            );
          })}
          <div className={styles.footer}>
            {(step !== 0) ? <Button onClick={() => this.handleStep(-1)}>Back</Button> : <div />}
            <Button type="primary"
              data-aaa="continueApplication"
              onClick={this.handleContinue}
              loading={loading}
            >
              {(step === (steps.length - 1)) ? 'Submit' : 'Continue'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Stepper;
