import React, { Component } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { routes } from '@usurp-power/aqua-silver';
import PropertySearch from '$components/common/PropertySearch';
import { ThemeContext } from '$lib/context';
import ContractorScreen from '$components/contractor/screens/ContractorScreen';

import styles from './styles.scss';

const { Option } = Select;

class NewPaceRefi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: null,
    };
  }

  handlePaceInfoSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          values,
        });
      }
    });
  }

  renderSearch() {
    const { values } = this.state;
    const {
      scopeOfWork,
      projectMonth,
    } = values;
    const { propertyAddress } = values;
    const contractorApplicationInput = {
      projectCategory: 'paceRefi',
      paceRefi: {
        scopeOfWork: JSON.stringify(scopeOfWork),
        projectMonth: moment(projectMonth).format('YYYY-MM'),
      },
    };

    return (
      <ThemeContext.Consumer>
        {({ organizationUuid }) => (
          <PropertySearch prefilledAddress={propertyAddress}>
            {({ location, parcels, resetMap, setSidebarStatus }) => (
              <ContractorScreen
                geocodedLocation={location}
                parcels={parcels}
                organizationUuid={organizationUuid}
                resetMap={resetMap}
                nextPath={routes.contractor.projects.project.overview}
                setSidebarStatus={setSidebarStatus}
                contractorApplicationInput={contractorApplicationInput}
              />
            )}
          </PropertySearch>
        )}
      </ThemeContext.Consumer>
    );
  }

  render() {
    const { form } = this.props;
    const {
      getFieldDecorator,
    } = form;
    const { values } = this.state;
    if (values) {
      return this.renderSearch();
    }

    return (
      <div className={styles.layout}>
        <div className={styles.well}>
          <div className={styles.title}>Qualify for capital recovery financing</div>
          <div className={styles.info}>
            Use this money to help you get through these hard times.
            <br />You may be eligible if you&apos;ve recently upgraded your building.
          </div>
          <div className={styles.form}>
            <Form onSubmit={this.handlePaceInfoSubmit}>
              <Form.Item label="Approximate date of upgrade">
                {getFieldDecorator('projectMonth', {
                  rules: [{ required: true, message: 'Required' }],
                })(
                  <DatePicker.MonthPicker
                    placeholder="Select month"
                  />
                )}
              </Form.Item>
              <Form.Item label="Type of upgrade">
                {getFieldDecorator('scopeOfWork', {
                  rules: [{ required: true, message: 'Required' }],
                })(
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Click to select"
                  >
                    <Option key="Renewable">Renewable</Option>
                    <Option key="Envelope">Envelope</Option>
                    <Option key="Lighting">Lighting</Option>
                    <Option key="Landscaping">Landscaping</Option>
                    <Option key="Hot Water">Hot Water</Option>
                    <Option key="HVAC">HVAC</Option>
                    <Option key="Water">Water</Option>
                    <Option key="Other">Other</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Property address">
                {getFieldDecorator('propertyAddress', {
                  rules: [{ required: true, message: 'Required' }],
                })(
                  <Input placeholder="29 Norman Ave, Brooklyn, NY" />
                )}
              </Form.Item>
              <Button htmlType="submit" type="primary" onClick={this.handlePaceInfoSubmit}>Check eligibility</Button>
            </Form>
          </div>
          <div className={styles.explanation}>
            <h4>How much does it cost?</h4>
            <p>Fixed interest rate of ~5-7%, paid back over 10-20 years</p>

            <h4>How much can I get?</h4>
            <p>You could receive funding of up to 20% of your property&apos;s value</p>

            <h4>Who&apos;s offering this?</h4>
            <p>Government-certified lenders provide this funding; Unety connects you to them</p>

            <p>
              <a href="https://unety.io/resources/pace" target="_blank" rel="noopener noreferrer">Learn more</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(NewPaceRefi);
