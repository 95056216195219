import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { FundingOptionsTable } from '../funding-options';
import styles from './application-form.scss';

class ProjectFundingOptions extends PureComponent {
  static propTypes = {
    applicationUuid: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
  };

  render() {
    const {
      applicationUuid,
      contractorApplication,
      currentUser,
    } = this.props;

    return (
      <div className={styles.content}>
        <FundingOptionsTable
          applicationUuid={applicationUuid}
          contractorApplication={contractorApplication}
          currentUser={currentUser}
        />
      </div>
    );
  }
}

export default ProjectFundingOptions;
