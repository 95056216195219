import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import React, { Component } from 'react';
import keyMirror from 'keymirror';
import { Button, Icon } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import Cookies from 'js-cookie';
import MultiStepFrom from '../MultiStep';
import QualificationError from './QualificationError';

import styles from './styles.scss';

const STEP = keyMirror({
  application: null,
  details: null,
  preApplication: null,
});

class UnwrappedApplicationForm extends Component {
  constructor(props) {
    super(props);

    const { parcels, setSidebarStatus } = props;

    // 20191108JP: Probably should just send undefined or [] instead.
    const notFound = (!parcels || _isEmpty(parcels) || _every(parcels, (parcel) => !parcel.apn));
    const step = notFound ? STEP.details : STEP.preApplication;

    setSidebarStatus('full');

    this.state = {
      isApplicationFormLoading: false,
      startTime: new Date(),
      step,
    };
  }

  // try {
  //   const { data: { getZipcodes } } = await client.query({
  //     query: GET_ZIPCODES,
  //     variables: {
  //       match: postalCode
  //     }
  //   });

  //   if (getZipcodes.length === 0) {
  //     this.setState({
  //       foundParcels: [],
  //       isAvailable: false,
  //       step: STEP.preApplication,
  //     });
  //     return;
  //   }

  handleApplicationComplete = (values) => {
    this.setState({
      isApplicationFormLoading: true,
    });

    this.props.submit({
      variables: {
        input: values,
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          foundParcels,
          location,
        } = this.state;

        const utmSource = Cookies.get('utmSource');

        this.props.submit({
          variables: {
            input: {
              ...values,
              location,
              utmSource,
              source: 'applicant',
              rawParcels: JSON.stringify(foundParcels),
            },
          }
        });
      }
    });
  }

  handleStartApplication = () => {
    this.setState(() => ({
      step: STEP.application
    }));
  }

  renderStep() {
    const { location, parcels } = this.props;
    const {
      isApplicationFormLoading,
      step,
      values
    } = this.state;

    switch (step) {
      case STEP.details: {
        return (
          <QualificationError location={location} errorType='notFound' />
        );
      }
      case STEP.preApplication:
        return this.renderPreApplication();
      case STEP.application: {
        const {
          address,
          city,
          state,
          postalCode,
        } = location;

        const stringLocation = `${address}, ${city}, ${state} ${postalCode}`;
        const initialValues = {
          ...values,
          address,
          city,
          state,
          postalCode,
        };

        return (
          <MultiStepFrom
            initialValues={initialValues}
            location={stringLocation}
            parcels={parcels}
            saving={isApplicationFormLoading}
            onComplete={this.handleApplicationComplete}
          />
        );
      }
      default: {
        return (
          <div>Unknown step: {step}</div>
        );
      }
    }
  }

  renderPreApplication() {
    const { isPaceAvailable, location } = this.props;

    if (!isPaceAvailable) {
      return (
        <QualificationError location={location} errorType='unavailable' />
      );
    }

    return (
      <div className={styles.preApplicationLayout}>
        <div className={styles.preApplicationTitle}>We found your address!</div>
        <div className={styles.preApplicationSubtitle}>Apply in less than five minutes to get competitive quotes from multiple lenders.</div>
        <div className={styles.preApplicationContainer}>
          <Button type="primary" onClick={this.handleStartApplication}>
            Start application
            <Icon type='right' />
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return this.renderStep();
  }
}

export default withApollo(UnwrappedApplicationForm);
