import _get from 'lodash/get';
import _set from 'lodash/set';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import numeral from 'numeral';
import { Button, Icon, Input, Select, Spin, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import { routes } from '@usurp-power/aqua-silver';

import { ADMIN_APPLICATION } from '../queries/application';
import ScoreTable from '$components/common/ScoreTable';
import EdrResultsGrid from '$components/common/EdrEvents/EdrResultsGrid';
import { PROPERTY_TYPE_OBJECTS } from '$lib/filters';
import Quotes from './Quotes';
import { scoresData, communicationScoreDate } from '$components/common/ScoreTable/score-data-format';
import {
  aggregatedInputs,
  applicationInputs,
  conflictInputs,
  dataTreeInputs,
  rowLabels
} from './data-formats/input-formats';

import styles from './application.scss';

const { Option } = Select;
const selectStyles = {
  width: '100%',
  fontSize: '12px',
  minHeight: '20px !important',
};

const yesNoSelectFields = ['ownerOccupied', 'singleTenant'];
const inputFieldsNumber = ['propertyDebt', 'propertyBuildingSize', 'ask', 'propertyMarketValue'];

const ValueRenderer = ({ name, val, onChange }) => {
  if (inputFieldsNumber.indexOf(name) !== -1 && onChange) {
    return (
      <Input className={styles.application__input} defaultValue={val} onChange={(evt) => onChange(parseInt(evt.target.value, 10))} />
    );
  }

  if (yesNoSelectFields.indexOf(yesNoSelectFields) !== -1 && onChange) {
    return (
      <Select className={styles.application__select} defaultValue={val} onChange={onChange} style={selectStyles}>
        <Option className={styles.application__option} value={true}>Yes</Option>
        <Option className={styles.application__option} value={false}>No</Option>
      </Select>
    );
  }

  if (name === 'propertyType' && onChange) {
    return (
      <Select className={styles.application__select} defaultValue={val} onChange={onChange} style={selectStyles}>
        {PROPERTY_TYPE_OBJECTS.map(({ text, value }) => (
          <Option className={styles.application__option} key={value} value={value}>{text}</Option>
        ))}
      </Select>
    );
  }


  if (val === null || typeof val === 'undefined') {
    return <span className={styles.application__values__null}>-</span>;
  }

  if (typeof val === 'number' && val > 100) {
    const shouldRenderDollar = name === 'estimatedProjectCost'
      || name === 'propertyMarketValue';
    return shouldRenderDollar
      ? numeral(val).format('$0,0')
      : numeral(val).format('0');
  }

  if (val === 'yes' || val === true || val === 'true') {
    return 'yes';
  }
  if (val === 'no' || val === false || val === 'false') {
    return 'no';
  }


  return val.toString();
};

class ApplicationScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      eventMatrix: null,
      formPristine: true,
      modifiers: {},
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  }

  async componentDidMount() {
    await this.getData();
  }

  async getData() {
    try {
      const { data } = await this.props.client.query({
        query: ADMIN_APPLICATION,
        variables: {
          applicationUuid: this.props.match.params.applicationUuid,
          modifiers: this.state.modifiers,
        },
        fetchPolicy: 'no-cache'
      });

      let eventMatrix = null;

      try {
        eventMatrix = JSON.parse(data.adminApplication.property.edrEventMatrix);
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }

      this.setState({
        data: data.adminApplication,
        eventMatrix,
        formPristine: true,
      });
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  }

  handleFieldChange(field, value) {
    const { data, modifiers } = this.state;

    modifiers[field] = value;
    _set(data, field, value);

    this.setState(() => ({
      data,
      formPristine: false,
      modifiers,
    }));
  }

  async handleUpdateClick() {
    await this.getData();
  }

  render() {
    const {
      data,
      eventMatrix,
      formPristine,
    } = this.state;

    if (!data) {
      return <Spin className={styles.application__loading} />;
    }

    const isContractorApplication = data.contractorApplication;

    const headerRowClasses = classNames(styles.application__inputs__row, {
      [styles.application__inputs__row__contractor]: isContractorApplication
    });

    const goNoGoClasses = classNames(styles.application__goNoGo, {
      [styles.application__goNoGo__passed]: data && data.usurp.goNoGo,
      [styles.application__goNoGo__failed]: data && !data.usurp.goNoGo,
    });

    return (
      <div className={styles.application}>
        <div className={styles.application__header}>
          <div className={styles.application__title}>
            <Link
              className={styles.application__back}
              to={routes.admin.applications}
            >
              <Icon type="arrow-left" /> Back to applications
            </Link>
            <div>{data.slug}</div>
            <div>{data.rawAddress}</div>
            <div className={styles.applicationSubtitle}>Type of score: <strong>{isContractorApplication ? 'Go/No Go' : 'Full Rating'}</strong></div>
            <div className={styles.applicationSubtitle}>Status: <strong>{data.status}</strong></div>
          </div>
          <div className={styles.application__actions}>
            {!isContractorApplication && <Button type="primary" disabled={formPristine} onClick={this.handleUpdateClick}>Update</Button>}
          </div>
        </div>
        <div className={styles.application__content}>
          <div className={styles.application__scoresContainer}>
            <ScoreTable
              data={data}
              isContractorApplication={isContractorApplication}
              tableData={scoresData}
            />
            {isContractorApplication && (
              <div className={goNoGoClasses}>
                Go no go: <span>{data && data.usurp.goNoGo ? 'Passed' : 'Failed'}</span>
              </div>
            )}
            {!isContractorApplication && (
              <ScoreTable
                data={data}
                isContractorApplication={isContractorApplication}
                showHeader={false}
                tableData={communicationScoreDate}
              />
            )}
            {eventMatrix && <EdrResultsGrid eventMatrix={eventMatrix} />}
            <Quotes application={data} quotes={data.quotes} />
          </div>
          <div className={styles.application__inputsContainer}>
            <div className={styles.application__inputs}>
              <div className={headerRowClasses}>
                <div></div>
                {!isContractorApplication && <div><strong>Application Inputs</strong></div>}
                {!isContractorApplication && (
                  <div className={styles.application__inputs__row__header}>
                    <Tooltip
                      overlayStyle={{ fontSize: 14 }}
                      placement="top"
                      title="Conflict between application and sourced inputs"
                    >
                      <strong>Conflicts</strong>
                      <Icon type="info-circle" />
                    </Tooltip>
                  </div>
                )}
                {!isContractorApplication && <div><strong>Sourced inputs</strong></div>}
                <div><strong>Reconciled inputs</strong></div>
              </div>
              {data.usurp && Object.entries(rowLabels).map(([label, key]) => {
                const aggregateVal = typeof aggregatedInputs[key] === 'function' ? aggregatedInputs[key](data) : _get(data, aggregatedInputs[key]);
                const applicationVal = typeof applicationInputs[key] === 'function' ? applicationInputs[key](data) : _get(data, applicationInputs[key]);
                const conflictVal = typeof conflictInputs[key] === 'function' ? conflictInputs[key](data) : _get(data, conflictInputs[key]);
                const dtVal = typeof dataTreeInputs[key] === 'function' ? dataTreeInputs[key](data) : _get(data, dataTreeInputs[key]);

                const aggregatedClasses = classNames({
                  [styles.application__dirtyField]: !formPristine
                });

                const rowClasses = classNames(styles.application__inputs__row, {
                  [styles.application__inputs__row__contractor]: isContractorApplication
                });

                return (
                  <div key={key} className={rowClasses}>
                    <div>{label}</div>
                    {!isContractorApplication && (
                      <div>
                        <ValueRenderer
                          name={key}
                          val={applicationVal}
                          onChange={this.handleFieldChange.bind(this, key)}
                        />
                      </div>
                    )}
                    {!isContractorApplication && (
                      <div>
                        <ValueRenderer name={key} val={conflictVal} />
                      </div>
                    )}
                    {!isContractorApplication && (
                      <div>
                        <ValueRenderer name={key} val={dtVal} />
                      </div>
                    )}
                    <div className={aggregatedClasses}>
                      <ValueRenderer name={key} val={aggregateVal} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withApollo(ApplicationScreen));
