import _ from 'lodash';
import numeral from 'numeral';
import React, { useState } from 'react';
import { withApollo } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Modal } from 'antd';
import { routes } from '@usurp-power/aqua-silver';
import { reverse } from 'named-urls';
import update from 'immutability-helper';
import gql from 'graphql-tag';
import styles from './styles.scss';

const QUICK_RESUBMIT_APPLICATION = gql`
  mutation QuickResubmitApplication($input: QuickResubmitApplicationInput!) {
    quickResubmitApplication(input: $input) {
      _empty
    }
  }
`;

const joinSentence = (array, oxfordComma = true) => {
  array = _.clone(array);
  let lastWord;
  if (array.length > 1) {
    lastWord = ` and ${array.pop()}`;
    if (oxfordComma && array.length > 1) {
      lastWord = `, ${lastWord}`;
    }
  } else {
    lastWord = '';
  }
  return array.join(', ') + lastWord;
};


const ResubmitApplication = (props) => {
  const { client, contractorApplication, mostRecentFinancingApplications } = props;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkValues, setCheckValues] = useState({
    ask: false,
    scopeOfWork: false,
    financingTypes: false,
  });

  const canQuickResubmit = _.every(checkValues);

  const updateCheckValues = ({ key, e }) => {
    const { target: { checked } } = e;
    const newCheckValues = update(checkValues, {
      [key]: {
        $set: checked,
      },
    });
    setCheckValues(newCheckValues);
  };

  const { applicationUuid } = contractorApplication;

  const prototypeApplication = mostRecentFinancingApplications[0];
  const { scopeOfWork, ask, propertyMarketValue, propertyDebt } = prototypeApplication;

  const ALTV_DIFF = 0.05;
  const altv = (ask + (propertyDebt || 0)) / propertyMarketValue;
  const askLow = (() => {
    const lowAltv = (altv - ALTV_DIFF) * propertyMarketValue - (propertyDebt || 0);
    const lowSize = ask - 100000;
    const low = Math.max(lowAltv, lowSize);
    return Math.max(low, 0);
  })();
  const askHigh = (() => {
    const highAltv = (altv + ALTV_DIFF) * propertyMarketValue - (propertyDebt || 0);
    const highSize = ask + 100000;
    const high = Math.min(highAltv, highSize);
    return high;
  })();

  const sizeQuestion = (() => {
    const formattedHigh = numeral(askHigh).format('$0,0');
    if (!askLow) {
      return `Is the project size still less than ${formattedHigh}?`;
    }

    return `Is the project size still within ${numeral(askLow).format('$0,0')} to ${formattedHigh}?`;
  })();


  let scopeOfWorkParsed = [];
  try {
    scopeOfWorkParsed = JSON.parse(scopeOfWork);
  } catch (e) { /* NOP */ }
  const scopeOfWorkText = joinSentence(scopeOfWorkParsed);

  const financingTypes = _.uniq(mostRecentFinancingApplications.map((application) => application.financingType));
  const financingTypesText = joinSentence(financingTypes);

  const doQuickResubmit = async () => {
    const { uuid: prototypeApplicationUuid } = prototypeApplication;

    setLoading(true);

    await client.mutate({
      mutation: QUICK_RESUBMIT_APPLICATION,
      variables: {
        input: {
          prototypeApplicationUuid,
          financingTypes,
        },
      },
    });

    window.location.reload();
  };

  return (
    <div>
      <Modal
        title="Refresh financing application quotes"
        visible={visible}
        closable={!loading}
        onCancel={() => {
          if (loading) {
            return;
          }
          setVisible(false);
        }}
        footer={null}
      >
        <p>Let&apos;s review your project. Have there been any material changes?</p>
        <p>Check all boxes that apply.</p>
        <div>
          <p><Checkbox
            checked={checkValues.ask}
            disabled={loading}
            onChange={(e) => updateCheckValues({ key: 'ask', e })}
          >{sizeQuestion}</Checkbox></p>
          <p><Checkbox
            checked={checkValues.scopeOfWork}
            disabled={loading}
            onChange={(e) => updateCheckValues({ key: 'scopeOfWork', e })}
          >Is the scope of work still primarily {scopeOfWorkText}?</Checkbox></p>
          <p><Checkbox
            checked={checkValues.financingTypes}
            disabled={loading}
            onChange={(e) => updateCheckValues({ key: 'financingTypes', e })}
          >Are you still applying for {financingTypesText}?</Checkbox></p>
        </div>

        <div className={styles.actions}>
          <Button onClick={doQuickResubmit} loading={loading} disabled={!canQuickResubmit} type={canQuickResubmit ? 'primary' : null}>Resubmit without revisions</Button>
          <Link to={reverse(routes.applicant.revise, { applicationUuid })}>
            <Button disabled={loading || canQuickResubmit} type={canQuickResubmit ? null : 'primary'}>Revise application</Button>
          </Link>
        </div>
      </Modal>

      <Button type="primary" onClick={() => setVisible(true)}>Refresh quotes</Button>
    </div>
  );
}

export default withApollo(ResubmitApplication);
