import React, { Component } from 'react';
import ProjectOverview from '$components/contractor/screens/ProjectOverview';
import ProjectOverviewPaceRefi from '$components/contractor/screens/ProjectOverviewPaceRefi';
import withContractorApplication from '../withContractorApplication';

class ProjectOverviewSwitcher extends Component {
  render() {
    const {
      currentUser,
      contractorApplication,
      application,
      usurp,
      location,
      match,
    } = this.props;

    if (contractorApplication.projectCategory === 'paceRefi') {
      return (
        <ProjectOverviewPaceRefi
          currentUser={currentUser}
          contractorApplication={contractorApplication}
          application={application}
          usurp={usurp}
          location={location}
          match={match}
        />
      );
    }

    return (
      <ProjectOverview
        currentUser={currentUser}
        contractorApplication={contractorApplication}
        application={application}
        usurp={usurp}
        location={location}
        match={match}
      />
    );
  }
}

export default withContractorApplication(ProjectOverviewSwitcher);
