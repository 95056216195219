const PREPAYMENT = {
  '': {
    shorthand: '(none)',
    description: '(none)',
  },
  noCall: {
    shorthand: 'No Call',
    description: 'No option for prepayment',
  },
  v105FullTerm: {
    shorthand: '105 Full Term',
    description: 'Prepayment available at anytime, subject to a fee of 5%',
  },
  v105_5: {
    shorthand: '105-5 1% p.a. decline to par',
    description: 'Prepayment available at anytime, subject to a fee of 5% during the first 5 years of the PACE term. After year 5, the fee will decrease to 4% in year 6, 3% in year 7, 2% in year 8, 1% in year 9. After year 9, there is no fee.',
  },
  v105_7: {
    shorthand: '105-7, 103-8/9/10, par',
    description: 'Prepayment available at anytime, subject to a fee of 5% during the first 7 years of the PACE term. After year 7, the fee will decrease to 3%. After year 10, there will be no fee.',
  },
  ym: {
    shorthand: 'YM',
    description: 'Prepayment available at anytime, subject to .....',
  },
};

module.exports = PREPAYMENT;
