/* eslint-disable react/display-name */
import React from 'react';
import RiskIcon from '$components/RiskIcon';
import styles from './styles.scss';

export const scoresData = [
  {
    className: styles.layout__headline,
    label: 'Overall Bank-ability Score',
    key: 'usurp.usurpScore',
    rating: ({ usurp }) => () => <span>{usurp.usurpRating}</span>,
  },
  {
    className: styles.layout__subHeadline,
    label: 'Usurp Score - Documentation',
    key: 'usurp.documentationScore',
    rating: ({ usurp }) => () => <span>{usurp.documentationRating} / 3</span>,
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Available documents',
    key: 'usurp.availableDocumentsScore',
    show: ({ isContractorApplication }) => !isContractorApplication
  },
  {
    label: 'Conflict score',
    key: 'usurp.applicationConflictsScore',
    show: ({ isContractorApplication }) => !isContractorApplication
  },
  {
    className: styles.layout__subHeadline,
    label: 'Unety credit score',
    key: 'usurp.creditScore',
    rating: ({ usurp }) => () => <span>{usurp.creditRating} / 3</span>,
    tooltip: 'The "FICO" score for commercial buildings, representing the credit risk of the real property',
  },
  {
    label: 'Unety cap rate score',
    key: 'usurp.capRateScore',
    tooltip: 'How does the cap rate of this property type in this location compare to other property types around the country',
  },
  {
    label: 'Unety property type score',
    key: 'usurp.propertyTypeConsentScore',
    tooltip: 'How attractive is this property type compared to others from the perspective of commercial real estate capital markets, benchmarked against CMBS'
  },
  {
    label: 'ALTV score',
    key: 'usurp.altvScore',
    tooltip: 'Aggregated Loan To Value: How much risk is associated with the total amount of debt on the property, including any debt from with this project',
  },
  {
    label: 'Tenancy score',
    key: 'usurp.multiTenantScore',
    tooltip: 'Credit ratings of individual tenants have an impact on credit risk of a property. While Unety doesn\'t score individual tenants, we recognize that more tenants lower overall credit risk',
  },
  {
    label: 'Property class score',
    key: 'usurp.propertyClassScore',
    tooltip: 'The higher the class, the lower the credit risk of the property.',
  },
  {
    label: 'Ownership duration score',
    key: 'usurp.ownershipDurationScore',
    tooltip: 'Extended stable ownership positively impacts credit risk.',
  },
  {
    label: 'Property class',
    key: 'usurp.propertyClass',
    tooltip: 'Underwriters segment commercial properties into classes based on how much rent they can be expected to achieve. Unety assigns a class to a property according to a wide variety of design specifications and location conditions.'
  },
  {
    label: 'NOI score',
    key: 'usurp.noiScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    className: styles.layout__subHeadline,
    label: 'Title score',
    key: 'usurp.titleScore',
    rating: ({ usurp }) => () => <RiskIcon value={usurp.titleRiskRating} />,
    tooltip: 'The expected level of difficulty associated with demonstrating that the building owner has the authority and permissions to sign a financing agreement. Variables include number of owners, consistency of legal owner entity name across title documents, existing property liens or easements, property ownership structure, and other conditions.',
  },
  {
    className: styles.layout__subHeadline,
    label: 'Existing lender consent score',
    key: 'usurp.lenderConsentScore',
    rating: ({ usurp }) => () => <RiskIcon value={usurp.lenderConsentRiskRating} />,
    tooltip: 'If there is existing debt on the property, will this get in the way of new financings.',
  },
  {
    label: 'Property type consent score',
    key: 'usurp.propertyTypeConsentScore',
    tooltip: 'Certain property types are more prone than others to existing debt restrictions against new financings.',
  },
  {
    label: 'Lender score',
    key: 'usurp.lenderConsentScore',
    tooltip: 'Certain lenders have a reputation and/or official policy on consenting to new property financings',
  },
  {
    className: styles.layout__subHeadline,
    label: 'Environmental risk score',
    key: 'usurp.environmentalScore',
    rating: ({ usurp }) => () => <RiskIcon value={usurp.environmentalRiskRating} />,
    tooltip: 'Unety runs a phase 1 environmental scan on and near the property to identify any historical environmental events that could create contamination or condemnation risk, such as leaky underground fuel tanks.',
  },
];

export const communicationScoreDate = [
  {
    className: styles.layout__subHeadline,
    label: 'Communication Score',
    key: 'usurp.communicationScore',
    rating: ({ usurp }) => () => <span>{usurp.communicationRating}</span>,
    show: ({ isContractorApplication }) => !isContractorApplication
  },
  {
    label: 'Multiple real owner score',
    key: 'usurp.multipleRealOwnersScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Phone number score',
    key: 'usurp.phoneNumberScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Project stage score',
    key: 'usurp.projectStageScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Website time score',
    key: 'usurp.websiteTimeScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Application completed score',
    key: 'usurp.applicationCompletenessScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Application source score',
    key: 'usurp.applicationSourceScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Financial urgency score',
    key: 'usurp.financingUrgencyScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
];
