import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import { ThemeContext } from '$lib/context';
import PropertyOwnerSignUpBanner from '$components/common/PropertyOwnerSignUpBanner';

import ApplyBlock from './ApplyBlock';
import EnergySavingsBlock from './EnergySavingsBlock';
import FavoriteContractorApplication from './FavoriteContractorApplication';
import FindContractorsBlock from './FindContractorsBlock';
import GoNoGoBlock from './GoNoGoBlock';
import FundingOptionsBlock from './FundingOptionsBlock';
import ValueCalculatorBlock from './ValueCalculatorBlock';

import withContractorApplication from '../withContractorApplication';

import styles from './styles.scss';

class ProjectOverview extends Component {
  render() {
    const {
      currentUser,
      contractorApplication,
      application,
      usurp,
    } = this.props;

    const {
      uuid: contractorUuid,
      isPaceBestMatch,
      fundingOptions,
    } = contractorApplication;

    const { uuid: applicationUuid } = application;

    const showFavoriteButton = currentUser && currentUser.isEnterprise;

    console.log(currentUser);

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug } = theme;
          const showReportButton = !!fundingOptions;
          const signUpLink = `${routes.auth.signUp}?contractorApplicationUuid=${contractorUuid}&applicationUuid=${applicationUuid}`;

          const showEnergySavings = (slug !== 'ravti');
          const showFindContractors = (slug === 'ecoamerica');
          const showApplyFinancing = currentUser.type !== 'lender';

          return (
            <div className={styles.layout}>
              <div className={styles.header}>
                <div>{application.rawAddress}</div>
                {showFavoriteButton && (
                  <FavoriteContractorApplication contractorApplication={contractorApplication} />
                )}
                {showReportButton && (
                  <Link target="_blank" to={reverse(routes.contractor.projects.project.report, { contractorUuid })}>
                    <Button type="primary">
                      View report <Icon type="snippets" />
                    </Button>
                  </Link>
                )}
              </div>
              <div className={styles.container}>
                {!currentUser && (
                  <PropertyOwnerSignUpBanner
                    signUpLink={signUpLink}
                    iconClasses="fad fa-exclamation-triangle"
                    message="Your data will be lost unless you create an account"
                  />
                )}
                <div className={styles.gridContainer}>
                  <GoNoGoBlock
                    application={application}
                    contractorApplication={contractorApplication}
                    usurp={usurp}
                  />
                  <FundingOptionsBlock
                    application={application}
                    contractorApplication={contractorApplication}
                    usurp={usurp}
                  />
                  {showApplyFinancing && (
                    <ApplyBlock
                      application={application}
                      contractorApplication={contractorApplication}
                      usurp={usurp}
                    />
                  )}
                  <ValueCalculatorBlock
                    application={application}
                    contractorApplication={contractorApplication}
                    usurp={usurp}
                  />
                  {showEnergySavings && (
                    <EnergySavingsBlock
                      application={application}
                      contractorApplication={contractorApplication}
                      usurp={usurp}
                    />
                  )}
                  {showFindContractors && (
                    <FindContractorsBlock
                      application={application}
                      contractorApplication={contractorApplication}
                      usurp={usurp}
                      signUpLink={signUpLink}
                      currentUser={currentUser}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default withContractorApplication(ProjectOverview);
