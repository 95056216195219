import classNames from 'classnames';
import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import numeral from 'numeral';

import styles from './styles.scss';

const getNumFromEvent = (e) => {
  if (!e || !e.target) {
    return e;
  }
  const { target } = e;


  // const value =
  // if (Number.isNaN(value)) {
  //   return null;
  // }

  return numeral(target.value).format('0,0');
};

class UnwrappedForm extends Component {
  state = {
    error: false,
    visible: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          percentLeasedFsg,
          percentLeasedMg,
          percentLeasedNnn,
        } = Object.entries(values).reduce((acc, [key, val]) => {
          acc[key] = parseFloat(val.toString().replace(/\D/g, ''));
          return acc;
        }, {});

        const hasLeaseTypeError = percentLeasedFsg + percentLeasedMg + percentLeasedNnn > 100;

        if (hasLeaseTypeError) {
          this.setState({
            error: true
          });
          return;
        }

        this.setState({
          error: false
        });

        const percentOwnerOccupied = 100 - values.percentLeased;

        const ownerValues = {
          percentLeased: values.percentLeased,
          percentLeasedFsg: values.percentLeasedFsg,
          percentLeasedMg: values.percentLeasedMg,
          percentLeasedNnn: values.percentLeasedNnn,
          percentOwnerOccupied,
          percentUnoccupied: 0,
          taxEscalationModifiedGrossLease: values.taxEscalationModifiedGrossLease,
        };

        this.props.refetch({ ownerValues });
      }
    });
  };

  handleToggleVisible = () => {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    const { form, ownerValues: initialOwnerValues, loading, refetch } = this.props;
    const { getFieldDecorator } = form;
    const {
      error,
      visible,
    } = this.state;

    if (!visible) {
      return (
        <div className={styles.showAdvanced} onClick={this.handleToggleVisible}>Show advanced leasing inputs?</div>
      );
    }

    const {
      percentLeased,
      percentLeasedFsg,
      percentLeasedMg,
      percentLeasedNnn,
      percentOwnerOccupied,
      percentUnoccupied,
      taxEscalationModifiedGrossLease,
    } = initialOwnerValues;

    return (
      <div className={styles.layout}>
        <div className={styles.layoutHeader}>Advanced leasing inputs</div>
        <div className={styles.layoutContent}>
          <Form onSubmit={this.handleSubmit}>
            <div className={styles.formRow}>
              <Form.Item label="Percent leased">
                {getFieldDecorator('percentLeased', {
                  initialValue: percentLeased,
                  rules: [{ required: true }],
                  getValueFromEvent: getNumFromEvent,
                })(
                  <Input placeholder="Percent leased" addonAfter="%" />
                )}
              </Form.Item>
              <Form.Item label="Property tax escalation cap">
                {getFieldDecorator('taxEscalationModifiedGrossLease', {
                  initialValue: taxEscalationModifiedGrossLease || 0,
                  rules: [{ required: true }, {
                    message: 'Enter a number, max 20%',
                    validator: (rule, value, cb) => {
                      try {
                        const val = parseInt(value, 10);
                        if (val > 20) {
                          cb(val);
                          return;
                        }
                      } catch {
                        cb('Enter a number');
                        return;
                      }
                      cb();
                    },
                  }],
                  getValueFromEvent: getNumFromEvent,
                })(
                  <Input placeholder="Property tax escalation cap" addonAfter="%" />
                )}
              </Form.Item>
            </div>
            <div className={styles.leasedRow}>
              <Form.Item label="Leased as triple net">
                {getFieldDecorator('percentLeasedNnn', {
                  initialValue: percentLeasedNnn,
                  rules: [{ required: true }],
                  getValueFromEvent: getNumFromEvent,
                })(
                  <Input placeholder="Percent triple net" addonAfter="%" />
                )}
              </Form.Item>
              <Form.Item label="Leased as modified gross">
                {getFieldDecorator('percentLeasedMg', {
                  initialValue: percentLeasedMg,
                  rules: [{ required: true }],
                  getValueFromEvent: getNumFromEvent,
                })(
                  <Input placeholder="Percent modified gross" addonAfter="%" />
                )}
              </Form.Item>
              <Form.Item label="Leased as full service gross">
                {getFieldDecorator('percentLeasedFsg', {
                  initialValue: percentLeasedFsg,
                  rules: [{ required: true }],
                  getValueFromEvent: getNumFromEvent,
                })(
                  <Input placeholder="Percent full service gross" addonAfter="%" />
                )}
              </Form.Item>
            </div>
            <div className={styles.actions}>
              <div className={styles.actionErrors}>Leases must add up to 100%</div>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>Update</Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create()(UnwrappedForm);
