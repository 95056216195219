import React from 'react';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import Well from '$components/common/layout/Well';
import UpdatePasswordForm from './UpdatePasswordForm';
import styles from './styles.scss';

const Account = ({ currentUser, location }) => {
  return (
    <DefaultLayout
      location={location}
      currentUser={currentUser}
    >
      <Well>
        <div className={styles.layout}>
          <h1>Account</h1>
          <p>Update your Unety account information</p>
          <h2 className={styles.subheader}>Password</h2>
          <UpdatePasswordForm currentUser={currentUser} />
        </div>
      </Well>
    </DefaultLayout>
  );
}

export default Account;
