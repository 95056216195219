import _get from 'lodash/get';
import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import pluralize from 'pluralize';
import gql from 'graphql-tag';
import { Button, Icon, Input, Modal, notification, Spin } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import withContractorApplication from '../withContractorApplication';

import { ProjectFundingOptions } from '../../project';
import ValueCalculator from '../../ValueCalculator';
import { GoogleStreetViewImage } from '../../../common/google';

import styles from './styles.scss';

const CONTRACTOR_SEND_REPORT = gql`
  query ContractorApplicationSendReport($uuid: ID!, $email: String!){
    contractorApplicationSendReport(uuid:$uuid, email: $email) {
      message
    }
  }
`;

const dataList = [
  {
    label: 'Market Value',
    valueKey: 'application.propertyMarketValue',
    render: (val) => numeral(val).format('$0,0')
  },
  {
    label: 'Percent Occupied',
    valueKey: 'contractorApplication.fundingOptions.pctOccupied',
    render: (val) => numeral(val).format('0%')
  },
  {
    label: 'Project type',
    valueKey: 'contractorApplication.projectType'
  },
  {
    label: 'Project size',
    valueKey: 'contractorApplication.projectSize',
    render: (val) => numeral(val).format('$0,0')
  },
  {
    label: 'Scope of work',
    valueKey: 'contractorApplication.scopeOfWork',
    render: (val) => {
      try {
        const json = JSON.parse(val);
        return json.join(', ');
      } catch (e) {
        return val;
      }
    }
  },
  {
    label: 'Percent of leased area under triple net lease',
    valueKey: 'contractorApplication.pctTripleNetLease',
    render: (val) => numeral(val).format('0.[00]%')
  },
  {
    label: 'Percent of leased area under full service gross lease',
    valueKey: 'contractorApplication.pctFullServiceGrossLease',
    render: (val) => numeral(val).format('0.[00]%')
  },
  {
    label: 'Percent of leased area under modified gross lease',
    valueKey: 'contractorApplication.modifiedGrossLease',
    render: (val) => numeral(val).format('0.[00]%')
  },
  {
    label: 'Tax escalation cap for modified gross lease',
    valueKey: 'contractorApplication.taxEscalationModifiedGrossLease',
    render: (val) => numeral(val).format('0.[00]%')
  },
  {
    label: 'Rentable units',
    valueKey: 'usurp.unitCount'
  },
  {
    label: 'Gross floor area',
    valueKey: 'contractorApplication.grossFloorArea',
    render: (val) => numeral(val).format('0,0')
  },
  {
    label: 'Does the existing mortgage limit the property\'s ability to add debt?',
    valueKey: 'contractorApplication.existingMortgageAddDebt',
    render: (val) => (val ? 'Yes' : 'No')
  },
  {
    label: 'How important is preserving the ability to sell in the next five years?',
    valueKey: 'contractorApplication.sellAbilityFiveYears'
  },
  {
    label: 'With financing, which is more important?',
    valueKey: 'contractorApplication.financingMoreImportant'
  },
  {
    label: 'What is the preferred financing term?',
    valueKey: 'contractorApplication.preferredFinancingTerm',
    render: (val) => pluralize('year', val, true)
  },
  {
    label: 'How quickly is the financing needed?',
    valueKey: 'contractorApplication.fundingQuickness'
  },
  {
    label: 'How important is zero downpayment?',
    valueKey: 'contractorApplication.zeroDownImportance'
  },
];

class ProjectReport extends Component {
  constructor(props) {
    super(props);

    this.pageRef = React.createRef();
    this.emailInput = React.createRef();

    this.state = {
      downloading: false,
      email: '',
      emailModalOpen: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailKeyDown = this.handleEmailKeyDown.bind(this);
    this.handleEmailClick = this.handleEmailClick.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  handlePrintClick = () => {
    window.print();
  }

  handleEmailChange(evt) {
    const email = evt.target.value;
    this.setState({
      email
    });
  }

  handleEmailKeyDown(evt) {
    if (evt.which === 13) {
      this.handleOk();
    }
  }

  handleEmailClick() {
    this.toggleModalVisibility(true);
  }

  async handleOk() {
    if (this.state.email) {
      try {
        await this.props.client.query({
          query: CONTRACTOR_SEND_REPORT,
          variables: {
            uuid: this.props.match.params.contractorUuid,
            email: this.state.email,
          }
        });
        notification.open({
          message: 'Email sent successfully',
        });
        this.toggleModalVisibility(false);
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }
    }
  }

  toggleModalVisibility(emailModalOpen) {
    if (emailModalOpen) {
      setTimeout(() => this.emailInput.current.focus());
    }
    this.setState({
      emailModalOpen
    });
  }

  render() {
    const {
      application,
      contractorApplication,
      currentUser,
      usurp,
    } = this.props;

    if (!contractorApplication) {
      return <Spin size="large" />;
    }

    const dataSources = {
      application,
      contractorApplication,
      usurp,
    };

    const {
      downloading,
      email,
      emailModalOpen,
    } = this.state;

    const { propertyType } = contractorApplication;
    const { rawAddress } = application;

    return (
      <div className={`${styles.projectReport} projectReport projectReport__viewing`}>
        <div className={styles.topHeader}>
          <Button
            disabled={downloading}
            type='primary'
            onClick={this.handlePrintClick}
          >
            Print
            <Icon type="printer" />
          </Button>
          <Button
            type="primary"
            onClick={this.handleEmailClick}
          >
            Email
            <Icon type="mail" />
          </Button>
        </div>
        <div ref={this.pageRef} className={styles.container}>
          <div className={styles.header}>
            <h1 className={styles.title}>
              Unety property report
              <span>{rawAddress}</span>
            </h1>
            <div className={styles.generated}>
              Generated: { moment().format('MMMM Mo YYYY')}
            </div>
          </div>

          <div>
            <div className={styles.sectionTitle}>
              Property info
            </div>
            <div className={styles.infoContent}>
              <div className={styles.map}>
                <GoogleStreetViewImage
                  address={rawAddress}
                  propertyType={propertyType}
                />
              </div>
              <div className={styles.info}>
                {dataList.map(({ label, valueKey, render }) => {
                  const value = _get(dataSources, valueKey);
                  if (typeof value === 'undefined' || value === null) {
                    return null;
                  }
                  return (
                    <div key={label} className={styles.info__item}>
                      <div className={styles.info__label}>
                        {label}
                      </div>
                      <div className={styles.info__value}>
                        {render ? render(value) : value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles.infoFunding}>
            <div className={styles.sectionTitle}>
              Unety funding options
            </div>
            <ProjectFundingOptions
              applicationUuid={contractorApplication.uuid}
              contractorApplication={contractorApplication}
              currentUser={currentUser}
            />
          </div>
          <div className={styles.infoCalculator}>
            <div className={styles.sectionTitle}>Cash flow calculator</div>
            <ValueCalculator
              application={contractorApplication}
            />
          </div>
        </div>

        <Modal
          okText="Send"
          title="Email Report"
          visible={emailModalOpen}
          onOk={this.handleOk}
          onCancel={this.toggleModalVisibility.bind(this, false)}
        >
          <p>Send a link of this report to a property owner</p>
          <Input
            placeholder="Enter a email"
            ref={this.emailInput}
            value={email}
            onChange={this.handleEmailChange}
            onKeyDown={this.handleEmailKeyDown}
          />
        </Modal>
      </div>
    );
  }
}

export default withApollo(withContractorApplication(ProjectReport));
