import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';
import { Button, Input, Popconfirm } from 'antd';
import SpinnerError from '$components/common/SpinnerError';
import storage from '$lib/storage';
import styles from './styles.scss';

const STAFF_USER_SEARCH = gql`
  query StaffUserSearch($input: StaffUserSearchInput!) {
    staffUserSearch(input: $input) {
      users {
        uuid
        email
        fullName
        accessToken
      }
    }
  }
`;

class Users extends Component {
  state = {
    loading: false,
    users: [],
  };

  impersonate = ({ user }) => {
    const { accessToken, email } = user;
    storage.tempAuth.set({ email, accessToken });
    window.location.href = '/';
  }

  handleOnSearch = async ({ client, value }) => {
    this.setState({ loading: true });
    const { data } = await client.query({
      query: STAFF_USER_SEARCH,
      variables: { input: { email: value } },
    });
    const { staffUserSearch: { users } } = data;
    this.setState({
      loading: false,
      users,
    });
  }

  renderUsers = () => {
    const { loading, users, error } = this.state;
    if (loading || error) {
      return (
        <SpinnerError error={error} />
      );
    }

    if (users.length === 0) {
      return (
        <div>No matches.</div>
      );
    }

    return (
      <div>
        {users.map((user) => {
          return (
            <div key={user.uuid} className={styles.user}>
              <div>Email: {user.email}</div>
              <div>Full name: {user.fullName}</div>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => this.impersonate({ user })}
              >
                <Button>Impersonate</Button>
              </Popconfirm>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { client } = this.props;

    return (
      <div className={styles.layout}>
        <br />
        <Input.Search
          placeholder="input search text"
          onSearch={(value) => this.handleOnSearch({ client, value })}
          enterButton
          style={{ width: '300px' }}
          data-aaa="userSearch"
        />
        <br />
        <br />
        {this.renderUsers()}
      </div>
    );
  }
}

export default withApollo(Users);
