import React, { Component } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import { Spin } from 'antd';
import { routes } from '@usurp-power/aqua-silver';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import Applications from '$components/Applications';
import Quote from '$components/Quote';
import Profile from '$components/Profile';
import NotFound from '$components/NotFound';
import Unauthorized from '$components/common/Unauthorized';
import { PropsRoute } from '$lib/routes';
import DASHBOARD_QUERIES from '$queries/dashboard';
import styles from './styles.scss';

class Dashboard extends Component {
  renderContent({ currentUser, data, selectedApplications }) {
    switch (currentUser.status) {
      case 'active':
        return (
          <Switch>
            <Redirect exact from={routes.lender.overview} to={routes.lender.applications.new} />
            <PropsRoute path={routes.lender.applications.overview} component={Applications} data={data} currentUser={currentUser} />
            <PropsRoute path={routes.lender.quote} component={Quote} items={selectedApplications} currentUser={currentUser} />
            <Route exact path={routes.lender.profile} component={Profile} />
            <Route component={NotFound} />
          </Switch>
        );
      case 'disabled':
        return (
          <div className={styles.messageCard}>
            <div className={styles.messageCard__message}>
              Your account has been disabled
            </div>
          </div>
        );
      case 'waitingApproval':
        return (
          <div className={styles.messageCard}>
            <div className={styles.messageCard__message}>
              Unety is not accepting new lenders. We will contact you as soon as this changes.<br />
              If you are an employee of one of our existing lenders, please contact your Unety account manager directly.
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { currentUser, location } = this.props;

    if (['lender', 'admin'].indexOf(currentUser.type) === -1) {
      return <Unauthorized />;
    }

    return (
      <Query query={DASHBOARD_QUERIES}>
        {({ loading, _error, data }) => {
          if (loading) {
            return (
              <Spin size="large" />
            );
          }

          if (!data) {
            return null;
          }

          const locationState = this.props.location.state && this.props.location.state.checkedApplications
            ? this.props.location.state.checkedApplications
            : [];

          const selectedApplications = data
            ? data.activeApplications.filter(({ uuid }) => {
              return locationState.indexOf(uuid) !== -1;
            })
            : [];

          return (
            <DefaultLayout
              location={location}
              currentUser={currentUser}
            >
              <div className={styles.padding}>
                <div className={styles.body}>
                  {this.renderContent({
                    currentUser,
                    data,
                    selectedApplications
                  })}
                </div>
                <div className={styles.footer}>
                  Unety. &copy;2018-{new Date().getFullYear()}
                </div>
              </div>
            </DefaultLayout>
          );
        }}
      </Query>
    );
  }
}

export default Dashboard;
