import _ from 'lodash';
import numeral from 'numeral';
import React, { Component } from 'react';
import { withApollo } from '@apollo/react-hoc';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import { Link } from 'react-router-dom';
import { Card, Spin, Table } from 'antd';
import gql from 'graphql-tag';
import NotFound from '$components/NotFound';
import DesktopRequired from '$components/DesktopRequired';
import PREPAYMENT from '$lib/prepayment';
import AcceptQuote from './AcceptQuote';
import DetailsForm from './DetailsForm';
import styles from './styles.scss';

const {
  Column,
} = Table;

const APPLICATION_OFFERS_QUERY = gql`
  query ApplicationOffers($viewUuid: String!) {
    applicationOffers(viewUuid: $viewUuid) {
      application {
        status
        scopeOfWork
        propertyBuildingSize
        financingType
        uuid
        projectUuid
        property {
          uuid
          county
          formattedAddress
          lat
          lng
          locality
          state
        }
        quotes {
          uuid
          lenderUuid
          applicationUuid
          ask
          term
          rate
          prepayment
          fees
          message
          lender {
            uuid
            company
          }
        }
        usurp {
          uuid
          altv
          marketValue
          propertyType
          sqft
          debt
        }
      }
    }
  }
`;

const VALUE_CALCULATOR_QUERY = gql`
  query ValueCalculator($input: ValueCalculatorInput!) {
    valueCalculator(input: $input) {
      paceMonthlyPaymentLandlord
      paceMonthlyPaymentSticker
      paceStickerPrice
      paceLandlordPrice
    }
  }
`;

const initialValues = {
  grossFloorArea: 5000,
  percentLeased: 0,
  percentLeasedFsg: 25,
  percentLeasedMg: 25,
  percentLeasedNnn: 50,
  percentOwnerOccupied: 100,
  percentUnoccupied: 0,
  taxEscalationModifiedGrossLease: 0,
};

class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialDataLoading: true,
      valueCalculatorFormLoading: false,
      detailValues: {
        ...initialValues,
      },
    };

    this.handleUpdateDetails = this.handleUpdateDetails.bind(this);
  }

  async componentDidMount() {
    await this.fetchData(this.props);
  }

  handleUpdateDetails({ values }) {
    this.setState({
      detailValues: {
        ...values
      },
    }, () => this.fetchData());
  }

  async fetchData(props = this.props) {
    const { client, match } = props;

    const { params } = match;
    const { viewUuid } = params;

    const { data } = await client.query({
      query: APPLICATION_OFFERS_QUERY,
      variables: {
        viewUuid,
      },
    });

    const { applicationOffers } = data;
    const { application } = applicationOffers;

    if (!application) {
      this.setState({
        initialDataLoading: false,
      });
      return;
    }
    const {
      quotes,
      property,
      propertyBuildingSize,
      usurp,
    } = application;


    const detailValues = {
      ...usurp,
      buildingSqft: usurp.sqft || propertyBuildingSize || initialValues.rentableSqft,
      ...this.state.detailValues,
    };

    const valueCalculator = await Promise.all(quotes.reduce((acc, quote) => {
      return [
        ...acc,
        this.getValueCalculatorData({
          ...detailValues,
          application,
          property,
          quote,
          usurp,
        })
      ];
    }, []));

    const _quotes = quotes.reduce((acc, quote, i) => [
      ...acc,
      {
        ...quote,
        ...valueCalculator[i]
      }
    ], []);

    this.setState({
      application,
      detailValues,
      initialDataLoading: false,
      quotes: _quotes,
    });
  }

  async getValueCalculatorData(vals) {
    const {
      application,
      buildingSqft,
      property,
      quote,
      usurp,
      percentLeased,
      percentLeasedFsg,
      percentLeasedMg,
      percentLeasedNnn,
      percentOwnerOccupied,
      percentUnoccupied,
      taxEscalationModifiedGrossLease,
    } = vals;
    const scopeOfWork = application.scopeOfWork ? application.scopeOfWork.split('|') : [];

    const grossFloorArea = parseFloat((buildingSqft || initialValues.grossFloorArea).toString().replace(/\D/g, ''));

    const valueCalculatorVariables = {
      input: {
        city: (property.locality || property.county),
        cpaceProjectAmount: quote.ask,
        projectCompleteYear: 1,
        propertyType: usurp.propertyType,
        rate: quote.rate,
        grossFloorArea,
        scopeOfWork,
        state: property.state,
        preferredFinancingTerm: quote.term,
        percentLeased: percentLeased / 100,
        percentLeasedFsg: percentLeasedFsg / 100,
        percentLeasedMg: percentLeasedMg / 100,
        percentLeasedNnn: percentLeasedNnn / 100,
        percentOwnerOccupied: percentOwnerOccupied / 100,
        percentUnoccupied: percentUnoccupied / 100,
        taxEscalationModifiedGrossLease: Math.min(0.2, taxEscalationModifiedGrossLease / 100 || 0),
      },
    };

    const { data: valueData } = await this.props.client.query({
      query: VALUE_CALCULATOR_QUERY,
      variables: valueCalculatorVariables,
    });

    window.scrollTo(0, 0);

    return valueData.valueCalculator;
  }

  render() {
    const {
      application,
      detailValues,
      initialDataLoading,
      quotes,
      valueCalculatorFormLoading,
    } = this.state;

    if (initialDataLoading) {
      return (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      );
    }

    if (!application) {
      return (
        <NotFound />
      );
    }

    if (application.status === 'accepted') {
      return (
        <Card>
          <h2>Congratulations, you and your lender struck a deal!</h2>
          <p>The lender will contact you with more information and next steps.</p>
        </Card>
      );
    }

    if (application.status === 'acceptedOther') {
      return (
        <Card>
          <h2>Project was financed with a related application</h2>
          <p>The lender will contact you with more information and next steps.</p>
        </Card>
      );
    }

    if (application.status !== 'applicantNotified') {
      return (
        <Card>
          <p>Quotes are not currently available.</p>
        </Card>
      );
    }

    const { financingType, projectUuid, property } = application;
    const isPace = (financingType === 'PACE');
    const quotesByLenderUuid = _.groupBy(quotes, 'lenderUuid');

    return (
      <DesktopRequired>
        <div className={styles.layout}>
          <div className={styles.property}>
            <span>Your Unety offers for:</span>
            <div>Financing type: {financingType}</div>
            {projectUuid ? (
              <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid: projectUuid })}>{property.formattedAddress}</Link>
            ) : (
                <div>{property.formattedAddress}</div>
              )}
          </div>
          <div className={styles.content}>
            {isPace && (
              <div className={styles.form}>
                <div className={styles.cardTitle}>
                  Customize inputs
                </div>
                <Card>
                  <DetailsForm
                    initialValues={detailValues}
                    loading={valueCalculatorFormLoading}
                    handleSubmit={this.handleUpdateDetails}
                  />
                </Card>
              </div>
            )}
            <div className={styles.lenderQuoteBlock}>
              <div className={styles.cardTitle}>
                Your offers
              </div>
              {Object.entries(quotesByLenderUuid).map(([lenderUuid, offers], lenderIndex) => {
                const { lender } = offers[0];
                const defaultLenderName = `Lender ${lenderIndex + 1}`;
                let lenderName = _.get(lender, 'company', defaultLenderName);
                if (lenderName === 'CHANGE ME') {
                  lenderName = defaultLenderName;
                }

                return (
                  <Card key={`table-${lenderUuid}`} title={lenderName}>
                    <Table
                      dataSource={_.sortBy(offers, 'term')}
                      pagination={false}
                      rowKey="uuid"
                      expandedRowRender={(record) => {
                        const prepayment = PREPAYMENT[record.prepayment]
                          ? PREPAYMENT[record.prepayment].description
                          : record.prepayment;
                        return (
                          <div>
                            {!_.isEmpty(prepayment) && (
                              <p>
                                <strong>Prepayment:</strong> {prepayment}
                              </p>
                            )}
                            {!_.isEmpty(record.fees) && (
                              <p>
                                <strong>Fees:</strong> {record.fees}
                              </p>
                            )}
                            {!_.isEmpty(record.message) && (
                              <p>
                                <strong>Message:</strong> {record.message}
                              </p>
                            )}

                          </div>
                        );
                      }}
                    >
                      <Column
                        dataIndex=""
                        key="option"
                        title="Option"
                        render={(kot, kot2, i) => `#${i + 1}`}
                      />
                      <Column
                        dataIndex="ask"
                        key="ask"
                        title="Amount"
                        className={styles.bold}
                        render={(kot, { ask }) => (ask ? numeral(ask).format('$0,') : '(error)')}
                      />
                      <Column
                        align="center"
                        dataIndex="term"
                        key="term"
                        className={styles.bold}
                        title="Term"
                      />
                      {/* Sticker */}
                      <Column
                        align="center"
                        key="rate"
                        title="Rate"
                        className={styles.bold}
                        render={(kot, { paceStickerPrice }) => (paceStickerPrice ? numeral(paceStickerPrice).format('0.00%') : '(error)')
                        }
                      />
                      <Column
                        align="center"
                        key="paceMonthlyPaymentSticker"
                        title="Est Mo/ Payments"
                        className={styles.bold}
                        render={(kot, { paceMonthlyPaymentSticker }) => (
                          paceMonthlyPaymentSticker ? numeral(Math.abs(paceMonthlyPaymentSticker)).format('$0,') : '(error)'
                        )}
                      />
                      {isPace && (
                        <Column
                          align="center"
                          key="costOfCapitalPace"
                          title="Effective rate"
                          className={styles.dim}
                          render={(kot, { paceLandlordPrice }) => (paceLandlordPrice ? numeral(paceLandlordPrice).format('0.00%') : '(error)')
                          }
                        />
                      )}
                      {isPace && (
                        <Column
                          align="center"
                          key="paceMonthlyPaymentLandlord"
                          title="Est Mo/ Payments"
                          className={styles.dim}
                          render={(kot, { paceMonthlyPaymentLandlord }) => (paceMonthlyPaymentLandlord ? numeral(Math.abs(paceMonthlyPaymentLandlord)).format('$0,') : '(error)')
                          }
                        />
                      )}
                      <Column
                        align="right"
                        key="accept-quote"
                        render={(kot, { uuid }) => <AcceptQuote uuid={uuid} />}
                      />
                    </Table>
                  </Card>
                );
              })}
            </div>
          </div>
        </div >
      </DesktopRequired>
    );
  }
}

export default withApollo(Offers);
