import gql from 'graphql-tag';


export const ADMIN_APPLICATION = gql`
  query AdminApplication($applicationUuid: String!, $modifiers: AdminApplicationModifierInput) {
    adminApplication(applicationUuid: $applicationUuid, modifiers: $modifiers) {
      address
      applicantAcceptedAt
      applicantEmail
      applicantNotifiedAt
      applicantPhone
      applicationPercentComplete
      ask
      availableDocuments
      city
      contactMethod
      createdAt
      expiresAt
      financingType
      financingUrgency
      firstName
      hasRealPhoneNumber
      lastName
      lender
      lenderNotifiedAt
      lenderOther
      nameTitle
      ownerOccupied
      postalCode
      preferredFinancingTerm
      projectEstimatedCost
      projectStage
      projectType
      propertyBuildingSize
      propertyDebt
      propertyElectricRate
      propertyMarketValue
      propertyOtherLenders
      propertyPositiveOperatingIncome
      propertyRelationship
      propertyType
      rawAddress
      rawParcels
      scopeOfWork
      singleTenant
      slug
      solarFirstYearProduction
      solarSystemSize
      solarSystemType
      source
      state
      status
      termsAccepted
      utmSource
      uuid
      verifiedMarketValue
      viewUuid
      wal
      websiteTimeMs
      zohoId

      quotes {
        uuid
        ask
        term
        rate
        prepayment
        fees
        message
        createdAt
        lender {
          uuid
          email
          company
        }
      }

      usurp {
        propertyType
        propertyOwnerName
        sqft
        singleTenant
        marketValue
        valuationSource
        propertyStructureBuiltYear
        ownershipYears
        ownerOccupied
        multipleTitleOwners
        multipleRealOwners
        multipleOwnerMailingAddresses
        debt
        buyer
        lender
        lenderDatatree
        involuntaryLienSummary
        dataTreeSituation
        numberActiveInvoluntaryLiens
        nonActiveInvoluntaryLiensSummary
        propertyClass
        propertyClassScore
        ownershipDurationScore
        capRate
        capRateScore
        propertyTypeConsentScore
        wal
        ltv
        altv
        altvScore
        creditScore
        applicationCompletenessScore
        applicationSourceScore
        financingUrgencyScore
        multipleRealOwnersScore
        phoneNumberScore
        projectStageScore
        websiteTimeScore
        communicationScore
        applicationConflictsScore
        availableDocumentsScore
        taxAssessedValue
        titleScore
        environmentalScore
        documentationScore
        lenderConsentScore
        usurpScore
        noiScore
        multiTenantScore

        deedType
        unitCount
        taxDelinquency
        ownerMailingAddress
        singleLender
        postalCodeValid
        goNoGo

        communicationRating
        usurpRating
        documentationRating
        creditRating
        environmentalRiskRating
        titleRiskRating
        lenderConsentRiskRating
      }

      contractorApplication {
        uuid
        applicationUuid
        address
        city
        createdAt
        existingMortgageAddDebt
        grossAreaLargestTenant
        grossAreaSmallestTenant
        grossFloorArea
        favorite
        financingMoreImportant
        fundingQuickness
        isPaceBestMatch
        modifiedGrossLease
        monthlyUtilitySpend
        multiTenantBuilding
        numberFundingOptions
        ownerOccupied
        pctFullServiceGrossLease
        pctOccupied
        pctTripleNetLease
        postalCode
        preferredFinancingTerm
        projectSize
        projectType
        propertyMarketValue
        propertyType
        rentableUnits
        scopeOfWork
        sellAbilityFiveYears
        state
        taxEscalationModifiedGrossLease
        zeroDownImportance
      }

      property {
        streetNumber
        route
        neighborhood
        locality
        county
        state
        postalCode
        postalCodeSuffix
        formattedAddress
        googlePlusCode
        googlePlaceId
        lat
        lng
        edrCabinetId
        edrLocationId
        edrEventMatrix
        sqft
        marketValue
        debt
        debtStartDate
        lender
        otherLender
        propertyType
      }

      dataTree {
        dtPropertyType
        propertyType
        address {
          line
          county
          city
          stateCode
          postalCode
        }
        mailingAddress {
          line
          county
          city
          stateCode
          postalCode
        }
        apn
        propertyOwnerName
        propertyStructureBuiltYear
        tvr {
          buyer
          debt
          firstOpenLienYear
          involuntaryLienSummary
          lastOpenLienYear
          lastSaleDate
          lender
          nonActiveInvoluntaryLiensSummary
          numberActiveInvoluntaryLiens
          openLiens
          term
        }
        conflicts
        ownershipYears
        estimatedParcels
        block
        lot
        groundLease
        taxAssessedValue
        marketValue
        valuationSource
        taxDelinquentYear
        delinquent
        livingArea
        grossLivingAreaSqft
        sqft
        unitCount
        propertyOwnerEntityType
        ownerOccupied
        multipleTitleOwners
        multipleRealOwners
        lenders
        multipleOwnerMailingAddresses
        dataTreeSituation
        lendersDatatree
        apns
        activeLiens
        leinSummary
        ownerMailingAddress
        taxDelinquencyYear
        singleTenantLikelihood
        singleTenant
        propertyAddressMatches
        ownerAddressMatch
        quantDataMatch
        titleOwnerMatch
      }
    }
  }
`;
