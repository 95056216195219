import gql from 'graphql-tag';

const APPLICANT_DASHBOARD_QUERIES = gql`
  fragment applicantApplicationFields on ApplicationWithQuotes {
    ask
    createdAt
    financingType
    projectStage
    projectType
    propertyType
    status
    uuid
    viewUuid
    property {
      ...applicantPropertyFields
    }
    quotes {
      ...applicantQuotesFields
    }
  }

  fragment applicantPropertyFields on Property {
    uuid
    streetNumber
    route
    neighborhood
    locality
    county
    state
    postalCode
    postalCodeSuffix
    formattedAddress
    googleStaticMapUrl,
    googleStreetViewUrl
    googlePlusCode
    googlePlaceId
    lat
    lng
  }

  fragment applicantQuotesFields on Quote {
    uuid
  }

  query ApplicantDashboardQueries {
    applicantApplications {
      ...applicantApplicationFields
    }
    contractorApplications {
      uuid
      address
      city
      state
      postalCode
      createdAt
      application {
        uuid
        property {
          uuid
          formattedAddress
          route
          streetNumber
        }
        usurp {
          uuid
          marketValue
          taxAssessedValue
        }
      }
    }
  }
`;


export default APPLICANT_DASHBOARD_QUERIES;
