import React, { Component } from 'react';
import { Switch, Link, withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { routes } from '@usurp-power/aqua-silver';
import ApplicationsNew from '$components/ApplicationsNew';
import ApplicationsQuoted from '$components/ApplicationsQuoted';
import ApplicationsExpired from '$components/ApplicationsExpired';
import { PropsRoute } from '$lib/routes';

const getDefaultSelectedKeys = (pathname) => {
  if (/quoted/.test(pathname)) {
    return ['quoted'];
  }

  if (/expired/.test(pathname)) {
    return ['expired'];
  }

  return ['new'];
};

class Applications extends Component {
  render() {
    const { currentUser, data } = this.props;
    const { activeApplications, quotedApplications, expiredApplications } = data;
    const defaultSelectedKeys = getDefaultSelectedKeys(this.props.location.pathname);

    return (
      <div>
        <Menu defaultSelectedKeys={defaultSelectedKeys} mode="horizontal">
          <Menu.Item key="new">
            <Link to={routes.lender.applications.new}>
              <Icon type="thunderbolt" />New ({activeApplications.length})
            </Link>
          </Menu.Item>
          <Menu.Item key="quoted">
            <Link to={routes.lender.applications.quoted}>
              <Icon type="user" />Quoted ({quotedApplications.length})
            </Link>
          </Menu.Item>
          <Menu.Item key="expired">
            <Link to={routes.lender.applications.expired}>
              <Icon type="clock-circle" />Expired ({expiredApplications.length})
            </Link>
          </Menu.Item>
        </Menu>
        <div style={{ padding: '24px' }}>
          <Switch>
            <PropsRoute
              exact
              path={routes.lender.applications.new}
              items={activeApplications}
              component={ApplicationsNew}
              currentUser={currentUser}
            />
            <PropsRoute
              exact
              path={routes.lender.applications.quoted}
              items={quotedApplications}
              component={ApplicationsQuoted}
              currentUser={currentUser}
            />
            <PropsRoute
              exact
              path={routes.lender.applications.expired}
              items={expiredApplications}
              component={ApplicationsExpired}
              currentUser={currentUser}
            />
          </Switch>
        </div>
      </div >
    );
  }
}

export default withRouter(Applications);
