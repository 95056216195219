import React, { Component } from 'react';
import { withApollo } from '@apollo/react-hoc';

import {
  ProjectList
} from '../project';

class ContractorProjects extends Component {
  render() {
    const { currentUser } = this.props;
    return (
      <ProjectList currentUser={currentUser} />
    );
  }
}

export default withApollo(ContractorProjects);
