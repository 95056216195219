import _pick from 'lodash/pick';
import React, { PureComponent } from 'react';
import { Button, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';
import { withApollo } from '@apollo/react-hoc';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import gql from 'graphql-tag';

import PropertyDetailsForm from '../application-sidebar/multistep-form/property-details';
import FinancingTermsForm from '../application-sidebar/multistep-form/financing-terms';

import styles from './application-form.scss';

const UPDATE_CONTRACTOR_APPLICATION_FUNDING_OPTIONS = gql`
  mutation UpdateContractorApplicationFundingOptions($input: UpdateContractorApplicationFundingOptionsInput!) {
    updateContractorApplicationFundingOptions(input: $input) {
      contractorApplication {
        uuid
        existingMortgageAddDebt
        financingMoreImportant
        fundingQuickness
        grossFloorArea
        isPaceBestMatch
        preferredFinancingTerm
        projectSize
        propertyType
        scopeOfWork
        sellAbilityFiveYears
        zeroDownImportance

        fundingOptions {
          name
          message
          score
          options {
            availability
            rate
            rateText
            closeTime
            projectType
            projectSize
            upfrontCost
            contractDuration
            abilityToSell
            borrowingCapacity
            taxDeductions
          }
        }
      }
    }
  }
`;

class ProjectApplicationForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      client,
      form,
    } = this.props;

    const contractorApplicationUuid = this.props.values.uuid;

    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        const input = {
          ..._pick(values, [
            'existingMortgageAddDebt',
            'grossFloorArea',
            'financingMoreImportant',
            'fundingQuickness',
            'preferredFinancingTerm',
            'projectSize',
            'propertyType',
            'scopeOfWork',
            'sellAbilityFiveYears',
            'zeroDownImportance',
          ]),
          contractorApplicationUuid,
        };

        input.scopeOfWork = JSON.stringify(input.scopeOfWork);
        ['existingMortgageAddDebt'].forEach((field) => {
          input[field] = (input[field] === 'yes' || input[field] === 'true');
        });

        ['grossFloorArea', 'projectSize', 'preferredFinancingTerm'].forEach((field) => {
          input[field] = numeral(input[field]).value();
        });

        try {
          await client.mutate({
            mutation: UPDATE_CONTRACTOR_APPLICATION_FUNDING_OPTIONS,
            variables: {
              input,
            },
          });
          this.props.history.push(reverse(routes.contractor.projects.project.fundingOptions, { contractorUuid: contractorApplicationUuid }));
        } catch (updateError) {
          this.setState({
            loading: false,
          });
        }
      }
    });
  }

  render() {
    const {
      form,
      values,
    } = this.props;

    const { loading } = this.state;

    const { getFieldDecorator } = form;

    const _values = {
      ...values,
      scopeOfWork: typeof values.scopeOfWork === 'string' ? JSON.parse(values.scopeOfWork) : values.scopeOfWork,
    };

    return (
      <div className={styles.content}>
        <Form onSubmit={this.handleSubmit}>
          <div className={styles.formBlock}>
            <div className={styles.title}>Property details</div>
            <PropertyDetailsForm
              getFieldDecorator={getFieldDecorator}
              values={_values}
            />
          </div>
          <div className={styles.formBlock}>
            <div className={styles.title}>Financing Details</div>
            <FinancingTermsForm
              getFieldDecorator={getFieldDecorator}
              values={_values}
            />
          </div>
          <div className={styles.formBlock}>
            <Button data-aaa="submitFundingOptions" type="primary" htmlType="submit" loading={loading}>See results</Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create()(withApollo(withRouter(ProjectApplicationForm)));
