import React from 'react';
import { Checkbox, Form } from 'antd';
import { fieldDecoratorRules } from '../../../constants/forms';
import { fieldError } from '../../../lib/formatter';

import styles from './styles.scss';

const { Group: CheckboxGroup } = Checkbox;

const documentOptions = [
  'Most recent mortgage statement',
  'Most recent property tax invoice',
  'Rent roll',
  '3 years of audited financials',
  'Property appraisal'
];

const ProjectDetails = ({
  errors,
  getFieldDecorator,
  values,
  className,
}) => {
  let _availableDocuments = values.availableDocuments;

  try {
    _availableDocuments = JSON.parse(_availableDocuments);
  } catch (e) { } // eslint-disable-line no-empty

  return (
    <div className={className}>
      <div className={errors && errors.find((e) => e === 'availableDocuments') ? styles.errorComponent : ''}>
        <Form.Item label="If requested by a lender, will you be able to provide the following documents (check all that apply)">
          {getFieldDecorator('availableDocuments', {
            initialValue: _availableDocuments,
          })(
            <CheckboxGroup
              options={documentOptions}
              style={{ marginTop: 15 }}
            />
          )}
        </Form.Item>
      </div>

      <Form.Item
        label="Terms and conditions"
        validateStatus={fieldError('termsAccepted', errors)}
      >
        {getFieldDecorator('termsAccepted', {
          rules: fieldDecoratorRules,
          initialValue: values.termsAccepted,
        })(
          <div className={styles.termsGroup}>
            <Checkbox data-aaa="termsAccepted">
              I agree to the <a target="_blank" rel="noopener noreferrer" href="https://unety.io/terms/"><strong>terms and conditions</strong></a>
            </Checkbox>
          </div>
        )}
      </Form.Item>
    </div>
  );
};

export default ProjectDetails;
