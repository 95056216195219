import { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import storage from '$lib/storage';

class SessionUuid extends Component {
  componentDidMount() { // eslint-disable-line class-methods-use-this
    const sessionUuid = storage.sessionUuid.get();
    if (!sessionUuid) {
      storage.sessionUuid.set(uuidv4());
    }
  }

  render() {
    return null;
  }
}

export default SessionUuid;
