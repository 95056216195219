import React from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import Well from '$components/common/layout/Well';
import ManageBilling from './ManageBilling';
import PlanTiles from './PlanTiles';
import styles from './styles.scss';

const PlanText = (props) => {
  const {
    planCredits,
    planExpiresAt,
    planExpiresAtF,
    planStatus,
    planType,
  } = props;

  if (!planType) {
    return (
      <div className={styles.planInfo}>
        <div>Pick a plan based on the number of properties you&apos;d like to screen each month.</div>
        <div>Both plans come with a 30-day free trial.</div>
      </div>
    );
  }

  if (planStatus !== 'active') {
    return (
      <div className={styles.planInfo}>
        <div>Your plan status is: <b>{planStatus}</b>.</div>
        <div>Please use the button below to manage your billing account.</div>
      </div>
    );
  }

  if (planExpiresAt < moment()) {
    return (
      <div className={styles.planInfo}>
        <div>Your plan expired at {planExpiresAtF}.</div>
        <div>Please use the button below to manage your billing account.</div>
      </div>
    );
  }

  if (planCredits <= 0) {
    return (
      <div className={styles.planInfo}>
        <div>You&apos;ve used all your property screening credits for this plan cycle.</div>
        <div>Your <b>{planType}</b> plan will renew at {planExpiresAtF}.</div>
      </div>
    );
  }

  return (
    <div className={styles.planInfo}>
      <div>You have {pluralize('property screening credit', planCredits, true)}.</div>
      <div>Your <b>{planType}</b> plan will renew at {planExpiresAtF}.</div>
    </div>
  );
};

const Plan = ({ currentUser, location }) => {
  const {
    planCredits,
    planExpiresAt,
    planStatus,
    planType,
    stripeCustomerId,
  } = currentUser;

  const planExpiresAtF = planExpiresAt ? moment(planExpiresAt).format('dddd, MMMM Do YYYY, h:mm a') : null;

  return (
    <DefaultLayout
      location={location}
      currentUser={currentUser}
    >
      <Well>
        <div className={styles.layout}>
          <h1>Your plan details</h1>

          <PlanText
            planType={planType}
            planStatus={planStatus}
            planCredits={planCredits}
            planExpiresAt={planExpiresAt}
            planExpiresAtF={planExpiresAtF}
          />

          <PlanTiles user={currentUser} />
          <ManageBilling user={currentUser} />
        </div>
      </Well>
    </DefaultLayout>
  );
};

export default Plan;
