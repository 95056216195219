import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Button, Spin } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import { Link } from 'react-router-dom';
import PaceRefiSubmitEmail from './PaceRefiSubmitEmail';
import withContractorApplication from '../withContractorApplication';

import sharedStyles from '../ProjectOverview/CommonSmallBlock/shared.scss';
import styles from './styles.scss';

const iconClass = (field) => {
  if (field === 'pass') {
    return 'fal fa-check';
  }

  if (field === 'fail') {
    return 'fal fa-times';
  }


  return 'fal fa-exclamation-triangle';
};

const CONTRACTOR_APPLICATION = gql`
  query ContractorApplication($uuid: ID!) {
    contractorApplication(uuid: $uuid) {
      uuid

      application {
        uuid
        status
        dataStatus
        dataTreeStatus
        edrStatus
      }

      paceRefi {
        uuid
        scopeOfWork
        projectMonth
        result
        resultState
        resultLookback
        resultLookbackText
        resultCredit
        resultTitle
        resultEnvironmental
        email
      }
    }
  }
`;

const ResultRow = ({ field, text }) => {
  return (
    <div className={styles.resultRow}>
      <div className={styles.iconWrapper}>
        <i className={iconClass(field)} />
      </div>
      <div className={styles.resultText}>{text}</div>
    </div>
  );
};

class ProjectOverviewPaceRefi extends Component {
  constructor(props) {
    super(props);

    const { application } = props;

    if (application.status === 'pendingData') {
      setTimeout(this.checkApplication, 5000);
    }
  }

  checkApplication = async () => {
    const { client, contractorApplication } = this.props;

    const { data: { contractorApplication: { application } } } = await client.query({
      query: CONTRACTOR_APPLICATION,
      variables: { uuid: contractorApplication.uuid },
      fetchPolicy: 'network-only',
    });

    this.setState({
      application,
    });

    if ((application.status === 'pendingData') || (application.dataStatus === 'pendingData')) {
      setTimeout(this.checkApplication, 5000);
    }
  }

  render() {
    return (
      <div className={styles.layout}>
        <div className={styles.well}>
          <div className={styles.title}>Qualify for capital recovery financing</div>
          {this.renderContent()}
          {this.renderAction()}
          <div className={styles.explanation}>
            <h4>Questions? We&apos;re here for you.</h4>
            <p>Connect with us to discuss your project, financing, or capital recovery options. <a href="mailto:questions@unety.io" target="_blank" rel="noopener noreferrer">Email our team</a> or <a href="https://meetings.hubspot.com/frank130/30-min-intro" target="_blank" rel="noopener noreferrer">schedule a call</a>.
          </p>
          </div>
        </div>
      </div>
    );
  }

  renderAction = () => {
    const { contractorApplication } = this.props;
    const { application, paceRefi } = contractorApplication;
    const { uuid: applicationUuid } = application;

    if (!paceRefi.result) {
      return null;
    }

    if (paceRefi.result === 'fail') {
      return (
        <PaceRefiSubmitEmail paceRefi={paceRefi} />
      );
    }

    const flavorText = (paceRefi.result === 'pass')
      ? 'Your project is an ideal candidate'
      : 'Your project passed our pre-screen but might require additional information';

    return (
      <div>
        <p>{flavorText}</p>
        <Link to={`${reverse(routes.applicant.revise, { applicationUuid })}?projectUuid=${contractorApplication.uuid}`}>
          <Button type="primary">Shop for pricing in less than 10 minutes</Button>
        </Link>
      </div>
    );
  }

  renderContent = () => {
    const { contractorApplication } = this.props;
    const { application, paceRefi } = contractorApplication;

    const {
      status: applicationStatus,
      dataStatus,
      rawAddress,
    } = application;

    if (/^failed/.test(applicationStatus)) { // 20191230JP: Might want another state for address not matched.
      return (
        <div className={sharedStyles.layout}>
          <div className={sharedStyles.title}>We couldn&apos;t automatically screen this property</div>
          <br />
          <div className={sharedStyles.description}>
            Your property may have a special situation which our engine is not currently able to automatically process. Our team has been notified and we will investigate the issue.
          </div>
        </div>
      );
    }

    if (applicationStatus !== 'augmented') {
      return (
        <div className={styles.loadingContainer}>
          <div className={sharedStyles.title}>One moment, we&apos;re evaluating your property</div>
          <br />
          <div className={sharedStyles.description}>This process usually takes less than two minutes, and this page will update automatically.</div>
          <Spin className={styles.spinner} />
        </div>
      );
    }

    const dataReady = (dataStatus === 'done');
    if (!dataReady) {
      return (
        <div className={styles.loadingContainer}>
          <div className={sharedStyles.title}>Still processing...</div>
          <br />
          <div className={sharedStyles.description}>This property is taking longer to score than usual. Please check back in a few minutes.</div>
          <Spin className={styles.spinner} />
        </div>
      );
    }

    const {
      result,
      resultState,
      resultLookback,
      resultLookbackText,
      resultCredit,
      resultTitle,
      resultEnvironmental,
    } = paceRefi;

    const resultStateText = (resultState === 'pass') ? 'Active state programs' : 'No active state programs';
    const resultCreditText = 'Credit score';
    const resultTitleText = 'Title score';
    const resultEnvironmentalText = 'Environmental score';

    const resultIconClass = iconClass(result);

    return (
      <div className={styles.resultContainer}>
        <p>{rawAddress}</p>
        <div className={styles.result}>
          <i className={resultIconClass} />
        </div>
        <div className={styles.resultBreakdown}>
          <ResultRow field={resultState} text={resultStateText} />
          <ResultRow field={resultLookback} text={`Retroactive to: ${resultLookbackText}`} />
          <ResultRow field={resultCredit} text={resultCreditText} />
          <ResultRow field={resultTitle} text={resultTitleText} />
          <ResultRow field={resultEnvironmental} text={resultEnvironmentalText} />
        </div>
      </div>
    );
  }
}

export default withContractorApplication(withApollo(ProjectOverviewPaceRefi));
