import gql from 'graphql-tag';
import storage from '$lib/storage';
import CURRENT_USER_ONBOARDING_PROGRESS from '$queries/currentUserOnboardingProgress';

const CREATE_APPLICATIONS = gql`
  mutation CreateApplications($input: CreateApplicationsInput!) {
    createApplications(input: $input) {
      applications {
        uuid
        viewUuid
        status
      }
    }
  }
`;

const CREATE_CONTRACTOR_APPLICATION = gql`
  mutation CreateContractorApplication($input: CreateContractorApplicationInput!) {
    createContractorApplication(input: $input) {
      uuid
      user {
        uuid
        planCredits
      }
    }
  }
`;

const createApplications = async (input) => {
  const {
    client,
    organizationUuid,
    parcels,
    location: {
      address,
      city,
      postalCode,
      state,
    },
    source = 'contractor',
    contractorApplicationInput = {},
  } = input;

  const location = `${address}, ${city}, ${state} ${postalCode}`;

  const { data } = await client.mutate({
    mutation: CREATE_APPLICATIONS,
    variables: {
      input: {
        organizationUuid,
        financingTypes: [null],
        applicantEmail: '',
        location,
        source,
        rawParcels: JSON.stringify(parcels.filter(({ apn }) => Boolean(apn))),
      },
    },
  });

  const {
    applications,
  } = data.createApplications;

  const {
    uuid: applicationUuid,
    viewUuid,
  } = applications[0];

  const sessionUuid = storage.sessionUuid.get();

  const { data: contractorData } = await client.mutate({
    mutation: CREATE_CONTRACTOR_APPLICATION,
    variables: {
      input: {
        applicationUuid,
        organizationUuid,
        address,
        city,
        postalCode,
        state,
        sessionUuid,
        ...contractorApplicationInput,
      },
    },
    refetchQueries: [{ query: CURRENT_USER_ONBOARDING_PROGRESS }]
  });

  return {
    contractorUuid: contractorData.createContractorApplication.uuid,
    applicationUuid,
    applicationViewUuid: viewUuid,
  };
};

export {
  createApplications,
};
