import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { withApollo } from '@apollo/react-hoc';

import gql from 'graphql-tag';
import styles from './button.scss';

const SEND_FEEDBACK = gql`
  mutation SendFeedbackEmail($issue: String!, $message: String!, $url: String!, $prevUrl: String!, $applicationUuid: String){
    sendFeedbackEmail(issue: $issue, message: $message, url: $url, prevUrl: $prevUrl, applicationUuid: $applicationUuid) {
      success
    }
  }
`;

class FeedbackButton extends PureComponent {
  static proptypes = {
    applicationUuid: PropTypes.string,
    buttonLabel: PropTypes.string,
  }

  static defaultProps = {
    buttonLabel: 'Let us know'
  };

  constructor(props) {
    super(props);

    this.messageFieldRef = React.createRef();

    this.state = {
      formSubmitting: false,
      modalOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleModalVisibility = this.toggleModalVisibility.bind(this);
  }

  handleSubmit(evt) {
    evt.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { data, error } = await this.props.client.mutate({
          mutation: SEND_FEEDBACK,
          variables: {
            ...values,
            applicationUuid: this.props.applicationUuid,
            issue: 'Application rating feedback',
            prevUrl: window.document.referrer,
            url: window.location.href,
          }
        });

        const notificationType = !data.sendFeedbackEmail.success || error ? 'error' : 'success';
        notification[notificationType]({
          message: notificationType === 'error'
                   ? 'There was an error:'
                   : 'Thank you for your feedback',
          description: notificationType === 'error'
                       ? error || ''
                       : 'We\'ll get back to you shortly.'
        });

        this.setState(() => ({
          formSubmitting: false,
          modalOpen: false
        }));
      }
    });
  }

  toggleModalVisibility(modalOpen = true) {
    if (modalOpen) {
      setTimeout(() => {
        if (this.messageFieldRef.current) {
          this.messageFieldRef.current.focus();
        }
      }, 300);

      this.props.form.resetFields();
    }
    this.setState(() => ({
      modalOpen
    }));
  }

  render() {
    const { formSubmitting, modalOpen } = this.state;
    const { buttonLabel } = this.props;

    const {
      getFieldDecorator
    } = this.props.form;

    const okButtonProps = {
      form: 'feedbackMessageForm',
      htmlType: 'submit',
      key: 'submit',
      loading: formSubmitting,
    };

    return (
      <div>

        <Modal
          okText="Send"
          title="Share an Improvement"
          visible={modalOpen}
          okButtonProps={okButtonProps}
          onOk={this.handleOk}
          onCancel={this.toggleModalVisibility.bind(this, false)}
        >
          <p>Do the ratings seem off based on what you know about the property. Fill in the form below and let us know.</p>
          <Form
            id="feedbackMessageForm"
            onSubmit={this.handleSubmit}
          >
            <Form.Item label="Message">
              {getFieldDecorator('message', {
                rules: [{ required: true, message: 'Please explain what the rating should be and why you think this.' }],
              })(
                <Input.TextArea
                  ref={this.messageFieldRef}
                />
              )}
            </Form.Item>
          </Form>
        </Modal>

        <Button
          className={styles.feedbackButton}
          size="small"
          type="primary"
          onClick={this.toggleModalVisibility.bind(this, true)}
        >
          { buttonLabel }
        </Button>
      </div>
    );
  }
}


export default Form.create({ name: 'register' })(withApollo(FeedbackButton));
