import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Form, Input, Rate, Select } from 'antd';

import PREPAYMENT from '$lib/prepayment';
import RiskRow from '$components/RiskRow';
import { fieldDecoratorRules } from '../../constants/forms';
import { formatNumber } from '../../lib/formatter';
import { PROPERTY_TYPE_MAPPINGS } from '$lib/filters';

import styles from './listItemStyles.scss';

class ListItemComponent extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    onAddQuote: PropTypes.func,
    onMessageChange: PropTypes.func,
    onNumberChange: PropTypes.func,
    onQuoteInputChange: PropTypes.func,
    onRemoveQuote: PropTypes.func,
  };

  static defaultProps = {
    active: true,
    onAddQuote: () => {},
    onNumberChange: () => {},
    onQuoteInputChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.item && this.props.item.quoteStatus.quotes.length === 0) {
      this.props.onAddQuote({
        uuid: this.props.item.uuid,
        applicationUuid: this.props.item.application.uuid
      });
    }
  }

  validateField({ id }, e) {
    const {
      value
    } = e.target;
    const {
      errors
    } = this.state;
    if (id.indexOf('rate') === 0) {
      const decimal = value.split('.')[1];
      errors[id] = decimal && decimal.length > 2
        ? 'Rate is limited to hundreths'
        : '';
    } else if (id.indexOf('term') === 0) {
      errors[id] = value.indexOf('.') !== -1
        ? 'Term is limited to whole numbers'
        : '';
    }

    this.setState({
      errors,
    });
    return true;
  }

  render() {
    const {
      errors
    } = this.state;

    const {
      active,
      form,
      item,
      messageText,
      onAddQuote,
      onMessageChange,
      onNumberChange,
      onQuoteInputChange,
      onRemoveQuote,
      lenderPreferences,
    } = this.props;

    const {
      application: {
        ask,
        slug,
        financingType,
        projectStage,
        projectType,
        uuid: applicationUuid
      },
      message,
      property: {
        googleStaticMapUrl,
        googleStreetViewUrl,
      },
      quoteStatus: {
        quotes
      },
      usurp: {
        wal,
        ltv,
        altv,
        environmentalScore,
        titleScore,
        lenderConsentScore,
        propertyType,
        usurpScore,
      },
      uuid
    } = item;

    const {
      getFieldDecorator
    } = form;

    const propertyName = slug;

    return (
      <div className={styles.root}>
        <div className={[styles.row, styles.infoRow].join(' ')}>
          <div className={styles.leftContent}>
            <img className={styles.map} src={googleStaticMapUrl} />
            <img className={styles.map} src={googleStreetViewUrl} />
          </div>
          <div className={styles.rightContent}>
            <h4 className={styles.propertyName}>{propertyName} - {financingType}</h4>
            <Rate disabled defaultValue={Math.round(usurpScore * 5)} className={styles.rate} />
            <div className={styles.statsColumns}>
              <div className={styles.statsColumn}>
                <div>Ask: {numeral(ask).format('$0,0')}</div>
                <div className={styles.projectTypeStage}>
                  {projectType} {projectStage && `(${projectStage})`}
                </div>
                <div>{numeral(wal).format('0.00')} WAL</div>
                <div>{PROPERTY_TYPE_MAPPINGS[propertyType]}</div>
                <div>{numeral(ltv).format('0%')} LTV</div>
                <div>{numeral(altv).format('0%')} ALTV</div>
              </div>
              <div className={styles.statsColumn}>
                <RiskRow value={environmentalScore} type="environmental" text="Environmental Risk" lenderPreferences={lenderPreferences} />
                <RiskRow value={titleScore} type="title" text="Title Risk" lenderPreferences={lenderPreferences} />
                <RiskRow value={lenderConsentScore} type="lenderConsent" text="Lender Consent Risk" lenderPreferences={lenderPreferences} />
              </div>
              <div className={styles.statsColumn}>
                <div className={styles.quoteMessage}>
                  <Input.TextArea
                    disabled={!active}
                    name="message"
                    placeholder="Message for applicant."
                    value={messageText}
                    onChange={(e) => onMessageChange({ e, uuid })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            {_.map(quotes, (quote, i) => {
              const termId = `term-${quote.uuid}`;
              const rateId = `rate-${quote.uuid}`;
              return (
                <div key={quote.uuid} className={styles.quote}>
                  <div className={styles.quoteHeader}>
                    <div className={styles.quoteHeaderLabel}>
                      Quote #{i + 1} for {financingType}
                      {active && (quotes.length > 1) && (
                        <Button
                          className={styles.removeQuoteButton}
                          defaultValue={message}
                          shape="circle"
                          icon="minus"
                          size="small"
                          type="danger"
                          onClick={() => active && onRemoveQuote(uuid, i)}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.quoteContent}>
                    <div className={styles.quoteBasics}>
                      <div className={styles.quoteField}>
                        <Form.Item label="Size">
                          {getFieldDecorator(`ask-${quote.uuid}`, {
                            getValueFromEvent: (e) => formatNumber(e.target.value),
                            rules: fieldDecoratorRules,
                            initialValue: formatNumber(quote.ask),
                          })(
                            <Input
                              disabled={!active}
                              name="ask"
                              type="text"
                              onBlur={(e) => active && onQuoteInputChange({ e, i, uuid })}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className={styles.quoteField}>
                        <Form.Item label="Prepayment">
                          {getFieldDecorator(`prepayment-${quote.uuid}`, {
                            rules: fieldDecoratorRules,
                            initialValue: quote.prepayment,
                          })(
                            <Input
                              disabled={!active}
                              name="prepayment"
                              type="text"
                              onBlur={(e) => onQuoteInputChange({ e, i, uuid })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    <div className={styles.quoteAdvanced}>
                      <div className={styles.quoteField}>
                        <Form.Item label="Term" extra={errors[termId]}>
                          {getFieldDecorator(termId, {
                            getValueFromEvent: (e) => formatNumber(e.target.value),
                            rules: fieldDecoratorRules,
                            initialValue: parseInt(quote.term, 10),
                          })(
                            <Input
                              addonAfter="years"
                              disabled={!active}
                              name="term"
                              type="text"
                              onBlur={(e) => onQuoteInputChange({ e, i, uuid })}
                              onChange={this.validateField.bind(this, { id: termId })}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className={styles.quoteField}>
                        <Form.Item label="Rate" extra={errors[rateId]}>
                          {getFieldDecorator(rateId, {
                            getValueFromEvent: onNumberChange,
                            rules: fieldDecoratorRules,
                            initialValue: parseFloat(quote.rate).toFixed(2),
                            // trigger: 'onBlur'
                          })(
                            <Input
                              addonAfter="%"
                              disabled={!active}
                              type="number"
                              name="rate"
                              onBlur={(e) => {
                                onQuoteInputChange({ e, i, uuid });
                              }}
                              onChange={this.validateField.bind(this, { id: rateId })}
                            />
                          )}
                        </Form.Item>
                      </div>
                      <div className={styles.quoteField}>
                        <Form.Item label="Fees">
                          {getFieldDecorator(`fees-${quote.uuid}`, {
                            rules: fieldDecoratorRules,
                            initialValue: quote.fees,
                          })(
                            <Input
                              disabled={!active}
                              name="fees"
                              type="text"
                              onBlur={(e) => onQuoteInputChange({ e, i, uuid })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          { active && (
            <Button
              disabled={!active}
              className={styles.addQuoteButton}
              onClick={() => onAddQuote({ uuid, applicationUuid })}
            >
              Add quote
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(ListItemComponent);
