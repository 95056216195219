import React, { Component } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { auth, routes } from '@usurp-power/aqua-silver';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import Unauthorized from '$components/common/Unauthorized';

import AdminDashboard from '$components/admin/AdminDashboard';
import AdminUsers from '$components/admin/Users';
import ApplicationsScreen from './applications';
import ApplicationScreen from './application';
import NotFound from '$components/NotFound';
import { PropsRoute } from '$lib/routes';

const { isAdmin } = auth;

class AdminDashbord extends Component {
  render() {
    const { currentUser, location } = this.props;

    if (!isAdmin(currentUser)) {
      return <Unauthorized />;
    }

    return (
      <DefaultLayout
        location={location}
        currentUser={currentUser}
      >
        <Switch>
          <Redirect exact from={routes.admin.prefix} to={routes.admin.applications} />
          <PropsRoute path={routes.admin.dashboard} component={AdminDashboard} />
          <PropsRoute path={routes.admin.users} component={AdminUsers} />
          <PropsRoute path={routes.admin.applications} component={ApplicationsScreen} />
          <PropsRoute path={routes.admin.application.applicationScreen} component={ApplicationScreen} />
          <Route component={NotFound} />
        </Switch>
      </DefaultLayout>
    );
  }
}

export default AdminDashbord;
