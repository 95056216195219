import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Input, Select } from 'antd';

import styles from './multiStep.scss';
import { fieldDecoratorRules } from '../../../../constants/forms';
import { formatNumber } from '../../../../lib/formatter';

const { Option } = Select;

class FinancingTerms extends PureComponent {
  static propTypes = {
    disableFields: PropTypes.bool,
    errors: PropTypes.shape(),
    getFieldDecorator: PropTypes.func.isRequired,
    values: PropTypes.shape(),
  };

  static defaultProps = {
    disableFields: false,
    errors: {},
    values: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      values: this.props.values,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  handleChange(key, evt) {
    const value = (() => {
      if ((evt === true) || (evt === false)) {
        return evt;
      }

      const val = (typeof evt === 'string') ? evt : evt.target.value;

      if (val === 'true' || val === 'false') {
        return Boolean(val);
      }

      return val;
    })();

    this.setState(() => ({
      values: {
        ...this.state.values,
        [key]: value,
      }
    }));
  }

  handleNumberChange(evt) {
    const result = formatNumber(evt.target.value);
    return result;
  }

  render() {
    const {
      disableFields,
      getFieldDecorator,
    } = this.props;
    const {
      values
    } = this.state;

    const initialExistingMortgageAddDebt = (() => {
      const { application, existingMortgageAddDebt } = values;
      const { usurp } = application;
      const { debt } = usurp;

      if (typeof existingMortgageAddDebt === 'undefined' || existingMortgageAddDebt === null) {
        if (typeof debt === 'undefined') {
          return undefined;
        }

        return debt ? 'yes' : 'no';
      }

      if (typeof existingMortgageAddDebt === 'boolean') {
        return existingMortgageAddDebt ? 'yes' : 'no';
      }

      return existingMortgageAddDebt;
    })();

    return (
      <div>
        <div className={styles.twoColumn}>
          <Form.Item label="Does the existing mortgage limit the property's ability to add debt?" colon={false}>
            {getFieldDecorator('existingMortgageAddDebt', {
              initialValue: initialExistingMortgageAddDebt,
              rules: fieldDecoratorRules,
            })(
              <Select
                data-aaa="existingMortgageAddDebt"
                disabled={disableFields}
                placeholder="Select One"
                onChange={this.handleChange.bind(this, 'existingMortgageAddDebt')}
              >
                <Option value='yes'>Yes</Option>
                <Option data-aaa="existingMortgageAddDebt--no" value='no'>No</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="How important is preserving the ability to sell in the next five years?" colon={false}>
            {getFieldDecorator('sellAbilityFiveYears', {
              initialValue: values.sellAbilityFiveYears || 'Very',
              rules: fieldDecoratorRules,
            })(
              <Select
                disabled={disableFields}
                placeholder="Select One"
                onChange={this.handleChange.bind(this, 'sellAbilityFiveYears')}
              >
                <Option value="Very">Very</Option>
                <Option value="Not Very">Not Very</Option>
              </Select>
            )}
          </Form.Item>
        </div>
        <div className={styles.twoColumn}>
          <Form.Item label="With financing, which is more important?" colon={false}>
            {getFieldDecorator('financingMoreImportant', {
              initialValue: values.financingMoreImportant || 'Monthly Payments',
              rules: fieldDecoratorRules,
            })(
              <Select
                disabled={disableFields}
                placeholder="Select One"
                onChange={this.handleChange.bind(this, 'financingMoreImportant')}
              >
                <Option value="Rate">Rate</Option>
                <Option value="Monthly Payments">Monthly Payments</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="What is the preferred financing term?" colon={false}>
            {getFieldDecorator('preferredFinancingTerm', {
              getValueFromEvent: this.handleNumberChange,
              initialValue: values.preferredFinancingTerm || 15,
              rules: fieldDecoratorRules,
            })(
              <Input
                data-aaa="preferredFinancingTerm"
                addonAfter="Years"
                disabled={disableFields}
                maxLength={10}
                placeholder="5"
                type="text"
                onChange={this.handleChange.bind(this, 'preferredFinancingTerm')}
              />
            )}
          </Form.Item>
        </div>
        <div className={styles.twoColumn}>
          <Form.Item label="How quickly is the financing needed?" colon={false}>
            {getFieldDecorator('fundingQuickness', {
              initialValue: values.fundingQuickness || 'Flexible',
              rules: fieldDecoratorRules,
            })(
              <Select
                disabled={disableFields}
                placeholder="Select One"
                onChange={this.handleChange.bind(this, 'fundingQuickness')}
              >
                <Option value="ASAP">ASAP</Option>
                <Option value="Flexible">Flexible</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="How important is zero downpayment?" colon={false}>
            {getFieldDecorator('zeroDownImportance', {
              initialValue: values.zeroDownImportance || 'Very',
              rules: fieldDecoratorRules,
            })(
              <Select
                disabled={disableFields}
                placeholder="Select One"
                onChange={this.handleChange.bind(this, 'zeroDownImportance')}
              >
                <Option value="Very">Very</Option>
                <Option value="Not Very">Not Very</Option>
              </Select>
            )}
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default FinancingTerms;
