import _ from 'lodash';
import { Component } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import qs from 'qs';

const ROOT_DOMAIN = process.env.ROOT_DOMAIN; // eslint-disable-line prefer-destructuring

class UtmSource extends Component {
  componentDidMount() { // eslint-disable-line class-methods-use-this
    const { search } = window.location;
    if (_.isEmpty(search)) {
      return;
    }

    const query = search.substring(1);
    const parsed = qs.parse(query);

    const {
      utm_source: utmSource,
    } = parsed;

    const now = +moment();

    if (utmSource) {
      Cookies.set('utmSource', utmSource, { domain: ROOT_DOMAIN });
      Cookies.set('utmSourceAt', now, { domain: ROOT_DOMAIN });
      console.log(`set cookie for utmSource: ${utmSource}`); // eslint-disable-line no-console
    }
  }

  render() {
    return null;
  }
}

export default UtmSource;
