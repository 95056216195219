import React, { PureComponent } from 'react';
import { routes } from '@usurp-power/aqua-silver';
import { Link, Redirect } from 'react-router-dom';
import ContractorScreen from './ContractorScreen';
import PropertySearch from '$components/common/PropertySearch';
import { ThemeContext } from '$lib/context';

import styles from './styles.scss';

const canScreenProperty = ({ user }) => {
  const { planCredits, planExpiresAt } = user;
  if (!planCredits || (planCredits <= 0)) {
    return false;
  }

  if (!planExpiresAt || (planExpiresAt < new Date())) {
    return false;
  }

  return true;
};

class ContractorQuote extends PureComponent {
  render() {
    const { currentUser } = this.props;

    if (!canScreenProperty({ user: currentUser })) {
      return (
        <Redirect to={routes.plan} />
      );
    }

    const banner = (
      <Link to={routes.contractor.projects.newPaceRefi} className={styles.bannerLink}>
        Upgraded your building in the last five years? You may qualify for a special government loan program. Click here.
      </Link>
    );

    return (
      <ThemeContext.Consumer>
        {({ organizationUuid }) => (
          <PropertySearch banner={banner}>
            {({ location, parcels, resetMap, setSidebarStatus }) => (
              <ContractorScreen
                geocodedLocation={location}
                parcels={parcels}
                organizationUuid={organizationUuid}
                resetMap={resetMap}
                nextPath={routes.contractor.projects.project.overview}
                setSidebarStatus={setSidebarStatus}
              />
            )}
          </PropertySearch>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default ContractorQuote;
