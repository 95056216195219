// Keep this in sync with usurp-server/src/daos/propertyTypes.js

const PROPERTY_TYPE_OBJECTS = [{
  key: 'agricultural',
  name: 'Agricultural',
}, {
  key: 'assistedLiving',
  name: 'Assisted living',
}, {
  key: 'autoServiceOrGas',
  name: 'Auto service or gas',
}, {
  key: 'bowlingAlley',
  name: 'Bowling alley',
}, {
  key: 'cafeOrRestaurant',
  name: 'Cafe or restaurant',
}, {
  key: 'commercialOther',
  name: 'Commercial - other',
}, {
  key: 'countryClub',
  name: 'Country club',
}, {
  key: 'danceHall',
  name: 'Dance hall',
}, {
  key: 'driveInTheater',
  name: 'Drive in theater',
}, {
  key: 'education',
  name: 'Education',
}, {
  key: 'golfCourse',
  name: 'Golf course',
}, {
  key: 'groceryOrConvenienceStore',
  name: 'Grocery or convenience store',
}, {
  key: 'hotelAndHospitality',
  name: 'Hotel and hospitality',
}, {
  key: 'industrial',
  name: 'Industrial',
}, {
  key: 'inpatientHospital',
  name: 'Inpatient hospital',
}, {
  key: 'laundromat',
  name: 'Laundromat',
}, {
  key: 'mobileHomePark',
  name: 'Mobile home park',
}, {
  key: 'multifamily',
  name: 'Multi family',
}, {
  key: 'office',
  name: 'Office',
}, {
  key: 'other',
  name: 'Other',
}, {
  key: 'outpatientClinic',
  name: 'Outpatient clinic',
}, {
  key: 'publicOrSemiPublic',
  name: 'Public or semi public',
}, {
  key: 'recreational',
  name: 'Recreational',
}, {
  key: 'religiousWorship',
  name: 'Religious worship',
}, {
  key: 'residential',
  name: 'Residential',
}, {
  key: 'residenceHallAndDormitories',
  name: 'Residence hall and dormitories',
}, {
  key: 'retailNotMall',
  name: 'Retail - not mall',
}, {
  key: 'rvPark',
  name: 'RV park',
}, {
  key: 'shoppingCenterAndStripMalls',
  name: 'Shopping center and strip malls',
}, {
  key: 'skatingRink',
  name: 'Skating rink',
}, {
  key: 'theater',
  name: 'Theater',
}, {
  key: 'transporationAndUtility',
  name: 'Transportation and utility',
}, {
  key: 'vacant',
  name: 'Vacant',
}, {
  key: 'warehouseAndStorage',
  name: 'Warehouse and storage',
}].map((p) => {
  const _p = {
    ...p
  };
  _p.text = _p.name;
  _p.value = _p.key;
  return _p;
});

const PROPERTY_TYPE_MAPPINGS = PROPERTY_TYPE_OBJECTS.reduce((acc, obj) => {
  acc[obj.key] = obj.name;
  return acc;
}, {});

export {
  PROPERTY_TYPE_OBJECTS,
  PROPERTY_TYPE_MAPPINGS,
};
