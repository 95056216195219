import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import { Spin } from 'antd';
import { reverse } from 'named-urls';
import { createApplications } from './createApplication';

// 20191226JP: This needs to be refactored once we've settled on a flow.
class ContractorScreen extends PureComponent {
  componentDidMount() {
    this.createApplicationAndNavigate();
  }

  goToProjectOverview = ({ contractorUuid }) => {
    const { nextPath } = this.props;
    this.props.history.push(reverse(nextPath, { contractorUuid }));
  }

  createApplicationAndNavigate = async () => {
    const {
      client,
      geocodedLocation,
      organizationUuid,
      parcels,
      source,
      contractorApplicationInput,
    } = this.props;

    const {
      contractorUuid,
    } = await createApplications({
      client,
      organizationUuid,
      parcels,
      location: geocodedLocation,
      source,
      contractorApplicationInput,
    });

    this.goToProjectOverview({ contractorUuid });
  }

  liftState = (state) => {
    this.setState(state);
  };

  render() {
    return (
      <div>
        <Spin />
      </div>
    );
  }
}

export default withRouter(withApollo(ContractorScreen));
