import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { ThemeContext } from '$lib/context';
import unetyLogoSrc from '$assets/logoWithText.png';

import styles from './form.scss';

class UnwrappedForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { token } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.resetPassword({
          variables: {
            ...values,
            token,
          },
        });
      }
    });
  }

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug } = theme;

          const logoSrc = (slug === 'ecoamerica') ? 'https://storage.googleapis.com/public-assets.usurppower.com/theme/ecoamerica.png' : unetyLogoSrc;

          return (
            <Form onSubmit={this.handleSubmit} className={styles.form}>
              <Form.Item>
                <img className={styles.logo} src={logoSrc} />
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email!' }],
                })(
                  <Input autoComplete="username" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your new password!' }],
                })(
                  <Input autoComplete="new-password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="New password" />
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block loading={loading}>Reset password</Button>
              </Form.Item>
            </Form>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default Form.create()(UnwrappedForm);
