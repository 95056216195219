import _ from 'lodash';
import qs from 'qs';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import SpinnerError from '$components/common/SpinnerError';
import NotFound from '$components/NotFound';
import styles from './withContractorApplication.scss';

const WITH_CONTRACTOR_APPLICATION = gql`
  query ContractorApplication($uuid: ID!) {
    contractorApplication(uuid: $uuid) {
      uuid
      address
      applicationUuid
      city
      cpaceProjectAmount
      createdAt
      existingMortgageAddDebt
      favorite
      financingMoreImportant
      fundingQuickness
      grossAreaLargestTenant
      grossAreaSmallestTenant
      grossFloorArea
      isPaceBestMatch
      modifiedGrossLease
      monthlyUtilitySpend
      multiTenantBuilding
      numberFundingOptions
      ownerOccupied
      pctFullServiceGrossLease
      pctOccupied
      pctTripleNetLease
      percentLeased
      percentLeasedFsg
      percentLeasedMg
      percentLeasedNnn
      percentOwnerOccupied
      percentUnoccupied
      postalCode
      preferredFinancingTerm
      projectCategory
      projectSize
      projectType
      propertyMarketValue
      propertyType
      rentableUnits
      scopeOfWork
      sellAbilityFiveYears
      state
      taxEscalationModifiedGrossLease
      userLightingUpgrade
      userMonthlyEnergyCost
      userPropertyType
      userSqft
      whiteGloveRequestedAt
      zeroDownImportance

      fundingOptions {
        name
        message
        score
        options {
          availability
          rate
          rateText
          closeTime
          projectType
          projectSize
          upfrontCost
          contractDuration
          abilityToSell
          borrowingCapacity
          taxDeductions
        }
      }

      energySavings {
        annualEnergySavings
        paceCanFinance
        pacePayments
        paceSavings
      }

      valueCalculator {
        effectiveCostPace
        effectiveCostLoan
        effectiveCostCash

        newRevenueFromTenantsPace
        newRevenueFromTenantsLoan
        newRevenueFromTenantsCash

        owedFromLandlordPace
        owedFromLandlordLoan
        owedFromLandlordCash

        tenantCostIncreasePace
        tenantCostIncreaseLoan
        tenantCostIncreaseCash
      }

      nearbyContractors {
        uuid
      }

      contractorProjectInvitations {
        uuid
        contractor {
          uuid
          company
          postalCode
        }
        status
      }

      application {
        dataStatus
        dataTreeStatus
        edrStatus
        expiresAt
        projectUuid
        rawAddress
        scopeOfWork
        status
        uuid

        parcels {
          uuid
          apn
          dataTreeJsonPdr
          dataTreeJsonTvr
        }

        property {
          county
          debt
          debtStartDate
          edrCabinetId
          edrEventMatrix
          edrLocationId
          environmentalEvents
          formattedAddress
          googlePlaceId
          googlePlusCode
          googleStaticMapUrl
          googleStreetViewUrl
          isPaceAvailable
          isPpaAvailable
          lat
          lender
          lng
          locality
          marketValue
          neighborhood
          otherLender
          postalCode
          postalCodeSuffix
          propertyType
          route
          sqft
          state
          streetNumber
          uuid
        }
        usurp {
          altv
          altvScore
          applicationCompletenessScore
          applicationConflictsScore
          applicationSourceScore
          availableDocumentsScore
          buyer
          capRate
          capRateScore
          communicationRating
          communicationScore
          creditRating
          creditScore
          dataTreeSituation
          debt
          deedType
          documentationRating
          documentationScore
          environmentalRiskRating
          environmentalScore
          financingUrgencyScore
          goNoGo
          involuntaryLienSummary
          lender
          lenderConsentRiskRating
          lenderConsentScore
          lenderDatatree
          ltv
          marketValue
          multipleOwnerMailingAddresses
          multipleRealOwners
          multipleRealOwnersScore
          multipleTitleOwners
          multiTenantScore
          noiScore
          nonActiveInvoluntaryLiensSummary
          numberActiveInvoluntaryLiens
          ownerMailingAddress
          ownerOccupied
          ownershipDurationScore
          ownershipYears
          phoneNumberScore
          postalCodeValid
          projectStageScore
          propertyClass
          propertyClassScore
          propertyGroup
          propertyOwnerName
          propertyStructureBuiltYear
          propertyType
          propertyTypeConsentScore
          singleLender
          singleTenant
          sqft
          taxAssessedValue
          taxDelinquency
          titleRiskRating
          titleScore
          unitCount
          usurpRating
          usurpScore
          uuid
          valuationSource
          wal
          websiteTimeScore
        }
        dataTree {
          dtPropertyType
          propertyType
          address {
            line
            county
            city
            stateCode
            postalCode
          }
          mailingAddress {
            line
            county
            city
            stateCode
            postalCode
          }
          apn
          propertyOwnerName
          propertyStructureBuiltYear
          tvr {
            buyer
            debt
            firstOpenLienYear
            involuntaryLienSummary
            lastOpenLienYear
            lastSaleDate
            lender
            nonActiveInvoluntaryLiensSummary
            numberActiveInvoluntaryLiens
            openLiens
            term
          }
          conflicts
          ownershipYears
          estimatedParcels
          block
          lot
          groundLease
          taxAssessedValue
          marketValue
          valuationSource
          taxDelinquentYear
          delinquent
          livingArea
          grossLivingAreaSqft
          sqft
          unitCount
          propertyOwnerEntityType
          ownerOccupied
          multipleTitleOwners
          multipleRealOwners
          lenders
          multipleOwnerMailingAddresses
          dataTreeSituation
          lendersDatatree
          apns
          activeLiens
          leinSummary
          ownerMailingAddress
          taxDelinquencyYear
          singleTenantLikelihood
          singleTenant
          propertyAddressMatches
          ownerAddressMatch
          quantDataMatch
          titleOwnerMatch
        }
      }

      financingApplications {
        uuid
        ask
        scopeOfWork
        viewUuid
        projectUuid
        financingType
        status
        propertyMarketValue
        propertyDebt
        createdAt
        expiresAt
        quotes {
          uuid
        }
      }

      scoreEstimate {
        propertyClass
        environmentalRisk
        creditRisk
        bankability
        capRate
      }

      paceRefi {
        uuid
        scopeOfWork
        projectMonth
        result
        resultState
        resultLookback
        resultLookbackText
        resultCredit
        resultTitle
        resultEnvironmental
        email
      }
    }
  }
`;

const withContractorApplication = (WrappedComponent, options = {}) => {
  const {
    ignoreMissing = false,
  } = options;

  return class WithContractorApplication extends Component {
    render() {
      const {
        match,
        location,
      } = this.props;

      const { contractorUuid } = match.params;
      const { search } = location;
      const query = search.substring(1);
      const parsed = qs.parse(query);
      const { projectUuid } = parsed;

      const uuid = contractorUuid || projectUuid;
      if (!uuid) {
        return <WrappedComponent {...this.props} />;
      }

      return (
        <Query
          query={WITH_CONTRACTOR_APPLICATION}
          variables={{ uuid }}
        >
          {({ loading, error, data }) => {
            if (loading || error) {
              return (
                <div className={styles.spinnerContainer}>
                  <SpinnerError error={error} />
                </div>
              );
            }

            const { contractorApplication } = data;

            if (!contractorApplication && !ignoreMissing) {
              return <NotFound />;
            }

            const application = _.get(contractorApplication, 'application');
            const usurp = _.get(application, 'usurp');

            return (
              <WrappedComponent
                {...this.props}
                application={application}
                contractorApplication={contractorApplication}
                usurp={usurp}
              />
            );
          }}
        </Query>
      );
    }
  };
};

export default withContractorApplication;
export {
  WITH_CONTRACTOR_APPLICATION,
};
