import _isEmpty from 'lodash/isEmpty';
import qs from 'qs';

const getTheme = (location) => {
  const { host, search } = location;

  const query = search.substring(1);
  const parsed = qs.parse(query);
  const { theme } = parsed;

  if (!_isEmpty(theme)) {
    return theme;
  }

  const matches = /(.*?)(?:\.|--).*(usurppower.com|unety.io)/i.exec(host);
  if (matches) {
    const hostSlug = matches[1];
    if (hostSlug !== 'app') {
      return hostSlug;
    }
  }

  return null;
};


export default getTheme;
