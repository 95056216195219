import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Card as AntCard } from 'antd';

import styles from './card.scss';

class UpCard extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    subTitle: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    subTitle: '',
  };

  render() {
    const {
      children,
      subTitle,
      title,
      ...rest
    } = this.props;

    return (
      <AntCard
        {...rest}
        title={
          <div className={styles.upCard__title}>
            {title}
            {subTitle && <span>{subTitle}</span>}
          </div>
        }
      >
        {children}
      </AntCard>
    );
  }
}

export default UpCard;
