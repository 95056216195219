import React, { Component } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

import styles from './styles.scss';

const ROOT_DOMAIN = process.env.ROOT_DOMAIN; // eslint-disable-line prefer-destructuring

class SessionRedirect extends Component {
  constructor(props) {
    super(props);

    // 20200619JP(TODO): Delete this! Temporary hack for a user who got to staging.
    const previousSessionDomain = null;
    // const previousSessionDomain = Cookies.get('sessionDomain');

    const sessionDomain = window.location.host;

    if (!previousSessionDomain) {
      const now = +moment();
      Cookies.set('sessionDomain', sessionDomain, { domain: ROOT_DOMAIN });
      Cookies.set('sessionDomainAt', now, { domain: ROOT_DOMAIN });
      console.log(`set cookie for sessionDomain: ${sessionDomain}`); // eslint-disable-line no-console
    }

    // 20200619JP(TODO): Delete this! Temporary hack for a user who got to staging.
    setTimeout(this.handleClearSession, 10 * 1000);

    this.state = {
      previousSessionDomain,
      sessionDomain,
      bypass: false,
    };
  }

  handleClearSession = () => {
    const sessionDomain = window.location.host;
    const now = +moment();
    Cookies.set('sessionDomain', sessionDomain, { domain: ROOT_DOMAIN });
    Cookies.set('sessionDomainAt', now, { domain: ROOT_DOMAIN });
    this.setState({
      previousSessionDomain: sessionDomain,
    });
  }

  handleBypass = () => {
    this.setState({
      bypass: true,
    });
  }

  render() {
    const { children } = this.props;
    const { bypass, previousSessionDomain, sessionDomain } = this.state;

    if (bypass) {
      return children;
    }

    if (sessionDomain === 'app--uat.unety.io') {
      return (
        <div className={styles.layout}>
          <div className={styles.title}><i className="fas fa-do-not-enter" /> Unety UAT site <i className="fas fa-do-not-enter" /></div>
          <div>You&apos;ve reached Unety&apos;s User Acceptance Testing (UAT) site.</div>
          <div>Data on this site WILL BE ERASED and unless you are part of the Unety team, you probably should be on the real site instead.</div>
          <div className={styles.actions}>
            <Button onClick={this.handleBypass}><i className="fal fa-exclamation-triangle" />&nbsp;Continue&nbsp;<i className="fal fa-exclamation-triangle" /></Button>
            <a href="https://app.unety.io"><Button type="primary">Visit the real site</Button></a><br />
          </div>
        </div>
      );
    }

    if (!previousSessionDomain || (previousSessionDomain === sessionDomain)) {
      return children;
    }

    const href = `https://${previousSessionDomain}`;

    return (
      <div className={styles.layout}>
        <div className={styles.title}>Welcome back!</div>
        <div>Last time you came here, you visited via a different branded experience (https://{previousSessionDomain})</div>
        <div>Would you like to continue, or restore your previous session?</div>
        <div className={styles.actions}>
          <Button onClick={this.handleClearSession}>Continue</Button>
          <a href={href}><Button type="primary">Restore</Button></a><br />
        </div>
      </div>
    );
  }
}

export default withRouter(SessionRedirect);
