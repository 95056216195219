import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { AutoComplete, Form, Input, Select } from 'antd';

import styles from '../../../Applicant/Apply/MultiStep/multiStep.scss';
import { fieldDecoratorRules } from '../../../../constants/forms';
import { formatNumber } from '$lib/formatter';
import { PROPERTY_TYPE_OBJECTS } from '$lib/filters';

const { Option } = Select;

class PropertyDetails extends PureComponent {
  static propTypes = {
    disableFields: PropTypes.bool,
    errors: PropTypes.shape(),
    getFieldDecorator: PropTypes.func.isRequired,
    values: PropTypes.shape(),
    onPropertyTypeChange: PropTypes.func,
  };

  static defaultProps = {
    disableFields: false,
    errors: {},
    values: {},
    onPropertyTypeChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      values: this.props.values,
    };
  }

  filterOption(inputValue, option) {
    return option.props.children === 'everything else'
      || option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  }

  handleChange = (key, evt) => {
    const value = (() => {
      if (typeof evt === 'string') {
        return evt;
      }

      return (evt.target ? evt.target.value : evt);
    })();

    this.setState({
      values: {
        ...this.state.values,
        [key]: value,
      }
    });

    if (key === 'propertyType') {
      this.props.onPropertyTypeChange(value);
    }
  }

  handleNumberChange = (evt) => {
    return formatNumber(evt.target.value);
  }

  render() {
    const {
      disableFields,
      getFieldDecorator,
    } = this.props;
    const {
      values
    } = this.state;

    const { address, city, state, postalCode } = values;
    const location = `${address}, ${city}, ${state} ${postalCode}`;

    const { application } = values;
    const { usurp } = application;

    const isResidential = values.propertyType === 'residential';
    return (
      <div>
        <Form.Item label="Property address">
          <Input
            disabled={true}
            maxLength={155}
            type="text"
            value={location}
          />
        </Form.Item>
        <div className={styles.twoColumn}>
          <Form.Item
            validateStatus={isResidential ? 'error' : ''}
            label="Property type"
            extra={ isResidential ? 'Unety does not support residential properties' : ''}
          >
            {getFieldDecorator('propertyType', {
              rules: fieldDecoratorRules,
              initialValue: values.propertyType || usurp.propertyType,
            })(
              <AutoComplete
                data-aaa="propertyType"
                dataSource={PROPERTY_TYPE_OBJECTS}
                disabled={disableFields}
                filterOption={this.filterOption}
                placeholder="Type to start searching"
                onChange={(e) => this.handleChange('propertyType', e)}
              />
          )}
          </Form.Item>
          <Form.Item label="Gross floor area">
            {getFieldDecorator('grossFloorArea', {
              getValueFromEvent: this.handleNumberChange,
              initialValue: formatNumber(values.grossFloorArea || usurp.sqft),
              rules: fieldDecoratorRules,
            })(
              <Input
                data-aaa="grossFloorArea"
                addonAfter="sqft"
                disabled={disableFields}
                maxLength={10}
                placeholder="2,000"
                type="text"
                onChange={(e) => this.handleChange('grossFloorArea', e)}
              />
            )}
          </Form.Item>
        </div>
        <div className={styles.twoColumn}>
          <Form.Item label="Scope of work">
            {getFieldDecorator('scopeOfWork', {
              rules: fieldDecoratorRules,
              initialValue: (values.scopeOfWork || []),
            })(
              <Select
                data-aaa="scopeOfWork"
                disabled={disableFields}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Click to select"
                onChange={(e) => this.handleChange('scopeOfWork', e)}
              >
                <Option key="Renewables">Renewables</Option>
                <Option key="Envelope">Envelope</Option>
                <Option key="Lighting">Lighting</Option>
                <Option key="Landscaping">Landscaping</Option>
                <Option key="Hot Water">Hot Water</Option>
                <Option key="HVAC">HVAC</Option>
                <Option key="Water">Water</Option>
                <Option key="Other">Other</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Project size">
            {getFieldDecorator('projectSize', {
              // 20191126JP: Should have validation of <=20% of market value.
              getValueFromEvent: this.handleNumberChange,
              initialValue: formatNumber(values.projectSize || (Math.floor(usurp.taxAssessedValue * 0.2))),
              rules: fieldDecoratorRules,
            })(
              <Input
                data-aaa="projectSize"
                addonBefore="$"
                disabled={disableFields}
                maxLength={10}
                placeholder="100,000"
                type="text"
                onChange={(e) => this.handleChange('projectSize', e)}
              />
            )}
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default PropertyDetails;
