import React, { Component } from 'react';
import { ApolloConsumer } from '@apollo/react-hooks';
import storage from '$lib/storage';

class SignOut extends Component {
  componentDidMount() {
    const { client } = this.props;
    storage.clearAuth();
    client.resetStore();
  }

  render() {
    return (
      <div> Signing out... </div>
    );
  }
}

class SignOutWrapper extends Component {
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <SignOut client={client} />
        )}
      </ApolloConsumer>
    );
  }
}

export default SignOutWrapper;
