export default {
  PACE: {
    closeTime: 'Average time to receive funds is 30 days to 60 days after submitting an application.',
    projectType: 'Qualified projects include a wide variety of building infrastructure for both new construction and retrofits.',
    projectSize: 'Project sizes usually range from $150,000 to $10 million, capped based on the value of the property.',
    upfrontCost: 'No downpayment or upfront costs required.',
    contractDuration: 'Terms are usually between 5 years and the useful life of the installed equipment, up to 25 years.',
    abilityToSell: 'Transfer it to the new owner or pay it off early with a fee that is often low.',
    borrowingCapacity: 'Usually no impact to owner equity or liabilities.',
    taxDeductions: 'Usually interest payments are tax deductable and you can keep any project-related tax credits, such as solar tax credits.'
  },
  'On-bill': {
    closeTime: 'Average time to receive funds is 30 days after completing the project.',
    projectType: 'Qualified projects are usually limited to energy efficiency equipment upgrades and replacements.',
    projectSize: 'Project sizes usually range from $500 to $100,000, capped based on the amount of the monthly payment.',
    upfrontCost: 'No downpayment or upfront costs required.',
    contractDuration: 'Terms are usually less than 3 years, depending on the utility company.',
    abilityToSell: 'Pay it off early with a fee that is often low.',
    borrowingCapacity: 'Usually no impact to owner equity or liabilities.',
    taxDeductions: 'Usually you can keep any project-related tax credits.'
  },
  Loan: {
    closeTime: 'Average time to receive funds is 30 to 60 days after submitting an application.',
    projectType: 'Qualified projects include most new construction and existing building upgrades.',
    projectSize: 'Project sizes usually range from $50,000 to $2 million.',
    upfrontCost: 'A downpayment of at least 10% is usually required.',
    contractDuration: 'Terms are usually between 5 years and 20 years.',
    abilityToSell: 'Pay it off early with a fee that is often high.',
    borrowingCapacity: 'Usually counts against owner equity and adds to accounting liabilities.',
    taxDeductions: 'Usually interest payments are tax deductible and you can keep any project-related tax credits, such as solar tax credits.'
  },
  PPA: {
    closeTime: 'Installation may begin within 30 days after submitting an application.',
    projectType: 'Qualified projects are usually limited to renewable energy installations, such as solar.',
    projectSize: 'Project sizes usually range from $15,000 to $4 million.',
    upfrontCost: 'No downpayment or upfront costs required.',
    contractDuration: 'Terms are usually 15, 20, or 25 years.',
    abilityToSell: 'Pay it off early with a fee that is often very high.',
    borrowingCapacity: 'Usually no impact to owner equity or liabilities.',
    taxDeductions: 'Limited positive or negative impact to your taxes; and usually, you cannot keep the tax credits.'
  },
  'Capital lease': {
    closeTime: 'Installation may begin within 30 days after submitting an application.',
    projectType: 'Qualified projects are usually limited to renewable energy and other major energy equipment installations.',
    projectSize: 'Project sizes usually range from $1 million to $4 million.',
    upfrontCost: 'A downpayment of 5% to 10% is often required.',
    contractDuration: 'Terms are usually 7 years or less.',
    abilityToSell: 'Pay it off early with a fee that is often very high.',
    borrowingCapacity: 'Usually impacts liabilities, but not owner equity.',
    taxDeductions: 'Usually interest payments are tax deductable and you can not keep any project-related tax credits.',
  },
  EFA: {
    closeTime: 'Installation may begin within 30 days after submitting an application. ',
    projectType: 'Qualified projects are usually limited to major energy efficiency equipment installations.',
    projectSize: 'Project sizes usually range from $10,000 to $250,000.',
    upfrontCost: 'No downpayment or upfront costs required.',
    contractDuration: 'Terms are usually 7 years or less.',
    abilityToSell: 'Pay it off early with a fee that is often very high.',
    borrowingCapacity: 'Usually no impact to owner equity or liabilities.',
    taxDeductions: 'Limited positive or negative impact to your taxes; and usually, you cannot keep the tax credits.'
  },
  'Hard money': {
    closeTime: 'Average time to receive funds is 5 to 15 days after submitting an application.',
    projectType: 'Qualified projects include most new construction and existing building upgrades.',
    projectSize: 'Project sizes usually range from $50,000 to $10 million.',
    upfrontCost: 'A downpayment of at least 10% is usually required.',
    contractDuration: 'Terms are usually 12 months, but can sometimes be extended to 2 years.',
    abilityToSell: 'Pay it off early with a fee that is often high.',
    borrowingCapacity: 'Usually counts against owner equity and adds to accounting liabilities.',
    taxDeductions: 'Usually interest payments are tax deductable and you can keep any project-related tax credits, such as solar tax credits.'
  },
};
