import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Progress } from 'antd';

import styles from '../../ApplicationsNew/listItemStyles.scss';

class ApplicationProgress extends PureComponent {
  static propTypes = {
    expiresAt: PropTypes.string,
  };

  render() {
    const {
      expiresAt
    } = this.props;

    const minutesLeft = moment.duration(moment(expiresAt).diff(moment())).asMinutes();
    const hoursLeft = (minutesLeft > 0) ? minutesLeft / 60.0 : 0;
    const hoursPercent = (hoursLeft / 48) * 100;

    const format = () => (
      <div className={styles.progressText}>
        {hoursLeft < 1
          ? <div>Last<br />chance!</div>
          : <div>{Math.round(hoursLeft)}<br />hours left</div>
        }
      </div>
    );

    return (
      <Progress
        className={styles.progress}
        width={80}
        type="circle"
        percent={hoursPercent}
        format={format }
      />
    );
  }
}

export default ApplicationProgress;
