import _ from 'lodash';
import React from 'react';
import { Checkbox, Form } from 'antd';

import styles from '../styles.scss';

const { Group: CheckboxGroup } = Checkbox;

const FINANCING_TYPES = [{
  label: 'PACE',
  value: 'PACE',
}, {
  label: 'PPA',
  value: 'PPA',
}, {
  label: 'EFA',
  value: 'EFA',
}, {
  label: 'Lease',
  value: 'Lease',
}, {
  label: 'Loan',
  value: 'Loan',
}, {
  label: 'Hard money',
  value: 'Hard money',
}];

const comingSoonFinancingTypes = [
  'On-bill',
];

const FinancingTypesForm = ({
  errors,
  getFieldDecorator,
  values, // eslint-disable-line no-unused-vars
  className,
  contractorApplication,
}) => {
  const { application, paceRefi } = contractorApplication;
  const { property } = application || {};
  const { isPaceAvailable, isPpaAvailable } = property || {
    isPaceAvailable: true,
    isPpaAvailable: true,
  };
  const initialFinancingType = paceRefi ? ['PACE-refi'] : [];
  const financingTypes = (() => {
    if (paceRefi) {
      return ['PACE-refi'];
    }

    const ourFinancingTypes = _.cloneDeep(FINANCING_TYPES);

    if (!isPaceAvailable) {
      const paceFinancingType = _.find(ourFinancingTypes, { value: 'PACE' });
      paceFinancingType.disabled = true;
      paceFinancingType.label = 'PACE (not available in this region)';
    }

    if (!isPpaAvailable) {
      const ppaFinancingType = _.find(ourFinancingTypes, { value: 'PPA' });
      ppaFinancingType.disabled = true;
      ppaFinancingType.label = 'PPA (not available in this region)';
    }

    return ourFinancingTypes;
  })();

  return (
    <div className={className}>
      <div className={errors && errors.find((e) => e === 'financingTypes') ? styles.errorComponent : ''}>
        <div>Applications are non-binding, and you will have the opportunity to discuss terms directly with the lender(s).<br /><br /></div>
        <Form.Item label="Which types of financing would you like to apply for?">
          {getFieldDecorator('financingTypes', {
            initialValue: initialFinancingType,
            rules: [{ required: true }],
          })(
            <CheckboxGroup
              data-aaa="financingTypes"
              options={financingTypes}
              style={{ marginTop: 15 }}
            />
          )}
        </Form.Item>
        {!paceRefi && (
          <Form.Item label="Additional financing types coming soon">
            <CheckboxGroup
              disabled={true}
              options={comingSoonFinancingTypes}
              style={{ marginTop: 15 }}
            />
          </Form.Item>
        )}
        {!paceRefi && (
          <div>
            <div><b>PACE</b><br />PACE is a long- or short-term contract that provides financing for qualifying renovations and new construction with no money down. Payments are made on your property tax bill.<br /><br /></div>
            <div><b>PPA</b><br />PPA is a long-term contract that provides property owners with renewable energy. You are not charged for equipment or installation and you only pay for the energy use.<br /><br /></div>
            <div><b>EFA</b><br />EFA is a mid- or short-term contract associated with new equipment, such as a boiler. It is a hybrid between a loan and a lease. Payments are made monthly.<br /><br /></div>
            <div><b>Lease</b><br />Lease is a long- or short-term contract associated with new equipment, such as a boiler. Payments are made monthly to the lease provider.<br /><br /></div>
            <div><b>Loan</b><br />Loan is a long- or short-term contract. You may use it to pay for a wide variety of projects. Payments are made monthly to the loan provider.<br /><br /></div>
            <div><b>Hard money</b><br />Hard money is a type of short-term loan that is secured by real property, with high interest rates and low prepayment penalties.<br /><br /></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinancingTypesForm;
