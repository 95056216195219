import React, { Component } from 'react';
import { Button } from 'antd';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import allInOneApplication from '../../../../assets/all-in-one-application.svg';
import contactLenders from '../../../../assets/contact-lenders.svg';

import ProjectScreenLayout from '../ProjectScreenLayout';
import styles from './styles.scss';

class FindFundingScreen extends Component {
  render() {
    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          const { uuid: contractorUuid, applicationUuid } = contractorApplication;
          const applicationTo = `${reverse(routes.applicant.revise, { applicationUuid })}?projectUuid=${contractorUuid}`;

          return (
            <div className={styles.layout}>
              <div className={styles.title}>Find funding</div>
              <div className={styles.description}>
                <div className={styles.options}>
                  <Link to={routes.contractor.lenderList}>
                    <div className={styles.option}>
                      <img src={contactLenders} alt=""/>
                      <Button type="primary">Contact lenders</Button>
                    </div>
                  </Link>
                  <Link to={applicationTo}>
                    <div className={styles.option}>
                      <img src={allInOneApplication} alt=""/>
                      <Button type="primary">All-in-one application</Button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default FindFundingScreen;
