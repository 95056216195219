import React, { Component } from 'react';
import QualificationForm from '../QualificationForm';

import styles from './styles.scss';

class QualificationError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSubmit: false,
    };
  }

  renderDetailsContent = () => {
    const { location, errorType } = this.props;
    const { postalCode } = location;
    const { hasSubmit } = this.state;

    if (hasSubmit) {
      return (
        <>
          <div className={styles.detailsTitle}>Thank you!</div>
          <div className={styles.detailsSubtitle}>We&apos;ll be in touch soon.</div>
        </>
      );
    }

    switch (errorType) {
      case 'notFound':
        return (
          <>
            <div className={styles.detailsTitle}>We couldn&apos;t find your address</div>
            <div className={styles.detailsSubtitle}>Enter your email to be notified about news and when your address is available.</div>
            <QualificationForm
              location={location}
              onCompleted={() => this.setState({ hasSubmit: true })}
            />
          </>
        );
      case 'unavailable':
        return (
          <>
            <div className={styles.detailsTitle}>PACE is unavailable for zipcode: {postalCode}</div>
            <div className={styles.detailsSubtitle}>Enter your email to be notified when PACE and Unety is available in your area.</div>
            <QualificationForm
              location={location}
              onCompleted={() => this.setState({ hasSubmit: true })}
            />
          </>
        );
      default:
        return (
          <div>Unknown errorType: {errorType}</div>
        );
    }
  }

  render() {
    return (
      <div className={styles.detailsContainer}>
        {this.renderDetailsContent()}
      </div>
    );
  }
}

export default QualificationError;
