import _every from 'lodash/every';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import { Base64 } from 'js-base64';
import classnames from 'classnames';
import keyMirror from 'keymirror';
import React, { PureComponent } from 'react';
import { Button, Icon, Input } from 'antd';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import { ThemeContext } from '$lib/context';

import InteractiveMap from '$components/InteractiveMap';
import { parseAddressWithFormatting } from '../../../lib/parcels';
import { FIND_PARCELS } from '../../../services/parcels';
import ParcelSelect from './ParcelSelect';

import styles from './styles.scss';

const defaultAddress = '29 Norman Ave, Brooklyn, NY 11222';

const { Search } = Input;

const STEP = keyMirror({
  inputAddressNotRegion: null,
  notFound: null,
  ready: null,
  searchAddress: null,
  selected: null,
  selectParcels: null,
});

const renderHeader = ({ header, slug }) => {
  if (header) {
    return header;
  }

  switch (slug) {
    case 'ecoamerica': {
      return (
        <header className={styles.ravtiHeader}>
          <p>Blessed Tomorrow has teamed up with Unety to provide houses of worship with resources to help you get through these hard times without sacrificing commitments to sustainability.</p>
        </header>
      );
    }
    case 'ravti': {
      return (
        <header className={styles.ravtiHeader}>
          <p>Unety has partnered with Ravti to help property owners find financing for their projects through the Unety marketplace, free of charge.</p>
        </header>
      );
    }
    default: {
      return null;
    }
  }
};

class PropertySearch extends PureComponent {
  constructor(props) {
    super(props);

    this.map = React.createRef();

    let { prefilledAddress } = props;

    if (!prefilledAddress) {
      const { location } = props;
      const { search } = location;
      const query = search.substring(1);
      const parsed = qs.parse(query);

      const { data } = parsed;
      if (data) {
        try {
          const decoded = Base64.decode(data);
          const jsonData = JSON.parse(decoded);
          const { address } = jsonData;
          prefilledAddress = address;
        } catch (e) { /* NOP */ }
      }
    }

    this.state = {
      highlightedParcels: {},
      parcels: [],
      prefilledAddress,
      parcelsValid: false,
      searching: false,
      selectedParcels: [],
      showParcelMarkers: false,
      forceConflict: false,
      startPos: {
        lat: 40.7128,
        lng: -74.0060
      },
      step: STEP.searchAddress,
      sidebarStatus: null,
    };

    this.handleAddSelectedParcel = this.handleAddSelectedParcel.bind(this);
    this.handleAddressSearch = this.handleAddressSearch.bind(this);
    this.handleGeolocationError = this.handleGeolocationError.bind(this);
    this.handleGeolocationSuccess = this.handleGeolocationSuccess.bind(this);
    this.handleParcelClick = this.handleParcelClick.bind(this);
    this.handleParcelMouseOver = this.handleParcelMouseOver.bind(this);
    this.handleParcelMouseOut = this.handleParcelMouseOut.bind(this);
    this.handleSelectParcels = this.handleSelectParcels.bind(this);
  }

  componentDidMount = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.handleGeolocationSuccess, this.handleGeolocationError);
    }

    const { prefilledAddress } = this.props;
    if (prefilledAddress) {
      this.handleAddressSearch(prefilledAddress);
    }
  }

  getMarkers() {
    const {
      highlightedParcels,
      parcels,
      step,
    } = this.state;

    if (this.props.step === STEP.searchAddress) {
      return [];
    }

    const markerParcels = step === STEP.checkApproval // TODO
      ? parcels.slice(0, 1)
      : parcels;

    if (!markerParcels) {
      return [];
    }

    const markers = markerParcels.reduce((acc, parcel) => {
      if (acc[parcel.street]) {
        return acc;
      }

      return {
        ...acc,
        [parcel.street]: {
          ...parcel,
          hovered: Boolean(Object.values(highlightedParcels).find((p) => p.apn === parcel.apn)),
          id: parcel.apn,
          title: parcel.formattedAddress,
        }
      };
    }, {});

    return Object.values(markers);
  }

  async handleAddressSearch(location) {
    if (!location) {
      location = defaultAddress; // eslint-disable-line no-param-reassign
    }

    const { forceConflict } = this.state;

    this.setState({
      searching: true,
    });

    const parsedAddress = await parseAddressWithFormatting({ location });
    if (!parsedAddress) {
      this.setState({
        searching: false,
      });
      return;
    }

    const {
      city,
      number,
      state,
      street,
      zip: postalCode,
      lat,
      lng,
    } = parsedAddress;

    this.setState({
      startPos: {
        lat,
        lng,
      },
    });

    const address = (() => {
      if (!street) {
        return null;
      }

      if (!number) {
        return street;
      }

      return `${number} ${street}`;
    })();

    const initialAddress = {
      address,
      city,
      postalCode,
      state,
    };

    const stringLocation = `${address}, ${city}, ${state} ${postalCode}`;
    this.setState({
      location: initialAddress,
      stringLocation,
    });

    if (!address || !postalCode) {
      this.setState({
        step: STEP.inputAddressNotRegion,
        searching: false,
        sidebarStatus: 'medium',
      });
      return;
    }

    let hasConflict = false;
    let parcels = [];
    let error = null;
    let isPaceAvailable;

    try {
      const { data, error: err } = await this.props.client.query({
        query: FIND_PARCELS,
        variables: {
          input: {
            originalLocation: location,
            location: stringLocation,
            forceConflict,
          },
        },
      });

      parcels = data.getParcelsByLocation && data.getParcelsByLocation.parcels;
      hasConflict = data.getParcelsByLocation && data.getParcelsByLocation.hasConflict;
      isPaceAvailable = data.getParcelsByLocation && data.getParcelsByLocation.isPaceAvailable;
      error = err;
    } catch (e) {
      error = e;
    }

    const notFound = (!parcels || _isEmpty(parcels) || _every(parcels, (parcel) => !parcel.apn));
    if (notFound) {
      this.setState({
        searching: false,
        sidebarStatus: 'medium',
        step: STEP.notFound,
      });
      return;
    }

    let step = STEP.ready;
    if (hasConflict) {
      step = STEP.selectParcels;
      this.setState({
        sidebarStatus: 'small',
      });
      parcels.push({
        apn: null,
        formattedAddress: 'Address not found',
      });
    }

    // 20200316JP: This branch doesn't seem right.
    if (error) {
      step = STEP.ready;
      this.setState({
        sidebarStatus: 'large',
      });
    }

    this.setState({
      location: initialAddress,
      parcels: parcels.reduce((acc, p) => [
        ...acc,
        {
          ...p,
          id: p.apn,
          lat: parseFloat(p.lat),
          lng: parseFloat(p.lng),
        }
      ], []),
      isPaceAvailable,
      searching: false,
      selectedParcels: [],
      showParcelMarkers: true,
      step
    });
  }

  toggleforceConflict = () => {
    this.setState({
      forceConflict: !this.state.forceConflict,
    });
  }

  acceptUnknownAddress = () => {
    this.setState({
      sidebarStatus: null,
      step: STEP.ready,
    });
  }

  handleAddSelectedParcel(selectedParcels) {
    this.setState({
      selectedParcels
    });

    selectedParcels.forEach((parcel) => {
      const index = this.state.parcels.findIndex((p) => p.id === parcel.id);
      this.resizeMarker(index);
    });
  }

  resetMap = () => {
    this.map.current.clearMarkers({ force: true });
    this.setState({
      parcels: [],
      startPos: {},
      step: STEP.searchAddress,
      sidebarStatus: null,
    });
  }

  handleGeolocationError() {

  }

  handleGeolocationSuccess(pos) {
    const {
      latitude: lat,
      longitude: lng
    } = pos.coords;

    if (lat && lng) {
      this.setState({
        startPos: {
          lat,
          lng,
        }
      });
    }
  }

  handleParcelMouseOut({ id }, i) {
    if (id && !this.state.selectedParcels.find((p) => p.id === id)) {
      this.resizeMarker(i, false);
    }
  }

  handleParcelClick(info) {
    const {
      parcels,
      selectedParcels,
    } = this.state;
    const selectedIndex = selectedParcels.findIndex((p) => p.apn === info.id);
    this.setState({
      selectedParcels: selectedIndex === -1
        ? [
          ...selectedParcels,
          parcels.find((p) => p.apn === info.id),
        ]
        : selectedParcels.filter((p) => p.apn !== info.id)
    });
  }

  handleParcelMouseOver(parcel, i) {
    if (parcel && parcel.apn) {
      this.resizeMarker(i);
    }
  }

  handleSelectParcels = (selectedParcels) => {
    const {
      parcels: stateParcels
    } = this.state;

    const allSelectedParcels = selectedParcels.reduce((acc, parcel) => {
      return [
        ...acc,
        ...stateParcels.filter(({ street }) => street === parcel.street)
      ];
    }, []);

    this.setState({
      sidebarStatus: null,
      parcels: allSelectedParcels,
      step: STEP.ready,
    });
  }

  isStep(...checks) {
    const {
      step
    } = this.state;
    if (!Array.isArray(checks)) {
      return step === checks;
    }

    return Boolean(checks.find((c) => c === step));
  }

  resizeMarker(index, over = true) {
    const x = over
      ? 27 * 1.5
      : 27;
    const y = over
      ? 43 * 1.5
      : 43;

    this.map.current.updateMarkerIcon(index)({
      scaledSize: new window.google.maps.Size(x, y),
      url: 'http://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
    });
  }

  renderSidebarContent() {
    const {
      isPaceAvailable,
      location,
      parcels,
      step,
      stringLocation,
    } = this.state;

    const { children } = this.props;

    switch (step) {
      case STEP.searchAddress:
        return null;
      case STEP.inputAddressNotRegion:
        return (
          <div className={styles.layout}>
            <div className={styles.title}>Please enter a property address</div>
            <div className={styles.subtitle}>
              <p>In addition to pulling information about the region, our scoring engine evaluates characteristics of the property and structures, so we need a specific address rather than a general area.</p>
              <p>
                <i className={classnames('fas fa-times-circle', styles.error)} /> Brooklyn, NY<br />
                <i className={classnames('fas fa-check-circle', styles.success)} /> 29 Norman Ave, Brooklyn, NY
            </p>
            </div>
            <div className={styles.actions}>
              <Button type="primary" onClick={this.resetMap} data-aaa="adjustAddress">Adjust address</Button>
            </div>
          </div>
        );
      case STEP.notFound:
        return (
          <div className={styles.layout}>
            <div className={styles.title}>We couldn&apos;t automatically find this property</div>
            <div className={styles.subtitle}>The closest match we&apos;ve identified is below; is this correct?</div>
            <div className={styles.location}>{stringLocation}</div>
            <div className={styles.actions}>
              <Button onClick={this.resetMap} data-aaa="adjustAddress">Adjust address</Button>
              <Button onClick={this.acceptUnknownAddress} data-aaa="continueAddress" type="primary">Continue</Button>
            </div>
          </div>
        );
      case STEP.selectParcels:
        return (
          <ParcelSelect
            parcels={this.state.parcels}
            selectedParcels={this.state.selectedParcels}
            onParcelsSelect={this.handleSelectParcels}
            onParcelsSelectChange={this.handleAddSelectedParcel}
            onParcelOut={this.handleParcelMouseOut}
            onParcelOver={this.handleParcelMouseOver}
            resetMap={this.resetMap}
          />
        );
      case STEP.ready:
        return children({
          location,
          parcels,
          resetMap: this.resetMap,
          setSidebarStatus: this.setSidebarStatus,
          isPaceAvailable,
        });
      default:
        return (
          <div>Step not found: {step}</div>
        );
    }
  }

  setSidebarStatus = (sidebarStatus) => {
    this.setState({ sidebarStatus });
  }

  render() {
    const { banner, header } = this.props;
    const {
      prefilledAddress,
      parcels,
      searching,
      startPos: {
        lat,
        lng
      },
      sidebarStatus,
      forceConflict,
      step,
    } = this.state;

    const mapProps = {
      markers: this.getMarkers(),
    };

    if (parcels && parcels[0] && parcels[0].lat && parcels[0].lng) {
      mapProps.center = {
        lat: parseFloat(parcels[0].lat),
        lng: parseFloat(parcels[0].lng)
      };
    } else if (lat && lng) {
      mapProps.center = {
        lat,
        lng,
      };
    }

    const addresssearchClasses = classnames(styles.addressSearch, {
      [styles.addressSearch__active]: this.isStep(STEP.searchAddress),
    });
    const mapContainerClassName = classnames(styles.mapContainer, {
      [styles[`mapContainer__${sidebarStatus}`]]: !!sidebarStatus,
      [styles.mapContainer__full]: !sidebarStatus,
    });
    const overlayClassName = classnames(styles.overlay, {
      [styles.overlay__active]: this.isStep(STEP.searchAddress, STEP.applicationForm),
    });
    const sidebarClassName = classnames(styles.sidebar, {
      [styles.sidebar__active]: !!sidebarStatus,
      [styles[`sidebar__${sidebarStatus}`]]: !!sidebarStatus,
    });

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug } = theme;

          return (
            <div className="contractorQuoteForm__container">
              {(step === STEP.searchAddress) && banner && (
                <div className={styles.banner}>{banner}</div>
              )}
              <div className={styles.quoteContainer}>
                <div className={mapContainerClassName}>
                  <InteractiveMap
                    {...mapProps}
                    ref={this.map}
                    onMarkerClick={this.handleParcelClick}
                    onMarkerOut={this.handleParcelMouseOut}
                    onMarkerOver={this.handleParcelMouseOver}
                  />
                </div>

                <div className={overlayClassName} />

                <div className={addresssearchClasses}>
                  {renderHeader({ header, slug })}
                  <div className={styles.addressSearchCard}>
                    <div className={styles.addressSearchCardHeader}>Bank-ability score</div>
                    <div className={styles.addressSearchCardSubtext}>Enter the address of the property that needs financing</div>
                    <ContextMenuTrigger id="searchContextMenu">
                      <Search
                        data-aaa="searchInput"
                        defaultValue={prefilledAddress}
                        disabled={searching}
                        enterButton={<Icon data-aaa="searchSubmit" type={searching ? 'loading' : 'search'} />}
                        maxLength={255}
                        size="large"
                        placeholder={defaultAddress}
                        onSearch={this.handleAddressSearch}
                      />
                    </ContextMenuTrigger>
                  </div>
                </div>

                <div className={sidebarClassName}>
                  {this.renderSidebarContent()}
                </div>

                <ContextMenu id="searchContextMenu">
                  <MenuItem onClick={this.toggleforceConflict}>{forceConflict ? <i className="fal fa-check-circle" /> : <i className="fal fa-times-circle" />} Force conflict</MenuItem>
                </ContextMenu>
              </div>
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default withRouter(withApollo(PropertySearch));
