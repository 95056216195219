import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};

class PropsRoute extends Component {
  render() {
    const { component, ...rest } = this.props;

    return (
      <Route {...rest} render={routeProps => {
        return renderMergedProps(component, routeProps, rest);
      }} />
    );
  }
}

class AuthenticatedRoute extends Component {
  render() {
    const { currentUser } = this.props;

    if (!currentUser) {
      return (
        <Redirect to={routes.auth.signIn} />
      );
    }

    return (
      <PropsRoute {...this.props} />
    );
  }
}

class UnauthenticatedRoute extends Component {
  render() {
    const { currentUser } = this.props;

    if (currentUser) {
      return (
        <Redirect to={{ pathname: '/' }} />
      );
    }

    return (
      <PropsRoute {...this.props} />
    );
  }
}

export {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  PropsRoute,
};
