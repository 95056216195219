import _ from 'lodash';
import _sortBy from 'lodash/sortBy';
import pluralize from 'pluralize';
import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import CommonSmallBlock from '../CommonSmallBlock';
import ResubmitApplication from './ResubmitApplication';

import sharedStyles from '../CommonSmallBlock/shared.scss';
import styles from './styles.scss';

const NAME = 'Compare financing options';
const TIME = '3 min';

const NAME_APPLY = 'Shop for financing';
const TIME_APPLY = '10 min';

class FundingOptionsBlock extends Component {
  render() {
    const {
      contractorApplication,
    } = this.props;

    const {
      uuid: contractorUuid,
      applicationUuid,
      financingApplications,
      fundingOptions,
    } = contractorApplication;

    return (
      <CommonSmallBlock name={NAME} time={TIME}>
        <div className={sharedStyles.layout}>
          <div className={sharedStyles.title}>Find the right financing for your project</div>
          <div className={sharedStyles.description}>
            Compare the pros, cons, and costs of various types of financing specific for energy efficiency and renewable energy projects.
          </div>
          <Link to={reverse(routes.contractor.projects.project.fundingOptionsSurvey, { contractorUuid })}>
            <Button
              data-aaa="fundingOptions"
              type="primary"
            >Show me financing options</Button>
          </Link>
        </div>
      </CommonSmallBlock>
    );

    if (financingApplications.length > 0) {
      const latestBatchExpiresAt = _.maxBy(financingApplications, 'expiresAt').expiresAt;
      const mostRecentFinancingApplications = financingApplications.filter((application) => (application.expiresAt === latestBatchExpiresAt));

      const hasAcceptedApplication = _.some(financingApplications, (application) => application.status === 'accepted');
      const hasActiveApplications = _.some(financingApplications, (application) => application.status === 'applicantNotified');
      const allExpired = _.every(financingApplications, (application) => application.status === 'expired');

      const title = (() => {
        if (hasAcceptedApplication) {
          return 'Quote accepted';
        }

        if (hasActiveApplications) {
          return 'Quotes received';
        }

        if (allExpired) {
          return 'Quotes expired'
        }

        return 'Application pending';
      })();

      const description = (() => {
        if (hasAcceptedApplication) {
          return (
            <>
              <div className={sharedStyles.description}>
                You have accepted a quote from a lender. The lender will be reaching out directly.
              </div>
              <Link to={reverse(routes.contractor.projects.project.applications, ({ contractorUuid }))}><Button>View details</Button></Link>
            </>
          );
        }

        if (hasActiveApplications) {
          return (
            <>
              <div className={sharedStyles.description}>
                Your application has received quotes from lenders; review them now and continue the process.
              </div>
              <Link to={reverse(routes.contractor.projects.project.applications, ({ contractorUuid }))}><Button type="primary">View details</Button></Link>
            </>
          );
        }

        if (allExpired) {
          return (
            <div className={sharedStyles.description}>
              <p>As the market changes so do rates. Your application received quotes previously, but they're no longer valid.</p>
              <ResubmitApplication
                contractorApplication={contractorApplication}
                mostRecentFinancingApplications={mostRecentFinancingApplications}
              />
              <p><br /><Link to={reverse(routes.contractor.projects.project.applications, ({ contractorUuid }))}>View expired quotes</Link></p>
            </div>
          );
        }

        return (
          <>
            <div className={sharedStyles.description}>
              <p>Lenders typically respond to applications within three business days from submission.</p>
              <p>You'll receive an email once quotes are received, and the status here will update automatically.</p>
            </div>
          </>
        );
      })();

      return (
        <CommonSmallBlock name="Financing application status">
          <div className={sharedStyles.layout}>
            <div className={sharedStyles.title}>{title}</div>
            {description}
          </div>
        </CommonSmallBlock>
      );
    }

    if (!fundingOptions) {
      return (
        <CommonSmallBlock name={NAME} time={TIME}>
          <div className={sharedStyles.layout}>
            <div className={sharedStyles.title}>Find the right financing for your project</div>
            <div className={sharedStyles.description}>
              Each type of financing has its own benefits; there&apos;s more to it than just the rate. Answer a few questions and we&apos;ll match you with your best options.
            </div>
            <Link to={reverse(routes.contractor.projects.project.fundingOptionsSurvey, { contractorUuid })}>
              <Button
                data-aaa="fundingOptions"
                type="primary"
              >Show me financing options</Button>
            </Link>
          </div>
        </CommonSmallBlock>
      );
    }

    // 20191205JP: FundingOption.available should be a boolean
    const availableFundingOptions = fundingOptions.filter((value) => value.options.availability !== 'unavailable');
    const sortedAvailableFundingOptions = _sortBy(availableFundingOptions, 'score').reverse();
    const bestOption = sortedAvailableFundingOptions[0];

    if (!bestOption) {
      return (
        <CommonSmallBlock name={NAME} time={TIME}>
          <div className={styles.layout}>
            <div>No funding options found</div>
            <br />
            <Link to={reverse(routes.contractor.projects.project.fundingOptionsSurvey, { contractorUuid })}>
              <Button data-aaa="fundingOptions" type="primary">Redo</Button>
            </Link>
          </div>
        </CommonSmallBlock>
      );
    }

    const { name } = bestOption;

    const title = (availableFundingOptions.length === 1)
      ? `Your best option is ${name}`
      : <>Your options are {name}<br /> and {pluralize('other', (availableFundingOptions.length - 1), true)}</>;

    return (
      <CommonSmallBlock name={NAME_APPLY} time={TIME_APPLY}>
        <div className={sharedStyles.layout}>
          <div className={sharedStyles.title}>{title}</div>
          <div className={sharedStyles.description}>
            Shop for various types of financing with a single common application, and get quotes from multiple lenders.<br />You might even get a lower rate!
            <br /><br />
            <Link to={reverse(routes.contractor.projects.project.fundingOptions, { contractorUuid })}>review options</Link>
          </div>
          <Link to={`${reverse(routes.applicant.revise, { applicationUuid })}?projectUuid=${contractorUuid}`}>
            <Button type="primary" data-aaa="applyNow">Shop for financing now</Button>
          </Link>
        </div>
      </CommonSmallBlock>
    );
  }
}

export default FundingOptionsBlock;
