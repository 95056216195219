import React from 'react';
import pluralize from 'pluralize';
import numeral from 'numeral';
import moment from 'moment';
import styles from './styles.scss';

const ApplicantNotifiedAt = ({ applicantNotifiedAt, expiresAt }) => {
  if (!expiresAt) {
    return null;
  }

  const expiresAtString = `${moment(expiresAt).local().format("ddd, MMM Do YYYY, h:mm:ssa")} ${String(moment(expiresAt).local()._d).split(' ')[5]}`;
  const expiresAtEl = (
    <div className={styles.applicantNotifiedAt}>Quoting close at {expiresAtString}</div>
  );

  if (!applicantNotifiedAt) {
    return (
      <div>
        {expiresAtEl}
        <div className={styles.applicantNotifiedAt}>Applicant has not been notified</div>
      </div>
    );
  }

  const applicantNotifiedAtString = `${moment(applicantNotifiedAt).local().format("ddd, MMM Do YYYY, h:mm:ssa")} ${String(moment(applicantNotifiedAt).local()._d).split(' ')[5]}`;

  const quotesExpireAt = moment(applicantNotifiedAt).add(2, 'week');
  const quotesExpireAtString = `${quotesExpireAt.local().format("ddd, MMM Do YYYY, h:mm:ssa")} ${String(moment(quotesExpireAt).local()._d).split(' ')[5]}`;

  return (
    <div>
      {expiresAtEl}
      <div className={styles.applicantNotifiedAt}>Applicant notified at {applicantNotifiedAtString}</div>
      <div className={styles.applicantNotifiedAt}>Quotes expire approx {quotesExpireAtString}</div>
    </div>
  );
};

const Quote = ({ quote }) => {
  const { lender } = quote;
  const { email, company } = lender;
  const { term, ask, rate, fees, prepayment, message } = quote;

  return (
    <div className={styles.quote}>
      <div>{company} ({email})</div>
      <div>{numeral(ask).format('$0,0')} | {term}yr | {numeral(rate).format('0.0%')}</div>
      <div>{prepayment} | {fees}</div>
      <div>{message}</div>
    </div>
  );
};

const Quotes = ({ application, quotes }) => {
  const { applicantNotifiedAt, expiresAt, financingType } = application;
  if (!financingType) {
     return (
      <div className={styles.layout}>This is not an application for financing.</div>
     );
  }

  return (
    <div className={styles.layout}>
      <div>Received {pluralize('quote', quotes.length, true)} for {financingType}</div>
      <ApplicantNotifiedAt expiresAt={expiresAt} applicantNotifiedAt={applicantNotifiedAt} />
      <div className={styles.quotes}>
        {quotes.map((quote) => (
          <Quote key={quote.uuid} quote={quote} />
        ))}
      </div>
    </div>
  );
}

export default Quotes;
