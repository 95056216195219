import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import industrial from './images/industrial.png';
import multifamily from './images/multifamily.png';
import office from './images/office.png';
import retailNotMall from './images/retailNotMall.png';
import shoppingCenterAndStripMalls from './images/shoppingCenterAndStripMalls.png';

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY; // eslint-disable-line prefer-destructuring

const imageMap = {
  industrial,
  multifamily,
  office,
  retailNotMall,
  shoppingCenterAndStripMalls
};

class GoogleStreetViewImage extends PureComponent {
  static propTypes = {
    address: PropTypes.string.isRequired,
    propertyType: PropTypes.string,
  };

  static defaultProps = {
    propertyType: 'office',
  };

  state = {
    url: null
  };

  async componentDidMount() {
    const {
      address,
      propertyType,
    } = this.props;
    const { status } = await fetch(`https://maps.googleapis.com/maps/api/streetview/metadata?size=600x600&location=${address}&source=outdoor&key=${GOOGLE_API_KEY}`)
      .then((resp) => resp.json());

    this.setState(() => ({
      url: status === 'ZERO_RESULTS'
        ? imageMap[propertyType] || office
        : `https://maps.googleapis.com/maps/api/streetview?size=600x600&location=${address}&source=outdoor&key=${GOOGLE_API_KEY}`
    }));
  }

  render() {
    const {
      propertyType
    } = this.props;
    const {
      url
    } = this.state;

    return (
      <img
        alt=""
        src={url}
        onError={(e) => {
          return e.target.src = imageMap[propertyType] || office; // eslint-disable-line no-return-assign
        }}
      />
    );
  }
}

export default GoogleStreetViewImage;
