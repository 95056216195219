import React, { Component } from 'react';
import { Tabs } from 'antd';
import ScoreTable from '$components/common/ScoreTable';
import EdrResultsGrid from '$components/common/EdrEvents/EdrResultsGrid';
import { scoresData } from '$components/common/ScoreTable/score-data-format';
import EnvironmentalEvents from './EnvironmentalEvents';
import RawData from './RawData';
import ValuesList from './ValuesList';
import ProjectScreenLayout from '../ProjectScreenLayout';
import valuesStyles from './ValuesList/styles.scss';
import styles from './styles.scss';

const { TabPane } = Tabs;

class ContractorDataSheet extends Component {
  render() {
    return (
      <ProjectScreenLayout {...this.props}>
        {({ application, contractorApplication, usurp }) => {
          const { dataTree, property } = application;
          const isContractorApplication = true;
          const data = {
            usurp,
            application,
            dataTree,
            property,
            contractorApplication,
            isContractorApplication,
          };

          let eventMatrix = null;
          try {
            eventMatrix = JSON.parse(application.property.edrEventMatrix);
          } catch (e) {
            console.log(e); // eslint-disable-line no-console
          }

          return (
            <div className={styles.layout}>
              <div className={styles.title}>Data sheet</div>
              <div className={styles.description}>
                <Tabs defaultActiveKey="analyzed">
                  <TabPane tab="Analyzed data" key="analyzed">
                    <div className={styles.dataLayout}>
                      <ScoreTable
                        data={data}
                        isContractorApplication
                        tableData={scoresData}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Synthesized data" key="synthesized">
                    <div>
                      <ValuesList data={data} />
                      <div className={valuesStyles.section}>
                        <div className={valuesStyles.title}>Environmental risk details</div>
                        {eventMatrix && <EdrResultsGrid eventMatrix={eventMatrix} />}
                        <EnvironmentalEvents data={data} />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Raw data" key="raw">
                    <RawData data={data} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default ContractorDataSheet;
