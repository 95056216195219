import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';

import styles from './styles.scss';

class GetConnected extends PureComponent {
  render() {
    return (
      <div className={styles.getConnected}>
        <h1 className={styles.getConnected__title}>
          <span>Get Connected</span>
          Explore the Marketplace
        </h1>
        <div className={styles.getConnected__grid}>
          <div className={styles.getConnected__grid__item}>
            <h3 className="up-resource-page__connected__item__title">
              Connect With Lenders
            </h3>
            <p>Our lenders are unique. They have the exclusive ability to finance clean energy real estate projects through local government development programs, ensuring you access to great terms.</p>
            <div className={styles.getConnected__grid__item__actions}>
              <Link to={routes.applicant.apply}>
                <Button type="primary">
                  Create application
                  <Icon type="right" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GetConnected;
