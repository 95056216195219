import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { notification } from 'antd';
import Bounce from '$components/auth/Bounce';
import storage from '$lib/storage';
import SignInForm from './SignInForm';

const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      email
      accessToken
      errors {
        detail
        pointer
      }
    }
  }
`;

class SignIn extends Component {
  render() {
    const { currentUser } = this.props;

    if (currentUser) {
      return (
        <Bounce currentUser={currentUser} />
      );
    }
    return (
      <Mutation
        mutation={SIGN_IN}
        refetchQueries={['CurrentUser']}
        update={(_cache, { data }) => {
          const { signIn } = data;
          if (signIn.errors) {
            notification.error({
              message: 'Unauthorized',
              description: 'Please verify your credentials.',
            });
            return;
          }
          const { email, accessToken } = signIn;
          storage.setAuth({ email, accessToken });
        }}
      >
        {(signIn, { loading }) => (
          <SignInForm signIn={signIn} loading={loading} />
        )}
      </Mutation>
    );
  }
}

export default SignIn;
