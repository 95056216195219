import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import { Mutation } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import qs from 'qs';
import storage from '$lib/storage';

import styles from '../styles.scss';

const CONTRACTOR_SIGN_UP = gql`
  mutation ContractorSignUp($input: ApplicantSignUpInput!) {
    contractorSignUp(input: $input) {
      email
      accessToken
    }
  }
`;

class ContractorSignUpForm extends Component {
  constructor(props) {
    super(props);

    const { location } = props;
    const { search } = location;

    const query = search.substring(1);
    const parsed = qs.parse(query);
    const { contractorApplicationUuid, applicationUuid } = parsed;

    this.state = {
      contractorApplicationUuid,
      applicationUuid,
    };
  }

  handleSubmit = ({ signUpMutation }) => (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, input) => {
      if (!err) {
        const {
          fullName,
          email,
          password,
          referredByCode,
        } = input;
        const { organizationUuid } = this.props;
        const {
          applicationUuid,
          contractorApplicationUuid,
         } = this.state;
        const { data } = await signUpMutation({
          variables: {
            input: {
              fullName,
              email,
              password,
              referredByCode,
              applicationUuid,
              contractorApplicationUuid,
              organizationUuid,
            },
          },
        });

        if (data && data.contractorSignUp && contractorApplicationUuid) {
          const returnTo = reverse(routes.contractor.projects.project.overview, { contractorUuid: contractorApplicationUuid });
          this.props.history.push(returnTo);
        }
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Mutation
        mutation={CONTRACTOR_SIGN_UP}
        refetchQueries={['CurrentUser']}
        update={(_cache, { data }) => {
          const { contractorSignUp } = data;
          if (!contractorSignUp) {
            return;
          }
          const { email, accessToken } = contractorSignUp;
          storage.setAuth({ email, accessToken });
        }}
      >
        {(signUpMutation, { loading }) => (
          <Form onSubmit={this.handleSubmit({ signUpMutation })} className={styles.form__large}>
            <Form.Item label="Full name">
              {getFieldDecorator('fullName', {
                rules: [{ required: true, message: 'Please input your name!' }],
              })(
                <Input
                  data-aaa="name"
                  placeholder="James White"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              )}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Please input a email!' }],
              })(
                <Input
                  data-aaa="email"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item label="Password">
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input a password!' }],
              })(
                <Input
                  data-aaa="password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item label="Invite code (optional)">
              {getFieldDecorator('referredByCode')(
                <Input
                  data-aaa="referredByCode"
                  placeholder="ABC123"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                data-aaa="signUpSubmit"
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                Sign up
              </Button>
            </Form.Item>
          </Form>
        )}
      </Mutation>
    );
  }
}

export default Form.create()(withApollo(withRouter(ContractorSignUpForm)));
