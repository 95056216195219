import React, { Component } from 'react';
import Media from 'react-media';
import { Button } from 'antd';
import styles from './styles.scss';

export default class DesktopRequired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acknowledged: false,
    };
  }

  render() {
    const { children } = this.props;
    const { acknowledged } = this.state;

    return (
      <Media queries={{
        bigEnough: '(min-width: 868px)',
      }}>
        {(matches) => {
          if (acknowledged) {
            return this.props.children;
          }

          if (matches.bigEnough) {
            return children;
          }

          return (
            <div className={styles.layout}>
              <div className={styles.title}>This page isn&apos;t optimized for mobile</div>
              <div className={styles.text}>
                This page is best viewed on a larger screen. You can still continue, but it might not display properly.
              </div>
              <Button type="primary" onClick={() => this.setState({ acknowledged: true })}>Continue</Button>
            </div>
          );
        }}
      </Media>
    );
  }
}
