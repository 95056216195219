import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import CommonSmallBlock from '../CommonSmallBlock';

import sharedStyles from '../CommonSmallBlock/shared.scss';

const NAME = 'Apply for financing';
const TIME = '5 min';

class ApplyBlock extends Component {
  render() {
    const {
      contractorApplication,
    } = this.props;

    const {
      uuid: contractorUuid,
    } = contractorApplication;

    return (
      <CommonSmallBlock name={NAME} time={TIME}>
        <div className={sharedStyles.layout}>
          <div className={sharedStyles.title}>Apply for financing</div>
          <div className={sharedStyles.description}>
            Find lenders and use our all-in-one application to apply for multiple types financing from the dozens of lenders at once. It takes just 5 minutes.
          </div>
          <Link to={reverse(routes.contractor.projects.project.findFunding, { contractorUuid })}>
            <Button type="primary">Find funding</Button>
          </Link>
        </div>
      </CommonSmallBlock>
    );
  }
}

export default ApplyBlock;
