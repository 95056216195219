import gql from 'graphql-tag';
import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import PropertySearch from '$components/common/PropertySearch';

import ApplicationForm from './ApplicationForm';
import applyFormStyles from './applyForm.scss';
import styles from './styles.scss';

const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($input: CreateApplicationsInput!) {
    updateApplication(input: $input) {
      application {
        uuid
      }
      newAccountCreated
    }
  }
`;

class Apply extends Component {
  render() {
    return (
      <Mutation mutation={UPDATE_APPLICATION}>
        {(updateApplication, { loading, data }) => {
          if (data) {
            const { updateApplication: { newAccountCreated } } = data;

            const cta = newAccountCreated ? (
              <div className={applyFormStyles.accountText}>
                We created an account for you; check your email to set a password and check your application status.
              </div>
            ) : (
              <div className={applyFormStyles.accountText}>
                Visit your <Link to={routes.applicant.dashboard}>dashboard</Link> to view your application status.
              </div>
            );

            return (
              <div className={applyFormStyles.confirmation}>
                <div className={applyFormStyles.confirmationTitle}>Thank you!</div>
                <span>Your application has been submit.</span>
                <br />
                {cta}
              </div>
            );
          }

          return (
            <PropertySearch
              header={(
                <header className={styles.header}>
                  <div className={styles.pin}></div>
                  <div className={styles.title}>See if sustainable financing is available for your area</div>
                </header>
              )}
            >
              {({ location, parcels, isPaceAvailable, resetMap, setSidebarStatus }) => (
                <ApplicationForm
                  isPaceAvailable={isPaceAvailable}
                  loading={loading}
                  location={location}
                  parcels={parcels}
                  resetMap={resetMap}
                  setSidebarStatus={setSidebarStatus}
                  submit={updateApplication}
                />
              )}
            </PropertySearch>
          );
        }}
      </Mutation>
    );
  }
}

export default Apply;
