import React, { Component } from 'react';
import { Icon } from 'antd';

const getValues = (value) => {
  let color;
  let icon;

  switch (value) {
    case 'low':
      color = 'green';
      icon = 'check-circle';
      break;
    case 'high':
      color = 'red';
      icon = 'close-circle';
      break;
    default:
      color = 'orange';
      icon = 'warning';
      break;
  }

  return {
    color,
    icon,
  };
};

class RiskIcon extends Component {
  render() {
    const { value } = this.props;
    const {
      color,
      icon,
    } = getValues(value);

    const style = {
      color,
    };

    return (
      <Icon type={icon} style={style} />
    );
  }
}

export default RiskIcon;
