import { Component } from 'react';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';

const LAST_SEEN_INTERVAL = 1000 * 60;

const LAST_SEEN = gql`
  mutation LastSeen {
    lastSeen {
      _empty
    }
  }
`;

class LastSeen extends Component {
  constructor(props) {
    super(props);
    this.lastSeenInterval = null;
  }

  componentDidMount() {
    const { client, currentUser } = this.props;
    if (!currentUser) {
      return;
    }

    this.lastSeenInterval = setInterval(() => {
      client.mutate({
        mutation: LAST_SEEN,
      });
    }, LAST_SEEN_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.lastSeenInterval);
  }

  render() {
    return null;
  }
}

export default withApollo(LastSeen);
