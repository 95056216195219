import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { ThemeContext } from '$lib/context';

class Bounce extends Component {
  render() {
    const { currentUser } = this.props;

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { organizationUuid } = theme;

          if (organizationUuid) {
            return (
              <Redirect to={routes.contractor.projects.new} />
            );
          }

          if (!currentUser) {
            return (
              <Redirect to={routes.auth.signIn} />
            );
          }

          switch (currentUser.type) {
            case 'applicant': {
              return (
                <Redirect to={routes.applicant.overview} />
              );
            }
            case 'contractor': {
              return (
                <Redirect to={routes.contractor.overview} />
              );
            }
            case 'manager': {
              return (
                <Redirect to={routes.manager.overview} />
              );
            }
            case 'lender':
              return (
                <Redirect to={routes.lender.overview} />
              );
            case 'admin':
              return (
                <Redirect to={routes.admin.dashboard} />
              );
            default: {
              return (
                <div>
                  Unknown user type: {currentUser.type}
                </div>
              );
            }
          }
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default Bounce;
