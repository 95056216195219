import React, { Component } from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { LENDER_NOTIFICATION_CRITERIA } from './lenderList';
import styles from './styles.scss';

const { Option } = Select;

class LenderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      financingTypes: [],
    };
  }

  onChange = (financingTypes) => {
    this.setState({
      financingTypes,
    });
  }

  render() {
    const { financingTypes } = this.state;
    const filteredLenders = (financingTypes.length === 0)
      ? LENDER_NOTIFICATION_CRITERIA
      : LENDER_NOTIFICATION_CRITERIA.filter((lender) => _.intersection(lender.financingTypes, financingTypes).length > 0);

    return (
      <div className={styles.layout}>
        <div className={styles.title}>Lender list</div>
        <div className={styles.description}>
          <div>Feel free to contact these lenders directly.</div>
          <br />
          <div>
            <div>Filter by financing types offered:</div>
            <Select
              data-aaa="lenderFinancingTypes"
              mode="multiple"
              onChange={this.onChange}
              placeholder="Click to select"
            >
              <Option key="PACE">PACE</Option>
              <Option key="PPA">PPA</Option>
              <Option key="EFA">EFA</Option>
              <Option key="LEase">Lease</Option>
              <Option key="Loan">Loan</Option>
              <Option key="Hard money">Hard money</Option>
              <Option key="On-bill">OnBill</Option>
            </Select>
          </div>
          <br />
          {filteredLenders.map((lender) => {
            const { company, logoHref, email, financingTypes: lenderFinancingTypes } = lender;
            return (
              <div key={email} className={styles.lender}>
                <img src={logoHref} className={styles.lenderLogo} />
                <div className={styles.lenderInfo}>
                  <div className={styles.lenderCompany}>{company}</div>
                  <div>{email}</div>
                  <div>Financing types: {lenderFinancingTypes.join(', ')}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default LenderList;
