import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Button, Modal } from 'antd';
import { NetworkStatus } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '@apollo/react-components';
import numeral from 'numeral';
import gql from 'graphql-tag';
import DesktopRequired from '$components/DesktopRequired';
import SpinnerError from '$components/common/SpinnerError';
import AccceptQuote from '$components/Applicant/Offers/AcceptQuote';
import CalculatorForm from './CalculatorForm';
import ProjectScreenLayout from '../ProjectScreenLayout';
import styles from './styles.scss';

const VALUE_CALCULATOR_FINANCING_TYPES = ['PACE'];

const AnimatedEllipsis = () => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    let interval;
    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        setCount((prevCount) => (prevCount || 2) + 1);
      }, 500);
    }, 500);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  if (count === null) {
    return <div>&nbsp;</div>
  }

  const ellip = '...'.substring(0, (count % 3) + 1);

  return (
    <div>{ellip}</div>
  );
};


const PROJECT_APPLICATIONS = gql`
  query ProjectApplications($input: ProjectApplicationsInput!) {
    projectApplications(input: $input) {
      applications {
        uuid
        slug
        financingType
        applicantNotifiedAt
        status
        createdAt
        expiresAt
        acceptedQuoteUuid
        quotes {
          uuid
          lenderUuid
          applicationUuid
          ask
          term
          rate
          prepayment
          fees
          message
          lender {
            uuid
            company
          }
        }
      }
    }
  }
`;

const VALUE_CALCULATOR_QUERY = gql`
  query ValueCalculatorMulti($input: ValueCalculatorMultiInput!) {
    valueCalculatorMulti(input: $input) {
      calculations {
        quoteUuid
        paceMonthlyPaymentLandlord
        paceMonthlyPaymentSticker
        paceStickerPrice
        paceLandlordPrice
      }
    }
  }
`;

const QuoteCell = ({ label, value }) => {
  return (
    <div className={styles.quoteCell}>
      <div className={styles.quoteCellLabel}>{label}</div>
      <div className={styles.quoteCellValue}>{value}</div>
    </div>
  );
};

const LenderQuotes = ({ quotes, quoteLoading, projectStatus, vcData, acceptedQuoteUuid }) => {
  const prototypeQuote = quotes[0];
  const { lender, message } = prototypeQuote;
  const { company } = lender;

  const sortedQuotes = _.sortBy(quotes, 'term');

  const calculationsByQuoteUuid = (() => {
    if (quoteLoading) {
      return {};
    }

    const { valueCalculatorMulti: { calculations } } = vcData;
    return _.keyBy(calculations, (calculation) => calculation.quoteUuid);
  })();

  return (
    <div className={styles.lenderQuotesLayout}>
      <div>{company}</div>
      <div>
        {sortedQuotes.map((quote) => {
          const {
            uuid,
            ask,
            term,
            rate,
            prepayment: rawPrepayment,
            fees: rawFees,
          } = quote;

          const prepayment = (!rawPrepayment || _.isEmpty(rawPrepayment)) ? '(not specified)' : rawPrepayment;
          const fees = (!rawFees || _.isEmpty(rawFees)) ? '(not specified)' : rawFees;

          const showConditions = () => {
            Modal.info({
              title: 'Financing offer conditions',
              content: (
                <div className={styles.financingOfferConditions}>
                  <div><div className={styles.financingOfferConditionsHeader}>Prepayment terms</div>{prepayment}</div>
                  <div><div className={styles.financingOfferConditionsHeader}>Additional fees</div>{fees}</div>
                </div>
              ),
            });
          };
          const rateEffective = (() => {
            if (quoteLoading) {
              return <AnimatedEllipsis />;
            }

            const calculation = calculationsByQuoteUuid[uuid] || {};
            const { paceLandlordPrice } = calculation;
            if (!paceLandlordPrice) {
              return '-';
            }
            return numeral(paceLandlordPrice).format('0.00%');
          })();

          const monthlyEffective = (() => {
            if (quoteLoading) {
              return <AnimatedEllipsis />;
            }

            const calculation = calculationsByQuoteUuid[uuid] || {};
            const { paceMonthlyPaymentLandlord } = calculation;
            if (!paceMonthlyPaymentLandlord) {
              return '-';
            }
            return numeral(Math.abs(paceMonthlyPaymentLandlord)).format('$0,0');
          })();

          const monthly = (() => {
            if (quoteLoading) {
              return <AnimatedEllipsis />;
            }

            const calculation = calculationsByQuoteUuid[uuid] || {};
            const { paceMonthlyPaymentSticker } = calculation;
            return numeral(Math.abs(paceMonthlyPaymentSticker)).format('$0,0');
          })();

          const quoteActions = (() => {
            if (_.includes(['expired', 'active', 'accepted'], projectStatus)) {
              return (
                <div className={styles.quoteActions} />
              );
            }

            return (
              <div className={styles.quoteActions}>
                <Button onClick={showConditions}>View conditions</Button>
                <AccceptQuote uuid={uuid} size="small" />
              </div>
            );
          });

          const layoutClassName = classnames([
            styles.quoteLayout,
            ((projectStatus === 'accepted') && (acceptedQuoteUuid === uuid)) && styles.quoteLayoutAccepted,
          ]);

          return (
            <div key={uuid} className={layoutClassName}>
              <div className={styles.quoteRow}>
                <QuoteCell label="Amount" value={numeral(ask).format('$0,0')} />
                <QuoteCell label="Term" value={`${term}yr`} />
                <QuoteCell label="Rate" value={numeral(rate).format('0.00%')} />
                <QuoteCell label="Effective" value={rateEffective} />
                <QuoteCell label="Monthly" value={monthly} />
                <QuoteCell label="Net" value={monthlyEffective} />
              </div>
              {quoteActions()}
            </div>
          );
        })}
      </div>
      <div className={styles.lenderQuotesMessage}>{message}</div>
    </div>
  );
};

const Quotes = ({ quotes, quoteLoading, projectStatus, vcData, acceptedQuoteUuid }) => {
  if (quotes.length === 0) {
    return (
      <div>
        No quotes received for this type of financing.
      </div>
    );
  }

  const quotesByLenderUuid = _.groupBy(quotes, 'lenderUuid');

  return (
    <div>
      {_.map(quotesByLenderUuid, (quotesForLenderUuid, lenderUuid) => (
        <LenderQuotes
          key={lenderUuid}
          quotes={quotesForLenderUuid}
          quoteLoading={quoteLoading}
          projectStatus={projectStatus}
          acceptedQuoteUuid={acceptedQuoteUuid}
          vcData={vcData}
        />
      ))}
    </div>
  );
};

const ApplicationQuotes = ({ application, quoteLoading, projectStatus, vcData, acceptedQuoteUuid }) => {
  const {
    quotes,
  } = application;

  return (
    <div>
      <Quotes
        quotes={quotes}
        quoteLoading={quoteLoading}
        vcData={vcData}
        projectStatus={projectStatus}
        acceptedQuoteUuid={acceptedQuoteUuid}
      />
    </div>
  );
};

const getVcVariables = ({ ownerValues, property, usurp, scopeOfWork, grossFloorArea, quotes }) => {
  const {
    percentLeased,
    percentLeasedFsg,
    percentLeasedMg,
    percentLeasedNnn,
    percentOwnerOccupied,
    percentUnoccupied,
    taxEscalationModifiedGrossLease,
  } = ownerValues;

  const quotesInput = quotes.map((quote) => ({
    uuid: quote.uuid,
    term: quote.term,
    amount: quote.amount,
    rate: quote.rate,
    financingType: quote.financingType,
  }));

  const valueCalculatorVariables = {
    input: {
      city: (property.locality || property.county),
      projectCompleteYear: 1,
      propertyType: usurp.propertyType,
      grossFloorArea: usurp.sqft || grossFloorArea,
      scopeOfWork,
      state: property.state,
      percentLeased: percentLeased / 100,
      percentLeasedFsg: percentLeasedFsg / 100,
      percentLeasedMg: percentLeasedMg / 100,
      percentLeasedNnn: percentLeasedNnn / 100,
      percentOwnerOccupied: percentOwnerOccupied / 100,
      percentUnoccupied: percentUnoccupied / 100,
      taxEscalationModifiedGrossLease: Math.min(0.2, taxEscalationModifiedGrossLease / 100 || 0),
      quotes: quotesInput,
    },
  };

  return valueCalculatorVariables;
};

const FinancingTypeSection = ({ financingType, applications, quoteLoading, projectStatus, vcData }) => {
  if (applications.length === 0) {
    return null;
  }

  return (
    <div className={styles.financingTypeLayout}>
      <div className={styles.financingTypeHeader}>{financingType}</div>
      <div className={styles.financingTypeApplications}>
        {applications.map((application) => (
          <ApplicationQuotes
            key={application.uuid}
            application={application}
            quoteLoading={quoteLoading}
            projectStatus={projectStatus}
            acceptedQuoteUuid={application.acceptedQuoteUuid}
            vcData={vcData}
          />
        ))}
      </div>
    </div>
  );
};

const ValueCalculatorManager = (props) => {
  const { applications, contractorApplication } = props;

  const [ownerValues, setOwnerValues] = useState({
    percentLeased: 50,
    percentLeasedFsg: 25,
    percentLeasedMg: 25,
    percentLeasedNnn: 50,
    percentOwnerOccupied: 50,
    percentUnoccupied: 0,
    taxEscalationModifiedGrossLease: 0,
  });
  const grossFloorArea = 5000;


  let filteredApplications = applications;

  const hasAcceptedApplication = _.some(applications, (application) => application.status === 'accepted');
  const hasNotifiedApplications = _.some(applications, (application) => application.status === 'applicantNotified');
  const hasExpiredApplications = _.some(applications, (application) => application.status === 'expired');
  const allExpired = _.every(applications, (application) => application.status === 'expired');

  const projectStatus = (() => {
    if (allExpired) {
      return 'expired';
    }

    if (hasAcceptedApplication) {
      return 'accepted';
    }

    if (hasNotifiedApplications) {
      return 'notified';
    }

    return 'active';
  })();

  if (hasAcceptedApplication) {
    filteredApplications = filteredApplications.filter((application) => application.acceptedQuoteUuid);
  } else if (hasNotifiedApplications) {
    filteredApplications = filteredApplications.filter((application) => application.status === 'applicantNotified');
  } else if (hasExpiredApplications) {
    filteredApplications = filteredApplications.filter((application) => application.status === 'expired');
  }

  const latestBatchExpiresAt = _.maxBy(filteredApplications, 'expiresAt').expiresAt;
  const latestBatchApplications = filteredApplications.filter((application) => (application.expiresAt === latestBatchExpiresAt));

  const showValueCalculator = _.some(latestBatchApplications, (application) => _.includes(VALUE_CALCULATOR_FINANCING_TYPES, application.financingType));

  const applicationsByFinancingType = _.groupBy(latestBatchApplications, (application) => application.financingType);

  const { application } = contractorApplication;
  const { property, usurp, scopeOfWork } = application;

  const quotes = _.flatten(applications.map((applicationForQuote) => {
    const { financingType, quotes: quotesForApplication } = applicationForQuote;
    return quotesForApplication.map((quote) => ({
      uuid: quote.uuid,
      amount: quote.ask,
      rate: quote.rate,
      term: quote.term,
      financingType,
    }));
  }));
  const vcVariables = getVcVariables({ ownerValues, property, usurp, scopeOfWork, grossFloorArea, quotes });

  const { loading: vcLoading, error: vcError, data: vcData, refetch: vcRefetch, networkStatus: vcNetworkStatus } = useQuery(VALUE_CALCULATOR_QUERY, {
    variables: vcVariables,
    notifyOnNetworkStatusChange: true,
  });

  const soonestNotifiedAt = _.minBy(latestBatchApplications, 'applicantNotifiedAt').applicantNotifiedAt;
  const quotesExpireAt = moment(soonestNotifiedAt).add(2, 'week');

  console.log(vcData);

  const quoteLoading = vcLoading || (vcNetworkStatus === NetworkStatus.refetch);

  const renderExpiration = (() => {
    if (projectStatus === 'expired') {
      return (
        <div>These quotes are no longer valid and need to be refreshed from the project overview screen.</div>
      );
    }

    if (projectStatus === 'active') {
      return (
        <div>These quotes are no longer valid. Your application has been resubmit and is in the process of receiving quotes from lenders.</div>
      );
    }

    if (projectStatus === 'accepted') {
      return (
        <div>You have accepted a quote and the lender will contact you.</div>
      );
    }

    return (
      <div>These financing offers are valid until {quotesExpireAt.format('M/D [at] H:mma')}</div>
    );
  });

  return (
    <div className={styles.layout}>
      {renderExpiration()}
      {showValueCalculator && (
        <CalculatorForm
          refetch={({ ownerValues: newOwnerValues }) => {
            const newVcVariables = getVcVariables({ ownerValues: newOwnerValues, property, usurp, scopeOfWork, grossFloorArea, quotes });
            vcRefetch(newVcVariables);
          }}
          ownerValues={ownerValues}
          setOwnerValues={setOwnerValues}
        />
      )}
      <div className={styles.financingTypeSections}>
        {_.map(applicationsByFinancingType, (applicationsForFinancingType, financingType) => {
          return (
            <FinancingTypeSection
              key={financingType}
              financingType={financingType}
              applications={applicationsForFinancingType}
              quoteLoading={quoteLoading}
              projectStatus={projectStatus}
              vcData={vcData}
            />
          );
        })}
      </div>
    </div>
  );
}

const ProjectApplications = (props) => {
  return (
    <DesktopRequired>
      <ProjectScreenLayout {...props}>
        {({ contractorApplication }) => {
          const { uuid: contractorUuid } = contractorApplication;

          return (
            <Query query={PROJECT_APPLICATIONS} variables={{ input: { projectUuid: contractorUuid } }}>
              {({ loading, error, data }) => {
                if (loading || error) {
                  return (
                    <SpinnerError error={error} />
                  );
                }

                const { projectApplications: { applications } } = data;

                return (
                  <ValueCalculatorManager
                    contractorApplication={contractorApplication}
                    applications={applications}
                  />
                );
              }}
            </Query>
          );
        }}
      </ProjectScreenLayout>
    </DesktopRequired>
  );
};

export default ProjectApplications;
