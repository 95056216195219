import _isString from 'lodash/isString';

import {
  THRESHOLD_ENVIRONMENTAL_LOW,
  THRESHOLD_ENVIRONMENTAL_MEDIUM,
  THRESHOLD_LENDER_CONSENT_LOW,
  THRESHOLD_LENDER_CONSENT_MEDIUM,
  THRESHOLD_TITLE_LOW,
  THRESHOLD_TITLE_MEDIUM,
} from './riskThresholds';

const getRiskLevel = ({ lenderPreferences, value, type }) => {
  let thresholdEnvironmentalLow = THRESHOLD_ENVIRONMENTAL_LOW;
  let thresholdEnvironmentalMedium = THRESHOLD_ENVIRONMENTAL_MEDIUM;
  let thresholdLenderConsentLow = THRESHOLD_LENDER_CONSENT_LOW;
  let thresholdLenderConsentMedium = THRESHOLD_LENDER_CONSENT_MEDIUM;
  let thresholdTitleLow = THRESHOLD_TITLE_LOW;
  let thresholdTitleMedium = THRESHOLD_TITLE_MEDIUM;

  try {
    const { riskThresholds } = _isString(lenderPreferences) ? JSON.parse(lenderPreferences) : lenderPreferences;
    thresholdEnvironmentalLow = riskThresholds.environmental.low;
    thresholdEnvironmentalMedium = riskThresholds.environmental.medium;
    thresholdLenderConsentLow = riskThresholds.lenderConsent.low;
    thresholdLenderConsentMedium = riskThresholds.lenderConsent.medium;
    thresholdTitleLow = riskThresholds.title.low;
    thresholdTitleMedium = riskThresholds.title.medium;
  } catch (e) { /* NOP */ }

  switch (type) {
    case 'environmental': {
      if (value >= thresholdEnvironmentalLow) {
        return 'low';
      }

      if (value >= thresholdEnvironmentalMedium) {
        return 'medium';
      }

      return 'high';
    }
    case 'credit':
    case 'lenderConsent': {
      if (value >= thresholdLenderConsentLow) {
        return 'low';
      }

      if (value >= thresholdLenderConsentMedium) {
        return 'medium';
      }

      return 'high';
    }
    case 'title': {
      if (value > thresholdTitleLow) {
        return 'low';
      }

      if (value > thresholdTitleMedium) {
        return 'medium';
      }

      return 'high';
    }
    default: {
      console.error(`Invalid risk icon type: ${type}`); // eslint-disable-line no-console
      return 'medium';
    }
  }
};

export default getRiskLevel;
