import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { AutoComplete, Form, Button, Input } from 'antd';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import SpinnerError from '$components/common/SpinnerError';
import TrueFalseButton from '$components/common/TrueFalseButton';

import ProjectScreenLayout from '../ProjectScreenLayout';

import styles from './styles.scss';

const ENERGY_SAVINGS = gql`
  mutation UpdateContractorApplicationEnergySavings($input: UpdateContractorApplicationEnergySavingsInput!) {
    updateContractorApplicationEnergySavings(input: $input) {
      contractorApplication {
        uuid

        userPropertyType
        userLightingUpgrade
        userSqft
        userMonthlyEnergyCost

        energySavings {
          annualEnergySavings
          paceCanFinance
          pacePayments
          paceSavings
        }
      }
    }
  }
`;

// 20200108JP: This should be tied to propertyTypes definition.
const PROPERTY_TYPE_OBJECTS = [
  { value: 'Café/Restaurants', text: 'Café / Restaurants' },
  { value: 'Education', text: 'Education' },
  { value: 'EnclosedAndStripMalls', text: 'Enclosed and strip malls' },
  { value: 'Grocery/Convenience Store', text: 'Grocery / Convenience Store' },
  { value: 'Healthcare', text: 'Healthcare (other)' },
  { value: 'Inpatient Hospital', text: 'Inpatient Hospital' },
  { value: 'Lodging', text: 'Lodging' },
  { value: 'Multifamily', text: 'Multifamily' },
  { value: 'Office', text: 'Office' },
  { value: 'Outpatient Clinic', text: 'Outpatient Clinic' },
  { value: 'Religious worship', text: 'Religious worship' },
  { value: 'Retail (other than mall)', text: 'Retail (other than mall)' },
  { value: 'Vacant', text: 'Vacant' },
  { value: 'Warehouse and storage', text: 'Warehouse and storage' },
];

class EnergySavingsScreen extends Component {
  onSubmit = ({ contractorApplication, updateEnergySavings }) => (e) => {
    e.preventDefault();

    const { form, history } = this.props;
    const { uuid: contractorUuid } = contractorApplication;

    form.validateFields(async (err, values) => {
      await updateEnergySavings({
        variables: {
          input: {
            ...values,
            contractorApplicationUuid: contractorApplication.uuid,
          },
        },
      });
      history.push(reverse(routes.contractor.projects.project.energySavingsResults, { contractorUuid }));
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          const {
            application,
            userPropertyType,
            userSqft,
            grossFloorArea,
            userMonthlyEnergyCost,
          } = contractorApplication;

          const { usurp } = application;
          const { sqft } = usurp;

          const userLightingUpgrade = contractorApplication.userLightingUpgrade || false;

          return (
            <div className={styles.layout}>
              <div className={styles.title}>Energy savings</div>

              <Mutation mutation={ENERGY_SAVINGS}>
                {(updateEnergySavings, { loading, error }) => {
                  if (loading || error) {
                    return <SpinnerError error={error} />;
                  }

                  return (
                    <div>
                      <Form onSubmit={this.onSubmit({ contractorApplication, updateEnergySavings })}>
                        <Form.Item label="Property type">
                          {getFieldDecorator('userPropertyType', {
                            rules: [{ required: true }],
                            initialValue: userPropertyType,
                          })(
                            <AutoComplete
                              data-aaa="propertyType"
                              disabled={loading}
                              dataSource={PROPERTY_TYPE_OBJECTS}
                              placeholder="Type to start searching"
                            />
                          )}
                        </Form.Item>
                        <Form.Item label="Gross floor sqft">
                          {getFieldDecorator('userSqft', {
                            getValueFromEvent: (e) => {
                              const convertedValue = Number(e.currentTarget.value);
                              if (Number.isNaN(convertedValue)) {
                                return Number(this.props.form.getFieldValue('sqft'));
                              }
                              return convertedValue;
                            },
                            rules: [{ required: true }],
                            initialValue: userSqft || grossFloorArea || sqft,
                          })(
                            <Input data-aaa="sqft" disabled={loading} />
                          )}
                        </Form.Item>
                        <Form.Item label="Monthly energy bill">
                          {getFieldDecorator('userMonthlyEnergyCost', {
                            getValueFromEvent: (e) => {
                              const convertedValue = Number(e.currentTarget.value);
                              if (Number.isNaN(convertedValue)) {
                                return Number(this.props.form.getFieldValue('userMonthlyEnergyCost'));
                              }
                              return convertedValue;
                            },
                            rules: [{ required: true }],
                            initialValue: userMonthlyEnergyCost,
                          })(
                            <Input data-aaa="energyCost" disabled={loading} />
                          )}
                        </Form.Item>
                        <Form.Item label="Lighting upgraded in last 5 years?">
                          {getFieldDecorator('userLightingUpgrade', {
                            initialValue: userLightingUpgrade,
                            getValueFromEvent: ({ value }) => value,
                          })(
                            <TrueFalseButton
                              data-aaa="lightingUpgrade"
                              disabled={loading}
                              initialValue={userLightingUpgrade}
                            />
                          )}
                        </Form.Item>
                        <Button
                          data-aaa="submitEnergySavings"
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                        >
                          Calculate energy savings
                      </Button>
                      </Form>
                    </div>
                  );
                }}
              </Mutation>
            </div>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default Form.create()(withRouter(EnergySavingsScreen));
