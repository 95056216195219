import _ from 'lodash';
import React, { Component } from 'react';
import { Collapse } from 'antd';
import ListItem from './ListItem';

import styles from './styles.scss';

const headers = {
  quoted: 'Applications you quoted',
  quotedWon: 'Applications awarded to you',
  quotedLost: 'Applications awarded to others',
  quotedLostOther: 'Applications financed by a different financing type',
  quotedExpired: 'Applications with no response',
};

class ApplicationsQuoted extends Component {
  render() {
    const { currentUser, items } = this.props;
    const { lenderPreferences } = currentUser;
    const itemsByStatus = _.groupBy(items, ({ status }) => status);
    const statusKeys = Object.keys(itemsByStatus).filter((status) => status !== 'null');

    return (
      <div className={styles.collapseContainer}>
        {(items.length > 0) ? (
          <Collapse className={styles.container} defaultActiveKey={statusKeys[0]}>
            {_.map(statusKeys, (status) => {
              const sortedItems = _.sortBy(itemsByStatus[status], 'createdAt').filter(({ application }) => Boolean(application));
              const header = `${headers[status] || 'Other'} (${sortedItems.length})`;
              return (
                <Collapse.Panel key={status} header={header}>
                  {sortedItems.map((item) => (
                      <ListItem
                        key={item.uuid}
                        item={item}
                        lenderPreferences={lenderPreferences}
                      />
                  ))}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        ) : (
          <div>No results.</div>
        )}
      </div>
    );
  }
}

export default ApplicationsQuoted;
