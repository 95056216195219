import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { routes } from '@usurp-power/aqua-silver';
import ResetPasswordForm from './ResetPasswordForm';

const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!, $token: String!) {
    resetPassword(email: $email, password: $password, token: $token) {
      email
    }
  }
`;

class ResetPassword extends Component {
  render() {
    const { params: { token } } = this.props.match;

    return (
      <Mutation mutation={RESET_PASSWORD}>
        {(resetPassword, { loading, data }) => {
          if (!data) {
            return (
              <ResetPasswordForm resetPassword={resetPassword} loading={loading} token={token} />
            );
          }

          const { resetPassword: { email } } = data;

          return (
            <div style={{ textAlign: 'center' }}>
              Password for <b>{email}</b> was reset.<br />
              <br />
              <Link to={routes.auth.signIn}>Sign in</Link>
            </div>
          );
        }}
      </Mutation>
    );
  }
}

export default ResetPassword;
