import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class UsurpRating extends PureComponent {
  static propTypes = {
    usurpRating: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div></div>
    );
  }
}

export default UsurpRating;
