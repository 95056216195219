import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from 'antd';
import styles from './styles.scss';

class RootBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  reload() { // eslint-disable-line class-methods-use-this
    window.location.href = window.location.href; // eslint-disable-line no-self-assign
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.layout}>
          <div className={styles.header}>
            <p>This is the bad news page :-(</p>
          </div>
          <div className={styles.message}>
            <p>
              Something unexpected occurred and it crashed the app.
              We&apos;ve automatically been notified, so all you need to do is reload the page.
              Sorry about that!
            </p>
            <p className={styles.from}> - Unety Engineering</p>
          </div>
          <Button className={styles.button} onClick={this.reload}>Reload</Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default RootBoundary;
