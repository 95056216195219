import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import numeral from 'numeral';
import { histogram } from 'd3-array';
import { Modal } from 'antd';
import { Range } from '@justinpincar/rc-slider';

import Bar from 'recharts/es6/cartesian/Bar';
import BarChart from 'recharts/es6/chart/BarChart';


import styles from './refineSizeModalStyles.scss';

const CHART_WIDTH = 350;
const CHART_HEIGHT = 70;

export default class RefineSizeModal extends Component {
  static propTypes = {
    range: PropTypes.arrayOf(PropTypes.number)
  };

  static defaultProps = {
    range: [0, 100]
  }

  constructor(props) {
    super(props);
    this.state = {
      range: this.props.range,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line camelcase
    if (nextProps.range) {
      this.setState(() => ({
        range: nextProps.range
      }));
    }
  }

  handleSliderChange = (range) => {
    this.setState({
      range,
    });
  }

  render() {
    const { range } = this.state;
    const { items } = this.props;
    const asks = items.map((item) => item.application.ask);
    const bins = histogram().thresholds(20)(asks);

    const average = _.mean(asks);
    const minValue = (asks.length > 1) ? _.min(asks) : 0;
    const maxValue = (asks.length > 1) ? _.max(asks) : asks[0] * 2;

    const chartData = (() => {
      if (bins.length === 1) {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((val, index) => {
          return {
            name: '',
            uv: val,
            pv: index,
          };
        });
      }

      return bins.map((bin, index) => {
        return {
          name: '',
          uv: bin.length,
          pv: index,
        };
      });
    })();

    const leftWidth = (range[0] * CHART_WIDTH) / 100.0;
    const rightWidth = ((100 - range[1]) * CHART_WIDTH) / 100.0;

    const delta = (maxValue - minValue);
    const minRange = minValue + ((delta * range[0]) / 100.0);
    const maxRange = minValue + ((delta * range[1]) / 100.0);

    const leftBlockStyle = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      bottom: '0px',
      width: `${leftWidth}px`,
      backgroundColor: 'black',
      opacity: 0.3,
      zIndex: 10,
    };

    const rightBlockStyle = {
      position: 'absolute',
      right: '0px',
      top: '0px',
      bottom: '0px',
      width: `${rightWidth}px`,
      backgroundColor: 'black',
      opacity: 0.3,
      zIndex: 10,
    };

    const roundedAverage = numeral(average).format('0,0');
    const roundedMinRange = numeral(minRange).format('0,0');
    const roundedMaxRange = numeral(maxRange).format('0,0');

    return (
      <Modal
        title="Refine application size"
        visible={this.props.visible}
        onOk={() => this.props.onOk([minRange, maxRange])}
        onCancel={this.props.onCancel}
      >
        <div className={styles.criteria}>
          <div className={styles.criteriaContent}>
            <div className={styles.criteriaHeading}>
              <div className={styles.criteriaHeadingRange}>
                ${roundedMinRange} - ${roundedMaxRange}
              </div>
              <div className={styles.criteriaHeadingAverage}>
                The average application amount is ${roundedAverage}
              </div>
            </div>
            <div className={styles.chartWrapper}>
              <div style={leftBlockStyle} />
              <div style={rightBlockStyle} />
              <Range className={styles.slider} allowCross={false} value={range} onChange={this.handleSliderChange} />
              <BarChart
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                width={CHART_WIDTH}
                height={CHART_HEIGHT}
                data={chartData}
                barCategoryGap={0}
                barGap={0}
              >
                <Bar dataKey='uv' fill='#993333' />
              </BarChart>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
