import classnames from 'classnames';
import React, { Component } from 'react';
import { Spin } from 'antd';
import styles from './styles.scss';

class SpinnerError extends Component {
  render() {
    const { error, className, style } = this.props;

    if (!error) {
      return (
        <div className={classnames(styles.layout, className)} style={style}>
          <Spin />
        </div>
      );
    }

    return (
      <div className={classnames(styles.layout, className)} style={style}>
        <i className="fad fa-exclamation-circle" />
        <div className={styles.text}>Error</div>
      </div>
    );
  }
}

export default SpinnerError;
