import _ from 'lodash';
import React, { Component } from 'react';
import { Query } from '@apollo/react-components';
import { Spin } from 'antd';
import APPLICANT_DASHBOARD_QUERIES from '$queries/applicantDashboard';
import Applications from './Applications';
import ContractorApplications from './ContractorApplications';

class DashboardLayout extends Component {
  render() {
    return (
      <Query query={APPLICANT_DASHBOARD_QUERIES} fetchPolicy={'network-only'}>
        {({ loading, _error, data }) => {
          if (loading) {
            return (
              <Spin size="large" />
            );
          }

          if (!data) {
            return null;
          }

          const {
            applicantApplications: applications,
            contractorApplications,
           } = data;

          const filteredContractorApplications = contractorApplications.filter((contractorApplication) => _.get(contractorApplication, 'application.property'));

          return (
            <div>
              <Applications applications={applications} />
              <br /><br />
              <ContractorApplications contractorApplications={filteredContractorApplications} />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default DashboardLayout;
