import gql from 'graphql-tag';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withApollo } from '@apollo/react-hoc';
import { Icon, Input, Table } from 'antd';

import styles from './list.scss';

const APPLICATIONS = gql`
  query {
    adminApplications {
      createdAt
      rawAddress
      slug
      financingType
      status
      uuid

      contractorApplication {
        uuid
      }
    }
  }
`;

const columns = [
  {
    align: 'center',
    title: 'Slug',
    dataIndex: 'slug'
  },
  {
    title: 'Address',
    dataIndex: 'rawAddress',
    render: (text, record) => { // eslint-disable-line react/display-name
      return (
        <Link to={`/admin/application/${record.uuid}`} style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
          {text}
        </Link>
      );
    }
  },
  {
    align: 'center',
    title: 'Financing type',
    dataIndex: 'financingType'
  },
  {
    align: 'center',
    title: 'Type of score',
    render: (text, record) => { // eslint-disable-line react/display-name
      if (record.contractorApplication) {
        return <span>Go/no go</span>;
      }

      return <span>Full rating</span>;
    }
  },
  {
    align: 'right',
    title: 'Status',
    dataIndex: 'status'
  },
  {
    align: 'right',
    title: 'Submitted',
    render: (rext, { createdAt }) => { // eslint-disable-line react/display-name
      return (
        <div>
          {moment(new Date(createdAt)).format('MMM Do YYYY')}
        </div>
      );
    }
  },
  {
    title: '',
    render: (text, record) => { // eslint-disable-line react/display-name
      return (
        <Link to={`/admin/application/${record.uuid}`}>
          <Icon type="eye" />
        </Link>
      );
    }
  }
];

class ApplicationList extends PureComponent {
  constructor(props) {
    super(props);

    this.typeDebounce = null;
    this.state = {
      data: null,
      rawData: null,
    };
  }

  async componentDidMount() {
    try {
      const { data } = await this.props.client.query({
        query: APPLICATIONS
      });

      this.setState(() => ({
        data: data.adminApplications,
        rawData: data.adminApplications,
      }));
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  }

  handleFilterChange = (evt) => {
    const filterBy = evt.target.value;

    this.setState({
      filterBy
    }, () => {
      clearTimeout(this.typeDebounce);

      this.typeDebounce = setTimeout(() => {
        this.filterData();
      }, 300);
    });
  }

  filterData(filterBy = this.state.filterBy) {
    const {
      rawData,
    } = this.state;
    const filterByNormalized = filterBy.toLowerCase();

    const newData = filterBy
      ? rawData.filter(({ uuid, slug, rawAddress }) => {
        return (
          uuid.toLowerCase().includes(filterByNormalized)
          || slug.toLowerCase().includes(filterByNormalized)
          || rawAddress.toLowerCase().includes(filterByNormalized)
        );
      })
      : rawData;

    this.setState(() => ({
      data: newData,
    }));
  }

  render() {
    const {
      data
    } = this.state;
    return (
      <div>

        <div className={styles.adminApplicationList__actions}>
          <Input
            className={styles.adminApplicationList__filter}
            placeholder="Filter applications"
            onChange={this.handleFilterChange}
          />
        </div>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="uuid"
          loading={data === null}
          pagination={{ pageSize: 25 }}
        />
      </div>
    );
  }
}

export default withApollo(ApplicationList);
