import gql from 'graphql-tag';

const APPLICATION = gql`
  query Application($applicationUuid: ID!) {
    application(applicationUuid: $applicationUuid) {
      availableDocuments
      financingUrgency
      propertyType
      rawParcels

      address
      applicantEmail
      applicantPhone
      applicationPercentComplete
      city
      contactMethod
      firstName
      lastName
      lender
      nameTitle
      ownerOccupied
      postalCode
      projectStage
      projectType
      propertyBuildingSize
      propertyDebt
      propertyFirstMortgageLenderOther
      propertyMarketValue
      propertyOtherLenders
      propertyPositiveOperatingIncome
      propertyRelationship
      propertyType
      scopeOfWork
      projectEstimatedCost
      singleTenant
      state
      status
      usurp {
        altv
        altvScore
        communicationScore
      }
      project {
        uuid
        address
        applicationUuid
        city
        cpaceProjectAmount
        createdAt
        existingMortgageAddDebt
        favorite
        financingMoreImportant
        fundingQuickness
        grossAreaLargestTenant
        grossAreaSmallestTenant
        grossFloorArea
        isPaceBestMatch
        modifiedGrossLease
        monthlyUtilitySpend
        multiTenantBuilding
        numberFundingOptions
        ownerOccupied
        pctFullServiceGrossLease
        pctOccupied
        pctTripleNetLease
        percentLeased
        percentLeasedFsg
        percentLeasedMg
        percentLeasedNnn
        percentOwnerOccupied
        percentUnoccupied
        postalCode
        preferredFinancingTerm
        projectCategory
        projectSize
        projectType
        propertyMarketValue
        propertyType
        rentableUnits
        scopeOfWork
        sellAbilityFiveYears
        state
        taxEscalationModifiedGrossLease
        userLightingUpgrade
        userMonthlyEnergyCost
        userPropertyType
        userSqft
        whiteGloveRequestedAt
        zeroDownImportance

        fundingOptions {
          name
          message
          score
          options {
            availability
            rate
            rateText
            closeTime
            projectType
            projectSize
            upfrontCost
            contractDuration
            abilityToSell
            borrowingCapacity
            taxDeductions
          }
        }

        energySavings {
          annualEnergySavings
          paceCanFinance
          pacePayments
          paceSavings
        }

        valueCalculator {
          effectiveCostPace
          effectiveCostLoan
          effectiveCostCash

          newRevenueFromTenantsPace
          newRevenueFromTenantsLoan
          newRevenueFromTenantsCash

          owedFromLandlordPace
          owedFromLandlordLoan
          owedFromLandlordCash

          tenantCostIncreasePace
          tenantCostIncreaseLoan
          tenantCostIncreaseCash
        }

        nearbyContractors {
          uuid
        }

        contractorProjectInvitations {
          uuid
          contractor {
            uuid
            company
            postalCode
          }
          status
        }

        application {
          uuid
          expiresAt
          rawAddress
          projectUuid
          status
          dataStatus
          dataTreeStatus
          edrStatus
          property {
            uuid
            edrEventMatrix
            googleStaticMapUrl
            googleStreetViewUrl
            isPaceAvailable
            isPpaAvailable
          }
          usurp {
            uuid
            capRate
            creditScore
            debt
            environmentalScore
            lenderConsentScore
            marketValue
            taxAssessedValue
            multipleTitleOwners
            ownerOccupied
            postalCodeValid
            propertyClass
            propertyGroup
            propertyType
            singleTenant
            sqft
            taxAssessedValue
            taxDelinquency
            titleScore
            usurpRating
            usurpScore
            wal
          }
        }

        financingApplications {
          uuid
          viewUuid
          projectUuid
          financingType
          status
          createdAt
          quotes {
            uuid
          }
        }

        scoreEstimate {
          propertyClass
          environmentalRisk
          creditRisk
          bankability
          capRate
        }

        paceRefi {
          uuid
          scopeOfWork
          projectMonth
          result
          resultState
          resultLookback
          resultLookbackText
          resultCredit
          resultTitle
          resultEnvironmental
          email
        }
      }
    }
  }
`;

export default APPLICATION;
