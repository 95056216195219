import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button, Input, Form } from 'antd';

import styles from './styles.scss';

const PACE_REFI_SUBMIT_EMAIL = gql`
  mutation PaceRefiSubitEmail($input: PaceRefiSubmitEmailInput!) {
    paceRefiSubmitEmail(input: $input) {
      paceRefi {
        uuid
        email
      }
    }
  }
`;

const PaceRefiSubmitEmailFormUnwrapped = ({ form, paceRefi }) => {
  const { uuid, email } = paceRefi;
  const { getFieldDecorator } = form;
  const [paceRefiSubmitEmail, { loading }] = useMutation(PACE_REFI_SUBMIT_EMAIL);

  if (email) {
    return (
      <div className={styles.inputEmail}>
        We&apos;ll notify you at {email} if a program becomes available.
      </div>
    );
  }

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          paceRefiSubmitEmail({ variables: { input: { uuid, email: values.email } } });
        }
      });
    }}>
      <div className={styles.inputEmail}>
        {getFieldDecorator('email', {
          rules: [{ required: true }],
        })(
          <Input placeholder="bob@bobross.com" />
        )}
        <Button htmlType="submit" loading={loading} type="primary">Submit</Button>
      </div>
    </Form>
  );
};
const PaceRefiSubmitEmailForm = Form.create()(PaceRefiSubmitEmailFormUnwrapped);

const PaceRefiSubmitEmail = ({ paceRefi }) => {
  return (
    <div>
      <div>
        At this time, it does not seem like PACE capital recovery is a good fit for your project. Check back soon as new states are quickly adopting PACE legislation, or provide your contact info below and we&apos;ll reach out to you once your eligibility changes.
      </div>
      <PaceRefiSubmitEmailForm paceRefi={paceRefi} />
    </div>
  );
};

export default Form.create()(PaceRefiSubmitEmail);
