import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import { serializeApplicationData } from '../../../../lib/application';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import styles from './multiStep.scss';

const CREATE_APPLICATIONS = gql`
  mutation CreateApplications($input: CreateApplicationsInput!) {
    createApplications(input: $input) {
      applications {
        uuid
      }
      newAccountCreated
    }
  }
`;

const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($input: CreateApplicationsInput!) {
    updateApplication(input: $input) {
      application {
        uuid
      }
    }
  }
`;

const steps = [
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
];

const statuses = [
  'applicationStep1',
  'applicationStep2',
  'applicationStep3',
  'applicationStep4',
  'applicationStep5',
  'pendingData'
];

class MultiStepFormContainer extends Component {
  static propTypes = {
    initialValues: PropTypes.shape(),
    location: PropTypes.string,
    parcels: PropTypes.arrayOf(PropTypes.shape()),
    saving: PropTypes.bool,
    onComplete: PropTypes.func,
  };

  static defaultProps = {
    initialValues: {},
    saving: false,
    onCompete: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      disableNext: false,
      startTime: new Date(),
      values: this.props.initialValues
    };

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  async handleNext(newValues) {
    const {
      client,
      location,
      parcels,
    } = this.props;
    const {
      currentStep,
      startTime,
      values,
    } = this.state;

    const nextStep = currentStep + 1;

    const generatedValues = {
      ...values,
      ...newValues,
      location,
      rawParcels: JSON.stringify(parcels),
      status: statuses[nextStep],
      source: 'applicant'
    };
    const _values = serializeApplicationData({
      startTime,
      values: generatedValues,
    });

    this.setState({
      disableNext: true,
    });

    const financingTypes = (_values.financingTypes && (_values.financingTypes.length > 0))
      ? _values.financingTypes
      : ['PACE'];

    try {
      const { data } = await client.mutate({
        mutation: currentStep === 0 ? CREATE_APPLICATIONS : UPDATE_APPLICATION,
        variables: {
          input: {
            ..._values,
            financingTypes,
          },
        },
      });

      if (currentStep === 0) {
        _values.uuid = data.createApplications.applications[0].uuid;
      }
    } catch (e) {
      console.log(`Error ${e}`); // eslint-disable-line no-console
    }

    window.scrollTo(0, 0);
    if (nextStep === steps.length) {
      this.props.onComplete(_values);
    } else {
      this.setState({
        currentStep: nextStep,
        disableNext: false,
        values: _values,
      });
    }
  }

  handlePrev() {
    window.scrollTo(0, 0);
    this.setState(() => ({
      currentStep: this.state.currentStep - 1
    }));
  }

  render() {
    const {
      currentStep,
      disableNext,
      values,
    } = this.state;

    const {
      saving
    } = this.props;

    const completed = ((currentStep + 1) / steps.length) * 100;
    const progressStyle = {
      width: `${completed}%`,
    };

    const StepComponent = steps[currentStep];

    return (
      <div className={styles.formLayout}>
        {currentStep === 0 && (
          <div className={styles.formTitleContainer}>
            <div>Start the application</div>
            <div className={styles.formSubtitle}>There is additional information to see if your project qualifies</div>
          </div>
        )}
        <Card>
          <div className={styles.progressBar}>
            <div className={styles.progressBarInner}
                 data-completed={completed}
                 style={progressStyle}
            />
          </div>
          <div className={styles.stepComponentContainer}>
            <StepComponent
              buttonLoading={saving || disableNext}
              values={values}
              onNext={this.handleNext}
              onPrev={this.handlePrev}
            />
          </div>
        </Card>
      </div>
    );
  }
}


export default withApollo(MultiStepFormContainer);
