import classNames from 'classnames';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Button, Icon, Spin } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import ReactSpeedometer from 'react-d3-speedometer';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import { isApplicationApproved } from '$lib/application';
import MessageRotator from '$components/common/MessageRotator';
import { WITH_CONTRACTOR_APPLICATION } from '$components/contractor/screens/withContractorApplication';
import CommonSmallBlock from '../CommonSmallBlock';

import sharedStyles from '../CommonSmallBlock/shared.scss';
import styles from './styles.scss';

const NAME = 'Property overview';

const GoNoGoVisual = ({ success }) => {
  const value = success ? 750 : 250;

  return (
    <div className={styles.speedometerContainer}>
      <ReactSpeedometer
        value={value}
        currentValueText="Ease of financing"
        segments={2}
        needleHeightRatio={0.6}
        width={268}
        ringWidth={48}
        needleColor="#993333"
        segmentColors={['#FFBA5B', '#368230']}
        customSegmentLabels={[{
          text: 'Harder',
          position: 'INSIDE',
          color: '#FFF',
        }, {
          text: 'Easier',
          position: 'INSIDE',
          color: '#FFF',
        }]}
      />
    </div>
  );
};

const getCheckClasses = ({ check }) => {
  const classes = [styles.checkApproval__checkList__item__icon];

  if (check) {
    classes.push(styles.success);
  }

  return classes.join(' ');
};

class GoNoGoBlock extends Component {
  constructor(props) {
    super(props);

    const { application } = props;
    const { usurp } = application;

    this.state = {
      application,
      usurp,
    };

    if (application.status === 'pendingData') {
      setTimeout(this.checkApplication, 5000);
    }
  }

  checkApplication = async () => {
    const { client, contractorApplication } = this.props;

    const { data: { contractorApplication: { application } } } = await client.query({
      query: WITH_CONTRACTOR_APPLICATION,
      variables: { uuid: contractorApplication.uuid },
      fetchPolicy: 'network-only',
    });

    const { usurp } = application;

    this.setState({
      application,
      usurp,
    });

    if ((application.status === 'pendingData') || (application.dataStatus === 'pendingData')) {
      setTimeout(this.checkApplication, 5000);
    }
  }

  render() {
    const { contractorApplication } = this.props;
    const { application, usurp } = this.state;
    const { uuid } = contractorApplication;

    const {
      status: applicationStatus,
      edrStatus,
    } = application;

    if (/^failed/.test(applicationStatus)) { // 20191230JP: Might want another state for address not matched.
      return (
        <CommonSmallBlock name={NAME}>
          <div className={styles.loadingContainer}>
            <div className={sharedStyles.title}>We couldn&apos;t automatically screen this property</div>
            <br />
            <div className={sharedStyles.description}>
              Your property may have a special situation which our engine is not currently able to automatically process.
              <br /><br />
              Our team has been notified and we will attempt to manually score your property. In the meantime, you can still interact with the other sections on this dashboard.
            </div>
          </div>
        </CommonSmallBlock>
      );
    }

    if (applicationStatus !== 'augmented') {
      return (
        <CommonSmallBlock name={NAME}>
          <div className={styles.loadingContainer}>
            <div className={sharedStyles.title}>One moment while we score your property</div>
            <br />
            <div className={sharedStyles.description}>
              The Unety engine collects thousands of data points that are used to analyze hundreds of questions financiers are likely to ask.
              <br /><br />
              This process typically takes less than two minutes.
            </div>
            <Spin className={styles.spinner} />
            <MessageRotator
              messages={[
                'Did you know that buildings account for 40% of CO2 emissions in the US?',
                'According to LBNL, energy-best-practice is adopted by less than 10% of commercial buildings.',
                'Most energy best practices save more money than they cost, so why is<br/>adoption so low?',
                'Because the savings is stretched out over many years while the construction cost occurs upfront.',
                'Financing is the easiest way to ensure that energy improvement projects help, instead of hurt, annual cash flow.'
              ]}
            />
          </div>
        </CommonSmallBlock>
      );
    }

    const {
      taxAssessedValue,
      environmentalScore,
      propertyType,
      taxDelinquency,
      titleScore,
      usurpScore,
    } = usurp;

    const qualifiedAmount = taxAssessedValue * 0.2;
    const qualifiedMin = numeral(qualifiedAmount * 0.75).format('$0,0');
    const qualifiedMax = numeral(qualifiedAmount * 1.25).format('$0,0');

    const {
      approved: isSuccess,
      components: {
        environmentalSuccess,
        propertyTypeSuccess,
        taxDeliquencySuccess,
        statusSuccess,
        titleSuccess,
        usurpSuccess,
      }
    } = isApplicationApproved({
      applicationStatus,
      environmentalScore,
      propertyType,
      taxDelinquency,
      titleScore,
      usurpScore,
    });

    const edrPending = (edrStatus !== 'done');

    const environmentalMessage = (() => {
      if (edrPending) {
        return 'Environmental screen pending';
      }

      return environmentalSuccess ? 'Environmental score' : 'Environmental score is low';
    })();

    const loading = false;

    const contentContainerClasses = classNames('checkApproval__content', styles.contentContainer, styles.checkApproval, {
      [styles.contentContainer__active]: !loading,
    });

    const contentClasses = classNames(styles.checkApproval__content, {
      [styles.checkApproval__content__active]: !loading,
    });

    return (
      <CommonSmallBlock name={NAME}>
        <div className={contentContainerClasses}>
          <div className={contentClasses}>
            <div className={styles.checkApproval__title}>
              <div className={styles.title}>{isSuccess ? 'Good candidate for financing!' : 'Address may not get approved'}</div>
              {!isSuccess && (
                <div className={styles.subtitle}>...but you can still continue</div>
              )}
            </div>
            <div className={styles.checkApproval__loader__container}>
              <div className={styles.checkApproval__checkList}>
                {!propertyTypeSuccess && (
                  <div className={styles.checkApproval__checkList__item}>
                    <div className={styles.checkApproval__checkList__item__icon}>
                      <Icon type="warning" />
                    </div>
                    <div className={styles.checkApproval__checkList__item__label}>
                      Residential properties not yet supported by Unety
                    </div>
                  </div>
                )}
                <div className={styles.checkApproval__checkList__item}>
                  <div className={getCheckClasses({ check: usurpSuccess })}>
                    <Icon type={usurpSuccess ? 'check' : 'warning'} />
                  </div>
                  <div className={styles.checkApproval__checkList__item__label}>
                    {usurpSuccess ? 'Credit score' : 'Credit score is low'}
                  </div>
                </div>
                <div className={styles.checkApproval__checkList__item}>
                  <div className={getCheckClasses({ check: titleSuccess })}>
                    <Icon type={titleSuccess ? 'check' : 'warning'} />
                  </div>
                  <div className={styles.checkApproval__checkList__item__label}>
                    {titleSuccess ? 'Title score' : 'Title score is low'}
                  </div>
                </div>
                <div className={styles.checkApproval__checkList__item}>
                  <div className={getCheckClasses({ check: (environmentalSuccess && !edrPending) })}>
                    <Icon type={(environmentalSuccess && !edrPending) ? 'check' : 'warning'} />
                  </div>
                  <div className={styles.checkApproval__checkList__item__label}>
                    {environmentalMessage}
                  </div>
                </div>
                {!taxDeliquencySuccess && (
                  <div className={styles.checkApproval__checkList__item}>
                    <div className={styles.checkApproval__checkList__item__icon}>
                      <Icon type="warning" />
                    </div>
                    <div className={styles.checkApproval__checkList__item__label}>
                      Property is delinquent on taxes
                    </div>
                  </div>
                )}
                {!statusSuccess && (
                  <div className={styles.checkApproval__checkList__item}>
                    <div className={styles.checkApproval__checkList__item__icon}>
                      <Icon type="warning" />
                    </div>
                    <div className={styles.checkApproval__checkList__item__label}>
                      The financing amount and any other existing debt cannot be more than 100% of your appraised property value. You will need to provide an appraisal.
                    </div>
                  </div>
                )}
              </div>
            </div>
            <GoNoGoVisual success={isSuccess} />
            {qualifiedAmount ? (
              <div className={styles.checkApproval__projectSize}>
                You could qualify for<br /><strong>{qualifiedMin}</strong> - <strong>{qualifiedMax}</strong>
              </div>
            ) : (
                <div className={styles.checkApproval__legalAddress}>
                  During our screen of this property we detected some missing data. Is there another address for the same location?
                </div>
              )}
            <Link to={reverse(routes.contractor.projects.project.dataSheet, { contractorUuid: uuid })}>
              <Button type="primary">View data sheet</Button>
            </Link>
          </div>
        </div>
      </CommonSmallBlock>
    );
  }
}

export default withApollo(GoNoGoBlock);
