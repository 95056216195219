import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './styles.scss';

class EdrResultsGrid extends PureComponent {
  static propTypes = {
    eventMatrix: PropTypes.shape().isRequired,
  };

  render() {
    if (!this.props.eventMatrix) {
      return null;
    }

    const {
      eventMatrix: {
        L1,
        L2,
        L3
      }
    } = this.props;

    return (
      <div className={styles.edrResultsTable}>
        <div className={styles.edrResultsTable__grid}>
          <div><strong>Environmental</strong></div>
          <div>Onsite</div>
          <div>Within 30m</div>
          <div>Within 50m</div>

          <div>Level 1 Events</div>
          <div>{L1.W0}</div>
          <div>{L1.W30}</div>
          <div>{L1.W50}</div>

          <div>Level 2 Events</div>
          <div>{L2.W0}</div>
          <div>{L2.W30}</div>
          <div>{L2.W50}</div>

          <div>Level 3 Events</div>
          <div>{L3.W0}</div>
          <div>{L3.W30}</div>
          <div>{L3.W50}</div>

          {/*
            <div><strong>Total</strong></div>
            <div><strong>{L1.W0 + L2.W0 + L3.W0}</strong></div>
            <div><strong>{L1.W30 + L2.W30 + L3.W30}</strong></div>
            <div><strong>{L1.W50 + L2.W50 + L3.W50}</strong></div>
          */}
        </div>
      </div>
    );
  }
}

export default EdrResultsGrid;
