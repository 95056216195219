import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { Component } from 'react';
import { Collapse } from 'antd';
import ListItem from './ListItem';

class ApplicationsExpired extends Component {
  render() {
    const { currentUser, items } = this.props;
    const { lenderPreferences } = currentUser;
    const itemsByMonth = _.groupBy(items, (item) => moment(item.createdAt).format('YYYYMM'));
    const itemMonths = Object.keys(itemsByMonth).sort().reverse();
    return (
      <div>
        {(items.length > 0) ? (
          <Collapse defaultActiveKey={itemMonths[0]}>
            {_.map(itemMonths, (itemMonth) => {
              const sortedItems = _.sortBy(itemsByMonth[itemMonth], 'createdAt');
              const quotedItems = sortedItems.filter((item) => item.receivedOtherQuotes);
              const header = `${moment(`${itemMonth}01`).format('MMMM')} - ${pluralize('offer', quotedItems.length, true)} / ${pluralize('application', sortedItems.length, true)}`;
              return (
                <Collapse.Panel key={itemMonth} header={header}>
                  {sortedItems.map((item) => {
                    return (
                      <ListItem key={item.uuid} item={item} lenderPreferences={lenderPreferences} />
                    );
                  })}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        ) : (
          <div>No results.</div>
        )}
      </div>
    );
  }
}

export default ApplicationsExpired;
