import _ from 'lodash';
import { AutoComplete, Form, Input, Select } from 'antd';
import React, { PureComponent } from 'react';
import styles from './styles.scss';
import { fieldDecoratorRules } from '../../../constants/forms';
import usStates from '../../../constants/states';
import { ZipcodeSelector } from '../../FormElements';

import { PROPERTY_TYPE_OBJECTS } from '$lib/filters';
import { fieldError, formatNumber } from '$lib/formatter';

const { Option } = Select;

class PropertyDetails extends PureComponent {
  filterOption(inputValue, option) {
    return option.props.children === 'everything else'
      || option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  }

  render() {
    const {
      errors,
      getFieldDecorator,
      values,
      usurp,
      className,
    } = this.props;

    let {
      ownerOccupied,
      singleTenant
    } = values;

    const sqft = formatNumber(values.propertyBuildingSize) || formatNumber(_.get(usurp, 'sqft')) || _.get(values, 'project.grossFloorArea');

    if (['yes', 1, '1', true].indexOf(ownerOccupied) !== -1) {
      ownerOccupied = 'Yes';
    } else if (['no', 0, '0', false].indexOf(ownerOccupied) !== -1) {
      ownerOccupied = 'No';
    }

    if (values.project.percentOwnerOccupied) {
      if (values.project.percentOwnerOccupied > 0.5) {
        ownerOccupied = 'Yes';
      } else {
        ownerOccupied = 'No';
      }
    }

    if (['yes', 1, '1', true].indexOf(singleTenant) !== -1) {
      singleTenant = 'Yes';
    } else if (['no', 0, '0', false].indexOf(singleTenant) !== -1) {
      singleTenant = 'No';
    }

    return (
      <div className={className}>
        <Form.Item
          extra="Address"
          label="Property address"
          validateStatus={fieldError('address', errors)}
        >
          {getFieldDecorator('address', {
            rules: fieldDecoratorRules,
            initialValue: values.address,
          })(
            <Input
              data-aaa="address"
              maxLength={100}
              placeholder="1301 Delaware Ave SW"
              type="text"
            />
          )}
        </Form.Item>

        <div className={styles.threeColumn}>
          <Form.Item
            extra="City"
            validateStatus={fieldError('city', errors)}
          >
            {getFieldDecorator('city', {
              rules: fieldDecoratorRules,
              initialValue: values.city,
            })(
              <Input
                data-aaa="city"
                maxLength={100}
                type="text"
              />
            )}
          </Form.Item>
          <Form.Item
            extra="State"
            validateStatus={fieldError('state', errors)}
          >
            {getFieldDecorator('state', {
              rules: fieldDecoratorRules,
              initialValue: values.state,
            })(
              <AutoComplete
                data-aaa="state"
                dataSource={usStates}
                filterOption={this.filterOption}
                maxLength={100}
                placeholder="Type to start searching"
              />
            )}
          </Form.Item>
          <Form.Item
            extra="Zipcode"
            validateStatus={fieldError('postalCode', errors)}
          >
            {getFieldDecorator('postalCode', {
              rules: fieldDecoratorRules,
              initialValue: values.postalCode,
            })(
              <ZipcodeSelector
                data-aaa="postalCode"
                initialValue={values.postalCode}
              />
            )}
          </Form.Item>
        </div>

        <div>
          <Form.Item
            label="Which timeframe most accurately reflects your net operating income?"
            validateStatus={fieldError('propertyPositiveOperatingIncome', errors)}
          >
            {getFieldDecorator('propertyPositiveOperatingIncome', {
              initialValue: values.propertyPositiveOperatingIncome,
            })(
              <Select placeholder="Select One" data-aaa="propertyPositiveOperatingIncome">
                <Option value="Last year">
                  Achieved positive NOI last year
                </Option>
                <Option value="This year">
                  Did not achieve positive NOI last year, but will in the near future
                </Option>
                <Option value="Next year or beyond">
                  Not forecasted to achieve positive annual NOI in next 24 months
                </Option>
              </Select>
            )}
          </Form.Item>
        </div>

        <div className={styles.twoColumn}>
          <Form.Item
            label="Estimated property market value"
            validateStatus={fieldError('propertyMarketValue', errors)}
          >
            {getFieldDecorator('propertyMarketValue', {
              getValueFromEvent: (e) => formatNumber(e.target.value),
              rules: fieldDecoratorRules,
              initialValue: formatNumber(values.propertyMarketValue) || formatNumber(_.get(usurp, 'taxAssessedValue')),
            })(
              <Input
                data-aaa="propertyMarketValue"
                addonBefore="$"
                maxLength={11}
                placeholder="$1,000,000"
                type="text"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Property type"
            validateStatus={fieldError('propertyType', errors)}
          >
            {getFieldDecorator('propertyType', {
              rules: fieldDecoratorRules,
              initialValue: values.propertyType || _.get(usurp, 'propertyType') || _.get(values, 'project.propertyType')
            })(
              <AutoComplete
                data-aaa="propertyType"
                dataSource={PROPERTY_TYPE_OBJECTS}
                filterOption={this.filterOption}
                placeholder="Type to start searching"
              />
            )}
          </Form.Item>
        </div>

        <div className={styles.twoColumn}>
          <Form.Item
            extra="Please enter a value greater than or equal to 0."
            label="Building size"
            validateStatus={fieldError('propertyBuildingSize', errors)}
          >
            {getFieldDecorator('propertyBuildingSize', {
              getValueFromEvent: (e) => formatNumber(e.target.value),
              rules: fieldDecoratorRules,
              initialValue: sqft,
            })(
              <Input
                data-aaa="propertyBuildingSize"
                addonAfter="sqft"
                maxLength={10}
                placeholder="90,000"
                type="text"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Is the property intended to be occupied by the property owner?"
            validateStatus={fieldError('ownerOccupied', errors)}
          >
            {getFieldDecorator('ownerOccupied', {
              initialValue: ownerOccupied,
            })(
              <Select placeholder="Select One" data-aaa="ownerOccupied">
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            )}
          </Form.Item>
        </div>

        <div className={styles.twoColumn}>
          <Form.Item
            label="Is the building intended for either a single tenant or an anchor tenant that occupies more than 50% of the total leasable floor area?"
            validateStatus={fieldError('singleTenant', errors)}
          >
            {getFieldDecorator('singleTenant', {
              initialValue: singleTenant,
            })(
              <Select placeholder="Select One" data-aaa="singleTenant">
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            )}
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default PropertyDetails;
