import React, { Component } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { AuthenticatedRoute, PropsRoute } from '$lib/routes';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import Apply from '$components/Applicant/Apply';
import Offers from '$components/Applicant/Offers';
import OfferAccepted from '$components/Applicant/OfferAccepted';
import NotFound from '$components/NotFound';
import GetConnected from './GetConnected';
import ReviseApplication from './Revise';
import DashboardLayout from './DashboardLayout';
import styles from './styles.scss';

class Applicant extends Component {
  render() {
    const { currentUser, location } = this.props;

    return (
      <DefaultLayout
        location={location}
        currentUser={currentUser}
      >
        <div className={styles.body}>
          <Switch>
            <Redirect exact from={routes.applicant.overview} to={routes.applicant.dashboard} />
            <AuthenticatedRoute exact path={routes.applicant.dashboard} component={DashboardLayout} currentUser={currentUser} />
            <Route exact path={routes.applicant.apply} component={Apply} />
            <PropsRoute exact path={routes.applicant.revise} component={ReviseApplication} currentUser={currentUser} />
            <Route exact path={routes.applicant.offers} component={Offers} />
            <Route exact path={routes.applicant.offerAccepted} component={OfferAccepted} />
            <Route exact path={routes.applicant.getConnected} component={GetConnected} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </DefaultLayout>
    );
  }
}

export default Applicant;
