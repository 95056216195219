import _ from 'lodash';
import Cookies from 'js-cookie';
import appConstants from '../constants/app';

const pctCompleteFields = [
  'applicantPhone',
  'availableDocuments',
  'contactMethod',
  'ownerOccupied',
  'propertyPositiveOperatingIncome',
  'propertyRelationship',
  'projectStage',
  'propertyType',
  'singleTenant',
];

const {
  environmentalScoreThreshold,
  titleScoreThreshold,
  usurpScoreThreshold,
} = appConstants.contractor;

// 20200206JP: This should be moved into the server codebase.
export const isApplicationApproved = ({
  applicationStatus,
  environmentalScore,
  propertyType,
  taxDelinquency,
  titleScore,
  usurpScore,
}) => {
  const environmentalSuccess = environmentalScore >= environmentalScoreThreshold;
  const propertyTypeSuccess = propertyType !== 'residential';
  const statusSuccess = !/^failed/.test(applicationStatus);
  const taxDeliquencySuccess = !taxDelinquency;
  const titleSuccess = titleScore >= titleScoreThreshold;
  const usurpSuccess = usurpScore >= usurpScoreThreshold;

  const approved = (environmentalSuccess
    && propertyTypeSuccess
    && taxDeliquencySuccess
    && statusSuccess
    && titleSuccess
    && usurpSuccess);

  return {
    approved,
    components: {
      environmentalSuccess,
      propertyTypeSuccess,
      taxDeliquencySuccess,
      statusSuccess,
      titleSuccess,
      usurpSuccess,
    },
  };
};

const ARRAY_KEYS_TO_JOIN = ['scopeOfWork', 'availableDocuments'];

const INT_FIELDS = [
  'preferredFinancingTerm',
  'solarSystemSize',
  'solarFirstYearProduction',
];

const FLOAT_FIELDS = [
  'propertyElectricRate',
];

export const serializeApplicationData = ({
  startTime,
  values,
}) => {
  const formattedValues = Object.entries(values).reduce((acc, [key, value]) => {
    let _value = value;
    if (value === 'Yes' || value === 'No') {
      _value = value === 'Yes';
    }
    if (_.includes(ARRAY_KEYS_TO_JOIN, key) && Array.isArray(value)) {
      _value = value.join(',');
    }

    if (_.includes(INT_FIELDS, key)) {
      _value = parseInt(_value.toString().replace(/\D/g, ''), 10) || null;
    } else if (_.includes(FLOAT_FIELDS, key)) {
      _value = parseFloat(_value.toString().replace(/[,\s]/g, '')) || null;
    }

    return {
      ...acc,
      [key]: _value,
    };
  }, {});

  if (formattedValues.availableDocuments) {
    let val = '';

    try {
      val = JSON.parse(formattedValues.availableDocuments);
    } catch (e) {
      val = formattedValues.availableDocuments.split(',');
    }

    formattedValues.availableDocuments = JSON.stringify(val);
  }
  if (formattedValues.scopeOfWork) {
    let val = '';
    try {
      val = JSON.parse(formattedValues.scopeOfWork);
    } catch (e) {
      val = formattedValues.scopeOfWork.split(',');
    }
    formattedValues.scopeOfWork = JSON.stringify(val);
  }
  if (formattedValues.propertyBuildingSize) {
    formattedValues.propertyBuildingSize = parseFloat(formattedValues.propertyBuildingSize.toString().replace(/\D/g, ''));
  }
  if (formattedValues.propertyDebt) {
    formattedValues.propertyDebt = parseFloat(formattedValues.propertyDebt.toString().replace(/\D/g, ''));
  }
  if (formattedValues.projectEstimatedCost) {
    formattedValues.projectEstimatedCost = parseFloat(formattedValues.projectEstimatedCost.toString().replace(/\D/g, ''));
  }
  if (formattedValues.propertyMarketValue) {
    formattedValues.propertyMarketValue = parseFloat(formattedValues.propertyMarketValue.toString().replace(/\D/g, ''));
  }
  if (formattedValues.termsAccepted) {
    formattedValues.termsAccepted = formattedValues.termsAccepted.toString();
  }

  const numFieldsCompleted = pctCompleteFields.reduce((acc, item) => {
    return acc + (typeof values[item] !== 'undefined' && values[item] !== null ? 1 : 0);
  }, 0);
  const applicationPercentComplete = numFieldsCompleted / pctCompleteFields.length;

  const anchor = document.createElement('a');
  anchor.href = document.referrer;
  const utmSource = Cookies.get('utmSource');
  const financingUrgency = utmSource === 'contractor_email' ? '1' : '0';

  formattedValues.applicationPercentComplete = applicationPercentComplete;
  formattedValues.utmSource = utmSource || 'other';
  formattedValues.financingUrgency = financingUrgency;
  formattedValues.websiteTimeMs = new Date().getTime() - startTime.getTime();

  return formattedValues;
};
