import React, { Component } from 'react';
import { Button, Modal, Spin } from 'antd';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import NotFound from '$components/NotFound';

const STAGE = process.env.STAGE; // eslint-disable-line prefer-destructuring

const DEBUG = gql`
  mutation Debug($action: String!) {
    debug(action: $action) {
      _empty
    }
  }
`;

class Debug extends Component {
  onCompleted = () => {
    window.location.href = '/';
  }

  doDebug = (action, debug) => {
    const content = (() => {
      switch (action) {
        case 'reset':
          return 'This will reset all applications and quotes.';
        case 'ff50':
          return 'This will set the expiration of applications to 50 hours sooner.';
        default:
          throw new Error(`Unknown action: ${action}`);
      }
    })();

    Modal.confirm({
      title: 'Are you sure?',
      content,
      onOk: () => {
        debug({ variables: { action } });
      },
    });
  }

  render() {
    if (STAGE !== 'development') {
      return (
        <NotFound />
      );
    }

    return (
      <Mutation mutation={DEBUG} onCompleted={this.onCompleted}>
        {(debug, { loading }) => {
          if (loading) {
            return <Spin size="large" />;
          }

          return (
            <div style={{ padding: '24px' }}>
              <Button onClick={() => this.doDebug('reset', debug)}>Reset</Button>
              <br />
              <br />
              <Button onClick={() => this.doDebug('ff50', debug)}>Expire applications 50 hours earlier</Button>
            </div>
          );
        }}
      </Mutation>
    );
  }
}

export default Debug;
