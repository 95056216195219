export const camelToTitle = (str) => {
  if (!str) {
    return '';
  }
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatNumber = (value, numFunc = parseInt) => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return '';
  }
  const val = value.toString().replace(/,/g, '');
  const num = numFunc(val);

  if (Number.isNaN(num)) {
    return '';
  }

  const parts = num.toString().split('.');
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const fieldError = (field, errors = []) => {
  if (!field || !errors) {
    return '';
  }

  return errors.indexOf(field) === -1 ? '' : 'error';
};
