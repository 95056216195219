import React, { Component } from 'react';

import styles from './styles.scss';

class CommonSmallBlock extends Component {
  render() {
    const {
      name,
      time,
      children
    } = this.props;

    return (
      <div className={styles.layout}>
        <div className={styles.subtitle}>
          <span className={styles.name}>{name}</span>
          <span className={styles.time}>{time}</span>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

export default CommonSmallBlock;
