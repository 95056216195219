import React from 'react';

const ThemeContext = React.createContext({
  slug: null,
  organizationUuid: null,
  logoUrl: null,
  contractorTagline: null,
});

export {
  ThemeContext,
};
