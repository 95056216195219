import classnames from 'classnames';
import React, { Component } from 'react';
import { Radio } from 'antd';

// 20200217JP: This is a class because functions don't support refs,
//  and a ref might be inserted by antd input field decorators.
class TrueFalseButton extends Component {
  render() {
    const {
      className,
      disabled,
      initialValue,
      onChange,
      ...rest
    } = this.props;

    const defaultValue = (typeof initialValue === 'undefined') // eslint-disable-line no-nested-ternary
      ? undefined
      : initialValue
        ? 'true'
        : 'false';

    const antOnChange = (e) => {
      const value = (e.target.value === 'true');
      onChange({ e, value });
    };

    return (
      <div className={classnames(className)} {...rest}>
        <Radio.Group disabled={disabled} onChange={antOnChange} defaultValue={defaultValue} buttonStyle="solid">
          <Radio.Button value="true">Yes</Radio.Button>
          <Radio.Button value="false">No</Radio.Button>
        </Radio.Group>
      </div>
    );
  }
}

export default TrueFalseButton;
