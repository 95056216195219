import React from 'react';
import _ from 'lodash';
import styles from './styles.scss';

const EnvironmentalEvents = (props) => {
  const { data } = props;
  const { property } = data;
  const environmentalEvents = _.get(property, 'environmentalEvents', []);
  const sortedEvents = _.sortBy(environmentalEvents, 'info.distance');

  return (
    <div className={styles.layout}>
      <div>Environmental events log</div>
      <ol>
        {sortedEvents.map((event) => {
          const {
            address,
            edrId,
            info: {
              distance,
              extra,
              type,
            },
          } = event;
          return (
            <li key={edrId} className={styles.event}>
              <div>{address} ({distance}m)</div>
              <div>{type}{extra && <span>&nbsp;- {extra}</span>}</div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default EnvironmentalEvents;
