import numeral from 'numeral';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import { reverse } from 'named-urls';

import styles from './styles.scss';

class ResponsiveProjects extends Component {
  render() {
    const { list, searchEl } = this.props;

    return (
      <div className={styles.layout}>
        <div className={styles.searchWrapper}>
          {searchEl}
        </div>
        <div className={styles.list}>
          {(list.length === 0) && (
            <div className={styles.listItem}>No search results matched.</div>
          )}

          {list.map((listItem) => {
            const {
              approved,
              createdAt,
              uuid,
              property,
              usurp,
            } = listItem;

            const qualifiedAmount = (() => {
              if (!usurp.taxAssessedValue) {
                return null;
              }

              return (
                numeral(usurp.taxAssessedValue * 0.2).format('$0,0')
              );
            })();

            return (
              <div key={uuid} className={styles.listItem}>
                <div className={styles.icon}>
                  <Icon
                    className={approved ? styles.success : styles.error}
                    type={approved ? 'check' : 'warning'}
                  />
                </div>
                <div className={styles.body}>
                  <div className={styles.address}>{property.streetNumber} {property.route}</div>
                  <div>Pre-qualified: {qualifiedAmount || 'unknown'}</div>
                  <div>{moment.unix(createdAt / 1000).format('MMM Do, YYYY')}</div>
                </div>
                <div className={styles.cta}>
                  <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid: uuid })}>
                    <Button>View</Button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ResponsiveProjects;
