import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Root from './Root.jsx';

const RAVEN_DSN = process.env.RAVEN_DSN; // eslint-disable-line prefer-destructuring
const STAGE = process.env.STAGE; // eslint-disable-line prefer-destructuring
const VERSION = process.env.VERSION; // eslint-disable-line prefer-destructuring

if (RAVEN_DSN) {
  Sentry.init({
    dsn: RAVEN_DSN,
    environment: STAGE,
    release: VERSION,
  });
}

ReactDOM.render(<Root />, document.getElementById('root'));
