import _ from 'lodash';
import { Form, Input, Select } from 'antd';
import React from 'react';
import styles from './styles.scss';
import { fieldDecoratorRules } from '../../../constants/forms';
import { fieldError } from '../../../lib/formatter';

const { Option } = Select;

const ApplicantDetails = ({
  currentUser,
  errors,
  getFieldDecorator,
  values,
  className,
}) => {
  const fullName = _.get(currentUser, 'fullName');
  const firstName = fullName ? fullName.split(' ').slice(0, -1).join(' ') : null;
  const lastName = fullName ? fullName.split(' ').slice(-1).join(' ') : null;

  return (
    <div className={className}>
      <div className={styles.twoColumn}>
        <Form.Item
          label="Your relationship to the property"
          validateStatus={fieldError('propertyRelationship', errors)}
        >
          {getFieldDecorator('propertyRelationship', {
            initialValue: values.propertyRelationship,
          })(
            <Select placeholder="Select One" data-aaa="propertyRelationship">
              <Option value="Owner">Owner</Option>
              <Option value="Tenant">Tenant</Option>
              <Option value="Broker or owner’s rep">Broker or owner’s rep</Option>
              <Option value="Contractor">Contractor</Option>
            </Select>
          )}
        </Form.Item>
      </div>

      <div className={styles.twoColumn}>
        <Form.Item
          label="First name"
          validateStatus={fieldError('firstName', errors)}
        >
          {getFieldDecorator('firstName', {
            rules: fieldDecoratorRules,
            initialValue: values.firstName || firstName,
          })(
            <Input
              data-aaa="firstName"
              maxLength={100}
              placeholder="James"
              type="text"
            />
          )}
        </Form.Item>
        <Form.Item
          label="Last name"
          validateStatus={fieldError('lastName', errors)}
        >
          {getFieldDecorator('lastName', {
            rules: fieldDecoratorRules,
            initialValue: values.lastName || lastName,
          })(
            <Input
              data-aaa="lastName"
              maxLength={100}
              placeholder="White"
              type="text"
            />
          )}
        </Form.Item>
      </div>

      <div className={styles.twoColumn}>
        <Form.Item
          label="Title"
          validateStatus={fieldError('nameTitle', errors)}
        >
          {getFieldDecorator('nameTitle', {
            initialValue: values.nameTitle,
          })(
            <Input
              data-aaa="nameTitle"
              maxLength={100}
              placeholder="Mr"
              type="text"
            />
          )}
        </Form.Item>
        <Form.Item
          label="Phone"
          validateStatus={fieldError('applicantPhone', errors)}
        >
          {getFieldDecorator('applicantPhone', {
            initialValue: values.applicantPhone,
          })(
            <Input
              data-aaa="applicantPhone"
              maxLength={100}
              type="tel"
              placeholder="(888) 888-8888"
            />
          )}
        </Form.Item>
      </div>

      <div className={styles.twoColumn}>
        <Form.Item
          label="Email"
          validateStatus={fieldError('applicantEmail', errors)}
        >
          {getFieldDecorator('applicantEmail', {
            rules: fieldDecoratorRules,
            initialValue: values.applicantEmail || _.get(currentUser, 'email'),
          })(
            <Input
              data-aaa='applicantEmail'
              maxLength={100}
              placeholder="james@gmail.com"
              type="email"
            />
          )}
        </Form.Item>
        <Form.Item
          label="Preferred method of contact"
          validateStatus={fieldError('contactMethod', errors)}
        >
          {getFieldDecorator('contactMethod', {
            initialValue: values.contactMethod,
          })(
            <Select placeholder="Select One" data-aaa="contactMethod">
              <Option value="Email">Email</Option>
              <Option value="Phone">Phone</Option>
            </Select>
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default ApplicantDetails;
