import React, { Component } from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Button, Form, Select } from 'antd';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';
import SpinnerError from '$components/common/SpinnerError';
import PropertyOwnerSignUpBanner from '$components/common/PropertyOwnerSignUpBanner';

import ProjectScreenLayout from '../ProjectScreenLayout';
import styles from './styles.scss';

const { Option } = Select;

const REQUEST_WHITE_GLOVE = gql`
  mutation RequestWhiteGlove($input: RequestWhiteGloveInput!) {
    requestWhiteGlove(input: $input) {
      contractorApplication {
        uuid
        whiteGloveRequestedAt
      }
    }
  }
`;

class WhiteGlove extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeOfWorkError: null,
    };
  }

  handleRequestWhiteGlove = ({ contractorApplication, requestWhiteGlove }) => {
    const { form } = this.props;

    form.validateFields(async (err, values) => {
      const { scopeOfWork } = values;
      if (!scopeOfWork || (scopeOfWork.length === 0)) {
        this.setState({
          scopeOfWorkError: 'Please select a scope of work above.',
        });
        return;
      }

      requestWhiteGlove({
        variables: {
          input: {
            contractorApplicationUuid: contractorApplication.uuid,
            scopeOfWork,
          },
        },
      });
    });
  }

  render() {
    const { currentUser, form } = this.props;
    const { getFieldDecorator } = form;
    const { scopeOfWorkError } = this.state;

    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          const { uuid: contractorUuid, application, scopeOfWork: scopeOfWorkJsonString } = contractorApplication;
          const { uuid: applicationUuid } = application;

          const signUpLink = `${routes.auth.signUp}?contractorApplicationUuid=${contractorUuid}&applicationUuid=${applicationUuid}`;

          const scopeOfWork = (() => {
            let result = [];
            try {
              result = JSON.parse(scopeOfWorkJsonString);
            } catch (e) { /* NOP */ }
            return result;
          })();

          return (
            <Mutation mutation={REQUEST_WHITE_GLOVE}>
              {(requestWhiteGlove, { data, error, loading }) => {
                if (error || loading) {
                  return (
                    <div className={styles.invitedLayout}>
                      <SpinnerError error={error} />
                    </div>
                  );
                }

                if (data) {
                  return (
                    <div className={styles.invitedLayout}>
                      <i className="fad fa-check-circle" />
                      <div className={styles.invitedTitle}>White glove service requested</div>
                      <div className={styles.invitedText}>
                        Thank you! One of our team members will be in touch soon.
                      </div>
                      <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid })}>
                        <Button type="primary">Back to dashboard</Button>
                      </Link>
                    </div>
                  );
                }

                return (
                  <div className={styles.layout}>
                    <div className={styles.title}>White glove service</div>
                    <div className={styles.description}>
                      <div>
                        <p>Unety provides property owners with a free white glove service to help ensure your project is a success. The process is simple:</p>

                        <ol>
                          <li>We contact you to learn more about your project</li>
                          <li>We reach out to qualified contractors on your behalf</li>
                          <li>We make introductions when there&apos;s a match</li>
                        </ol>

                        <p>While contractors will not be given any information on the financing of the project, we will need to share the following project information in order to help them determine if the project is a fit and contact you:</p>
                      </div>
                      <ul>
                        <li>Property postal code</li>
                        <li>Estimated project amount</li>
                        <li>Scope of work</li>
                        <li>Your email address</li>
                      </ul>

                      {currentUser ? (
                        <div className={styles.ctaBlock}>
                          <Form.Item label="Scope of work">
                            {getFieldDecorator('scopeOfWork', {
                              rules: [{ required: true, message: 'Required' }],
                              initialValue: (scopeOfWork || []),
                            })(
                              <Select
                                data-aaa="scopeOfWork"
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Click to select"
                              >
                                <Option key="Renewables">Renewables</Option>
                                <Option key="Envelope">Envelope</Option>
                                <Option key="Lighting">Lighting</Option>
                                <Option key="Landscaping">Landscaping</Option>
                                <Option key="Hot Water">Hot Water</Option>
                                <Option key="HVAC">HVAC</Option>
                                <Option key="Water">Water</Option>
                                <Option key="Other">Other</Option>
                              </Select>
                            )}
                          </Form.Item>
                          <div className={styles.scopeOfWorkError}>{scopeOfWorkError}</div>

                          <Button
                            loading={loading}
                            disabled={loading}
                            type="primary"
                            onClick={() => this.handleRequestWhiteGlove({ contractorApplication, requestWhiteGlove })}>
                            Request white glove service
                          </Button>
                        </div>
                      ) : (
                        <PropertyOwnerSignUpBanner
                          signUpLink={signUpLink}
                          iconClasses="fad fa-exclamation-triangle"
                          message="A free account is required for this service"
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default Form.create()(WhiteGlove);
