import React from 'react';
import styles from './styles.scss';

const Well = ({ children }) => {
  return (
    <div className={styles.layout}>{children}</div>
  );
};

export default Well;
