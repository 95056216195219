import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, Table } from 'antd';
import { withApollo } from '@apollo/react-hoc';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';

import ApplicationStatus from './ApplicationStatus';
import styles from './styles.scss';

const reviseStatuses = [
  'applicationStep1',
  'applicationStep2',
  'applicationStep3',
  'applicationStep4',
  'applicationStep5',
  'applicantNotified',
  'failedDataTree',
  'incomplete',
];
const ResponsiveApplications = ({ getButtonInfo, data }) => {
  return (
    <div className={styles.list}>
      {data.map((application) => {
        const { uuid, property, financingType, createdAt } = application;
        const status = application.hasBeenRevised ? 'revised' : application.status;

        return (
          <div key={uuid} className={styles.listItem}>
            <div className={styles.body}>
              <div className={styles.address}>{property.streetNumber} {property.route}</div>
              <div className={styles.status}>{financingType}<ApplicationStatus status={status} /></div>
              <div>{moment(createdAt).format('MMM Do, YYYY')}</div>
            </div>
            <div className={styles.cta}>
              {getButtonInfo(application)}
            </div>
          </div>
        );
      })}
    </div>
  );
};


const getButtonInfo = ({
  hasBeenRevised,
  quotes,
  status,
  uuid,
  viewUuid,
}) => {
  const hasQuotes = Array.isArray(quotes) && quotes.length > 0;
  const isReviseStatus = reviseStatuses.indexOf(status) > -1;
  if ((isReviseStatus && !hasQuotes) && !hasBeenRevised) {
    return (
      <Button type="primary">
        <Link to={reverse(routes.applicant.revise, { applicationUuid: uuid })}>Revise</Link>
      </Button>
    );
  }
  if (status === 'applicantNotified') {
    return (
      <Button>
        <Link to={reverse(routes.applicant.offers, { viewUuid })}>View quotes</Link>
      </Button>
    );
  }

  return null;
};

class Applications extends Component {
  render() {
    const applications = _.sortBy(this.props.applications, 'createdAt').reverse();
    const seenApplications = {};
    applications.forEach((application) => {
      const key = `${application.property.uuid}|${application.financingType}`;
      if (seenApplications[key]) {
        application.hasBeenRevised = true; // eslint-disable-line no-param-reassign
      } else {
        seenApplications[key] = true;
      }
    });

    const columns = [
      {
        title: 'Address',
        key: 'address',
        render: (text, record) => record.property && record.property.formattedAddress,
      },
      {
        title: 'Financing type',
        key: 'financingType',
        render: (text, record) => record.financingType,
      },
      {
        title: 'Status',
        key: 'status',
        render: (text, record) => {
          const status = record.hasBeenRevised ? 'revised' : record.status;
          return (
            <ApplicationStatus status={status} />
          );
        },
      },
      {
        align: 'right',
        title: 'Submitted',
        key: 'submitted',
        render: (text, record) => moment.unix(parseInt(record.createdAt, 10) / 1000).fromNow(),
      },
      {
        align: 'right',
        title: '',
        key: 'actions',
        render: (text, record) => getButtonInfo(record),
      },
    ];

    return (
      <Card title="Your applications">
        <div className={styles.desktop}>
          <Table
            columns={columns}
            dataSource={applications}
            rowKey="uuid"
          />
        </div>
        <div className={styles.mobile}>
          <ResponsiveApplications
            getButtonInfo={getButtonInfo}
            data={applications}
          />
        </div>
      </Card>
    );
  }
}

export default withApollo(Applications);
