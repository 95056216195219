import React, { Component } from 'react';
import { auth, routes } from '@usurp-power/aqua-silver';
import ContractorScreen from '$components/contractor/screens/ContractorScreen';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import PropertySearch from '$components/common/PropertySearch';
import Unauthorized from '$components/common/Unauthorized';

class Scoring extends Component {
  render() {
    const { currentUser, location } = this.props;

    if (!auth.canScore(currentUser)) {
      return <Unauthorized />;
    }

    return (
      <DefaultLayout
        location={location}
        currentUser={currentUser}
      >
        <PropertySearch>
          {({ location: geocodedLocation, parcels, resetMap, setSidebarStatus }) => (
            <ContractorScreen
              geocodedLocation={geocodedLocation}
              parcels={parcels}
              nextPath={routes.scoring.evaluate}
              resetMap={resetMap}
              setSidebarStatus={setSidebarStatus}
            />
          )}
        </PropertySearch>
      </DefaultLayout>
    );
  }
}

export default Scoring;
