import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import styles from './styles.scss';

class Unauthorized extends Component {
  render() {
    return (
      <div className={styles.layout}>
        <p>You don&apos;t have access.</p>
        <p><Link to="/"><Button>Home</Button></Link></p>
      </div>
    );
  }
}

export default Unauthorized;
