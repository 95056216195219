import _isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import numeral from 'numeral';
import { Button } from 'antd';
import { reverse } from 'named-urls';
import { Link, Redirect } from 'react-router-dom';
import { routes } from '@usurp-power/aqua-silver';

import ProjectScreenLayout from '../ProjectScreenLayout';

import styles from './styles.scss';

class EnergySavingsResultsScreen extends Component {
  render() {
    return (
      <ProjectScreenLayout {...this.props}>
        {({ contractorApplication }) => {
          const {
            uuid: contractorUuid,
            energySavings,
          } = contractorApplication;

          if (_isEmpty(energySavings)) {
            return <Redirect to={reverse(routes.contractor.projects.project.energySavings, { contractorUuid })} />;
          }

          const { annualEnergySavings } = energySavings;
          const lifetimeEnergySavings = annualEnergySavings * 15;

          if (lifetimeEnergySavings <= 1000) {
            return (
              <div className={styles.layout}>
                <div className={styles.header}>
                  <div className={styles.title}>Energy savings</div>
                  <Link to={reverse(routes.contractor.projects.project.energySavings, { contractorUuid })}>
                    <i className="fad fa-sliders-v" />
                  </Link>
                </div>
                <div className={styles.content}>
                  <i className="fad fa-exclamation-triangle" />
                  <div>Based on your input, it doesn&apos;t seem like you&apos;ll have significant energy savings.</div>
                  <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid })}>
                    <Button type="primary">Back to dashboard</Button>
                  </Link>
                </div>
              </div>
            );
          }

          return (
            <div className={styles.layout}>
              <div className={styles.header}>
                <div className={styles.title}>Energy savings</div>
                <Link to={reverse(routes.contractor.projects.project.energySavings, { contractorUuid })}>
                  <i className="fad fa-sliders-v" />
                </Link>
              </div>
              <div className={styles.content}>
                <i className="fad fa-check-circle" />
                <div>You could save {numeral(lifetimeEnergySavings).format('$0,0')}!</div>
                <hr />
                <div>That&apos;s {numeral(annualEnergySavings).format('$0,0')} each year with an average equipment lifespan of 15 years.</div>
                <Link to={reverse(routes.contractor.projects.project.overview, { contractorUuid })}>
                  <Button type="primary">Back to dashboard</Button>
                </Link>
              </div>
            </div>
          );
        }}
      </ProjectScreenLayout>
    );
  }
}

export default EnergySavingsResultsScreen;
