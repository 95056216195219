import _ from 'lodash';
import { AutoComplete, Form, Input } from 'antd';
import React, { PureComponent } from 'react';
import styles from './styles.scss';
import { fieldDecoratorRules } from '../../../constants/forms';
import lenders from '../../../constants/lenders';
import { fieldError, formatNumber } from '../../../lib/formatter';

const LENDERS_DATA_SOURCE = _.map(lenders, (obj, key) => {
  return {
    text: obj.name,
    value: key,
  };
});
LENDERS_DATA_SOURCE.unshift({
  text: 'Other / unknown',
  value: 'other',
});

class FinancialDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasDebt: false,
    };

    this.handleDebtChange = this.handleDebtChange.bind(this);
  }

  handleDebtChange(e) {
    const val = parseFloat(e.target.value);
    this.setState({
      hasDebt: val && !Number.isNaN(val),
    });
  }

  render() {
    const {
      errors,
      getFieldDecorator,
      values,
      usurp,
      className,
      contractorApplication,
    } = this.props;

    const {
      hasDebt
    } = this.state;

    const initialPreferredFinancingTerm = values.preferredFinancingTerm
      || _.get(contractorApplication, 'preferredFinancingTerm')
      || _.get(contractorApplication, 'wal')
      || 20;

    return (
      <div className={className}>
        <div className={styles.twoColumn}>
          <Form.Item
            label="Approximate amount of debt on the property"
            validateStatus={fieldError('propertyDebt', errors)}
          >
            {getFieldDecorator('propertyDebt', {
              getValueFromEvent: (e) => formatNumber(e.target.value),
              rules: fieldDecoratorRules,
              initialValue: formatNumber(values.propertyDebt) || formatNumber(_.get(usurp, 'debt')),
            })(
              <Input
                data-aaa="propertyDebt"
                addonBefore="$"
                placeholder="80,000"
                maxLength={10}
                type="text"
                onChange={this.handleDebtChange}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Name of the 1st mortgage lender"
            validateStatus={fieldError('lender', errors)}
          >
            {getFieldDecorator('lender', {
              rules: [{ required: hasDebt, message: 'Required' }],
              initialValue: values.lender,
            })(
              <AutoComplete
                data-aaa="lender"
                dataSource={LENDERS_DATA_SOURCE}
                maxLength={100}
                placeholder="Type to start searching"
                filterOption
              />
            )}
          </Form.Item>
        </div>
        <div className={styles.twoColumn}>
          <Form.Item label="Name any other lenders on the property">
            {getFieldDecorator('propertyOtherLenders', {
              initialValue: values.propertyOtherLenders,
            })(
              <Input
                data-aaa="propertyOtherLenders"
                maxLength={100}
                type="text"
              />
            )}
          </Form.Item>
          <Form.Item label="Preferred financing term (years)">
            {getFieldDecorator('preferredFinancingTerm', {
              initialValue: initialPreferredFinancingTerm,
            })(
              <Input
                data-aaa="preferredFinancingTerm"
                maxLength={100}
                type="text"
              />
            )}
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default FinancialDetails;
