import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'antd';
import DefaultLayout from '$components/common/layout/DefaultLayout';
import styles from './styles.scss';

const GENERATE_REFERRAL_CODE = gql`
  mutation GenerateReferralCode {
    generateReferralCode {
      user {
        uuid
        referralCode
      }
    }
  }
`;

const Invite = ({ currentUser, location }) => {
  const { referralCode } = currentUser;
  const [generateReferralCode, { loading }] = useMutation(GENERATE_REFERRAL_CODE);

  return (
    <DefaultLayout
      location={location}
      currentUser={currentUser}
    >
      <div className={styles.layout}>
        <h1>Invite and earn up to $5,000</h1>
        <p>You will receive a cash reward each time you refer a new contractor to us that results in a project being financed on the Unety platform. Cash rewards will be calculated in $100 increments based on the project cost that is financed, enabling you to receive $100 for every $100,000 in project cost, up to $5,000.</p>
        <div className={styles.codeBlock}>
          {referralCode ? (
            <div className={styles.code}>{currentUser.referralCode}</div>
          ) : (
            <Button type="primary" onClick={generateReferralCode} loading={loading}>Generate invite code</Button>
          )}
        </div>
        <p>Have your colleagues enter your code when they sign up to be eligible for the bonus.</p>
      </div>
    </DefaultLayout>
  );
}

export default Invite;
