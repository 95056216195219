import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import qs from 'qs';
import { routes } from '@usurp-power/aqua-silver';
import Bounce from '$components/auth/Bounce';
import { ThemeContext } from '$lib/context';
import ContratorSignupForm from './ContratorSignupForm';
import LenderSignupForm from './LenderSignupForm';
import ApplicantSignUpForm from './ApplicantSignUpForm';
import unetyLogoSrc from '$assets/logoWithText.png';

// 20200717JP: wtf
import styles from '../styles.scss';
import signUpFormPickerStyles from './signUpFormStyles.scss';
import actualStyles from './styles.scss';

const tabList = [{
  key: 'contractor',
  title: 'Service provider',
  text: 'I want to pre-screen projects for risk and match them to financing',
  icon: 'far fa-tools',
}, {
  key: 'lender',
  title: 'Lender',
  text: 'I provide financing and want to offer rate quotes',
  icon: 'far fa-university',
}, {
  key: 'applicant',
  title: 'Property owner',
  text: 'I own or manage a property and need financing or a contractor for a project',
  icon: 'far fa-building',
}];

const tabListByKey = _.keyBy(tabList, 'key');

const SignUpFormPicker = ({ currentTab, setCurrentTab, organizationUuid, isEcoamerica }) => {
  if (!currentTab) {
    return (
      <div>
        <div>Which best describes you?</div>
        <div>
          {tabList.map((tab) => {
            const { key, title, text, icon } = tab;

            return (
              <div data-aaa={`persona--${key}`} key={key} className={signUpFormPickerStyles.personaTile} onClick={() => setCurrentTab(key)}>
                <div className={signUpFormPickerStyles.personaTileIconWrapper}>
                  <i className={icon} />
                </div>
                <div className={signUpFormPickerStyles.personaTileMain}>
                  <div className={signUpFormPickerStyles.personaTileTitle}>{title}</div>
                  <div className={signUpFormPickerStyles.personaTileText}>{text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const tabInfo = tabListByKey[currentTab];

  const contentList = {
    contractor: <ContratorSignupForm organizationUuid={organizationUuid} />,
    lender: <LenderSignupForm organizationUuid={organizationUuid} />,
    applicant: <ApplicantSignUpForm organizationUuid={organizationUuid} />,
  };

  return (
    <div>
      <div className={signUpFormPickerStyles.subnav}>
        <div className={signUpFormPickerStyles.subnavTitle}>{tabInfo.title}</div>
        {!isEcoamerica && <a className={signUpFormPickerStyles.subnavBacklink} onClick={() => setCurrentTab(null)}>Back to user types</a>}
      </div>
      {contentList[currentTab]}
    </div>
  );
};

class SignUp extends Component {
  constructor(props) {
    super(props);

    const { location } = props;
    const { search } = location;

    const query = search.substring(1);
    const parsed = qs.parse(query);
    const { type } = parsed;

    const currentTab = type;

    this.state = {
      currentTab,
    };
  }

  handleTabChange = (currentTab) => {
    this.setState({
      currentTab
    });
  }

  render() {
    const { currentUser } = this.props;
    const { currentTab } = this.state;

    if (currentUser) {
      return (
        <Bounce currentUser={currentUser} />
      );
    }

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug, organizationUuid } = theme;

          const isEcoamerica = (slug === 'ecoamerica');
          const logoSrc = isEcoamerica ? 'https://storage.googleapis.com/public-assets.usurppower.com/theme/ecoamerica.png' : unetyLogoSrc;
          const title = isEcoamerica ? 'Unety program account setup' : 'Account creation';

          return (
            <Card
              actions={[
                <Link key="forgot" to={routes.auth.forgotPassword}>Forgot password</Link>,
                <Link key="signin" to={routes.auth.signIn}>Sign in</Link>,
              ]}
              className={actualStyles.card}
              title={(
                <div>
                  <img className={styles.logo} src={logoSrc} />
                  <div>{title}</div>
                </div>
              )}
            >
              <SignUpFormPicker
                currentTab={isEcoamerica ? 'applicant' : currentTab}
                organizationUuid={organizationUuid}
                setCurrentTab={this.handleTabChange}
                isEcoamerica={isEcoamerica}
              />
            </Card>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default SignUp;
