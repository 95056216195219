export default {
  applicationExpiryHours: 48,

  contractor: {
    creditScoreThreshold: 0.35,
    environmentalScoreThreshold: 0.6,
    lenderConsentScoreThreshold: 0.2,
    titleScoreThreshold: 0.35,
    usurpScoreThreshold: 0.35,
  }
};
