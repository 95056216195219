import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { Button } from 'antd';
import { reverse } from 'named-urls';
import { routes } from '@usurp-power/aqua-silver';
import { Link } from 'react-router-dom';
import CommonSmallBlock from '../CommonSmallBlock';

import sharedStyles from '../CommonSmallBlock/shared.scss';
import styles from './styles.scss';

const NAME = 'Find contractors';
const TIME = '3 min';

class FindContractorsBlock extends Component {
  render() {
    const { contractorApplication, currentUser } = this.props;
    const {
      uuid: contractorUuid,
      nearbyContractors,
      contractorProjectInvitations,
      whiteGloveRequestedAt,
    } = contractorApplication;

    if (_get(currentUser, 'type') === 'contractor') {
      return null;
    }

    // if (whiteGloveRequestedAt) {
    //   return (
    //     <CommonSmallBlock name={NAME}>
    //       <div className={sharedStyles.layout}>
    //         <div className={sharedStyles.title}>White glove service</div>
    //         <div className={sharedStyles.description}>
    //           Thank you for requesting our white glove service to match the best local contractors with your project.<br /><br />
    //           Our team will be in touch within a few days.<br />
    //         </div>
    //       </div>
    //     </CommonSmallBlock>
    //   );
    // }

    if (nearbyContractors.length < 5) {
      return (
        <CommonSmallBlock name={NAME} time={TIME}>
          <div className={sharedStyles.layout}>
            <div className={sharedStyles.title}>No contractors in your area</div>
            <div className={sharedStyles.description}>
              We do not currently have any pre-approved contractors in your area. We are always adding new contractors, so check back soon. In the meantime, you may be able to find what you need from <a href="https://CleanEnergyConnection.org" target="_blank" rel="noopener noreferrer">CleanEnergyConnection.org</a>
            </div>
          </div>
        </CommonSmallBlock>
      );
    }

    // if (_isEmpty(contractorProjectInvitations)) {
      return (
        <CommonSmallBlock name={NAME} time={TIME}>
          <div className={sharedStyles.layout}>
            <div className={sharedStyles.title}>Connect with contractors</div>
            <div className={sharedStyles.description}>
              Our contractors are pre-screened for your safety. See what local contractors are in your area and choose a few to get in touch.
            </div>
            <Link to={reverse(routes.contractor.projects.project.findContractors, { contractorUuid })}>
              <Button type="primary">View contractors in your area</Button>
            </Link>
          </div>
        </CommonSmallBlock>
      );
    // }

    // return (
    //   <CommonSmallBlock name={NAME}>
    //     <div className={sharedStyles.layout}>
    //       <div className={sharedStyles.title}>Contractors invited</div>
    //       <div className={styles.contractors}>
    //         {contractorProjectInvitations.map((contractorProjectInvitation) => {
    //           return (
    //             <div className={styles.contractor} key={contractorProjectInvitation.uuid}>
    //               <div className={styles.contractorName}>{contractorProjectInvitation.contractor.company}</div>
    //               <div className={styles.contractorStatus}>{contractorProjectInvitation.status}</div>
    //             </div>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   </CommonSmallBlock>
    // );
  }
}

export default FindContractorsBlock;
