import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Icon } from 'antd';

const getIconInfo = (status) => {
  switch (status) {
    case 'revised': {
      return {
        message: 'Revised',
      };
    }
    case 'applicantNotified':
    case 'failedAltv':
    case 'failedResidential':
    case 'failedDataTree':
    case 'incomplete':
      return {
        icon: 'warning',
        message: 'Needs attention',
        iconProps: {
          theme: 'twoTone',
          twoToneColor: '#cc0000',
        }
      };
    case 'accepted':
      return {
        icon: 'check',
        message: 'Accepted',
      };
    case 'pendingData':
    case 'active':
    case 'augmented':
    case 'lenderNotified':
      return {
        icon: 'sync',
        message: 'In progress',
      };
    case 'expired':
      return {
        icon: 'issues-close',
        message: 'Expired',
      };
    case 'applicationStep2':
    case 'applicationStep3':
    case 'applicationStep4':
    case 'applicationStep5':
      return {
        icon: 'line',
        message: 'Application not complete',
      };
    default:
      return {
        icon: 'line',
        message: 'Status not found',
      };
  }
};

class ApplicationStatus extends PureComponent {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    const { status } = this.props;

    const {
      icon,
      message,
      iconProps = {},
    } = getIconInfo(status);

    return (
      <div>
        <Icon
          style={{ marginRight: 5 }}
          title={message}
          type={icon}
          {...iconProps}
        />
        {message}
      </div>
    );
  }
}

export default ApplicationStatus;
