import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Checkbox, Icon, List } from 'antd';

import styles from './styles.scss';

class ParcelSelect extends PureComponent {
  static propTypes = {
    parcels: PropTypes.arrayOf(PropTypes.shape),
    selectedParcels: PropTypes.arrayOf(PropTypes.shape),
    onParcelsSelect: PropTypes.func,
    onParcelOut: PropTypes.func,
    onParcelOver: PropTypes.func,
  };

  static defaultProps = {
    parcels: [],
    selectedParcels: [],
    onParcelsSelect: () => {},
    onParcelsSelectChange: () => {},
    onParcelOut: () => {},
    onParcelOver: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedParcels: [],
    };

    this.handleParcelListChange = this.handleParcelListChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ selectedParcels }) { // eslint-disable-line camelcase
    if (selectedParcels) {
      this.setState(() => ({
        selectedParcels
      }));
    }
  }

  handleParcelListChange(index, apn, evt) {
    const isChecked = evt.target.checked;
    const selectedParcels = isChecked
      ? [...this.state.selectedParcels, ...this.props.parcels.filter((p) => p.apn === apn)]
      : this.state.selectedParcels.filter((p) => p.apn !== apn);
    this.props.onParcelsSelectChange(selectedParcels);
  }

  render() {
    const {
      parcels,
      onParcelsSelect,
      onParcelOut,
      onParcelOver,
      resetMap,
    } = this.props;

    const {
      selectedParcels
    } = this.state;

    let _parcels = parcels.reduce((acc, parcel) => {
      if (acc[parcel.street]) {
        return acc;
      }
      return {
        ...acc,
        [parcel.street]: parcel
      };
    }, {});

    _parcels = Object.values(_parcels);

    return (
      <div className={styles.addressSelect}>
        <div className={styles.addressSelect__content}>
          <div className={styles.addressSelect__title}>
            We found multiple matches for the address entered
            <span>We were not able to find an exact match for the address that you entered</span>
          </div>
          <div className={styles.addressSelect__list}>
            <List
              bordered
              dataSource={_parcels}
              header={<div>Select all that apply</div>}
              renderItem={(parcel, i) => {
                return (
                  <List.Item>
                    <div
                      className={styles.addressSelect__list__item}
                      onMouseOut={onParcelOut.bind(this, parcel, i)}
                      onMouseOver={onParcelOver.bind(this, parcel, i)}
                    >
                      <Checkbox
                        checked={Boolean(selectedParcels.find((p) => p.apn === parcel.apn))}
                        onChange={this.handleParcelListChange.bind(this, i, parcel.apn)}
                      >
                        {parcel.formattedAddress}
                      </Checkbox>
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
        <div className={styles.addressSelect__actions}>
          <Button onClick={resetMap}>
            <Icon type='reload' />
            Change address
          </Button>
          <Button
            data-aaa="selectParcels"
            disabled={this.state.selectedParcels.length === 0}
            type="primary"
            onClick={onParcelsSelect.bind(this, this.state.selectedParcels)}
          >
            Select
            <Icon type='right' />
          </Button>
        </div>
      </div>
    );
  }
}

export default ParcelSelect;
