import React, { Component } from 'react';
import { ApolloConsumer } from '@apollo/react-hooks';
import { Query } from '@apollo/react-components';
import { Button, Icon, Modal, PageHeader } from 'antd';
import gql from 'graphql-tag';
import LenderPreferences from '$components/LenderPreferences';
import CURRENT_USER from '$queries/currentUser';

const EXPORT_APPLICATIONS = gql`
  query ExportApplications {
    exportApplications {
      exportUrl
    }
  }
`;

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportLoading: false,
    };
  }

  handleExport = async ({ client }) => {
    this.setState(() => ({
      exportLoading: true,
    }));
    const { data } = await client.query({
      query: EXPORT_APPLICATIONS,
    });
    const { exportApplications: { exportUrl } } = data;
    Modal.info({
      title: 'Export completed',
      content: (
        <div>
          <p>Your export has been completed. The link below will expire after one hour, so please save the file.</p>
          <a href={exportUrl} target="_blank" rel="noopener noreferrer">(download here)</a>
        </div>
      ),
    });
    this.setState(() => ({
      exportLoading: false,
    }));
  };

  render() {
    const {
      exportLoading
    } = this.state;
    return (
      <div>
        <PageHeader
          title="Profile"
          onBack={() => window.history.back()}
          extra={[
            <ApolloConsumer key={1}>
              {client => (
                <Button
                  disabled={exportLoading}
                  loading={exportLoading}
                  size="small"
                  type="primary"
                  onClick={() => this.handleExport({ client })}
                >
                  <Icon type="export" />
                  Export accepted applications
                </Button>
              )}
            </ApolloConsumer>
          ]}
        />
        <Query query={CURRENT_USER}>
          {({ _loading, _error, data }) => {
            const { currentUser } = data;
            const lenderPreferences = (() => {
              try {
                return JSON.parse(currentUser.lenderPreferences) || {};
              } catch (e) { /* NOP */ }
              return {};
            })();

            return (
              <div style={{ padding: '24px' }}>
                <LenderPreferences lenderPreferences={lenderPreferences} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Profile;
