import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const ratingExplanation = {
  AAA: 'An obligation rated \'AAA\' has the highest rating assigned by S&P Global Ratings. The obligor\'s capacity to meet its financial commitments on the obligation is extremely strong.',
  AA: 'An obligation rated \'AA\' differs from the highest-rated obligations only to a small degree. The obligor\'s capacity to meet its financial commitments on the obligation is very strong.',
  A: 'An obligation rated \'A\' is somewhat more susceptible to the adverse effects of changes in circumstances and economic conditions than obligations in higher-rated categories. However, the obligor\'s capacity to meet its financial commitments on the obligation is still strong.',
  BBB: 'An obligation rated \'BBB\' exhibits adequate protection parameters. However, adverse economic conditions or changing circumstances are more likely to weaken the obligor\'s capacity to meet its financial commitments on the obligation.',
  // BB: 'Obligations rated \'BB\', \'B\', \'CCC\', \'CC\', and \'C\' are regarded as having significant speculative characteristics. \'BB\' indicates the least degree of speculation and \'C\' the highest. While such obligations will likely have some quality and protective characteristics, these may be outweighed by large uncertainties or major exposure to adverse conditions.',
  BB: 'An obligation rated \'BB\' is less vulnerable to nonpayment than other speculative issues. However, it faces major ongoing uncertainties or exposure to adverse business, financial, or economic conditions that could lead to the obligor\'s inadequate capacity to meet its financial commitments on the obligation.',
  B: 'An obligation rated \'B\' is more vulnerable to nonpayment than obligations rated \'BB\', but the obligor currently has the capacity to meet its financial commitments on the obligation. Adverse business, financial, or economic conditions will likely impair the obligor\'s capacity or willingness to meet its financial commitments on the obligation.',
  CCC: 'An obligation rated \'CCC\' is currently vulnerable to nonpayment and is dependent upon favorable business, financial, and economic conditions for the obligor to meet its financial commitments on the obligation. In the event of adverse business, financial, or economic conditions, the obligor is not likely to have the capacity to meet its financial commitments on the obligation.',
  CC: 'An obligation rated \'CC\' is currently highly vulnerable to nonpayment. The \'CC\' rating is used when a default has not yet occurred but S&P Global Ratings expects default to be a virtual certainty, regardless of the anticipated time to default.',
  C: 'An obligation rated \'C\' is currently highly vulnerable to nonpayment, and the obligation is expected to have lower relative seniority or lower ultimate recovery compared with obligations that are rated higher.',
  D: 'An obligation rated \'D\' is in default or in breach of an imputed promise. For non-hybrid capital instruments, the \'D\' rating category is used when payments on an obligation are not made on the date due, unless S&P Global Ratings believes that such payments will be made within five business days in the absence of a stated grace period or within the earlier of the stated grace period or 30 calendar days. The \'D\' rating also will be used upon the filing of a bankruptcy petition or the taking of similar action and where default on an obligation is a virtual certainty, for example due to automatic stay provisions. A rating on an obligation is lowered to \'D\' if it is subject to a distressed exchange offer.',
};

class UsurpRatingDescription extends PureComponent {
  static propTypes = {
    usurpRating: PropTypes.string.isRequired,
  };

  render() {
    const {
      usurpRating
    } = this.props;

    if (!ratingExplanation[usurpRating]) {
      return null;
    }

    return (
      <p>{ratingExplanation[usurpRating]}</p>
    );
  }
}

export default UsurpRatingDescription;
