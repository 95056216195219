import classNames from 'classnames';
import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import numeral from 'numeral';

import styles from './styles.scss';

const getNumFromEvent = (e) => {
  if (!e || !e.target) {
    return e;
  }
  const { target } = e;


  // const value =
  // if (Number.isNaN(value)) {
  //   return null;
  // }

  return numeral(target.value).format('0,0');
};

class UnwrappedForm extends Component {
  state = {
    error: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          percentLeasedFsg,
          percentLeasedMg,
          percentLeasedNnn,
        } = Object.entries(values).reduce((acc, [key, val]) => {
          acc[key] = parseFloat(val.toString().replace(/\D/g, ''));
          return acc;
        }, {});

        const hasLeaseTypeError = percentLeasedFsg + percentLeasedMg + percentLeasedNnn > 100;

        if (hasLeaseTypeError) {
          this.setState({
            error: true
          });
          return;
        }

        this.setState({
          error: false
        });
        this.props.handleSubmit({ values });
      }
    });
  };

  render() {
    const { form, initialValues, loading } = this.props;
    const { getFieldDecorator } = form;
    const {
      error
    } = this.state;

    const {
      percentLeased,
      percentLeasedFsg,
      percentLeasedMg,
      percentLeasedNnn,
      percentOwnerOccupied,
      percentUnoccupied,
      taxEscalationModifiedGrossLease,
    } = initialValues;

    const errorClasses = classNames(styles.actions__error, {
      [styles.actions__error__showing]: error
    });

    return (
      <div className={styles.formContainer}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Percent owner occupied">
            {getFieldDecorator('percentOwnerOccupied', {
              initialValue: percentOwnerOccupied,
              rules: [{ required: true }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Percent owner occupied" addonAfter="%" />
            )}
          </Form.Item>
          <Form.Item label="Percent leased">
            {getFieldDecorator('percentLeased', {
              initialValue: percentLeased,
              rules: [{ required: true }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Percent leased" addonAfter="%" />
            )}
          </Form.Item>
          <Form.Item label="Percent unoccupied">
            {getFieldDecorator('percentUnoccupied', {
              initialValue: percentUnoccupied,
              rules: [{ required: true }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Percent unoccupied" addonAfter="%" />
            )}
          </Form.Item>
          <Form.Item label="Percent of leased area under triple net lease">
            {getFieldDecorator('percentLeasedNnn', {
              initialValue: percentLeasedNnn,
              rules: [{ required: true }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Percent triple net" addonAfter="%" />
            )}
          </Form.Item>
          <Form.Item label="Percent of leased area under modified gross lease">
            {getFieldDecorator('percentLeasedMg', {
              initialValue: percentLeasedMg,
              rules: [{ required: true }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Percent modified gross" addonAfter="%" />
            )}
          </Form.Item>
          <Form.Item label="Percent of leased area under full service gross lease">
            {getFieldDecorator('percentLeasedFsg', {
              initialValue: percentLeasedFsg,
              rules: [{ required: true }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Percent full service gross" addonAfter="%" />
            )}
          </Form.Item>
          <Form.Item label="Property tax escalation cap (applies to modified gross leases only)">
            {getFieldDecorator('taxEscalationModifiedGrossLease', {
              initialValue: taxEscalationModifiedGrossLease || 0,
              rules: [{ required: true }, {
                message: 'Enter a number, max 20%',
                validator: (rule, value, cb) => {
                  try {
                    const val = parseInt(value, 10);
                    if (val > 20) {
                      cb(val);
                      return;
                    }
                  } catch {
                    cb('Enter a number');
                    return;
                  }
                  cb();
                },
              }],
              getValueFromEvent: getNumFromEvent,
            })(
              <Input placeholder="Property tax escalation cap" addonAfter="%" />
            )}
          </Form.Item>
          <div className={styles.actions}>
            <div className={errorClasses}>
              Triple Net, Full Service and Modified Gross lease need to add up to 100
            </div>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" loading={loading}>Update</Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create()(UnwrappedForm);
